import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { RoleDto } from "../../contracts/models/Roles/RoleDto";
import { PermissionType } from "../../contracts/enums/PermissionType";
import Select from "react-select";
import makeAnimated from "react-select/animated";

// Define a mapping object for friendly names
const permissionFriendlyNames = {
  [PermissionType.CreateEditAssets]: "Create and Edit Assets",
  [PermissionType.CreateEditJobs]: "Create and Edit Jobs",
  [PermissionType.Exporting]: "Exports",
  [PermissionType.ManageDrivers]: "Manage Drivers",
  [PermissionType.ManageExpenses]: "Manage Expenses",
  [PermissionType.ViewAssests]: "View Assets",
  [PermissionType.ViewDrivers]: "View Drivers",
  [PermissionType.ViewExpenses]: "View Expenses",
  [PermissionType.ViewJobs]: "View Jobs",
};

const animatedComponents = makeAnimated();

const permissionOptions = Object.keys(PermissionType)
  .filter((key) => isNaN(Number(key))) // Filter out the reverse mappings
  .map((key) => ({
    value: PermissionType[key as keyof typeof PermissionType],
    label:
      permissionFriendlyNames[
        PermissionType[key as keyof typeof PermissionType]
      ],
  }));

const RoleForm = ({ role, onSubmit }: { role?: RoleDto; onSubmit: any }) => {
  const initialValues: RoleDto = {
    id: role?.id || 0,
    roleName: role?.roleName || "",
    permissions: role?.permissions || [],
  };

  const validationSchema = Yup.object().shape({
    roleName: Yup.string().required("Required"),
    permissions: Yup.array()
      .of(Yup.number())
      .min(1, "At least one permission is required")
      .required("At least one permission is required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, formikHelpers) => {
        await onSubmit(values).then(() => {
          if(!role){
            formikHelpers.resetForm({ values: initialValues });
          }
        });
      }}
      enableReinitialize={true}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="name">
              Name<span className="required">*</span>
            </label>
            <Field name="roleName" className="form-control" />
            {errors.roleName && touched.roleName ? (
              <div className="alert alert-danger" role="alert">
                {errors.roleName}
              </div>
            ) : null}
          </div>
          <div className="form-group">
            <label>
              Permissions<span className="required">*</span>
            </label>
            {errors.permissions && touched.permissions ? (
              <div className="alert alert-danger" role="alert">
                {errors.permissions}
              </div>
            ) : null}
            <div className="row d-flex flex-wrap ml-1">
              <Select
                name="permissions"
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={initialValues.permissions.map((permission) => ({
                  value: permission,
                  label: permissionFriendlyNames[permission],
                }))}
                isMulti
                options={permissionOptions}
                onChange={(selectedOptions) => {
                  const values = selectedOptions.map((option) => option.value);
                  setFieldValue("permissions", values);
                }}
                value={values.permissions.map((permission) => ({
                  value: permission,
                  label: permissionFriendlyNames[permission],
                }))}
              />
            </div>
          </div>
          <div className="form-group mt-5">
            <button type="submit" className="btn btn-primary">
              {role ? "Update" : "Create"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RoleForm;
