import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Addresses from "./components/address/addresses.component";
import Login from "./components/auth/login.component";
import Logout from "./components/auth/logout.component";
import Profile from "./components/auth/profile.component";
import Contacts from "./components/contacts/contacts.component";
import Customers from "./components/customers/customers.component";
import Drivers from "./components/drivers/drivers.component";
import Header from "./components/header/header.component";
import Home from "./components/home/home.component";
import Privacy from "./components/home/privacy.component";
import Terms from "./components/home/terms.component";
import AddJob from "./components/jobs/add-job.component";
import AddSwing from "./components/jobs/add-job-group.component";
import Jobs from "./components/jobs/jobs.component";
import ManageVehicles from "./components/vehicles/manage-vehicles.component";
import ExpenseList from "./components/expenses/expense-list.component";
import { userContext } from "./context/userContext";
import PrivateRoute from "./PrivateRoute";
import * as Sentry from "@sentry/react";
import ComplianceAlertList from "./components/compliance/compliance-alert-list.component";
import QuestionnaireList from "./components/compliance/questionnaire-list.component";
import ViewClassUpdates from "./components/drivers/view-class-updates";
import JobRemovals from "./components/jobs/job-removals.component";
import OvertimeApproval from "./components/jobs/overtime-approval.component";
import Reporting from "./components/reporting/reporting.component";
import DriverHours from "./components/drivers/driver-hours.component";
import Dashboard from "./components/dashboard/dashboard.component";
import ListJobRequest from "./components/customer-requests/list-job-requests";
import { jwtDecode, JwtPayload, JwtHeader } from "jwt-decode";
import AddNewJobRequestForm from "./components/customer-requests/add-new-job-request";
import CustomerUsersPage, {
  AllCustomerUsersPage,
} from "./components/customers/customer-users";
import AdminListJobRequest from "./components/customer-requests-management/admin-list-customer-job-requests";
import Admin from "./components/admin/admin-component";
import Layout from "./components/shared/layout-component";
import { PermissionType } from "./contracts/enums/PermissionType";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import FleetBreakdownPage from "./components/jobs/fleet-breakdown.component";
import {Buffer} from 'buffer';
import PendingMileageList from "./components/jobs/pending-mileage";
window.Buffer = Buffer;

export interface UserClaimType extends JwtPayload {
  sub: string;
  role: string;
  permissions: PermissionType[];
}

function App(props: any) {
  // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const existingTokens = JSON.parse(localStorage.getItem("token"));
  const [authToken, setAuthToken] = useState(existingTokens);
  const [userType, setUserType] = useState("" as string | null);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  useEffect(() => {
    try {
      if (authToken) {
        const decodedHeader = jwtDecode<UserClaimType>(authToken);
        if (decodedHeader.role) setUserType(decodedHeader.role);
        else setUserType("Administrator");
      }
    } catch (e) {
      console.error(e);
      setAuthToken(null);
      setUserType(null);
    } finally {
      //redirect to home
      // window.location.href = "/";
    }
  }, [authToken]);

  const setToken = (token: any) => {
    localStorage.setItem("token", JSON.stringify(token));
    setAuthToken(token);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <userContext.Provider
        value={{
          authToken,
          setAuthToken: setToken,
          userType,
          setUserType,
        }}
      >
        <Router>
          <Layout {...props}>
            <Header></Header>
            <div className="content container-fluid d-flex justify-content-center mt-3">
              <div className="col col-lg-10">
                <Switch>
                  {!authToken && (
                    <Route exact path={["/", "/home"]} component={Home} />
                  )}

                  {authToken && userType == "Customer" && (
                    <>
                      <Route
                        exact
                        path={["/", "/home"]}
                        component={ListJobRequest}
                      />

                      <PrivateRoute
                        path="/request/create"
                        component={AddNewJobRequestForm}
                      />
                    </>
                  )}

                  {authToken && userType == "Administrator" && (
                    <>
                      <PrivateRoute path="/admin" component={Admin} />
                      <PrivateRoute
                        exact
                        path={["/", "/home"]}
                        component={Dashboard}
                      />
                      <PrivateRoute path="/swing/add" component={AddSwing} />
                      <PrivateRoute path="/job/add" component={AddJob} />
                      <PrivateRoute path="/swing/view/:id" component={Jobs} />
                      <PrivateRoute path="/customers" component={Customers} />
                      <PrivateRoute
                        path="/customer/users/:id"
                        component={CustomerUsersPage}
                      />
                      <PrivateRoute
                        path="/customer/requests"
                        component={AdminListJobRequest}
                      />
                      <PrivateRoute
                        path="/vehicles"
                        component={ManageVehicles}
                      />
                      <PrivateRoute
                        path="/fleet/breakdown"
                        component={FleetBreakdownPage}
                      />
                      <PrivateRoute path="/drivers" component={Drivers} />
                      <PrivateRoute
                        path="/driver/hours"
                        component={DriverHours}
                      />
                      <PrivateRoute
                        path="/classupdates"
                        component={ViewClassUpdates}
                      />
                      <PrivateRoute path="/address" component={Addresses} />
                      <PrivateRoute
                        path="/job/removals"
                        component={JobRemovals}
                      />
                      <PrivateRoute path="/contacts" component={Contacts} />
                      <PrivateRoute path="/expenses" component={ExpenseList} />
                      <PrivateRoute path="/reporting" component={Reporting} />
                      <PrivateRoute path="/overtime" component={OvertimeApproval} />
                      <PrivateRoute path="/mileage" component={PendingMileageList} />
                      <PrivateRoute
                        path="/compliance/alerts"
                        component={ComplianceAlertList}
                      />
                      <PrivateRoute
                        path="/compliance/submissions"
                        component={QuestionnaireList}
                      />

                      <PrivateRoute
                        path="/users/customers"
                        component={AllCustomerUsersPage}
                      />
                    </>
                  )}

                  {authToken && (
                    <>
                      <PrivateRoute path="/profile" component={Profile} />
                      <PrivateRoute path="/logout" component={Logout} />
                    </>
                  )}

                  <Route exact path="/login" component={Login} />
                  <Route path="/privacy" component={Privacy} />
                  <Route path="/terms" component={Terms} />

                  {/* <Route exact path="/register" component={Register} /> */}

                  {/* <Route path="/user" component={BoardUser} />
                <Route path="/mod" component={BoardModerator} />
              <Route path="/admin" component={BoardAdmin} /> */}
                </Switch>
              </div>
            </div>
            <footer className="footer">
              <div className="container mb-3">
                <hr />
                <div className="row">
                  <div className="col left">
                    <img className="logo" src="/images/logo.png" alt="Logo" />
                  </div>
                  <div className="col"></div>
                  <div className="col right">
                    <a href="https://directsoftwaregroup.com">
                      © Direct Software Group Ltd
                    </a>
                  </div>
                </div>
              </div>
            </footer>
          </Layout>
        </Router>
      </userContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default Sentry.withProfiler(App);
