import React from "react";
import { useQuery } from "@tanstack/react-query";
import DefaultModal from "../modal/modal.component";
import Moment from "react-moment";
import LoadingSpinner from "../loading/loading-spinner.component";
import customerManagementService from "../../services/customer-management.service";
import { CustomerRequestDetails } from "../customer-requests/view-customer-request.component";
import { useJobRequestById } from "../../hooks/useJobRequestById";
import { useAdminJobRequestById } from "../../hooks/useAdminJobRequestById";

export default function AdminViewCustomerRequestModal({ id }: { id: number }) {

  const { data: request, isLoading, isError, error } = useAdminJobRequestById (id);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return (
      <div>
        Error: {error instanceof Error ? error.message : "Unknown error"}
      </div>
    );
  }

  return (
    <DefaultModal
      title={"View Customer Request"}
      trigger={"View Customer Request"}
      content={
        request ? (
          <>
            <div className="view-customer-request-container">
              <CustomerRequestDetails request={request} />
            </div>
          </>
        )
        :
        <></>
      }
    />
  );
}

export function AdminViewCustomerRequestDetails({ id }: { id: number }) {

  const { data: request, isLoading, isError, error } = useAdminJobRequestById (id);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || !request) {
    return (
      <div>
        Error: {error instanceof Error ? error.message : "Unknown error"}
      </div>
    );
  }

  return (
            <div className="view-customer-request-container my-2 mb-4">
              <CustomerRequestDetails request={request} />
            </div>
  );
}