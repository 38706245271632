import { useState, useCallback } from "react";
import VehicleService from "../services/vehicle.service";
import { VehicleDto } from "../contracts/models/VehicleDto";
import { VehicleQuery } from "../contracts/models/Queries/VehicleQuery";

const useVehicleManager = () => {
  const [vehicles, setVehicles] = useState<VehicleDto[]>([]);

  const fetchVehicles = useCallback(async (query: VehicleQuery) => {
    try {
      const fetchedVehicles = await VehicleService.getVehicles(query);
      setVehicles(fetchedVehicles);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  }, []);

  const updateArchivedStatus = useCallback(
    async (vehicleId: number, archiveStatus: boolean) => {
      try {
        const result = await VehicleService.updateArchivedStatus(vehicleId, archiveStatus);
        if (result) {
          setVehicles((prevVehicles) =>
            prevVehicles.map((v) =>
              v.id === vehicleId ? { ...v, archived: archiveStatus } : v
            )
          );
        }
      } catch (error) {
        console.error("Error archiving vehicle:", error);
      }
    },
    []
  );

  // Additional functions based on VehicleService can be added here

  return { vehicles, fetchVehicles, updateArchivedStatus };
};

export default useVehicleManager;
