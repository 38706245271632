import ExpensesService from "../services/expenses.service";
import { useQuery } from "@tanstack/react-query";

export function useExpenses(filters: any) {
  return useQuery({
    queryKey: ["expenses", filters],
    queryFn: async () => {
      console.log(filters);
      const response = await ExpensesService.getExpenses(
        filters.from.value,
        filters.to.value,
        filters.searchTerm.value,
        filters.status.value,
        filters.customer.value
      );
      console.log(response.data);
      return response.data;
    },
    refetchInterval: 60000,
  });
}
