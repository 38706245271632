import React, { Component } from "react";
// import {userContext} from '../../context/userContext';
export default class Profile extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <h2>Profile</h2>
      //   {user => (
      //   <div className="offset-md-1 col-md-10">
      //       <header className="jumbotron">
      //         <h3>
      //           <strong>{user.fullname}</strong> Profile - {user.email}
      //         </h3>
      //       </header>
      //       <p>
      //         <strong>Token:</strong>{" "}
      //         {user.token}
      //       </p>
      //       <p>
      //         <strong>Id:</strong>{" "}
      //         {user.id}
      //       </p>
      //     </div>
      //   )}
      // </userContext.Consumer>
    );
  }
}