import { useContext, createContext } from "react";

// @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
export const userContext = createContext<UserContextProps>();

export function useAuth() {
  return useContext<UserContextProps>(userContext);
}

export type UserContextProps = {
  authToken: string | null;
  setAuthToken: (token: string | null) => void;
  userType: string | null;
  setUserType: (userType: string | null) => void;
};
