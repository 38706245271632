import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LoadingSpinner from "../loading/loading-spinner.component";
import SubmissionTable from "./submission-table.component";
import { useQuestionnaireSubmissions } from "../../hooks/useQuestionnaireSubmissions";

export default function QuestionnaireList() {
  const {
    data: submissions,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useQuestionnaireSubmissions();

  return (
    <div className="row">
      <div className="col">
        <div className="card card-container">
          <h3 className="page-title">Questionnaire Submissions</h3>
          {isLoading && <LoadingSpinner></LoadingSpinner>}

          {!isLoading && (
            <SubmissionTable
              submissions={submissions}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
            ></SubmissionTable>
          )}
        </div>
      </div>
    </div>
  );
}
