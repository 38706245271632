import CustomerJobRequestService from "../services/customer-request.service";
import { useQuery } from "@tanstack/react-query";

export function useJobRequestById(id: number) {
  return useQuery({
    queryKey: ["customerRequest", id],
    queryFn: async () => {
      return await CustomerJobRequestService.getCustomerJobRequestById(id);
    }
  });
}
