import React from 'react';
import DriverList from './driver-list.component';
import { useQuery } from '@tanstack/react-query';

export default function Drivers() {
    return (
        <div className="drivers-container">
            <div className="row">
                <div className="col">
                    <DriverList ></DriverList>
                </div>
            </div>
        </div>
    );
}
