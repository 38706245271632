import React, { useState } from "react";
import LoadingSpinner from "../loading/loading-spinner.component";
import "./drivers.css";
import { useDriverHours } from "../../hooks/useDriverHours";
import moment from "moment";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export function HoursMinutesDisplay({ minutes }: { minutes: number }) {
  return (
    <td>
      {(minutes / 60).toFixed(2)} ({minutes})
    </td>
  );
}

export default function DriverHours() {
  const [fromDate, setFromDate] = useState(moment().add(1, "hour").subtract("7", "days").toDate());
  const [toDate, setToDate] = useState(
    moment().add(1, "hour").toDate()
  );

  const {
    data: driverHours,
    isLoading,
    isFetching,
    refetch,
  } = useDriverHours(fromDate.toISOString(), toDate.toISOString());

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-container">
          <div className="">
          <h3 className="page-title">Driver Hours</h3>
            <DatePicker
              className="form-control date-picker my-2"
              selected={fromDate}
              onChange={(d) => {
                if (d) setFromDate(d);
              }}
              showTimeInput
              dateFormat="dd/MM/yyyy HH:mm"
              timeIntervals={5}
              calendarStartDay={1}
            />
            <DatePicker
              className="form-control date-picker my-2"
              selected={toDate}
              onChange={(d) => {
                if (d) setToDate(d);
              }}
              showTimeInput
              dateFormat="dd/MM/yyyy HH:mm"
              timeIntervals={5}
              calendarStartDay={1}
            />
            <button
              className="btn btn-success my-2 float-right"
              type="button"
              disabled={isLoading}
              onClick={() => refetch()}
            >
              {isFetching || isLoading ? "Loading" : "Fetch"}
            </button>
            </div>
            <table className="table-responsive table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Current Hours</th>
                  <th scope="col">Estimated Hours (Job Length)</th>
                  <th scope="col">Actual System Hours (Combination)</th>
                </tr>
              </thead>
              <tbody>
                {driverHours?.map((dh) => (
                  <tr>
                    <th scope="row">{dh.driver.name}</th>
                    <HoursMinutesDisplay
                      minutes={dh.driverHours.currentMinutes}
                    />
                    <HoursMinutesDisplay
                      minutes={dh.driverHours.estimatedMinutes}
                    />
                    <HoursMinutesDisplay
                      minutes={dh.driverHours.actualMinutes}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
            {isLoading && <LoadingSpinner />}
          </div>
      </div>
    </div>
  );
}
