import DriverService from "../services/driver.service";
import { useQuery } from "@tanstack/react-query";

export function useDriverJobs(driverId: any) {
  return useQuery({
    queryKey: ["driverJobs", driverId],
    queryFn: async () => {
      const response = await DriverService.getDriverJobs(driverId);
      console.log(response);
      return response.data;
    }
  });
}
