import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import jobService from "../../services/job.service";
import Toggle from "./toggle";

interface ToggleFitnessProps {
  jobId: number;
  swingId: number;
  fitnessToDriveRequired: boolean;
}

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const ToggleFitness: React.FC<ToggleFitnessProps> = ({ jobId, swingId, fitnessToDriveRequired }) => {
  const queryClient = useQueryClient();

  const handleSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ["jobs", swingId] });
    MySwal.fire("Success", "Fitness to drive check updated successfully.", "success");
  };

  const toggleFitness = async (value: boolean) => {
    await jobService.toggleFitness(jobId);
    return value;
  };

  return (
    <Toggle
      value={fitnessToDriveRequired}
      serviceCall={toggleFitness}
      onSuccess={handleSuccess}
      label="Fitness to Drive Required"
    />
  );
};

export default ToggleFitness;
