import React, { useState, useRef } from "react";
import { RoleDto } from "../../contracts/models/Roles/RoleDto";
import roleService from "../../services/role.service";
import { AxiosResponse } from "axios";
import { permissionFriendlyNames } from "../../contracts/enums/PermissionType";
import { CreateRoleResponseDto } from "../../contracts/models/Roles/CreateRoleResponseDto";
import { useSuccessSwal } from "../../hooks/useSuccessSwal";
import "./manage-roles-component.css";
import RoleForm from "./role-form-component";
import DefaultModal from "../modal/modal.component";
import { useRoles } from "../../hooks/useRoles";
import { useQueryClient } from "@tanstack/react-query";

export default function ManageRoles() {
  const { data: roles } = useRoles();
  const [role, setRole] = useState<RoleDto>();

  const swal = useSuccessSwal();
  const queryClient = useQueryClient();
  const modalRef = useRef<{ openModal: () => void; closeModal: () => void } | null>(null);

  const deleteRole = (roleId: number) => {
    roleService.deleteRole(roleId).then(
      (response) => {
        if (response.data.success) {
          swal.execute("Success", "Role deleted successfully", "success");
          queryClient.invalidateQueries({ queryKey: ["roles"] });
        } else {
          swal.execute("Error", response.data.message, "error");
        }
      },
      (error) => {
        swal.execute(
          "Error",
          "An error occurred while deleting the role",
          "error"
        );
      }
    );
  };

  const handleSubmission = (values: RoleDto) => {
    const operationPromise = role
      ? roleService.updateRole(values)
      : roleService.createRole(values);

    operationPromise.then(
      (response: AxiosResponse<CreateRoleResponseDto>) => {
        if (response.data.success) {
          swal.execute("Success", response.data.message, "success");
          queryClient.invalidateQueries({ queryKey: ["roles"] });
          modalRef?.current?.closeModal();
        } else {
          swal.execute("Error", response.data.message, "error");
        }
      },
      (error) => {
        swal.execute(
          "Error",
          "An error has occurred while creating role",
          "error"
        );
      }
    );
  };

  
  return (
    <div>
      <div className="shadow-sm p-3 rounded mb-4">
        <h4 className="mb-3">Roles</h4>
        <p>Manage your organisations roles. Create, edit and delete roles.</p>
      </div>
      <div className="shadow-sm p-3">
        <div className="mb-3 d-flex justify-content-end">
          <DefaultModal
          ref={modalRef}
            title={"Create Role"}
            trigger={"Create Role"}
            content={
              <RoleForm
                onSubmit={(values: RoleDto) => {
                  handleSubmission(values);
                }}
              />
            }
          ></DefaultModal>
        </div>
        {roles && roles.length > 0 ? (
          <div className="table-responsive text-center">
            <table className="table">
              <thead>
                <tr className="text-center">
                  <th scope="col">Role</th>
                  <th scope="col">Permissions</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {roles.map((role) => (
                  <tr key={role.id} className="text-center">
                    <td>{role.roleName}</td>
                    <td>
                      <ul className="list-group list-group-flush">
                        {role.permissions.map((permission) => (
                          <li key={permission} className="list-group-item">
                            {permissionFriendlyNames[permission]}
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      <div className="d-flex justify-content-center flex-wrap flex-xl-nowrap">
                        <div className="p-2">
                          <DefaultModal
                            title={"Edit Role"}
                            content={
                              <RoleForm
                                role={role}
                                onSubmit={handleSubmission}
                              />
                            }
                            trigger={
                              <button
                                className="btn btn-primary m-0"
                                type="button"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  fill="currentColor"
                                  className="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                  />
                                </svg>
                              </button>
                            }
                            onOpen={() => setRole(role)}
                            onClose={() => setRole(undefined)}
                          ></DefaultModal>
                        </div>
                        <div className="p-2">
                          <button
                            className="btn btn-danger"
                            type="button"
                            onClick={() => {
                              deleteRole(role.id);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              fill="currentColor"
                              className="bi bi-x-square"
                              viewBox="0 0 16 16"
                            >
                              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  );
}
