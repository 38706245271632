import { AxiosResponse } from "axios";
import { CreateRoleDto } from "../contracts/models/Roles/CreateRoleDto";
import { GetRolesDto } from "../contracts/models/Roles/GetRolesDto";
import { RoleDto } from "../contracts/models/Roles/RoleDto";
import api from "./network";

const API_URL = "Role/";

class RoleService {
  async getRoles(request: any): Promise<AxiosResponse<RoleDto[]>> {
    try {
      return await api.get(API_URL, {
        params: request,
      });
    } catch (error) {
      console.error("Error fetching roles:", error);
      throw error;
    }
  }

  async updateRole(request: RoleDto) {
    try {
      return await api.patch(`${API_URL}`, request);
    } catch (error) {
      console.error("Error updating role:", error);
      throw error;
    }
  }

  async deleteRole(roleId: number) {
    try {
      return await api.delete(`${API_URL}${roleId}`, {});
    } catch (error) {
      console.error("Error deleting role:", error);
      throw error;
    }
  }

  async createRole(request: CreateRoleDto) {
    try {
      return await api.post(`${API_URL}`, request);
    } catch (error) {
      console.error("Error creating role:", error);
      throw error;
    }
  }
}

export default new RoleService();