import React, { useState } from "react";

interface LoadingProps {
  size?: string;
}
export default function LoadingSpinner({ size }: LoadingProps) {
  var loadingContainer = null;
  var classNames = "sk-circle";

  if (size == "sm") {
    classNames = classNames + " sk-circle-sm";
  }

  // if (this.props.color == "white") {
  //   classNames = classNames + " sk-white";
  // }

  loadingContainer = (
    <div className={classNames}>
      <div className="sk-circle1 sk-child"></div>
      <div className="sk-circle2 sk-child"></div>
      <div className="sk-circle3 sk-child"></div>
      <div className="sk-circle4 sk-child"></div>
      <div className="sk-circle5 sk-child"></div>
      <div className="sk-circle6 sk-child"></div>
      <div className="sk-circle7 sk-child"></div>
      <div className="sk-circle8 sk-child"></div>
      <div className="sk-circle9 sk-child"></div>
      <div className="sk-circle10 sk-child"></div>
      <div className="sk-circle11 sk-child"></div>
      <div className="sk-circle12 sk-child"></div>
    </div>
  );

  return <div className="loading-container">{loadingContainer}</div>;
}
