import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";
import DriverService from "../../services/driver.service";
import DefaultModal from "../modal/modal.component";

const EditDriverSchema = Yup.object().shape({
  name: Yup.string().min(2, "Field is too short").max(150, "Field is too long"),
  email: Yup.string()
    .min(2, "Field is too short")
    .max(150, "Field is too long"),
  telephone: Yup.string()
    .min(9, "Field is too short")
    .max(14, "Field is too long"),
  licenceNumber: Yup.string()
    .min(2, "Field is too short")
    .max(150, "Field is too long"),
});

const MySwal = withReactContent(Swal);

export default function EditDriver({ driver, onComplete }: any) {
  const [isLoading, setLoading] = useState(false);

  const handleSubmission = (values: any) => {
    setLoading(true);

    // @ts-expect-error TS(2554): Expected 6 arguments, but got 4.
    DriverService.editDriver(
      driver.id,
      values.name,
      values.email,
      values.telephone
    ).then(
      (response) => {
        setLoading(false);

        onComplete();

        MySwal.fire({
          title: "Success",
          text: "Driver successfully edited.",
          icon: "success",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        MySwal.fire({
          title: "Error",
          text: resMessage,
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });

        setLoading(false);
      }
    );
  };

  return (
    <DefaultModal
      title={"Edit Driver Details"}
      trigger={"Edit Driver"}
      content={
        <div className="col-md-12">
          <div className="card card-container">
            <h3 className="page-title">Edit Driver</h3>
            <p className="required-text">
              Required fields<span className="required">*</span>
            </p>
            <Formik
              initialValues={{
                name: driver.name,
                email: driver.email,
                telephone: driver.telephoneNumber,
                licenceNumber: driver.licenceNumber,
              }}
              validationSchema={EditDriverSchema}
              onSubmit={(values) => {
                handleSubmission(values);
              }}
            >
              {({ errors, touched, isValid, dirty }) => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="name">
                      Name<span className="required">*</span>
                    </label>
                    {errors.name && touched.name ? (
                      <div className="alert alert-danger" role="alert">
                        {errors.name?.toString()}
                      </div>
                    ) : null}
                    <Field className="form-control" name="name" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">
                      Email<span className="required">*</span>
                    </label>
                    {errors.email && touched.email ? (
                      <div className="alert alert-danger" role="alert">
                        {errors.email?.toString()}
                      </div>
                    ) : null}
                    <Field className="form-control" name="email" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="telephone">
                      Telephone<span className="required">*</span>
                    </label>
                    {errors.telephone && touched.telephone ? (
                      <div className="alert alert-danger" role="alert">
                        {errors.telephone?.toString()}
                      </div>
                    ) : null}
                    <Field className="form-control" name="telephone" />
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-block"
                      disabled={isLoading || !isValid}
                      type="submit"
                    >
                      Save Changes
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      }
    ></DefaultModal>
  );
}
