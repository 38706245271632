import React, { useState, useEffect } from "react";
import Select from "react-select";
import { RoleDto } from "../../contracts/models/Roles/RoleDto";

const RoleSelector = ({
  allRoles,
  currentUserRoles,
  onUpdateUserRoles,
  className,
}: {
  allRoles: RoleDto[];
  currentUserRoles?: RoleDto[] | undefined;
  onUpdateUserRoles: (roles: RoleDto[]) => void;
  className?: string;
}) => {
  const [selectedRoles, setSelectedRoles] = useState(currentUserRoles);

  const handleRoleChange = (
    newValue: ReadonlyArray<{ value: RoleDto; label: string }>
  ) => {
    setSelectedRoles(newValue.map((option) => option.value));
  };

  useEffect(() => {
    onUpdateUserRoles(selectedRoles ?? []);
  }, [selectedRoles]);

  const options = allRoles.map((role) => ({
    value: role,
    label: role.roleName,
  }));

  return (
    <div className={className}>
      <Select
        className="w-100"
        options={options}
        isMulti
        onChange={handleRoleChange}
        value={options.filter((option) =>
          selectedRoles?.some((role) => role.roleName === option.value.roleName)
        )}
      />
    </div>
  );
};

export default RoleSelector;
