import React from "react";
import { useDriverClassUpdatesByDriver } from "../../hooks/useDriverClassUpdatesByDriver";
import DriverClassUpdatesTable from "./driver-class-updates-table.component";
export default function DriverClassUpdatesByDriver({
  driverId
}: any) {
  const { data: updates } = useDriverClassUpdatesByDriver(driverId);

  return (
    <DriverClassUpdatesTable
      updates={updates}
      showDriver={false}
    ></DriverClassUpdatesTable>
  );
}
