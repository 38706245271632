import React from "react";

type Props = {
  title: string;
  values: any[];
  action: any;
  actionText: string;
};

//OMG IF THIS WAS TYPE SCRIPT IT WOULD BE SO MUCH BETTER :(
export default function BasicTable({
  title,
  values,
  action,
  actionText,
}: Props) {
  let valuesTable = (
    <div className="alert alert-danger">
      <p>{title} list empty.</p>
    </div>
  );

  if (values?.length > 0) {
    valuesTable = (
      <div className="job-list">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                {Object.keys(Object.values(values)[0])
                  .filter((i) => i.indexOf("Id") == -1)
                  .map((title) => (
                    <th scope="col">{title}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {Object.values(values).map((v) => (
                <>
                  <tr>
                    {Object.keys(v)
                      .filter((i) => i.indexOf("Id") == -1)
                      .map((key) => (
                        <>
                          {typeof v[key] != "object" ? (
                            <th>{v[key]}</th>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                  </tr>
                </>
              ))}

              {action && (
                <th>
                  {" "}
                  <button
                    className="btn btn-sm btn-default"
                    type="button"
                    onClick={action}
                  >
                    {actionText}
                  </button>
                </th>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card-container">
          <h3 className="page-title">{title}</h3>
          {valuesTable}
        </div>
      </div>
    </div>
  );
}
