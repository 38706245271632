import React from "react";
import LoadingSpinner from "../loading/loading-spinner.component";
import SubmissionTable from "./submission-table.component";
import { useComplianceAlerts} from "../../hooks/useComplianceAlerts";

export default function ComplianceAlertList() {
  const { data: submissions, isLoading } = useComplianceAlerts();

  return (
      <div className="row">
        <div className="col">
          <div className="card card-container">
            <h3 className="page-title">Compliance Alerts</h3>
            <p>All failed compliance submission and their handling history. Submissions in this list will often need additional intervention from the office.</p>
            {isLoading && <LoadingSpinner></LoadingSpinner>}

            {!isLoading && (
              <SubmissionTable submissions={submissions}></SubmissionTable>
            )}
          </div>
        </div>
      </div>
  );
}
