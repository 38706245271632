export const Classes = [
  { value: 1, label: "B" },
  { value: 2, label: "BE" },
  { value: 3, label: "C" },
  { value: 4, label: "C1" },
  { value: 5, label: "CE" },
  { value: 6, label: "D" },
  { value: 7, label: "C1E" },
  { value: 8, label: "D1" },
];

export const ClassesWithTachoCPC = [
  ...Classes,
  { value: 9, label: "TACHO" },
  { value: 10, label: "CPC" },
];

export function GetById(id: any) {
  var licenceClass = ClassesWithTachoCPC.find((option) => option.value === id);
  if (licenceClass) {
    return licenceClass.label;
  }
  return "Unknown";
}

export function GetSelectItemById(id: any) {
  var licenceClass = ClassesWithTachoCPC.find((option) => option.value === id);
  if (licenceClass) {
    return licenceClass;
  }
  return Classes[0];
}

export function GetOptionalSelectItemById(id: any) {
  if (id) {
    return GetSelectItemById(id);
  }
  return null;
}
