import { PermissionType } from "../contracts/enums/PermissionType";
import api from "./network";
import { jwtDecode } from "jwt-decode";

const API_URL = "auth/";

class AuthService {
  async login(email: string, password: string) {
    const response = await api.post(API_URL + "authenticate", {
      email,
      password,
    });
    if (response.data.token) {
      return response.data.token;
    }
    console.log("Missing successful login token");
  }

  register(name: string, email: string, password: string) {
    return api.post(API_URL + "register", {
      email,
      password,
      name,
    });
  }

  registerCustomerUser(
    name: string,
    email: string,
    password: string,
    telephone: string,
    customerId: string
  ) {
    return api.post("user/register/customer", {
      name,
      email,
      password,
      telephone,
      customerId,
    });
  }
}

export default new AuthService();
