import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import Moment from "react-moment";
import vehicleService from "../../services/vehicle.service";
import LoadingSpinner from "../loading/loading-spinner.component";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_TableOptions,
  useMantineReactTable,
} from "mantine-react-table";
import {
  PendingMileage,
  MileageSubmissionDto,
} from "../../contracts/models/VehicleMoveDto";
import FilterDate from "./filter-date.component";
import moment from "moment";
import "./fleet.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ActionIcon, NumberInput, Tooltip, FileInput } from "@mantine/core";
import { IconDeviceFloppy, IconDisc, IconPencil, IconUpload } from "@tabler/icons-react";

export const usePendingMileage = (fromDate: string, toDate: string) => {
  return useQuery<PendingMileage[]>({queryKey: ["pendingMileage"], queryFn: async () => {
    var r = await vehicleService.getPendingMileage(fromDate, toDate);
    return r;
  }});
};

export const PendingMileageList: React.FC = () => {
  const [dates, setDates] = React.useState({
    startDate: moment().subtract(7, "days").toDate(),
    endDate: moment().add(28, "days").toDate(),
  });
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});

  const MySwal = withReactContent(Swal);

  const {
    data: pendingMileage,
    isLoading,
    refetch,
  } = usePendingMileage(
    dates.startDate.toISOString(),
    dates.endDate.toISOString()
  );

  const queryClient = useQueryClient();
  const submitMileageMutation = useMutation({
    mutationFn: (mileageSubmission: MileageSubmissionDto) =>
      vehicleService.submitMileageReading(mileageSubmission),
    onSuccess: () => {
      MySwal.fire("Success", "Mileage submitted successfully.", "success");
      queryClient.invalidateQueries({ queryKey: ["pendingMileage"] });
      refetch();
    },
    onError: (error: any) => {
      MySwal.fire("Error", error.response?.data || "An error occurred while submitting the mileage.", "error");
    },
  });
  

  const columns = useMemo<MRT_ColumnDef<PendingMileage>[]>(
    () => [
      {
        accessorKey: "vehicleMoveId",
        header: "Vehicle Move ID",
        enableEditing: false,
      },
      {
        accessorKey: "jobReference",
        header: "Job Reference",
        enableEditing: false,
      },
      {
        accessorKey: "driverName",
        header: "Driver Name",
        enableEditing: false,
      },
      {
        accessorKey: "startMileage",
        header: "Start Mileage (km)",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          const value = cell.getValue<number>();
          return value !== null ? `${value}` : '';
        },
        Edit: ({ column, row }) => (
          <NumberInput
            defaultValue={row.original.startMileage}
            onChange={(value: any) => (row._valuesCache[column.id] = value)}
            error={validationErrors?.startMileage}
          />
        ),
      },
      {
        accessorKey: "endMileage",
        header: "End Mileage (km)",
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        Cell: ({ cell }) => {
          const value = cell.getValue<number>();
          return value !== null ? `${value}` : '';
        },
        Edit: ({ column, row }) => (
          <NumberInput
            defaultValue={row.original.endMileage}
            onChange={(value: any) => (row._valuesCache[column.id] = value)}
            error={validationErrors?.endMileage}
          />
        ),
      },
      {
        accessorKey: "distance",
        header: "Total Distance (km)",
        enableColumnFilter: false,
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ cell }) => {
          const value = cell.getValue<number>();
          return value !== null ? `${value}` : '';
        },
        Edit: ({ column, row }) => (
          <NumberInput
            defaultValue={row.original.distance}
            onChange={(value: any) => (row._valuesCache[column.id] = value)}
            error={validationErrors?.distance}
          />
        ),
      },
      {
        accessorKey: "file",
        header: "Evidence",
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        Cell: ({ cell }) => {
          const value = cell.getValue<string>();
          return value ? <img src={value} alt="Evidence" style={{ width: '100px' }} /> : '';
        },
        Edit: ({ column, row }) => (
          <FileInput
            placeholder="Upload evidence"
            icon={<IconUpload size={14} />}
            onChange={(file: File) => (row._valuesCache[column.id] = file)}
            error={validationErrors?.evidence}
          />
        ),
      }
    ],
    [validationErrors, submitMileageMutation]
  );

  const handleSaveMileage: MRT_TableOptions<PendingMileage>['onEditingRowSave'] = async ({
    values,
    table,
  }) => {
    console.log('VHIUCLE', values, table);
    const mileageValues: MileageSubmissionDto = {
      // @ts-ignore
      vehicleMoveId: values.vehicleMoveId, //add es lint to ignore this duplicate error 
      ...values,
    };
    
    const newValidationErrors = validateMileage(mileageValues);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await submitMileageMutation.mutateAsync(mileageValues);
  };

  const table = useMantineReactTable({
    columns,
    data: pendingMileage || [],
    enableEditing: true,
    enableRowActions: true,
    editDisplayMode: "row",
    positionActionsColumn: 'last',
    getRowId: (row) => row.vehicleMoveId.toString(),
    onEditingRowSave: handleSaveMileage,
      initialState: {
    // columnVisibility: {
    //   vehicleMoveId: false,
    // }
  },
    renderRowActions: ({ row, table }) => (
      <Tooltip label="Edit">
        <ActionIcon color="blue" onClick={() => table.setEditingRow(row)}>
          <IconPencil />
        </ActionIcon>
      </Tooltip>
    ),
  });

  function validateMileage(mileage: MileageSubmissionDto) {
    const errors: Record<string, string | undefined> = {};
    const { startMileage, endMileage, distance } = mileage;
    const startOrEndRequired =
      (startMileage != null && endMileage != null) || distance != null;

    if (!startOrEndRequired) {
      errors.startMileage =
        "Either start and end mileage or total distance is required.";
      errors.endMileage =
        "Either start and end mileage or total distance is required.";
      errors.distance =
        "Either start and end mileage or total distance is required.";
    }

    return errors;
  }

  return (
    <div>
      <h2>Pending Mileage Submissions</h2>
      <ul>
        <li>Here you must submit mileage distances for all heavy classed vehicles.</li>
        <li>You must enter and start and end mileage OR a total distance, not both.</li>
      </ul>
      <FilterDate
        dates={dates}
        onDateChange={(d) => {
          setDates(d);
          setTimeout(() => refetch(), 500);
        }}
      />
      {isLoading ? <LoadingSpinner /> : <MantineReactTable table={table} />}
    </div>
  );
};

export default PendingMileageList;
