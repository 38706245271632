import VehicleService from "../services/vehicle.service";
import { useQuery } from "@tanstack/react-query";

export function useCurrentSwingVehicles(swingId: any) {
  return useQuery({
    queryKey: ["currentSwingVehicles", swingId],
    queryFn: async () => {
      if (!swingId || swingId == 0) return null;
      const response = await VehicleService.getSwingVehicles(swingId);
      return response.data;
    },
    // Uncomment if you want to enable refetch interval
    // refetchInterval: 10000,
  });
}
