import React from "react";
import { VehicleDto } from "../../contracts/models/VehicleDto";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const archiveVehicleSwal = withReactContent(Swal);
export default function ArchiveVehicle({
  vehicle,
  updateArchivedStatus,
}: {
  vehicle: VehicleDto;
  updateArchivedStatus: (
    vehicleId: number,
    archivedStatus: boolean
  ) => Promise<void>;
}) {
  async function onClick(archived: boolean) {
    try {
      const result = await archiveVehicleSwal.fire({
        title: `Are you sure you want to ${
          vehicle.archived ? "unarchive" : "archive"
        } this Vehicle?`,
        showCancelButton: true,
        confirmButtonText: vehicle.archived ? "Unarchive" : "Archive",
        denyButtonText: `Cancel`,
      });

      if (result.isConfirmed && vehicle?.id) {
        await updateArchivedStatus(vehicle.id, archived);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  return (
    <button
      type="button"
      className={`btn ${
        vehicle.archived ? "btn-primary" : "btn-danger"
      } text-white`}
      onClick={() => onClick(!vehicle.archived)}
    >
      {vehicle.archived ? "Unarchive Vehicle" : "Archive Vehicle"}
    </button>
  );
}
