import api from "./network";

const API_URL = "Compliance/";

class ComplianceService {
  getComplianceAlerts(swingId: any) {
    return api.get(API_URL + "alert");
  }

  getUnseenAlerts() {
    return api.get(API_URL + "alerts/unseen");
  }

  getSubmissions(pageNumber: any) {
    return api.get(API_URL + "all", {
      params: { pageNumber },
    });
  }

  exportSubmission(id: number) {
    return api.get(
      API_URL + `submission/${id}/export`,
      {
        responseType: "blob",
        referenceType: "blob",
      }
    );
  }

  getSubmission(id: any) {
    return api.get(API_URL + "submission", {
      params: {
        id,
      },
    });
  }

  getComplianceById(id: any) {
    return api.get(API_URL + "alert/get", {
      params: {
        id,
      },
    });
  }
}

export default new ComplianceService();
