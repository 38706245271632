import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { SwingDto } from "../../contracts/models/SwingDto";
import customerManagementService from "../../services/customer-management.service";
import SwingService from "../../services/swing.service";
import AddSwingForm from "./add-job-group-form.component";
import "./Jobs.css";

const MySwal = withReactContent(Swal);

export default function AddSwing() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['allocateSwing'],
    mutationFn: ({ requestId, swingId }: { requestId: number; swingId: number }) =>
      customerManagementService.allocateSwingToCustomerRequest(requestId, swingId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['swing']});
      MySwal.fire({
        text: "The Request was successfully allocated to the swing.",
        icon: "success",
        confirmButtonText: "OK"
      });
    },
    onError: (error) => {
      alert("Failed to allocate swing to customer request: " + error);
    },
  });

  const handleSubmission = (swing: SwingDto, resetForm: any, customerJobRequestId?: number) => {
    console.log(swing);
    SwingService.saveNewSwing(swing).then(
      (response) => {
        MySwal.fire({
          title: "Success",
          text: "Job successfully added.",
          icon: "success",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });

        if (customerJobRequestId && response.data?.id) {
          mutation.mutate({ requestId: customerJobRequestId, swingId: response.data.id });
        }

        resetForm();
      },
      (error) => {
        console.log(error);
        if (error.response) {
          MySwal.fire({
            title: "Error",
            text: error.response.data,
            icon: "error",
            // @ts-expect-error TS(2769): No overload matches this call.
            button: "Continue",
          });
        }
      }
    );
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="/images/icons/job.png"
          alt="page-img"
          className="page-img"
        />
        <h3 className="page-title">Add new Job Group</h3>
        <p className="title-message">
          Adding a Job group creates a daily collection of moves from one
          address to another. Every driver job must be saved against a Group.
        </p>
        <p className="title-message">
          A Job Group must be created each day if applicable.
        </p>
        <AddSwingForm onSubmit={handleSubmission}></AddSwingForm>

        <p className="posting-message">
          Posting this job will allow driver moves to be added against it.
        </p>
        <p className="posting-message">
          Submitting this Job will make no changes to the job list until
          driver moves are assigned
        </p>
      </div>
    </div>
  );
}
