import ExpensesService from "../services/expenses.service";
import { useQuery } from "@tanstack/react-query";

export function useExpenseImage(expenseId: any, name: any) {
  return useQuery({
    queryKey: ["expenseImage", expenseId, name],
    queryFn: async () => {
      return await ExpensesService.getExpenseImage(expenseId, name);
    },
  });
}
