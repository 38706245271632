import DriverService from "../services/driver.service";
import { useQuery } from "@tanstack/react-query";

export function useDriverClassUpdates() {
  return useQuery({
    queryKey: ["classUpdates"],
    queryFn: async () => {
      const response = await DriverService.GetDriverClassUpdates();
      return response.data;
    }
  });
}
