import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomerJobRequestService from "../../services/customer-request.service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SelectCustomer from "../customers/select-customer.component";
import NumberDriversComponent from "./number-drivers";
import VehicleTypeComponent from "./vehicle-type";
import { useCustomerLabels } from "../../hooks/useCustomers";
import StandardField from "../base/StandardField";
import SelectAddress from "../address/select-address.component";
import { useAddresses } from "../../hooks/useAddresses";
import SelectNotificationPreference from "../drivers/select-notification-preference";
import { CustomerJobRequestDto } from "../../contracts/models/CustomerJobRequestDto";
import { CustomerJobRequestResponseDto } from "../../contracts/models/CustomerJobRequestResponseDto";
const vehicleOptionsSwing = [
  { value: "VAN", label: "Van", imageSrc: "/images/vehicle-types/van.jpg" },
  {
    value: "3.5T_HGV",
    label: "3.5T HGV",
    imageSrc: "/images/vehicle-types/luton.jpeg",
  },
  {
    value: "18T_HGV",
    label: "18T HGV",
    imageSrc: "/images/vehicle-types/18t-tail.jpeg",
  },
];

const vehicleOptionsDailyRunner = [
  {
    value: "LUTON",
    label: "Luton",
    imageSrc: "/images/vehicle-types/luton.jpeg",
  },
  {
    value: "7.5T_BOX_TAIL_LIFT",
    label: "7.5T Box with Tail Lift",
    imageSrc: "/images/vehicle-types/75_box.jpeg",
  },
  {
    label: "7.5T Curtain Side with Tail Lift",
    value: "DR_7.5T_CURTAIN_SIDE_TAIL_LIFT",
    imageSrc: "/images/vehicle-types/75_curtain.jpeg",
  },
  {
    value: "18T_BOX_TRUCK_TAIL_LIFT",
    label: "18T Box Truck with Tail Lift",
    imageSrc: "/images/vehicle-types/18t-tail.jpeg",
  },
  {
    value: "18T_CURTAIN_SIDE_TAIL_LIFT",
    label: "18T Curtainside with Tail Lift",
    imageSrc: "/images/vehicle-types/18t-curtain-tail.jpeg",
  },
  {
    value: "18_T_FLATBED",
    label: "18T with Flatbed Trailer",
    imageSrc: "/images/vehicle-types/18t-curtain-tail.jpeg",
  },
  {
    value: "44T_40FT_ARTIC_FLATBED",
    label: "44T 40FT Arctic with Flatbed Trailer",
    imageSrc: "/images/vehicle-types/tractor-flatbed.jpeg",
  },
  {
    value: "44T_40FT_ARTIC_CURTAIN_SIDE",
    label: "44T 40FT Arctic with Curtain Side Trailer",
    imageSrc: "/images/vehicle-types/44_curtain.jpeg",
  },
  {
    value: "44T_40FT_ARTIC_BOX_TRAILER",
    label: "44T 40FT Arctic with Box Trailer",
    imageSrc: "/images/vehicle-types/44_curtain.jpeg",
  },
  {
    value: "44T_40FT_ARTIC_LOW_LOADER",
    label: "44T 40FT Arctic with Low Loader Trailer",
    imageSrc: "/images/vehicle-types/44_low.jpeg",
  },
  {
    value: "HIAB_ARTIC_WITH_TRAILER",
    label: "HIAB Arctic with 30FT Trailer",
    imageSrc: "/images/vehicle-types/artic_hiab.jpeg",
  },
  {
    value: "HIAB_RIGID_UNIT",
    label: "HIAB Rigid Unit",
    imageSrc: "/images/vehicle-types/rigid-hiab.jpeg",
  },
];

const JobRequestSchema = Yup.object().shape({
  customerId: Yup.number().required("Customer is required"),
  startDate: Yup.date().required("Start date is required").nullable(),
  endDate: Yup.date().nullable(),
  jobDescription: Yup.string().required("Job description is required"),
  department: Yup.string().required("Department is required"),
  jobType: Yup.string().required("Job type is required"),
  vehicleType: Yup.string().when("jobType", (jobType: any, schema) => {
    return jobType === "SWING"
      ? schema.required("Vehicle type is required")
      : schema.notRequired();
  }),
  vehicles: Yup.array().of(
    Yup.object().shape({
      vehicleType: Yup.string().required("Vehicle type is required"),
      numberOfDrivers: Yup.number()
        .required("Number of drivers is required")
        .min(1, "Minimum of 1 driver required")
        .max(50, "Maximum of 50 drivers allowed"),
    })
  ),
  //WHEN jobPickupAddresDetialsId is not set then pickup address is required
  jobPickupAddressDetailsId: Yup.string().when(
    "jobPickupAddress",
    (jobPickupAddress: any, schema) => {
      return !jobPickupAddress
        ? schema.required("Pickup address is required")
        : schema.notRequired();
    }
  ),
  jobDeliveryAddressDetailsId: Yup.string(),
  notes: Yup.string(),
  notificationPreference: Yup.string().required("Notification preference is required"),
});

interface Props {
  jobRequest?: CustomerJobRequestResponseDto;
}

export default function AddOrEditJobRequestForm({ jobRequest }: Props) {
  const [startDate, setStartDate] = useState<Date>(jobRequest?.startDate ? new Date(jobRequest?.startDate) : new Date());
  const [endDate, setEndDate] = useState<Date | undefined>(jobRequest?.endDate ? new Date(jobRequest.endDate) : undefined);
  const { data: customers } = useCustomerLabels(true, true);
  const { data: addresses, isLoading: isLoadingAddresses } = useAddresses();
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (jobRequest) {
      setStartDate(new Date(jobRequest.startDate));
      setEndDate(jobRequest.endDate ? new Date(jobRequest.endDate) : undefined);
    }
  }, [jobRequest]);

  const handleActionSuccess = (message: string) => {
    window.location.href = "/";
    MySwal.fire({
      title: "Success",
      text: message,
      icon: "success",
      confirmButtonText: "Ok",
    });
  };

  return (
    <Formik
      initialValues={{
        id: jobRequest?.id || undefined,
        customerId: jobRequest?.customerId || undefined,
        jobDescription: jobRequest?.jobDescription || "",
        department: jobRequest?.department || "",
        startDate: startDate,
        endDate: endDate,
        jobType: jobRequest?.jobType || "",
        vehicleType: jobRequest?.vehicleType,
        jobPickupAddress: jobRequest?.jobPickupAddress,
        jobDeliveryAddress: jobRequest?.jobDeliveryAddress,
        jobPickupAddressDetailsId: jobRequest?.jobPickupAddressDetailsId,
        jobDeliveryAddressDetailsId: jobRequest?.jobDeliveryAddressDetailsId,
        notes: jobRequest?.notes,
        notificationPreference: jobRequest?.notificationPreference,
        vehicles: jobRequest?.vehicles || [{ vehicleType: "", numberOfDrivers: 1 }],
      }}
      validationSchema={JobRequestSchema}
      onSubmit={(values, { setSubmitting }) => {
        const action = jobRequest?.id ? 'edit' : 'create';
        const successMessage = action === 'edit' ? "Your Job Request was updated successfully" : "Your Job Request was added successfully";

        if (action === 'edit') {
          CustomerJobRequestService.editCustomerJobRequest(values).then(() => {
            handleActionSuccess(successMessage);
            setSubmitting(false);
          });
        } else {
          CustomerJobRequestService.createCustomerJobRequest(values).then(() => {
            handleActionSuccess(successMessage);
            setSubmitting(false);
          });
        }
      }}
    >
      {({ errors, isSubmitting, setFieldValue, values, isValid }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="startDate">
              Select which Production this job is for
            </label>
            <SelectCustomer
            defaultCustomer={values.customerId ?? undefined}
              onChange={(value: number) => setFieldValue("customerId", value)}
              customers={customers || []}
              hideMessage={true}
            />
          </div>
          <StandardField
            fieldName="department"
            friendlyName="Which department is this for?"
          />
          <StandardField
            fieldName="jobDescription"
            friendlyName="Job Description"
          />
          <div className="form-group">
            <label htmlFor="startDate">When is the booking for?</label>
            <DatePicker
              selected={startDate}
              onChange={(date: Date) => {
                setStartDate(date);
                setFieldValue("startDate", date);
              }}
              className="form-control date-picker"
              showTimeInput
              dateFormat="dd/MM/yyyy HH:mm"
              timeIntervals={5}
              calendarStartDay={1}
            />
          </div>
          <div className="form-group">
            <label htmlFor="endDate">
              If the Job spans multiple days, when should it end?
            </label>
            <DatePicker
              selected={endDate}
              onChange={(date: Date) => {
                setEndDate(date);
                setFieldValue("endDate", date);
              }}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="jobType">Job Type</label>
            <Field
              as="select"
              name="jobType"
              className="form-control"
              onChange={(e: any) => {
                setFieldValue("jobType", e.target.value);
              }}
            >
              <option value="">Select a job type</option>
              <option value="SWING">Swing</option>
              <option value="DAILY_RUNNER">Daily Runner</option>
            </Field>
            {!values.jobType && (
              <div className="alert alert-info my-2" role="alert">
                Select a Job Type to continue building your job...
              </div>
            )}
          </div>
          {values.jobType && (
            <>
              <FieldArray name="vehicles">
                {({ push, remove }: { push: any; remove: any }) => (
                  <div>
                    {values.vehicles.map((vehicle, index) => (
                      <div key={index}>
                        {values.jobType === "SWING" && (
                          <>
                            <VehicleTypeComponent
                              defaultValue={vehicle.vehicleType}
                              setFieldValue={setFieldValue}
                              name={`vehicles.${index}.vehicleType`}
                              vehicleOptions={vehicleOptionsSwing}
                            />
                            <NumberDriversComponent
                              name={`vehicles.${index}.numberOfDrivers`}
                            />
                          </>
                        )}
                        {values.jobType === "DAILY_RUNNER" && (
                          <>
                            <VehicleTypeComponent
                              defaultValue={vehicle.vehicleType}
                              name={`vehicles.${index}.vehicleType`}
                              vehicleOptions={vehicleOptionsDailyRunner}
                              setFieldValue={setFieldValue}
                            />
                            <NumberDriversComponent
                              name={`vehicles.${index}.numberOfDrivers`}
                            />
                          </>
                        )}
                        <button
                          type="button"
                          className="btn btn-danger my-2"
                          onClick={() => remove(index)}
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="btn btn-secondary my-2"
                      onClick={() =>
                        push({ vehicleType: "", numberOfDrivers: undefined })
                      }
                    >
                      Additional Vehicle Requirement?
                    </button>
                  </div>
                )}
              </FieldArray>

              <hr />
              <div className="form-group">
                <label htmlFor="jobPickupAddressDetailsId">
                  What is the address the driver should come to?
                </label>
                {!isLoadingAddresses && (
                  <SelectAddress
                  defaultAddress={values.jobPickupAddressDetailsId}
                    addresses={addresses}
                    onChange={(value: string) =>
                      setFieldValue("jobPickupAddressDetailsId", value)
                    }
                  />
                )}

                {(!values.jobPickupAddressDetailsId ||
                  values.jobPickupAddressDetailsId == null) && (
                  <StandardField
                    fieldName="jobPickupAddress"
                    friendlyName="If you cant find the address you need above, Describe or give the exact address the driver should come to"
                  />
                )}
              </div>

              <hr />
              <div className="form-group">
                <label htmlFor="jobDeliveryAddressDetailsId">
                  If applicable, what is the delivery address for the job?
                </label>
                {!isLoadingAddresses && (
                  <SelectAddress
                    defaultAddress={values.jobDeliveryAddressDetailsId}
                    addresses={addresses}
                    onChange={(value: string) =>
                      setFieldValue("jobDeliveryAddressDetailsId", value)
                    }
                  />
                )}

                {(!values.jobDeliveryAddressDetailsId ||
                  values.jobPickupAddressDetailsId == null) && (
                  <StandardField
                    fieldName="jobDeliveryAddress"
                    friendlyName="If you cannot find the delivery address above, provide the destination"
                  />
                )}
              </div>
              <StandardField
                fieldName="notes"
                friendlyName="Provide any additional notes or information the office may need to create this job successfully"
                type="textarea"
              />

              {console.log("ERRORS", errors)}

              {console.log("VALS", values)}

              <div className="form-group">
                <label htmlFor="jobDeliveryAddressDetailsId">
                  Finally, how would you like to be notified about updates and progress for this job?
                </label>
                  <SelectNotificationPreference
                  defaultValue={values.notificationPreference}
                    onChange={(value: string) =>
                      setFieldValue("notificationPreference", value)
                    }
                  />
              </div>
            </>
          )}
          <div className="form-group">
            <button
              disabled={!isValid}
              className="btn btn-primary"
              type="submit"
            >
              {isSubmitting && (
                <span className="spinner-border spinner-border-sm"> </span>
              )}
              <span>{jobRequest?.id ? 'Edit' : 'Create New' } Request</span>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
