import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const DefaultModal = forwardRef(({
  title,
  content,
  size,
  trigger,
  onOpen,
  onClose,
  headerButtons,
  footerButtons,
}: {
  title: string;
  content: JSX.Element;
  size?: "sm" | "lg" | "xl";
  trigger: React.ReactNode | string;
  onOpen?: () => void;
  onClose?: () => void;
  headerButtons?: React.JSX.Element[];
  footerButtons?: React.JSX.Element[];
}, ref) => {
  const [modalShow, setModalShow] = useState(false);

  useImperativeHandle(ref, () => ({
    openModal: () => setModalShow(true),
    closeModal: () => setModalShow(false),
  }));

  const handleModalShow = () => {
    setModalShow(true);
    if (onOpen) {
      onOpen();
    }
  };

  const handleModalClose = () => {
    setModalShow(false);
    if (onClose) {
      onClose();
    }
  };

  // Render a simple button if trigger is a string, otherwise clone the React element
  const renderTrigger = () => {
    if (typeof trigger === "string") {
      return (
        <Button variant="primary" onClick={handleModalShow}>
          {trigger}
        </Button>
      );
    } else if (React.isValidElement(trigger)) {
      return React.cloneElement(trigger as React.ReactElement, {
        onClick: handleModalShow
      });
    } else {
      return null;
    }
  };
  
  return (
    <>
      {renderTrigger()}
      
      <Modal
        size={size ?? "lg"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={handleModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {title}
            {headerButtons && <div className="flex flex-end">
            {headerButtons.map(h => h)}
            </div> }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
        <>
          {footerButtons && footerButtons?.map((button: React.JSX.Element) => button)}
          <Button onClick={handleModalClose}>Close</Button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default DefaultModal;