import axios from "axios";

const base = process.env.REACT_APP_NETWORK_URL;
console.log("base url: " + process.env.REACT_APP_NETWORK_URL);

class api {
  instance = axios.create({ baseURL: base });

  constructor() {
    // Request interceptor for API calls
    this.instance.interceptors.request.use(
      async (config) => {
        // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        const token = JSON.parse(localStorage.getItem("token"));
        if (token) {
          config.headers = {
            Authorization: `Bearer ${token}`,
          };
        }
        config.headers.Accept = "application/json";
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    // Response interceptor for API calls
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        console.log("New Request Error - Request:");
        console.log(originalRequest);
        console.log("full error: ");
        console.log(error);
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            console.log("Unauthorised error! log us out and redirect");
            console.log(error.response);
            window.location.replace("/logout");
          }
        } else {
          //error but no response
        }
        return Promise.reject(error);
      }
    );
  }

  post = (url: any, data: any) => {
    return this.instance.post(url, data);
  };

  get = (url: any, data?: any) => {
    return this.instance.get(url, data);
  };

  put = (url: any, data: any) => {
    return this.instance.put(url, data);
  };

  patch = (url: any, data: any) => {
    return this.instance.patch(url, data);
  };

  delete = (url: any, data: any) => {
    return this.instance.delete(url, { data });
  };

  getUrl = (path: any) => {
    return base + "/" + path;
  };
}

export default new api();
