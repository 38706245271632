import withReactContent from "sweetalert2-react-content";
import Swal, { SweetAlertIcon } from "sweetalert2";

const MySwal = withReactContent(Swal);
export function useSuccessSwal() {
  const execute = (title: string, message: string, type?: SweetAlertIcon) =>
    MySwal.fire({
      title,
      text: message,
      icon: !type ? "success" : type,
      // @ts-expect-error TS(2769): No overload matches this call.
      button: "Continue",
    });

  return { execute };
}
