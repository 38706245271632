import ContactsService from "../services/contacts.service";
import { useQuery } from "@tanstack/react-query";

export function useContacts() {
  return useQuery({
    queryKey: ["contacts"],
    queryFn: async () => {
      const response = await ContactsService.getContacts();
      if (response.data.length > 0) {
        return response.data.map((contact: any) => ({
          value: contact.id,
          label: `${contact.name}, ${contact.telephone}, ${contact.email}`,
        }));
      }
      return [];
    },
    refetchInterval: 60000,
  });
}
