import React from "react";

const availableIcon = (
  <img
    className="icon-img"
    src="/images/icons/available.png"
    alt="Available Icon"
  ></img>
);
const acceptedIcon = (
  <img
    className="icon-img"
    src="/images/icons/accepted.png"
    alt="Accepted Icon"
  ></img>
);
const collectedIcon = (
  <img
    className="icon-img"
    src="/images/icons/truck-01.png"
    alt="Collected Icon"
  ></img>
);
const completedIcon = (
  <img
    className="icon-img"
    src="/images/icons/completed.png"
    alt="Completed Icon"
  ></img>
);
const closedIcon = (
  <img
    className="icon-img"
    src="/images/icons/closed.png"
    alt="Closed Icon"
  ></img>
);
const returnedIcon = (
  <img
    className="icon-img"
    src="/images/icons/returned.png"
    alt="Returned Icon"
  ></img>
);
const failedIcon = (
  <img
    className="icon-img"
    src="/images/icons/failed.png"
    alt="Failed Icon"
  ></img>
);
const declinedIcon = (
  <img
    className="icon-img"
    src="/images/icons/declined.png"
    alt="Declined Icon"
  ></img>
);
const progressIcon = (
  <img
    className="icon-img"
    src="/images/icons/truck-01.png"
    alt="Progress Icon"
  ></img>
);
const allocatedIcon = (
  <img
    className="icon-img"
    src="/images/icons/choice.png"
    alt="Allocated Icon"
  ></img>
);
const pendingIcon = (
  <img
    className="icon-img"
    src="/images/icons/pending.png"
    alt="Pending Icon"
  ></img>
);
const approvedIcon = (
  <img
    className="icon-img"
    src="/images/icons/approved.png"
    alt="Approved Icon"
  ></img>
);
const poRequestedIcon = (
  <img
    className="icon-img"
    src="/images/icons/po-request.png"
    alt="PO Requested Icon"
  ></img>
);
const poReceivedIcon = (
  <img
    className="icon-img"
    src="/images/icons/po-received.png"
    alt="PO Received Icon"
  ></img>
);
const invoicedIcon = (
  <img
    className="icon-img"
    src="/images/icons/invoice.png"
    alt="Invoiced Icon"
  ></img>
);
const paidIcon = (
  <img
    className="icon-img"
    src="/images/icons/paid.png"
    alt="Paid Icon"
  ></img>
);
const paymentCancelledIcon = (
  <img
    className="icon-img"
    src="/images/icons/payment-cancelled.png"
    alt="Payment Cancelled Icon"
  ></img>
);

interface StatusProps {
  status?: string | null | undefined;
}
export default function Status({ status }: StatusProps) {
  var statusElement = <></>;

  switch (status) {
    case "AVAILABLE":
      statusElement = (
        <>
          <p className="status-icon">{availableIcon}</p>
          <p className="status-text amber-status">Available</p>
        </>
      );
      break;
    case "ACCEPTED":
      statusElement = (
        <>
          <p className="status-icon">{acceptedIcon}</p>
          <p className="status-text positive-status">Accepted</p>
        </>
      );
      break;
    case "COLLECTED":
      statusElement = (
        <>
          <p className="status-icon">{collectedIcon}</p>
          <p className="status-text positive-status">Collected</p>
        </>
      );
      break;
    case "COMPLETED":
      statusElement = (
        <>
          <p className="status-icon">{completedIcon}</p>
          <p className="status-text positive-status">Completed</p>
        </>
      );
      break;
    case "CLOSED":
      statusElement = (
        <>
          <p className="status-icon">{closedIcon}</p>
          <p className="status-text positive-status">Closed</p>
        </>
      );
      break;
    case "FAILED":
      statusElement = (
        <>
          <p className="status-icon">{failedIcon}</p>
          <p className="status-text negative-status">Failed</p>
        </>
      );
      break;
    case "RETURNED":
      statusElement = (
        <>
          <p className="status-icon">{returnedIcon}</p>
          <p className="status-text negative-status">Returned</p>
        </>
      );
      break;
    case "DELIVERED":
      statusElement = (
        <>
          <p className="status-icon">{completedIcon}</p>
          <p className="status-text positive-status">Delivered</p>
        </>
      );
      break;
    case "IN PROGRESS":
      statusElement = (
        <>
          <p className="status-icon">{progressIcon}</p>
          <p className="status-text positive-status">In Progress</p>
        </>
      );
      break;
    case "DECLINED":
      statusElement = (
        <>
          <p className="status-icon">{declinedIcon}</p>
          <p className="status-text negative-status">Declined</p>
        </>
      );
      break;
    case "PENDING":
      statusElement = (
        <>
          <p className="status-icon">{pendingIcon}</p>
          <p className="status-text negative-status">Pending</p>
        </>
      );
      break;
    case "APPROVED":
      statusElement = (
        <>
          <p className="status-icon">{approvedIcon}</p>
          <p className="status-text positive-status">Approved</p>
        </>
      );
      break;
    case "CANCELLED":
      statusElement = (
        <>
          <p className="status-icon">{failedIcon}</p>
          <p className="status-text negative-status">Cancelled</p>
        </>
      );
      break;
    case "ALLOCATED":
      statusElement = (
        <>
          <p className="status-icon">{allocatedIcon}</p>
          <p className="status-text positive-status">Allocated</p>
        </>
      );
      break;
    case "POREQUESTED":
      statusElement = (
        <>
          <p className="status-icon">{poRequestedIcon}</p>
          <p className="status-text positive-status">PO Requested</p>
        </>
      );
      break;
    case "PORECEIVED":
      statusElement = (
        <>
          <p className="status-icon">{poReceivedIcon}</p>
          <p className="status-text positive-status">PO Received</p>
        </>
      );
      break;
    case "INVOICED":
      statusElement = (
        <>
          <p className="status-icon">{invoicedIcon}</p>
          <p className="status-text positive-status">Invoiced</p>
        </>
      );
      break;
    case "PAID":
      statusElement = (
        <>
          <p className="status-icon">{paidIcon}</p>
          <p className="status-text positive-status">Paid</p>
        </>
      );
      break;
    case "PAYMENT_CANCELLED":
      statusElement = (
        <>
          <p className="status-icon">{paymentCancelledIcon}</p>
          <p className="status-text negative-status">Payment Cancelled</p>
        </>
      );
      break;
    default:
      statusElement = <></>;
  }

  return <>{statusElement}</>;
}
