import { useAuth } from "../../context/userContext";

function Logout() {
  const { setAuthToken, setUserType } = useAuth();

  const logout = () => {
    setAuthToken(null);
    setUserType(null);
    window.location.href = "/";
  }

  logout();

  // <Redirect to="/" />
  return (null);
}

export default Logout;