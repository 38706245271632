import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ChargeDto } from "../contracts/models/Charges/ChargeDto";
import chargeService from "../services/charge.service";
import { GetChargesDto } from "../contracts/models/Charges/GetChargesDto";

// READ hook (get charges from api)
export function useGetCharges(request: GetChargesDto) {
  return useQuery<ChargeDto[]>({
    queryKey: ["charges", request.swingId],
    queryFn: async () => {
      return (await chargeService.getCharges(request)).data;
    },
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
  });
}

//CREATE hook (create charge in api)
export function useCreateCharge(swingId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (charge: ChargeDto) => {
      return (await chargeService.createCharge(charge)).data;
    },
    onMutate: (newChargeInfo: ChargeDto) => {
      queryClient.setQueryData(
        ["charges", swingId],
        (prevCharges: ChargeDto[] | undefined) => [
          ...(prevCharges || []),
          {
            ...newChargeInfo,
          },
        ]
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["charges", swingId] }),
  });
}

//DELETE hook (delete charge in api)
export function useDeleteCharge(swingId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (chargeId: string) => {
      await chargeService.deleteCharge(chargeId);
    },
    onMutate: (chargeId: string) => {
      queryClient.setQueryData(
        ["charges", swingId],
        (prevCharges: ChargeDto[] | undefined) =>
          prevCharges?.filter((charge: ChargeDto) => charge.id !== chargeId)
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["charges", swingId] }),
  });
}
