import React from "react";
import { usePendingCustomerRequests } from "../../hooks/usePendingCustomerRequests";
import { useUnseenAlerts } from "../../hooks/useUnseenAlerts";
import BannerAlert from "../alerts/banner-alert.component";

export default function PendingCustomerRequests() {
  return (
    <BannerAlert
      hook={usePendingCustomerRequests}
      type={"WARNING"}
      message={
        "There is one or more customer requests that have not yet been actioned. Please review these requests."
      }
      link={"/customer/requests"}
    ></BannerAlert>
  );
}
