import React, { useEffect, useState } from "react";
import { ContactDto } from "../../contracts/models/ContactDto";
import ContactsService from "../../services/contacts.service";
import AddContact from "./add-contact.component";

export default function Contacts() {
  const [contacts, setContacts] = useState<ContactDto[]>([]);

  useEffect(() => {
    getContacts();
  }, []);

  const getContacts = () => {
    ContactsService.getContacts().then(
      (response) => {
        setContacts(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  let contactsTable;
  if (contacts && contacts.length > 0) {
    let contactList = contacts.map((contact) => (
      <tr key={contact.id}>
        <th scope="row">{contact.name}</th>
        <td>{contact.telephone}</td>
        <td>{contact.email}</td>
      </tr>
    ));

    contactsTable = (
      <div className="address-list">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Telephone</th>
                <th scope="col">Email</th>
              </tr>
            </thead>
            <tbody>{contactList}</tbody>
          </table>
        </div>
      </div>
    );
  } else {
    contactsTable = (
      <div className="alert alert-danger">
        <p>Contact list empty. Add a contact using the form on this page.</p>
      </div>
    );
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <div className="card card-container">
            <h3 className="page-title">Contacts List</h3>
            {contactsTable}
          </div>
        </div>
        <div className="col-md-4">
          <AddContact onComplete={getContacts} />
        </div>
      </div>
    </div>
  );
}
