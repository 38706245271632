import { UseQueryResult, useQuery } from "@tanstack/react-query";
import userService from "../services/user.service";
import { PermissionType } from "../contracts/enums/PermissionType";

export function usePermissions(authToken: string): UseQueryResult<PermissionType[]> {
  return useQuery({
    queryKey: ["permissions", authToken],
    queryFn: async () => {
      if (!authToken) {
        console.log("no token");
        return [];
      }
      return await userService.getUserPermissions();
    },
    staleTime: 120000,
    refetchInterval: 120000,
    enabled: !!authToken,
  });
}
