import vehicleService from "../services/vehicle.service";
import { useQuery } from "@tanstack/react-query";

export function useVehicleImage(name: any) {
  return useQuery({
    queryKey: ["vehicleImage", name],
    queryFn: async () => {
      return await vehicleService.getVehicleImage(name);
    },
  });
}
