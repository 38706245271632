import React from "react";
import "./Compliance.css";
import Question from "./question.component";

export default function Questionnaire({
  questionnaire,
  submissionAnswers
}: any) {
  return (
    <div className="questionnaire-container">
    <div className="row">
      <div className="col">
        <p className="section-title">Submission Questionnaire</p>
        <p className="label">{questionnaire.name}</p>
        <p className="value">{questionnaire.description}</p>
      </div>
    </div>
    {questionnaire.questionnaireQuestions.map((q: any) => <Question key={q.id} question={q.question} submissionAnswers={submissionAnswers}></Question>)}  
    </div>
  );
}
