import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LoadingSpinner from "../loading/loading-spinner.component";
import DefaultModal from "../modal/modal.component";
import ExpenseImage from "./expense-image.component";
import Moment from "react-moment";
import ExpensesService from "../../services/expenses.service";
import DriverInformationLine from "../drivers/driver-information-line.component";
import "./Expenses.css";
import JobInformationSection from "../jobs/job-information-section.component";

const MySwal = withReactContent(Swal);

export default function ViewExpense({ expense }: any) {
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const getStatusStyle = () => {
    switch (expense.status) {
      case "PENDING":
        return "orange";
      case "DECLINED":
        return "red";
      case "ACCEPTED":
        return "green";
    }
  };

  const markProcessed = (status: any) => {
    MySwal.fire({
      title: "Are you sure?",
      text: `This will mark the expense mark as '${status}'`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "I'm sure.",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        ExpensesService.markProcessed(expense.id, status).then(
          () => {
            setLoading(false);
            // @ts-expect-error TS(2769): No overload matches this call.
            queryClient.invalidateQueries("expenses");
            MySwal.fire({
              title: "Success!",
              text: "Expense successfully updated",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
          },
          (error: any) => {
            setLoading(false);
            MySwal.fire({
              title: "Error",
              text: error.response.data,
              icon: "error",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
          }
        );
      }
    });
  };

  const toggleVATCharged = (isVATCharged: boolean) => {
    MySwal.fire({
      title: "Are you sure?",
      text: `This will ensure that for this expense, the client is ${isVATCharged ? "charged" : "not charged"} for VAT.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "I'm sure.",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        ExpensesService.markVatCharged(expense.id, isVATCharged).then(
          () => {
            setLoading(false);
            // @ts-expect-error TS(2769): No overload matches this call.
            queryClient.invalidateQueries("expenses");
            MySwal.fire({
              title: "Success!",
              text: "Expense VAT status successfully updated",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
          },
          (error) => {
            setLoading(false);
            MySwal.fire({
              title: "Error",
              text: error.response.data,
              icon: "error",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
          }
        );
      }
    });
  };

  return (
    <div>
      <DefaultModal
        title={"View Expense"}
        trigger={"View Expense"}
        content={
          <>
            {loading ? (
              <LoadingSpinner></LoadingSpinner>
            ) : (
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="expense-container">
                      <p className="section-title">Expense information</p>
                      <div className="row">
                        <div className="col">
                          <p className="label">Status</p>
                          <p className={`value ${getStatusStyle()}`}>
                            {expense.status}
                          </p>
                        </div>
                        <div className="col">
                          <p className="label">Type</p>
                          <p className="value">{expense.type}</p>
                        </div>
                        <div className="col">
                          <p className="label">Vehicle</p>
                          <p className="value">
                            {expense.vehicleRegistration ??
                              (expense?.vehicleMove.vehicle
                                ? expense?.vehicleMove?.vehicle?.registration
                                : "MISSING REG")}
                          </p>
                        </div>
                        <div className="col">
                          <p className="label">Amount</p>
                          <p className="value">£{expense.amount}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-8">
                          <p className="label">Description</p>
                          <p className="value">{expense.expenseDescription}</p>
                        </div>
                        <div className="col-md-4">
                          <p className="label">Created</p>
                          <p className="value">
                            <Moment
                              format="DD/MM/YYYY HH:mm"
                              date={expense.createdAt}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="expense-actions">
                      {expense.status != "ACCEPTED" &&
                        expense.status != "PAID" && (
                          <button
                            className="btn btn-success"
                            type="button"
                            onClick={() => markProcessed("ACCEPTED")}
                          >
                            Accept
                          </button>
                        )}
                      {expense.status == "ACCEPTED" && (
                        <button
                          className="btn btn-success"
                          type="button"
                          onClick={() => markProcessed("PAID")}
                        >
                          Paid
                        </button>
                      )}
                      {expense.status != "DECLINED" && (
                        <button
                          className="btn btn-danger"
                          type="button"
                          onClick={() => markProcessed("DECLINED")}
                        >
                          Decline
                        </button>
                      )}
                      <button
                        onClick={() => toggleVATCharged(!expense.vatCharged)}
                        className="btn btn-info"
                        type="button"
                      >
                        Toggle VAT Charge{" "}
                        {expense.vatCharged === true ? "On" : "Off"}
                      </button>
                    </div>
                  </div>
                </div>
                {expense.driver && <DriverInformationLine
                  driver={expense.driver} 
                  vehicleMove={expense.vehicleMove}
                  swing={expense.job.swing}
                ></DriverInformationLine>}
                <JobInformationSection
                  jobId={expense.jobId}
                  vehicleMove={expense.vehicleMove}
                  swing={expense.job.swing}
                ></JobInformationSection>
                {expense && expense.expenseImages && (
                  <div className="row">
                    <div className="col">
                      <p className="section-title">Expense Images</p>
                      {expense.expenseImages.map((image: any) => (
                        <ExpenseImage
                          key={image.name}
                          expenseId={expense.id}
                          image={image}
                        ></ExpenseImage>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        }
      ></DefaultModal>
    </div>
  );
}
