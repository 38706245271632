import { Field, Form, Formik } from 'formik';
import React, { useState } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';
import ContactService from "../../services/contacts.service";

const AddContactSchema = Yup.object().shape({
  name: Yup.string()
    .required('Field is required')
    .min(2, 'Field is too short')
    .max(150, 'Field is too long'),
  telephone: Yup.string()
    .required('Field is required')
    .min(2, 'Field is too short')
    .max(150, 'Field is too long'),
  email: Yup.string()
    .email('Invalid email')
    .required('Field is required'),
});

// <div className="alert alert-danger" role="alert">
//   This is not a valid email.
// </div>

const MySwal = withReactContent(Swal);

export default function AddContact({
  onComplete
}: any) {
  const [isLoading, setLoading] = useState(false);

  const handleSubmission = (contact: any, resetForm: any) => {
    setLoading(true)
    ContactService.saveContact(contact).then(
      () => {
        setLoading(false)
        onComplete();
        resetForm({})
        MySwal.fire({
          title: "Success",
          text: "Contact successfully added.",
          icon: "success",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        })

      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        MySwal.fire({
          title: "Error",
          text: resMessage,
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        })

        setLoading(false);
      }
    );
  }

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <h3 className="page-title">Add Contact</h3>
        <p className="required-text">Required fields<span className="required">*</span></p>
        {/* {!this.state.successful && this.state.message ? <div className="alert alert-danger" role="alert">{this.state.message}</div> : null} */}

        <Formik
          initialValues={{
            name: "",
            email: "",
            telephone: ""
          }}
          validationSchema={AddContactSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmission(values, resetForm);
          }}
        >
          {({ errors, touched, isValid, dirty }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="name">Name<span className="required">*</span></label>
                {errors.name && touched.name ? (
                  <div className="alert alert-danger" role="alert">{errors.name}</div>
                ) : null}
                <Field className="form-control" name="name" />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email<span className="required">*</span></label>
                {errors.email && touched.email ? (
                  <div className="alert alert-danger" role="alert">{errors.email}</div>
                ) : null}
                <Field className="form-control" name="email" />
              </div>
              <div className="form-group">
                <label htmlFor="telephone">Telephone<span className="required">*</span></label>
                {errors.telephone && touched.telephone ? (
                  <div className="alert alert-danger" role="alert">{errors.telephone}</div>
                ) : null}
                <Field className="form-control" name="telephone" />
              </div>                            
              <div className="form-group">
                <button className="btn btn-primary btn-block" disabled={isLoading || !dirty || !isValid} type="submit">Add Contact</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}