import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Moment from "react-moment";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import jobService from "../../services/job.service";
import SwingService from "../../services/swing.service";
import AddSwingForm from "./add-job-group-form.component";
import LoadingSpinner from "../loading/loading-spinner.component";
import DefaultModal from "../modal/modal.component";
import DuplicateSwing from "./duplicate-swing.component";
import { Link } from "react-router-dom";
import SelectSwingCustomerRequest from "../customer-requests-management/select-swing-customer-requests.component";
import { CustomerRequestDetails } from "../customer-requests/view-customer-request.component";
import { SwingDto } from "../../contracts/models/SwingDto";
import SetPaymentNumber from "./set-payment-number";
import ChargesTable from "../charges/charges-table-comonent";
import { useGetCharges } from "../../hooks/useCharges";
import JobGroupDiscountForm from "./job-group-discount.component";
import ChangeParentSwing from "./change-parent-swing";

const MySwal = withReactContent(Swal);

export default function Swing({ swing }: { swing: SwingDto }) {
  const [loading, setLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const queryClient = useQueryClient();

  console.log(swing);
  const releaseJob = () => {
    MySwal.fire({
      title: "Are you sure you want to release pending jobs?",
      text: "Pending jobs will be released to the selected drivers for viewing and acceptance",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, release them!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        SwingService.releaseSwing(swing.id).then(
          () => {
            setLoading(false);
            // @ts-expect-error TS(2769): No overload matches this call.
            queryClient.invalidateQueries("jobs");
            MySwal.fire({
              title: "Jobs successfully Released",
              text: "All pending jobs were successfully released to drivers",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
          },
          (error) => {
            setLoading(false);
            console.log(error.response);
            var msg =
              error.response?.data ?? "An error occured releasing the swing.";
            MySwal.fire({
              title: "Error",
              text: msg,
              icon: "error",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
          }
        );
      }
    });
  };

  const deleteSwing = (swingId: any) => {
    MySwal.fire({
      title: "Are you sure you want to delete this swing?",
      text: "This will remove the swing and its related data from the swing list and it cannot be recovered.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        SwingService.deleteSwing(swingId).then(
          () => {
            setLoading(false);

            MySwal.fire({
              title: "Job Successfully Deleted",
              text: "The swing and all related data has been removed.",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            }).then(() => {
              window.location.replace("/");
            });
          },
          (error) => {
            setLoading(false);
            MySwal.fire({
              title: "Error",
              text: error.response.data,
              icon: "error",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
          }
        );
      }
    });
  };

  const regenerateReference = (swingId: any) => {
    MySwal.fire({
      title: "Are you sure you want to regenerate this swing reference?",
      text: "This will remove the update the swing job reference based on updated swing and customer information.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        SwingService.regenerateReference(swingId).then(
          () => {
            setLoading(false);

            // @ts-expect-error TS(2769): No overload matches this call.
            queryClient.invalidateQueries("jobs");
            // @ts-expect-error TS(2769): No overload matches this call.
            queryClient.invalidateQueries("swings");
            MySwal.fire({
              title: "Job Reference Updated",
              text: "The reference has been successfully regenerated.",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            }).then(() => {});
          },
          (error) => {
            setLoading(false);
            MySwal.fire({
              title: "Error",
              text: error.response.data,
              icon: "error",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
          }
        );
      }
    });
  };

  const editSwing = (swingData: any, resetForm: any) => {
    MySwal.fire({
      title: "Are you sure you want to edit this swing?",
      text: "Editing this swing will update the details for all drivers who view this job. If drivers have already accepted this job, they will be provided the new swing details when they next view the job.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, edit it!",
    }).then((result) => {
      SwingService.editSwing(swing.id, swingData).then(
        (response) => {
          MySwal.fire({
            title: "Success",
            text: "Job successfully edited.",
            icon: "success",
            // @ts-expect-error TS(2769): No overload matches this call.
            button: "Continue",
          });
          resetForm();
          // @ts-expect-error TS(2769): No overload matches this call.
          queryClient.invalidateQueries("swing");
        },
        (error) => {
          console.log(error);
          if (error.response) {
            MySwal.fire({
              title: "Error",
              text: error.response.data,
              icon: "error",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
          }
        }
      );
    });
  };

  const exportPurchaseOrder = (swingId: any, isInvoice: boolean = false) => {
    setIsExporting(true);
    (isInvoice
      ? jobService.exportInvoice(swingId)
      : jobService.exportPurchaseOrder(swingId)
    )
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute(
          "download",
          swing.jobReference +
            "-" +
            (isInvoice ? "INVOICE" : "PO-REQUEST") +
            ".pdf"
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        setIsExporting(false);
        queryClient.invalidateQueries({ queryKey: ["swing"] });
      })
      .catch((error) => {
        MySwal.fire({
          title: "Error",
          text: "Error exporting - please contact support.",
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
        setIsExporting(false);
      });
  };

  const exportSwingData = (swingId: any) => {
    setIsExporting(true);
    jobService
      .exportSwingCSV(swingId)
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute(
          "download",
          swing.jobReference + "-TRACKEREXPORT.csv"
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        setIsExporting(false);
      })
      .catch((error) => {
        MySwal.fire({
          title: "Error",
          text: "Error exporting - please contact support.",
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
        setIsExporting(false);
      });
  };

  //call READ hook
  const {
    data: charges = [],
    isFetching: isFetchingCharges,
    isError: isLoadingChargesError,
  } = useGetCharges({ swingId: swing.id });

  return (
    <div>
      {loading || !swing ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <>
          <div className="swing-container">
            <div className="row">
              <div className="col">
                {swing.jobReference ? (
                  <div className="information-container">
                    <label className="job-label">Swing Reference</label>
                    <h4 className="job-description">{swing.jobReference}</h4>
                    {swing.parentSwingId && (
                      <>
                        {swing.id == swing.parentSwingId ? (
                          <span className="alert alert-success" role="alert">
                            Parent Swing
                          </span>
                        ) : (
                          <>
                            <Link to={"/swing/view/" + swing.parentSwingId}>
                              <Button className="btn info">
                                View Parent Swing
                              </Button>
                            </Link>
                          </>
                        )}
                      </>
                    )}
                  </div>
                ) : null}
                <ChangeParentSwing swingId={swing.id} currentParentId={swing.parentSwingId} />
                {swing.collectionDate ? (
                  <div className="information-container">
                    <label className="job-label">Job Date</label>
                    <h4 className="job-description">
                      <Moment format="DD/MM/YYYY" date={swing.collectionDate} />
                    </h4>
                  </div>
                ) : null}
                {swing.jobEndDate ? (
                  <div className="information-container">
                    <label className="job-label">Job End Date</label>
                    <h4 className="job-description">
                      <Moment format="DD/MM/YYYY" date={swing.jobEndDate} />
                    </h4>
                  </div>
                ) : null}
                {swing.meetTime ? (
                  <div className="information-container">
                    <label className="job-label">Meet Time</label>
                    <h4 className="job-description">
                      <Moment format="HH:mm" date={swing.meetTime} />
                    </h4>
                  </div>
                ) : null}
                {swing.shiftLength ? (
                  <div className="information-container">
                    <label className="job-label">Shift Length</label>
                    <h4 className="job-description">{swing.shiftLength}</h4>
                  </div>
                ) : null}
                {swing.groundTransportRequirement ? (
                  <div className="information-container">
                    <label className="job-label">
                      Ground Transport Requirement
                    </label>
                    <h4 className="job-description">
                      {swing.groundTransportRequirement}
                    </h4>
                  </div>
                ) : null}

                <div className="information-container">
                  <label className="job-label">Planned Out of Hours</label>
                  <h4 className="job-description">
                    {swing.plannedOutOfHours ? "Yes" : "No"}
                  </h4>
                </div>

                <div className="information-container">
                  <label className="job-label">Overnight Move</label>
                  <h4 className="job-description">
                    {swing.overnightMove ? "Yes" : "No"}
                  </h4>
                </div>

                {swing.meetLocation ? (
                  <div className="address-container text-left">
                    <label className="job-label">Meet Location Address</label>
                    {swing.meetLocation.line1 ? (
                      <p className="job-information">
                        {swing.meetLocation.line1}
                      </p>
                    ) : null}
                    {swing.meetLocation.line2 ? (
                      <p className="job-information">
                        {swing.meetLocation.line2}
                      </p>
                    ) : null}
                    {swing.meetLocation.city ? (
                      <p className="job-information">
                        {swing.meetLocation.city}
                      </p>
                    ) : null}
                    {swing.meetLocation.country ? (
                      <p className="job-information">
                        {swing.meetLocation.country}
                      </p>
                    ) : null}
                    {swing.meetLocation.postcode ? (
                      <p className="job-information">
                        {swing.meetLocation.postcode}
                      </p>
                    ) : null}
                    {swing.meetLocation.addressInformation ? (
                      <p className="job-information">
                        {swing.meetLocation.addressInformation}
                      </p>
                    ) : null}
                  </div>
                ) : null}
              </div>
              {swing.customer ? (
                <div className="col customer-container">
                  <label className="job-label">Customer Name</label>
                  {swing.customer.name ? (
                    <p className="job-information">{swing.customer.name}</p>
                  ) : null}
                  <label className="job-label">Customer Contact Name</label>
                  {swing.customer.contactName ? (
                    <p className="job-information">
                      {swing.customer.contactName}
                    </p>
                  ) : null}
                  <label className="job-label">Customer Email</label>
                  {swing.customer.email ? (
                    <p className="job-information">{swing.customer.email}</p>
                  ) : null}
                  <label className="job-label">Customer Telephone</label>
                  {swing.customer.contactTelephone ? (
                    <p className="job-information">
                      {swing.customer.contactTelephone}
                    </p>
                  ) : null}

                  <div className="information-container">
                    <p className="job-information">
                      Created At:{" "}
                      <Moment
                        format="DD/MM/YYYY HH:mm"
                        date={swing.createdAt}
                      />
                      <p>
                        Updated:{" "}
                        <Moment
                          format="DD/MM/YYYY HH:mm"
                          date={swing.updatedAt}
                        />
                      </p>
                    </p>
                  </div>

                  <div className="d-flex flex-column flex-end max-w-100 md:max-w-75">
                    {swing?.customerJobRequestAllocation
                      ?.customerJobRequest && (
                      <div className="my-2">
                        <DefaultModal
                          trigger={"View Customer Request"}
                          title={"View Customer Request"}
                          content={
                            <CustomerRequestDetails
                              request={
                                swing?.customerJobRequestAllocation
                                  ?.customerJobRequest
                              }
                            />
                          }
                        />
                      </div>
                    )}
                    <div className="my-2">
                      <DefaultModal
                        trigger={"Assign Customer Request"}
                        title={"Edit Customer Request"}
                        content={
                          <SelectSwingCustomerRequest swingId={swing.id} />
                        }
                      />
                    </div>
                    {swing.parentSwingId && swing.id != swing.parentSwingId ? (
                      <Link className="row offset-6 col-6 alert alert-info" to={"/swing/view/" + swing.parentSwingId}>
                          All Invoicing and Payment Details
                          are managed in the Parent Swing.
                      </Link>
                    ) : (
                      <>
                        {swing.poNumber && (
                          <div className="my-2">
                            <label className="job-label">PO Details</label>
                            <p className="job-information">
                              {swing.poNumber} - £{swing.poAmount}
                            </p>
                          </div>
                        )}
                        {swing.invoiceNumber && (
                          <div className="my-2">
                            <label className="job-label">Invoice Details</label>
                            <p className="job-information">
                              {swing.invoiceNumber} - £{swing.invoiceAmount}
                            </p>
                          </div>
                        )}
                        {swing.paymentStatus == "POREQUESTED" && (
                          <div className="my-2">
                            <SetPaymentNumber swingId={swing.id} type="PO" />
                          </div>
                        )}
                        {swing.paymentStatus == "PORECEIVED" && (
                          <div className="my-2">
                            <SetPaymentNumber
                              swingId={swing.id}
                              type="invoice"
                            />
                          </div>
                        )}
                        <div className="my-2">
                          <Button
                            className="btn btn-primary"
                            onClick={() => exportPurchaseOrder(swing.id)}
                            disabled={isExporting}
                          >
                            {swing.paymentStatus == "PENDING"
                              ? "Generate New Purchase Order"
                              : "Download Purchase Order"}
                          </Button>
                        </div>
                        <div>
                          <DefaultModal
                            size="lg"
                            title={
                              swing.discountType && swing.discountAmount
                                ? "Edit Discount"
                                : "Add Discount"
                            }
                            content={<JobGroupDiscountForm swing={swing} />}
                            trigger={
                              swing.discountType && swing.discountAmount
                                ? "Edit Discount"
                                : "Add Discount"
                            }
                          ></DefaultModal>
                        </div>

                        {(swing.paymentStatus == "PORECEIVED" ||
                          swing.paymentStatus == "INVOICED") && (
                          <div className="my-2">
                            <Button
                              className="btn btn-primary"
                              onClick={() =>
                                exportPurchaseOrder(swing.id, true)
                              }
                              disabled={isExporting}
                            >
                              {swing.paymentStatus == "INVOICED"
                                ? "Download"
                                : "Generate"}{" "}
                              Invoice
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                    <div>
                      <DefaultModal
                        size="xl"
                        title={"Charges"}
                        content={
                          <ChargesTable swingId={swing.id}></ChargesTable>
                        }
                        trigger={
                          <button
                            className="btn btn-primary my-2"
                            type="button"
                            data-toggle="tooltip"
                            data-placement="top"
                            disabled={
                              isLoadingChargesError || isFetchingCharges
                            }
                          >
                            {isFetchingCharges ? (
                              <div>
                                Fetching Charges
                                <span
                                  className="ml-2 spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </div>
                            ) : isLoadingChargesError ? (
                              "Error Loading Charges"
                            ) : (
                              `View Charges (${charges.length})`
                            )}
                          </button>
                        }
                      ></DefaultModal>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="row swing-action-container">
              {swing.jobs &&
              swing.jobs.filter((j: any) => j.status === "PENDING").length >
                0 ? (
                <Button
                  className="btn danger"
                  // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
                  onClick={() => releaseJob(swing.id)}
                >
                  Release Pending Jobs
                </Button>
              ) : null}
              <Button
                className="btn danger"
                onClick={() => deleteSwing(swing.id)}
              >
                Delete Swing
              </Button>
              <Button
                className="btn danger"
                onClick={() => regenerateReference(swing.id)}
              >
                Regenerate Swing Reference
              </Button>
              <Button
                className="btn info"
                onClick={() => exportSwingData(swing.id)}
                disabled={isExporting}
              >
                Export Jobs
              </Button>
              <DefaultModal
                trigger={"Edit Swing Details"}
                title={"Edit Swing Details"}
                content={
                  <AddSwingForm
                    onSubmit={editSwing}
                    initialValues={swing}
                  ></AddSwingForm>
                }
              ></DefaultModal>
              <DuplicateSwing swingId={swing.id}></DuplicateSwing>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
