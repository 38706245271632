import React from "react";

import { Link } from "react-router-dom";
import Select from "react-select";
import { convertVehicles } from "../../hooks/useSwingVehicles";

export default function SelectVehicle({
  vehicles,
  selectVehicle,
  defaultVehicle,
}: any) {
  //will need some additional filtering here where we can accept a class and it returns that class

  return (
    <>
      <label htmlFor="vehicleId">Vehicle</label>
      <Select
        onChange={selectVehicle}
        options={convertVehicles(vehicles)}
        isClearable={false}
        name="vehicleId"
        defaultValue={vehicles ? convertVehicles(vehicles).find((v: any) => v.value === defaultVehicle) : null}
      />
      <p className="input-note">
        The vehicle list auto-updates and can be maintained via the{" "}
        <Link to={"/vehicles"}>Vehicles</Link> page.
      </p>
    </>
  );
}
