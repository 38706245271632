import React, { Component } from "react";
import ExampleWithReactQueryProvider from "./swing-list/swing-list.component";
import { useQuery } from "@tanstack/react-query";
import SwingList from "./swing-list/swing-list.component";

export default function Dashboard() {
  {
    return (
      <div className="jumbotron">
        <SwingList></SwingList>
      </div>
    );
  }
}
