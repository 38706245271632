import { useQuery } from "@tanstack/react-query";
import expensesService from "../services/expenses.service";

export function useVehicleExpenses(vehicleId: number, jobReference: string) {
  return useQuery({
    queryKey: ["vehicleExpenses", vehicleId],
    queryFn: async () => {
      const response = await expensesService.getVehicleExpenses(vehicleId, jobReference);
      return response.data;
    },
  });
}
