import { AxiosResponse } from "axios";
import { OvertimeJobRecordDto } from "../components/jobs/overtime-approval.component";
import api from "./network";

const API_URL = "Jobs";

class JobService {
  getJobs(swingId: any, filters: any) {
    return api.get(API_URL, {
      params: {
        swingId,
      },
    });
  }

  getJob(jobId: number) {
    return api.get(`${API_URL}/${jobId}`).then((response) => {
      return response.data;
    });
  }

  saveNewJob(job: any) {
    console.log(job);
    return api.post(API_URL, {
      jobDescription: job.jobDescription,
      jobClassRequirementId: job.requiredClassId,
      fitnessToDriveRequired: job.fitnessToDriveRequired,
      jobLength: job.jobLength,
      swingId: job.swingId,
      vehicles: job.vehicleMoves,
    });
  }

  editJob(job: any) {
    console.log(job);
    return api.post(API_URL + "/edit", job);
  }

  deleteJob(jobId: any) {
    return api.delete(API_URL, {
      id: jobId,
    });
  }

  getJobRemovalAlerts(jobBased = true, unresolved = true) {
    return api.get(API_URL + "/removal/alerts", {
      params: {
        jobBased,
        unresolved,
      },
    });
  }

  allocateDriver(driverId: any, jobId: any) {
    return api.post(API_URL + "/allocate", {
      driverId,
      jobId,
    });
  }

  setTeamLeader(jobId: any, value: any) {
    return api.post(API_URL + "/teamleader", {
      jobId,
      value,
    });
  }

  addPendingDrivers(driverIds: any, jobId: any) {
    return api.post(API_URL + "/drivers/pending/add", {
      driverIds,
      jobId,
    });
  }

  removePendingDrivers(driverIds: any, jobId: any) {
    return api.post(API_URL + "/drivers/pending/remove", {
      driverIds,
      jobId,
    });
  }

  getAvailablePendingDrivers(jobId: any) {
    return api.get(API_URL + "/drivers/available", {
      params: {
        jobId,
      },
    });
  }

  getPendingDrivers(jobId: any) {
    return api.get(API_URL + "/drivers/pending", {
      params: {
        jobId,
      },
    });
  }

  exportSwingCSV(swingId: any) {
    return api.get(API_URL + "/export/swing/csv", {
      responseType: "blob",
      referenceType: "blob",
      params: {
        swingId,
      },
    });
  }

  exportPurchaseOrder(swingId: any) {
    return api.get(API_URL + "/export/purchase-order", {
      responseType: "blob",
      referenceType: "blob",
      params: {
        swingId,
      },
    });
  }

  exportInvoice(swingId: any) {
    return api.get(API_URL + "/export/invoice", {
      responseType: "blob",
      referenceType: "blob",
      params: {
        swingId,
      },
    });
  }

  getSwings(filters: any, pageNumber: any) {
    console.log(filters);
    return api.get(API_URL, {
      params: {
        fromDate: filters.fromDate,
        toDate: filters.toDate,
        searchTerm: filters.searchTerm,
        available: filters.available,
        accepted: filters.accepted,
        progress: filters.progress,
        completed: filters.completed,
        closed: filters.closed,
        failed: filters.failed,
        pending: filters.pending,
        cancelled: filters.cancelled,
        customerId: filters.customerId,
        pageNumber,
      },
    });
  }

  exportFilteredSwingCSV(exportType: any, filters: any) {
    return api.get(API_URL + "/export/search/csv", {
      responseType: "blob",
      referenceType: "blob",
      params: {
        exportType,
        fromDate: filters.fromDate,
        toDate: filters.toDate,
        searchTerm: filters.searchTerm,
        available: filters.available,
        accepted: filters.accepted,
        progress: filters.progress,
        completed: filters.completed,
        pending: filters.pending,
        closed: filters.closed,
        failed: filters.failed,
        cancelled: filters.cancelled,
        customerId: filters.customerId,
      },
    });
  }
  setOvertimeApproval(
    JobId: number,
    OvertimeApprovalStatus: string,
    CheckInTime: string,
    CheckOutTime?: string,
    ChargeCheckInTime?: string,
    ChargeCheckOutTime?: string
  ): Promise<AxiosResponse<any>> {
    return api.post(API_URL + "/overtime", {
      JobId,
      OvertimeApprovalStatus,
      CheckInTime,
      CheckOutTime,
      ChargeCheckInTime,
      ChargeCheckOutTime
    });
  }

  getOvertimeDrivers(
    fromDate: string,
    toDate: string,
    archived: boolean
  ): Promise<AxiosResponse<OvertimeJobRecordDto[]>> {
    return api.get(API_URL + "/overtime", {
      params: {
        fromDate,
        toDate,
        archived,
      },
    });
  }

  setPaymentStatusApproved(jobId: any) {
    return api.post(API_URL + "/status/approved", {
      jobId,
    });
  }

  markJobCompleted(jobId: any) {
    return api.post(API_URL + "/manual/completed", {
      jobId,
    });
  }

  resetJob(jobId: any) {
    return api.post(API_URL + "/manual/reset", {
      jobId,
    });
  }

  toggleFitness(jobId: any) {
    return api.post(API_URL + "/toggle/fitness", {
      jobId,
    });
  }
  
  resetJobFailedStatus(jobId: any, vehicleMoveId: any) {
    return api.post(API_URL + "/manual/compliance/reset", {
      jobId,
      vehicleMoveId,
    });
  }

  reopenJob(jobId: any) {
    return api.post(API_URL + "/manual/reopen", {
      jobId,
    });
  }

  closeJob(jobId: any) {
    return api.post(API_URL + "/manual/close", {
      jobId,
    });
  }

  cancelJobs(jobIds: any, cancelledWithin24: any) {
    return api.post(API_URL + "/cancel", {
      jobIds,
      cancelledWithin24,
    });
  }

  overrideJobTimes(jobId: number, isCheckIn: boolean, overrideTime: string) {
    return api.post(API_URL + "/jobtimes", {
      jobId,
      isCheckIn,
      overrideTime,
    });
  }

  setPaymentNumber(t: {
    type: string;
    swingId: number;
    number: string;
    value: number;
  }) {
    return api.post("/swing/payment-number/value", t);
  }
}

export default new JobService();
