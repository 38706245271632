import React from "react";
import { Col, ListGroup, Row, Tab } from "react-bootstrap";
import "../admin/admin-component.css";
import ManageRoles from "./manage-roles-component";
import ManageUsers from "./manage-users-component";

export default function Admin() {
  return (
    <>
      <Tab.Container id="list-group-tabs" defaultActiveKey="#roles-permissions">
        <Row>
          <Col sm={12} lg={3}>
            <ListGroup className="shadow-sm">
              <ListGroup.Item
                className="d-flex align-items-center justify-content-between"
                action
                href="#roles-permissions"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  className="bi bi-person-lock"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m0 5.996V14H3s-1 0-1-1 1-4 6-4q.845.002 1.544.107a4.5 4.5 0 0 0-.803.918A11 11 0 0 0 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664zM9 13a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1" />
                </svg>
                <span className="list-group-item-text">
                  Roles & Permissions
                </span>
              </ListGroup.Item>
              <ListGroup.Item action href="#manage-users">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  className="bi bi-people"
                  viewBox="0 0 16 16"
                >
                  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                </svg>
                <span className="list-group-item-text">Manage Users</span>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col sm={12} lg={9} className="my-3 my-lg-0">
            <Tab.Content>
              <Tab.Pane eventKey="#roles-permissions">
                <ManageRoles />
              </Tab.Pane>
              <Tab.Pane eventKey="#manage-users">
                <ManageUsers />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
}
