import React from "react";

import { useDriverClassUpdatesByDriver } from "../../hooks/useDriverClassUpdatesByDriver";

export default function DriverClassUpdatesTable({
  updates,
  showDriver
}: any) {
  let classTable;
  if (updates && updates.length > 0) {
    let classList = updates.map((c: any) => <tr key={c.id}>
      <th>{c.classUpdateType}</th>
      <td className="center">{c.class?.name}</td>
      {showDriver && <th>{c.driver?.name}</th>}
      <th>{c.previousIssueDate}</th>
      <th>{c.newIssueDate}</th>
      <th>{c.previousExpiryDate}</th>
      <th>{c.newExpiryDate}</th>
      <th>{c.createdAt}</th>
    </tr>);

    classTable = (
      <div className="job-list">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Update Type</th>
                <th scope="col">Class Name</th>
                {showDriver && <th scope="col">Driver</th>}
                <th scope="col">Previous Issue Date</th>
                <th scope="col">New Issue Date</th>
                <th scope="col">Previous Expiry Date</th>
                <th scope="col">New Expiry Date</th>
                <th scope="col">Created At</th>
              </tr>
            </thead>
            <tbody>{classList}</tbody>
          </table>
        </div>
      </div>
    );
  } else {
    classTable = (
      <div className="alert alert-danger">
        <p>Class updates list empty.</p>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card-container">
          <h3 className="page-title">Driver Class Updates</h3>
          {classTable}
        </div>
      </div>
    </div>
  );
}
