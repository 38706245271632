import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";
import Select from "react-select";
import JobService from "../../services/job.service";
import VehicleMove from "../vehicles/vehicle-move.component";
import ViewVehicleList from "../vehicles/view-vehicle-list.component";
import AddOrEditVehicleMove from "./add-vehicle-move.component";
import SelectSwing from "./select-swing.component";
import { useAddresses } from "../../hooks/useAddresses";
import { useContacts } from "../../hooks/useContacts";
import { useSwingVehicles } from "../../hooks/useSwingVehicles";
import { useCurrentSwingVehicles } from "../../hooks/useCurrentSwingVehicles";
import { Classes } from "../../helpers/LicenceClasses";
import AdminViewCustomerRequestModal, { AdminViewCustomerRequestDetails } from "../customer-requests-management/admin-view-customer-request.component";
import { debug } from "console";
import { SwingDto } from "../../contracts/models/SwingDto";

const AddJobSchema = Yup.object().shape({
  jobDescription: Yup.string()
    .required("Job description is required")
    .min(2, "Field is too short")
    .max(300, "Field is too long"),
  requiredClassId: Yup.string().required("Field is required"),
  fitnessToDriveRequired: Yup.boolean().required("Fitness to drive is required")
});

const MySwal = withReactContent(Swal);

export default function AddJob({}) {
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState();
  const [error, setError] = useState();
  const [swing, setSwing] = useState<any>();
  const [jobLength, setJobLength] = useState(6);
  const [newVehicleMoves, setVehicleMoves] = useState([]);

  const { data: addresses } = useAddresses();
  const { data: contacts } = useContacts();

  const { data: swingVehicles } = useSwingVehicles(swing?.id ?? 0); //dont need both - improve this
  const { data: currentSwingVehicles } = useCurrentSwingVehicles(
    swing?.id ?? 0
  );

  // addVehicle(vehicle) {
  //   vehicle.rid = vehicleMoves.length + 1;
  //   console.log(vehicle)
  //   var vm = vehicleMoves;
  //   vm.push(vehicle);
  //   console.log(vehicle);
  //   setVehicleMoves(vm)
  // }

  const addVehicle = (mv: any) => {
    var vehicleMove = {};
    Object.assign(vehicleMove, mv);
    // @ts-expect-error TS(2339): Property 'vehicleDetails' does not exist on type '... Remove this comment to see the full error message
    if (vehicleMove.vehicleDetails === "unknown") {
      // @ts-expect-error TS(2339): Property 'vehicle' does not exist on type '{}'.
      delete vehicleMove.vehicle;
      // @ts-expect-error TS(2339): Property 'vehicleId' does not exist on type '{}'.
      delete vehicleMove.vehicleId;
      // @ts-expect-error TS(2339): Property 'vehicleId' does not exist on type '{}'.
    } else if (vehicleMove.vehicleId) {
      var newVehicle = swingVehicles.find(
        // @ts-expect-error TS(2339): Property 'vehicleId' does not exist on type '{}'.
        (s: any) => s.id === vehicleMove.vehicleId
      );
      // @ts-expect-error TS(2339): Property 'vehicle' does not exist on type '{}'.
      vehicleMove.vehicle = newVehicle;
    }
    // @ts-expect-error TS(2339): Property 'rid' does not exist on type '{}'.
    vehicleMove.rid = newVehicleMoves.length + 1;
    // @ts-expect-error TS(2769): No overload matches this call.
    setVehicleMoves(newVehicleMoves.concat(vehicleMove));
  };

  const onSubmitVehicleMove = (vehicleMove: any, resetForm: () => void) => {
    addVehicle(vehicleMove);
    resetForm();
    MySwal.fire({
      title: "Success",
      text: "Vehicle move successfully added.",
      icon: "success",
      // @ts-expect-error TS(2769): No overload matches this call.
      button: "Continue",
    });
  };

  const removeVehicle = (id: any) => {
    // @ts-expect-error TS(2339): Property 'rid' does not exist on type 'never'.
    var vms = newVehicleMoves.filter((v) => v.rid !== id);
    setVehicleMoves(vms);
  };

  const editVehicle = (vehicle: any, existing: any) => {
    removeVehicle(existing);
    addVehicle(vehicle);
  };

  const onChangeSwing = (e: SwingDto | undefined) => {
    console.log("swing baby!!", e);
    if (e && swing !== e) {
      setSwing(e);
    }
  };

  const handleSubmission = (job: any, resetForm: any) => {
    console.log(job);
    // if (newVehicleMoves.length === 0) {
    //   MySwal.fire({
    //     title: "Error",
    //     text: "Atleast one vehicle move is required per job. This can be entered as unknown if vehicle details are not known.",
    //     icon: "error",
    //     // @ts-expect-error TS(2769): No overload matches this call.
    //     button: "Continue",
    //   });
    // } else {}
    job.jobLength = jobLength ?? 6;
    job.vehicleMoves = newVehicleMoves;
    JobService.saveNewJob(job).then(
      (response) => {
        MySwal.fire({
          title: "Success",
          text: "Job successfully posted.",
          icon: "success",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        }).then(() => {
          setVehicleMoves([]);
        });
      },
      (error) => {
        console.log(error);

        MySwal.fire({
          title: "Error",
          text: error.response.data,
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
        setSuccessful(false);
        setMessage(error.title);
      }
    );
  };

  console.log(newVehicleMoves);
  const vehicleMoveElements = newVehicleMoves.map((move) => (
    // key={move.vehicleId}\
    <VehicleMove
      vehicleMove={move}
      removeVehicleMove={removeVehicle}
    ></VehicleMove>
  ));

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-container">
            <img
              src="/images/icons/job.png"
              alt="page-img"
              className="page-img"
            />
            <h3 className="page-title">Add new Job</h3>
            <p className="title-message">
              A job defines a collection of moves a driver will do within a job.{" "}
            </p>
            <p className="title-message">
              A collection of moved must be saved against a job, which has a
              collection and delivery addresses, on a certain day.
            </p>

            <p className="required-text">
              Required fields<span className="required">*</span>
            </p>

            {!successful && message ? (
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            ) : null}

            <div className="row">
              <div className="col">
                <Formik
                  initialValues={{
                    jobDescription: "",
                    requiredClassId: "",
                    fitnessToDriveRequired: true,
                  }}
                  validationSchema={AddJobSchema}
                  onSubmit={(values, { resetForm }) => {
                    // @ts-expect-error TS(2339): Property 'swingId' does not exist on type '{ jobDe... Remove this comment to see the full error message
                    values.swingId = swing?.id;
                    handleSubmission(values, resetForm);
                  }}
                >
                  {({ errors, touched, setFieldValue, isValid, dirty }) => (
                    <Form>
                      <div className="form-group">
                        <SelectSwing onChange={onChangeSwing}></SelectSwing>
                      </div>

                      {swing && (
                        <>
                          {swing?.customerJobRequestAllocation?.customerJobRequest?.id && 
                          <div className="">
                            <p>This swing is currently allocated to a customer job request.</p>
                            <AdminViewCustomerRequestDetails id={swing.customerJobRequestAllocation.customerJobRequest.id} />
                            </div>}
                          <div className="form-group">
                            <label htmlFor="name">
                              Job Description<span className="required">*</span>
                            </label>
                            {errors.jobDescription && touched.jobDescription ? (
                              <div className="alert alert-danger" role="alert">
                                {errors.jobDescription}
                              </div>
                            ) : null}
                            <Field
                              className="form-control"
                              name="jobDescription"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="class">
                              Licence Class Required
                              <span className="required">*</span>
                            </label>
                            {errors.requiredClassId &&
                            touched.requiredClassId ? (
                              <div className="alert alert-danger" role="alert">
                                {errors.requiredClassId}
                              </div>
                            ) : null}
                            <Select
                              onChange={(option: any) => {
                                setFieldValue("requiredClassId", option.value);
                              }}
                              options={Classes}
                              name="requiredClassId"
                            />
                            <p className="input-note">
                              Enter the maximum required license category for
                              this vehicle move.
                            </p>
                            <p className="input-note">
                              It will not be possible to edit this move for a
                              higher class vehicle selected.
                            </p>
                          </div>
                          <div className="form-group">
                            <label htmlFor="name">
                              Estimated Maximum Job Length
                              <span className="required">*</span>
                            </label>
                            <Select
                              onChange={(v: any) => setJobLength(v.value)}
                              options={[
                                { value: 2, label: "2 hours" },
                                { value: 4, label: "4 hours" },
                                { value: 6, label: "6 hours" },
                                { value: 8, label: "8 hours" },
                                { value: 10, label: "10 hours" },
                                { value: 12, label: "12 hours" },
                                { value: 14, label: "14 hours" },
                                { value: 15, label: "15 hours" },
                              ]}
                              defaultValue={{ value: 6, label: "6 hours" }}
                              isClearable={false}
                              name="jobLength"
                            />
                          </div>
                          <div className="form-group">
                    <label className="radio-label">
                      <Field type="checkbox" name="fitnessToDriveRequired" />
                      Does the Driver complete a fitness to drive test for this
                      job?
                    </label>
                    {errors.fitnessToDriveRequired && touched.fitnessToDriveRequired ? (
                      <div className="alert alert-danger" role="alert">
                        {errors.fitnessToDriveRequired}
                      </div>
                    ) : null}
                  </div>

                          <AddOrEditVehicleMove
                            addresses={addresses}
                            contacts={contacts}
                            vehicles={swingVehicles}
                            onSubmit={onSubmitVehicleMove}
                          ></AddOrEditVehicleMove>
                          <div className="card-columns">
                            {vehicleMoveElements}
                          </div>
                        </>
                      )}

                      {!isValid ? (
                        <div className="alert alert-danger" role="alert">
                          Correct form errors before submitting.
                        </div>
                      ) : null}

                      <div className="form-group">
                        <button
                          className="btn btn-primary btn-block"
                          disabled={!dirty || !isValid || !swing}
                          type="submit"
                        >
                          Post New Job
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <p className="posting-message">
              Posting this job will allow your or other admins to release this
              job to valid drivers.
            </p>
            <p className="posting-message">
              Jobs can be managed at any time via the dashboard
            </p>
          </div>
        </div>
      </div>
      {!swing && (
        <div className="row">
          <div className="col-md-12">
            <div className="card card-container">
              <h3 className="page-title">Current Job Vehicles</h3>

              <p className="title-message">
                View vehicles that are currently posted on jobs on this swing.
              </p>

              {currentSwingVehicles && currentSwingVehicles.length > 0 && (
                <ViewVehicleList
                  vehicles={currentSwingVehicles}
                ></ViewVehicleList>
              )}

              {!currentSwingVehicles ||
                (currentSwingVehicles.length === 0 && (
                  <div className="alert alert-warning">
                    There are no vehicles currently on this swing.
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
