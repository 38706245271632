import React from "react";
import { useJobRemovalAlerts } from "../../hooks/useJobRemovalAlerts";
import { useUnseenAlerts } from "../../hooks/useUnseenAlerts";
import BannerAlert from "../alerts/banner-alert.component";

export default function JobRemovalAlertBanner({}) {
  return (
    <BannerAlert
      hook={useJobRemovalAlerts}
      type={"ERROR"}
      message={
        "There is one or more job removals needing resolution. Please allocate a new driver now."
      }
      link={"/job/removals"}
    ></BannerAlert>
  );
}
