import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ButtonGroup, InputGroup, ToggleButton } from "react-bootstrap";
import { SwingDto } from "../../contracts/models/SwingDto";
import { DiscountType } from "../../contracts/enums/DiscountType";
import Swal from "sweetalert2";
import { SetDiscountDto } from "../../contracts/models/swingjobs/SetDiscountDto";
import swingService from "../../services/swing.service";
import { invalid } from "moment";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

interface Props {
  swing: SwingDto;
}

const validationSchema = Yup.object({
  discountAmount: Yup.number()
    .min(1, "Discount amount must be greater than 0")
    .required("Discount amount is required"),
});

const MyComponent: React.FC<Props> = ({ swing }) => {
  const queryClient = useQueryClient();
  let { id }: { id: string } = useParams();

  return (
    <div>
      <Formik
        initialValues={{
          discountType: swing.discountType || DiscountType.Percentage,
          discountAmount: swing.discountAmount || 0,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            const result = await swingService.setDiscountAsync({
              swingId: swing.id,
              discountType: values.discountType,
              discountAmount: values.discountAmount,
            } as SetDiscountDto);
            if (result) {
              Swal.fire("Success", "Discount set successfully", "success");
              queryClient.invalidateQueries({ queryKey: ["swing", id] });
            } else {
              Swal.fire("Error", "Failed to set discount", "error");
            }
          } catch (error) {
            Swal.fire("Error", "An unexpected error occurred", "error");
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="form-group">
              <label>Discount Type</label>
              <ButtonGroup toggle>
                <ToggleButton
                  type="radio"
                  name="discountType"
                  value={DiscountType.Percentage}
                  checked={values.discountType === DiscountType.Percentage}
                  onChange={(e) =>
                    setFieldValue("discountType", e.currentTarget.value)
                  }
                  variant="outline-primary"
                >
                  Percentage
                </ToggleButton>
                <ToggleButton
                  type="radio"
                  name="discountType"
                  value={DiscountType.Fixed}
                  checked={values.discountType === DiscountType.Fixed}
                  onChange={(e) =>
                    setFieldValue("discountType", e.currentTarget.value)
                  }
                  variant="outline-primary"
                >
                  Fixed Amount
                </ToggleButton>
              </ButtonGroup>
            </div>

            <div className="form-group">
              <label>Discount Value</label>
              <InputGroup>
                {values.discountType === DiscountType.Fixed && (
                  <>
                    <InputGroup.Prepend>
                      <InputGroup.Text>£</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Field
                      type="number"
                      name="discountAmount"
                      className="form-control"
                      min={0.01}
                      step={0.01}
                      onChange={(e: { target: { value: string } }) => {
                        const value =
                          e.target.value === ""
                            ? ""
                            : parseFloat(e.target.value);
                        setFieldValue("discountAmount", value);
                      }}
                      onBlur={(e: { target: { value: string } }) => {
                        const value =
                          e.target.value === ""
                            ? ""
                            : parseFloat(e.target.value).toFixed(2);
                        setFieldValue("discountAmount", value);
                      }}
                    />
                  </>
                )}
                {values.discountType === DiscountType.Percentage && (
                  <>
                    <Field
                      type="number"
                      name="discountAmount"
                      className="form-control"
                      min={0}
                      step={1}
                      onChange={(e: { target: { value: string } }) => {
                        const value =
                          e.target.value === ""
                            ? ""
                            : parseFloat(e.target.value);
                        setFieldValue("discountAmount", value);
                      }}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </>
                )}
              </InputGroup>
              <ErrorMessage
                name="discountAmount"
                component="div"
                className="text-danger"
              />
            </div>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MyComponent;
