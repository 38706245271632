import React from "react";
import "./Compliance.css";

export default function Answer({
  answer,
  wasSelected
}: any) {
  const colorClass = () => {
    switch (answer.answerStatusType) {
      case "Correct":
        return "green";
      case "CorrectFlagged":
        return "orange";
      case "Incorrect":
        return "red";
    }
  };
  const correctClass = wasSelected ? "selected" : "";

  const labelConversion = (label: any) => {
    switch (label) {
      case "Flag":
        return "Minor defect";
      case "Confirm":
        return "Roadworthy";
      case "Decline":
        return "Unroadworthy";
      default:
        return label;
    }
  };

  return (
    <p className={colorClass() + " " + correctClass}>
      {labelConversion(answer.label)}
    </p>
  );
}
