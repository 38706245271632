import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";
import { CustomerDto } from "../../contracts/models/CustomerDto";
import CustomerService from "../../services/customer.service";
import DefaultModal from "../modal/modal.component";

const MySwal = withReactContent(Swal);

export default function EditCustomerRates({
  customer,
  onComplete,
}: {
  customer: CustomerDto;
  onComplete: any;
}) {
  const fields = customer.rates.map((rate) => rate.id);

  const EditCustomerRatesSchema = Yup.object().shape(
    fields.reduce((acc: {[k: string]: any}, key: string) => {
      acc[key] = Yup.number().min(0, "Rate must be 0 or greater");
      return acc;
    }, {})
  );

  const [isLoading, setLoading] = useState(false);
  const handleSubmission = (values: any) => {
    setLoading(true);
    //convert each value value to a number

    let rates: { id: string; value: number }[] = [];
    Object.keys(values).forEach((key) => {
      rates.push({ id: key, value: parseFloat(values[key]) });
    });

    CustomerService.editCustomer({id: customer.id, email: customer.email, contactName: customer.contactName, contactTelephone: customer.contactTelephone, name: customer.name, shortcode: customer.shortcode, rates: rates}).then(
      (response) => {
        setLoading(false);
        if (response.status === 200) {
          onComplete();

          MySwal.fire({
            title: "Success",
            text: "Customer rates updated successfully!",
            icon: "success",
            // @ts-expect-error TS(2769): No overload matches this call.
            button: "Continue",
          });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        MySwal.fire({
          title: "Error",
          text: resMessage,
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });

        setLoading(false);
      }
    );
  };

  const initValues = fields.reduce((acc: {[k: string]: any}, key: string) => {
    acc[key] = customer.rates.find((rate) => rate.id === key)?.value || 0;
    return acc;
  }, {});

  console.log(initValues);

  return (
    <DefaultModal
      title={"Edit Customer Rates Card"}
      trigger={"Edit Rates Card"}
      content={
        <div className="col-md-12">
          <div className="card card-container">
            <h3 className="page-title">Edit Customer Rates Card</h3>
            <p className="required-text">
              Required fields<span className="required">*</span>
            </p>
            <Formik
              validationSchema={EditCustomerRatesSchema}
              onSubmit={(values) => {
                handleSubmission(values);
              }}
              //set initial values to the rates of the customer (customer.rates) with each key
              initialValues={initValues}
            >
              {({ errors, isValid }) => (
                <Form>
                  <>
                    {console.log(errors)}
                    {fields.map((rateField) => (
                      <div className="form-group" key={rateField}>
                        <label htmlFor={rateField}>
                          {customer?.rates?.find(r => r.id == rateField)?.name}
                          {" "} ({rateField})
                          <span className="required">*</span>
                        </label>
                        <ErrorMessage name={rateField} />
                        {/* {errors[rateField] && touched[rateField] ? (
                        <div className="alert alert-danger" role="alert">
                          {errors[rateField]}
                        </div>
                      ) : null} */}
                        <Field className="form-control" name={rateField} />
                      </div>
                    ))}

                    <div className="form-group">
                      <button
                        className="btn btn-primary btn-block"
                        disabled={isLoading || !isValid}
                        type="submit"
                      >
                        Update Rates
                      </button>
                    </div>
                  </>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      }
    ></DefaultModal>
  );
}
