import { useQueryClient } from "@tanstack/react-query";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import * as Yup from "yup";
import { useCustomerLabels } from "../../hooks/useCustomers";
import UserService from "../../services/user.service";
import DefaultModal from "../modal/modal.component";
import SelectCustomer from "./select-customer.component";
import authService from "../../services/auth.service";

const AddCustomerUserSchema = Yup.object().shape({
  name: Yup.string().min(2, "Field is too short").max(150, "Field is too long"),
  email: Yup.string()
    .min(2, "Field is too short")
    .max(150, "Field is too long"),
  customerId: Yup.number().required("Customer ID is required"),
  password: Yup.string()
    .min(6, "Field is too short")
    .max(30, "Field is too long"),
  telephone: Yup.string()
    .min(6, "Field is too short")
    .max(30, "Field is too long"),
});

const MySwal = withReactContent(Swal);

export default function AddNewCustomerUser({
  customerId,
}: {
  customerId?: number;
}) {
  const [isLoading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const { data: customers } = useCustomerLabels(customerId === undefined);

  const handleSubmission = (values: any) => {
    setLoading(true);

    authService
      .registerCustomerUser(
        values.name,
        values.email,
        values.password,
        values.telephone,
        values.customerId
      )
      .then(
        (response) => {
          setLoading(false);
          if (response.data) {
            queryClient.invalidateQueries({ queryKey: ["allCustomerUsers"] });
            queryClient.invalidateQueries({ queryKey : ["customerUsers", values.customerId]});
            MySwal.fire({
              title: "Success",
              text: "Customer user successfully registered.",
              icon: "success",
              // button: "Continue",
            });
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          MySwal.fire({
            title: "Error",
            text: resMessage,
            icon: "error",
            //   button: "Continue",
          });

          setLoading(false);
        }
      );
  };

  return (
    <DefaultModal
      title={"Register New Customer User"}
      trigger={"Register New Customer User"}
      content={
        <div className="col-md-12">
          <div className="card card-container">
            <h3 className="page-title">New Customer User Details</h3>
            <p className="required-text">
              Required fields<span className="required">*</span>
            </p>
            <Formik
              validationSchema={AddCustomerUserSchema}
              onSubmit={(values) => {
                handleSubmission(values);
              }}
              initialValues={{
                name: "",
                email: "",
                password: "",
                telephone: "",
                customerId: customerId,
              }}
            >
              {({ errors, touched, isValid, dirty, setFieldValue }) => (
                <Form>
                  {!customerId && customers && (
                    <SelectCustomer
                      customers={customers}
                      onChange={(value: number) =>
                        setFieldValue("customerId", value)
                      }
                    />
                  )}
                  <div className="form-group">
                    <label htmlFor="name">
                      Name<span className="required">*</span>
                    </label>
                    {errors.name && touched.name ? (
                      <div className="alert alert-danger" role="alert">
                        {errors.name}
                      </div>
                    ) : null}
                    <Field className="form-control" name="name" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">
                      Email<span className="required">*</span>
                    </label>
                    {errors.email && touched.email ? (
                      <div className="alert alert-danger" role="alert">
                        {errors.email}
                      </div>
                    ) : null}
                    <Field className="form-control" name="email" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">
                      Password<span className="required">*</span>
                    </label>
                    {errors.password && touched.password ? (
                      <div className="alert alert-danger" role="alert">
                        {errors.password}
                      </div>
                    ) : null}
                    <Field
                      className="form-control"
                      name="password"
                      type="password"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="telephone">
                      Telephone<span className="required">*</span>
                    </label>
                    {errors.telephone && touched.telephone ? (
                      <div className="alert alert-danger" role="alert">
                        {errors.telephone}
                      </div>
                    ) : null}
                    <Field className="form-control" name="telephone" />
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-block"
                      disabled={isLoading || !isValid}
                      type="submit"
                    >
                      Register
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      }
    ></DefaultModal>
  );
}
