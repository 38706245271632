import React from "react";
import "./Banner.css";

import { Link } from "react-router-dom";

export default function BannerAlert({ hook, type, message, link }: any) {
  const { data, isLoading } = hook();

  var alertClass = type == "WARNING" ? "alert-warning" : "alert-danger";

  if (!isLoading && data && data.length > 0) {
    return (
      <Link to={link}>
        <div className={"alert-container alert " + alertClass} role="alert">
          {message}
        </div>
      </Link>
    );
  } else {
    return <></>;
  }
}
