import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CustomerDto } from "../../contracts/models/CustomerDto";
import CustomerService from "../../services/customer.service";
import AddCustomer from "./add-customer.component";
import EditCustomerRates from "./edit-customer-rates.component";

export default function Customers() {
  const [customers, setCustomers] = useState<CustomerDto[]>([]);

  const [customer, setCustomer] = useState<CustomerDto>();

  useEffect(() => {
    getCustomers();
  }, []);

  const getCustomers = () => {
    CustomerService.getCustomers().then(
      (response) => {
        setCustomers(response.data);
      },
      () => {
        // this.setState({
        // error:
        //     (error.response && error.response.data) ||
        //     error.message ||
        //     error.toString()
        // });
      }
    );
  };

  let customerTable;
  if (customers && customers.length > 0) {
    let customerList = customers.map((customer) => (
      <tr key={customer.id}>
        <td>{customer.shortcode}</td>
        <th scope="row">{customer.name}</th>
        <td>{customer.email}</td>
        <td>
          <Link className="btn btn-primary btn-block" to={`/customer/users/${customer.id}`}>Users</Link>
        </td>
        <td>
          <button
            className="btn btn-primary btn-block"
            type="button"
            onClick={() => {
              setCustomer(customer);
            }}
          >
            Edit
          </button>
        </td>
        <td>
          <EditCustomerRates customer={customer} onComplete={getCustomers} />
        </td>
      </tr>
    ));

    customerTable = (
      <div className="customer-list">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Shortcode</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
              </tr>
            </thead>
            <tbody>{customerList}</tbody>
          </table>
        </div>
      </div>
    );
  } else {
    customerTable = (
      <div className="alert alert-danger">
        <p>Customer list empty. Add a customer using the form on this page.</p>
      </div>
    );
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <div className="card card-container">
            <h3 className="page-title">Customer List</h3>
            {customerTable}
          </div>
        </div>
        <div className="col-md-4">
          <AddCustomer
            onComplete={getCustomers}
            setCustomer={setCustomer}
            existingCustomer={customer}
          />
        </div>
      </div>
    </div>
  );
}
