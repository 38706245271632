import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DriverService from "../../services/driver.service";
const MySwal = withReactContent(Swal);

interface ResetPasswordProps {
  userId: number;
  setLoading?: (loading: boolean) => void;
  ActionComponent?: React.ComponentType<{ onClick: () => void }>;
}

export default function ResetPassword({
  userId,
  setLoading,
  ActionComponent,
}: ResetPasswordProps) {
  const resetPassword = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: `This will reset the user password to a random new, string. Their previous password will no longer work.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "I'm sure.",
    }).then((result) => {
      if (result.isConfirmed) {
        if (setLoading) {
          setLoading(true);
        }
        DriverService.resetPassword(userId).then(
          (response) => {
            if (setLoading) {
              setLoading(false);
            }
            MySwal.fire({
              title: "Password Successfully Updated",
              text:
                "Password changed to: >>>  " +
                response.data +
                "  <<< Please save this password to give to the driver as it will not be shown again.",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
          },
          (error) => {
            if (setLoading) {
              setLoading(false);
            }
            MySwal.fire({
              title: "Error",
              text: error.response.data,
              icon: "error",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
          }
        );
      }
    });
  };

  return (
    ActionComponent ? (
      <ActionComponent onClick={resetPassword} />
    ) : (
      <button
        className="btn btn-warning"
        onClick={resetPassword}
      >
        Reset Password
      </button>
    )
  );
}
