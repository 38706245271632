import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CustomerDto } from "../../contracts/models/CustomerDto";
import CustomerService from "../../services/customer.service";
import * as Yup from "yup";

const AddCustomerSchema = Yup.object().shape({
  shortcode: Yup.string()
    .min(2, "Field is too short")
    .max(6, "Field is too long")
    .required("Field is required"),
  name: Yup.string()
    .min(2, "Field is too short")
    .max(50, "Field is too long")
    .required("Field is required"),
  contactName: Yup.string()
    .min(2, "Field is too short")
    .max(50, "Field is too long")
    .required("Field is required"),
  email: Yup.string().email("Invalid email").required("Field is required"),
  contactTelephone: Yup.string()
    .min(2, "Field is too short")
    .max(50, "Field is too long")
    .required("Field is required"),
});

const MySwal = withReactContent(Swal);

export default function AddCustomer({
  onComplete,
  existingCustomer,
  setCustomer,
}: any) {
  const [isLoading, setLoading] = useState(false);

  const handleSubmission = (customer: CustomerDto, resetForm: any) => {
    setLoading(true);
    const isEditing = Boolean(existingCustomer);

    (isEditing
      ? CustomerService.editCustomer(customer)
      : CustomerService.saveCustomer(
          customer.shortcode,
          customer.name,
          customer.contactName,
          customer.contactTelephone,
          customer.email
        )
    ).then(
      () => {
        setLoading(false);
        onComplete();
        
      // Only reset the form if it's a new address being added
      if (!isEditing) {
        resetForm({});
      }

        MySwal.fire({
          title: "Success",
          text: "Customer successfully added.",
          icon: "success",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        MySwal.fire({
          title: "Error",
          text: resMessage,
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });

        setLoading(false);
      }
    );
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <h3 className="page-title">
          {existingCustomer ? "Edit" : "Add"} Customer
        </h3>
        <p className="required-text">
          Required fields<span className="required">*</span>
        </p>
        <Formik
          initialValues={existingCustomer || {}}
          validationSchema={AddCustomerSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmission(values, resetForm);
          }}
          enableReinitialize={true}
        >
          {({ errors, touched, isValid, dirty }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="name">Shortcode</label>
                {errors.shortcode && touched.shortcode ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.shortcode?.toString()}
                  </div>
                ) : null}
                <Field className="form-control" name="shortcode" />
              </div>
              <div className="form-group">
                <label htmlFor="name">Company Name</label>
                {errors.name && touched.name ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.name?.toString()}
                  </div>
                ) : null}
                <Field className="form-control" name="name" />
              </div>
              <div className="form-group">
                <label htmlFor="name">Contact Name</label>
                {errors.contactName && touched.contactName ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.contactName?.toString()}
                  </div>
                ) : null}
                <Field className="form-control" name="contactName" />
              </div>
              <div className="form-group">
                <label htmlFor="contactTelephone">Contact Telephone</label>
                {errors.contactTelephone && touched.contactTelephone ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.contactTelephone?.toString()}
                  </div>
                ) : null}
                <Field className="form-control" name="contactTelephone" />
              </div>
              <div className="form-group">
                <label htmlFor="name">Contact Email</label>
                {errors.email && touched.email ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.email?.toString()}
                  </div>
                ) : null}
                <Field className="form-control" name="email" type="email" />
              </div>
              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  disabled={isLoading || !dirty || !isValid}
                  type="submit"
                >
                  {existingCustomer ? "Save" : "Add New Customer"}
                </button>
                {existingCustomer && (
                  <button
                    className="btn btn-primary btn-block"
                    type="button"
                    onClick={() => {
                      setCustomer();
                    }}
                  >
                    Reset
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
