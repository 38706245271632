import moment from "moment";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FilterReports from "./filter-reports.component";
import jobService from "../../services/job.service";
import "./Reporting.css";
export default function Reporting() {
  const defaultFilters = {
    fromDate: moment().subtract(31, "days").toDate(),
    toDate: moment().add(31, "days").toDate(),
    customer: null,
    searchTerm: "",
    status: "ALL",
    available: true,
    accepted: true,
    current: true,
    progress: true,
    completed: true,
    closed: true,
    failed: true,
    pending: true,
  };

  const [filters, setFilterState] = useState(() => {
    const savedFilters = localStorage.getItem('reportingFilters');
    return savedFilters ? JSON.parse(savedFilters, (key, value) => 
      (key === 'fromDate' || key === 'toDate') ? new Date(value) : value
    ) : defaultFilters;
  });

  useEffect(() => {
    const filtersToSave = {
      ...filters,
      fromDate: filters.fromDate.toISOString(),
      toDate: filters.toDate.toISOString(),
    };
    localStorage.setItem('reportingFilters', JSON.stringify(filtersToSave));
  }, [filters]);

  const [isExporting, setIsExporting] = useState(false);

  const MySwal = withReactContent(Swal);

  const exportSwingData = (type: any) => {
    setIsExporting(true);

    //based on the filters.status.value - set the accepted/current/progress values

    var filterValues = {
      exportType: type,
      fromDate: filters.fromDate,
      toDate: filters.toDate,
      searchTerm: filters.searchTerm,
      customer: filters.customer,
      available: true,
      accepted: true,
      progress: true,
      completed: true,
      pending: true,
      closed: true,
      failed: true,
      cancelled: true,
    };

    if (filters.status === "ALL") {
      //all true
    }

    if (filters.status === "COMPLETED") {
      filterValues.available = false;
      filterValues.accepted = false;
      filterValues.progress = false;
      filterValues.pending = false;
      filterValues.failed = false;
      filterValues.cancelled = false;
    }

    //functon to get current date and tme as string for use in a file name
    const getDateTimeString = () => {
      var currentdate = new Date();
      var datetime =
        currentdate.getDate() +
        "-" +
        (currentdate.getMonth() + 1) +
        "-" +
        currentdate.getFullYear() +
        "_" +
        currentdate.getHours() +
        "-" +
        currentdate.getMinutes() +
        "-" +
        currentdate.getSeconds();
      return datetime;
    };

    jobService
      .exportFilteredSwingCSV(type, filterValues)
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", type.toUpperCase()+"-EXPORT-"+getDateTimeString() + ".csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
        setIsExporting(false);
      })
      .catch((error) => {
        MySwal.fire({
          title: "Error",
          text: "Error exporting - please contact support.",
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
        setIsExporting(false);
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="card card-container">
            <h3 className="page-title">Reporting</h3>

            <FilterReports
              filters={filters}
              setFilters={setFilterState}
              isExporting={isExporting}
            ></FilterReports>

            <div className="filter-row row">
              <div className="col-md-3">
                <button
                  className="btn btn-info btn-block"
                  type="button"
                  disabled={isExporting}
                  onClick={() => exportSwingData("BASIC")}
                >
                  {isExporting && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  {!isExporting && <span>Basic Job Export</span>}
                </button>
              </div>
              <div className="col-md-3">
                <button
                  className="btn btn-info btn-block"
                  type="button"
                  disabled={isExporting}
                  onClick={() => exportSwingData("DETAILED")}
                >
                  {isExporting && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  {!isExporting && <span>Detailed Job Export</span>}
                </button>
              </div>
              <div className="col-md-3">
                <button
                  className="btn btn-info btn-block"
                  type="button"
                  disabled={isExporting}
                  onClick={() => exportSwingData("TRACKER")}
                >
                  {isExporting && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  {!isExporting && <span>Move Tracker Export</span>}
                </button>
              </div>
                <div className="col-md-3">
                <button
                  className="btn btn-info btn-block"
                  type="button"
                  disabled={isExporting}
                  onClick={() => exportSwingData("PRICE")}
                >
                  {isExporting && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  {!isExporting && <span>Pricing Calculations Export</span>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
