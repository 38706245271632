import { ErrorMessage, Field } from "formik";
import React from "react";

type StandardFieldProps = {
  fieldName: string;
  friendlyName: string;
  type?: string;
};

function StandardField(props: StandardFieldProps) {
  return (
    <>
      <label className="block mb-2 text-lg font-medium text-[#683fb9] dark:text-white">
        {props.friendlyName}
      </label>
      <Field
        className="form-control mb-2 "
        name={props.fieldName}
        type={props.type ?? "text"}
      ></Field>
    <ErrorMessage name={props.fieldName}>
    {(msg: string) => (
                      <div className="alert alert-danger" role="alert">
                      <>{msg}</>
                      </div>
    )}
                      </ErrorMessage>      
      
    </>
  );
}

export default StandardField;
