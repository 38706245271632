import React from "react";
import { Link } from "react-router-dom";
import { DriverJobDto } from "../../contracts/models/DriverJobDto";
import { VehicleMoveDto } from "../../contracts/models/VehicleMoveDto";
import { useVehicleJobs } from "../../hooks/useVehicleJobs";
import LoadingSpinner from "../loading/loading-spinner.component";
import VehicleImage from "./vehicle-image.component";
import ViewMoveImages from "./view-move-images.component";

export default function ViewVehicleImageList({
  id,
  vehicleJobs,
}: {
  id: number;
  vehicleJobs: DriverJobDto[];
}) {
  if (!vehicleJobs || vehicleJobs.length === 0) {
    return (
      <div className="alert alert-warning">
        <p>Job list empty</p>
      </div>
    );
  }
  return (
    <div className="row">
      <div className="card-columns">
        {vehicleJobs?.map((vj: DriverJobDto) => (
          <VehicleImageDetails key={vj.id} vehicleId={id} driverJob={vj} />
        ))}
      </div>
    </div>
  );
}

export function VehicleImageDetails({
  vehicleId,
  driverJob,
}: {
  vehicleId: number;
  driverJob: DriverJobDto;
}) {
  const vj = driverJob.vehicles?.find((v) => v.vehicleId === vehicleId);

  if (!vj) {
    return <></>;
  }

  return (
    <div className="card text-left">
      <div className="card-header">{driverJob.swing?.jobReference}</div>
      <div className="card-body">
        <h5 className="card-title">{driverJob.jobDescription}</h5>
        <span className="card-text">Collection Odometer</span>
        <p className="card-text odometer-text">{vj.initialOdometerReading}</p>
        <span>Delivery Odometer</span>
        <p className="card-text odometer-text">{vj.deliveryOdometerReading}</p>
        {vj.vehicleImages && vj.vehicleImages.length > 0 && (
          <ViewMoveImages vehicleImages={vj.vehicleImages} />
        )}
      </div>
      <div className="card-footer text-muted">
        {" "}
        <Link to={"/swing/view/" + driverJob?.swing?.id}>
          <button className="btn btn-primary">View Job Group</button>
        </Link>
      </div>
    </div>
  );
}
