import { AxiosResponse } from "axios";
import api from "./network";
import { ChargeDto } from "../contracts/models/Charges/ChargeDto";
import { GetChargesDto } from "../contracts/models/Charges/GetChargesDto";

const API_URL = "Charge/";

class ChargeService {
  // Method to fetch charges
  async getCharges(request: GetChargesDto): Promise<AxiosResponse<ChargeDto[]>> {
    try {
      return await api.get(API_URL, { params: request });
    } catch (error) {
      console.error("Error fetching charges:", error);
      throw error;
    }
  }

  // Method to create a new charge
  async createCharge(request: ChargeDto): Promise<AxiosResponse<ChargeDto>> {
    try {
      return await api.post(API_URL, request);
    } catch (error) {
      console.error("Error creating charge:", error);
      throw error;
    }
  }

  // Method to delete a charge
  async deleteCharge(id: string): Promise<AxiosResponse<boolean>> {
    try {
      return await api.delete(`${API_URL}${id}`, {});
    } catch (error) {
      console.error("Error deleting charge:", error);
      throw error;
    }
  }
}

export default new ChargeService();