import React from "react";
import Moment from "react-moment";
import ViewSubmission from "./view-submission.component";
import DefaultModal from "../modal/modal.component";
import { CustomerUserDto } from "../../contracts/models/CustomerUserDto";

export default function SubmissionTable({
  tableName,
  submissions,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage
}: any) {
  const getStatusClass = (status: any) => {
    switch (status) {
      case "PENDING":
      case "FAILED":
        return "red";
      case "VIEWED":
      case "FLAGGED":
        return "orange";
      case "ACCEPTED":
      case "SUCCESSFUL":
      case "HANDLED":
        return "green";
    }
  };

  let submissionTable; //= error message
  if (submissions && submissions?.pages?.length > 0) {
    let submissionList = submissions.pages.map((submissionPages: any) => (
      <>
        {submissionPages.items.map((item: any) => <tr key={item.submission.id}>
          <td className={`status ${getStatusClass(item.status)}`}>
            {item.status}
          </td>
          <td>{item.submission.submissionType}</td>
          <td>{item.driver.name}</td>
          <td>{item.submission.job.swing.jobReference}</td>
          <td>
            <Moment format="DD/MM/YYYY" date={item.submission.createdAt} />
          </td>
          <td>
            <DefaultModal
              title={"View Compliance Submission"}
              trigger={"View Submission"}
              content={
                <ViewSubmission
                  submissionId={item.submission.id}
                ></ViewSubmission>
              }
            ></DefaultModal>
          </td>
        </tr>)}
      </>
    ));

    submissionTable = (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Status</th>
              <th scope="col">Type</th>
              <th scope="col">Driver</th>
              <th scope="col">Job</th>
              <th scope="col">Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{submissionList}</tbody>
        </table>
        <button
          className="btn btn-primary btn-block"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? "Loading more..."
            : hasNextPage
            ? "Load More"
            : "Nothing more to load"}
        </button>
      </div>
    );
  } else {
    submissionTable = (
      <div className="alert alert-danger">
        <p>{tableName} List empty.</p>
      </div>
    );
  }

  return <div>{submissionTable}</div>;
}

interface CustomerUserListProps {
  customerUsers: CustomerUserDto[];
}

const CustomerUserList: React.FC<CustomerUserListProps> = ({ customerUsers }) => {
  return (
    <div>
      {customerUsers?.map((user) => (
        <CustomerUserItem key={user.id} user={user} />
      ))}
    </div>
  );
};

interface CustomerUserItemProps {
  user: CustomerUserDto;
}

const CustomerUserItem: React.FC<CustomerUserItemProps> = ({ user }) => {
  return (
    <div key={user.id}>
      <p>Id: {user.id}</p>
      <p>Name: {user.name}</p>
      <p>Email: {user.email}</p>
      <p>Status: {user.status}</p>
      <p>CreatedAt: {user.createdAt}</p>
    </div>
  );
};