import ComplianceService from "../services/compliance.service";
import { useQuery } from "@tanstack/react-query";

export function useComplianceAlerts() {
  return useQuery({
    queryKey: ["compliancealerts"],
    queryFn: async () => {
      // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
      const response = await ComplianceService.getComplianceAlerts();
      return response.data;
    },
    refetchInterval: 60000,
  });
}
