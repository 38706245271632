import { DriverHoursDriverDto } from "../contracts/models/DriverDto";
import api from "./network";

const API_URL = "DriverHours/";

class DriverHoursService {
  getDriverHours(fromDate: string, toDate: string) {
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    return api.get<DriverHoursDriverDto>(API_URL + "weekly", {
      params: {
        weekStart: fromDate,
        weekEnd: toDate,
      },
    });
  }
}

export default new DriverHoursService();
