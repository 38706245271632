import React from "react";

import { Link } from "react-router-dom";
import { DriverJobDto } from "../../contracts/models/DriverJobDto";
import LoadingSpinner from "../loading/loading-spinner.component";
import Status from "./status.component";

export default function JobList({
  jobs,
  isLoading,
}: {
  jobs: DriverJobDto[];
  isLoading: boolean;
}) {
  let jobsTable;
  if (isLoading) {
    jobsTable = <LoadingSpinner></LoadingSpinner>;
  } else {
    if (jobs && jobs.length > 0) {
      let jobsList = jobs.map((job: any) => (
        <tr key={job.id}>
          <td className="center">
            <Status status={job.status}></Status>
          </td>
          <th scope="row" className="center">
            {job.jobDescription}
          </th>
          <th className="center">{job.swing.jobReference}</th>
          <th className="center">{job.swing.customer.name}</th>
          <td className="center">
            <Link to={"/swing/view/" + job.swing.id} className="view">
              {" "}
              View Job Group
            </Link>
          </td>
        </tr>
      ));

      jobsTable = (
        <div className="job-list w-full">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Status</th>
                  <th scope="col">Description</th>
                  <th scope="col">Job Reference</th>
                  <th scope="col">Customer</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>{jobsList}</tbody>
            </table>
          </div>
        </div>
      );
    } else {
      jobsTable = (
        <div className="alert alert-danger">
          <p>Job list empty</p>
        </div>
      );
    }
  }

  return (
    <div className="row">
      <div className="col-md-12 w-full">
        <div className="card-container">
          <h3 className="page-title">Job List</h3>
          {/* <Filter onSet={setFilters} filters={jobFilters}></Filter> */}
          {jobsTable}
        </div>
      </div>
    </div>
  );
}
