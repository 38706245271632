import React from "react";
import Vehicle from "./vehicle.component";
import { VehicleDto } from "../../contracts/models/VehicleDto";

interface ViewVehicleListProps {
  vehicles: VehicleDto[];
  deleteVehicle?: any;
  addVehicle?: any;
  updateArchivedStatus?: (
    vehicleId: number,
    archivedStatus: boolean
  ) => Promise<void>;
}

export default function ViewVehicleList({
  vehicles,
  deleteVehicle,
  addVehicle,
  updateArchivedStatus,
}: ViewVehicleListProps) {
  const vehicleList = (vehicles ? vehicles : []).map((vehicle: any) => (
    <div className="row list-rowvehicle-row" key={vehicle.id}>
      <Vehicle
        vehicle={vehicle}
        deleteVehicle={deleteVehicle}
        onSubmit={addVehicle}
        updateArchivedStatus={updateArchivedStatus}
      ></Vehicle>
    </div>
  ));

  return <div className="vehicles-container">{vehicleList}</div>;
}
