import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn: "https://2cdc32aa587f41b4bbb76c29d26b36a1@o713623.ingest.sentry.io/5782150",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1,
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
