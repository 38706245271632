import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { GetVehicleById } from "../../helpers/VehicleTypes";
import { GetById } from "../../helpers/LicenceClasses";
import "./Vehicles.css";
import { ViewVehicleJobInformation } from "./view-vehicle-job-information.component";

export default function ViewVehicle({ vehicle }: any) {
  const [modalShow, setModalShow] = useState(false);

  function getType(type: any) {
    if (type === 1) return "Vehicle";
    else if (type === 2) return "Trailer";
    else if (type === 3) return "Minibus";
    else return "Unknown";
  }

  if (modalShow)
    return (
      <>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              View {getType(vehicle.type)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="vehicle-container">
              <div className="row">
                <div className="col">
                  <label className="job-label">Vehicle Class</label>
                  <h5 className="card-title">{GetById(vehicle.class)}</h5>
                </div>
                {vehicle.registration && (
                  <div className="col">
                    <label className="job-label">Registration</label>
                    <h5 className="card-title">{vehicle.registration}</h5>
                  </div>
                )}
                {vehicle.vehicleMake && (
                  <div className="col">
                    <label className="job-label">Vehicle Make</label>
                    <h5 className="card-title">{vehicle.vehicleMake}</h5>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-2">
                  <label className="job-label">Type</label>
                  <h5 className="card-title">{GetVehicleById(vehicle.type)}</h5>
                </div>
                <div className="col-md-8">
                  <label className="job-label">Description</label>
                  <h5 className="card-title">{vehicle.vehicleDescription}</h5>
                </div>
                {vehicle.weight && (
                  <div className="col-md-2">
                    <label className="job-label">Weight</label>
                    <h5 className="card-title">{vehicle.weight}</h5>
                  </div>
                )}
              </div>
              {vehicle.additionalInformation && (
                <div className="row">
                  <div className="col">
                    <label className="job-label">Additional Information</label>
                    <p className="job-information">
                      {vehicle.additionalInformation}
                    </p>
                  </div>
                </div>
              )}
              <div className="row">
                {vehicle.trailerAxles && vehicle.trailerAxles !== "" && (
                  <div className="col">
                    <div className="information-container">
                      <label className="job-label">Trailer Axles</label>
                      <p className="job-information">{vehicle.trailerAxles}</p>
                    </div>
                  </div>
                )}

                {vehicle.trailerReference &&
                  vehicle.trailerReference !== "" && (
                    <div className="col">
                      <div className="information-container">
                        <label className="job-label">Trailer Reference</label>
                        <p className="job-information">
                          {vehicle.trailerReference}
                        </p>
                      </div>
                    </div>
                  )}
                <div className="vehicle-container">
                  <ViewVehicleJobInformation
                    id={vehicle.id}
                  ></ViewVehicleJobInformation>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModalShow(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );

  return (
    <button
      type="button"
      className="btn btn-primary"
      onClick={() => setModalShow(true)}
    >
      View Vehicle
    </button>
  );
}
