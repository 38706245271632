import React from "react";
import "./layout-component.css";

interface LayoutProps {
  children: React.ReactNode; // This allows any valid React content
}

function Layout({ children }: LayoutProps) {
  return <main className="layout">{children}</main>;
}

export default Layout;
