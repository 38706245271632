import React, { useState } from "react";
import { FormControl } from "react-bootstrap";
import SelectCustomer from "../customers/select-customer.component";
import Select from "react-select";

import DatePicker from "react-datepicker";
import { useCustomerLabels } from "../../hooks/useCustomers";
import "react-datepicker/dist/react-datepicker.css";
import "./Reporting.css";

const statusLabels = [
  {
    value: "ALL",
    label: "All",
  },
  {
    value: "PENDING",
    label: "Pending",
  },
  {
    value: "INPROGRESS",
    label: "In Progress",
  },
  {
    value: "COMPLETED",
    label: "Completed",
  },
];

export default function FilterReports({ filters, setFilters }: any) {
  const { data: customers } = useCustomerLabels();

  const updateFilters = (item: any, value: any) => {
    var f = { ...filters, [item]: value };
    console.log("new filters", f);
    setFilters(f);
  };

  return (
    <div className="filter-container">
      <div className="filter-row row">
        <div className="col-md-6">
          <SelectCustomer
            customers={customers}
            onChange={(c: any) => updateFilters("customer", c)}
            hideMessage={true}
            defaultCustomer={filters.customer ?? undefined}
            clearable={true}
          ></SelectCustomer>
        </div>
        <div className="col-md-2">
          <Select
            onChange={(o: any) => updateFilters("status", o.value)}
            options={statusLabels}
            isClearable={false}
            name="status"
            value={statusLabels.find(
              (option) => option.value === filters.status
            )}
          />
        </div>
        <div className="col-md-2">
          <DatePicker
            className="form-control date-picker"
            selected={filters.fromDate}
            onChange={(date: any) => updateFilters("fromDate", date)}
            dateFormat="dd/MM/yyyy"
            calendarStartDay={1}
          />
        </div>
        <div className="col-md-2">
          <DatePicker
            className="form-control date-picker"
            selected={filters.toDate}
            onChange={(date: any) => updateFilters("toDate", date)}
            dateFormat="dd/MM/yyyy"
            calendarStartDay={1}
          />
        </div>
      </div>
      <div className="filter-row row">
        <div className="col-md-4">
          <FormControl
            placeholder="Job Reference"
            aria-label="Job Reference"
            value={filters.searchTerm}
            onChange={(evt) => updateFilters("searchTerm", evt.target.value)}
          />
        </div>
        <div className="col-md-4">
          <FormControl
            placeholder="Job Description"
            aria-label="Job Description"
            value={filters.searchTerm}
            onChange={(evt) => updateFilters("searchTerm", evt.target.value)}
          />
        </div>
        <div className="col-md-4">
          <FormControl
            placeholder="Location"
            aria-label="Location"
            value={filters.searchTerm}
            onChange={(evt) => updateFilters("searchTerm", evt.target.value)}
          />
        </div>
      </div>
    </div>
  );
}
