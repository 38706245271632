import JobService from "../services/job.service";
import { useQuery } from "@tanstack/react-query";

export function usePendingDrivers(jobId: any) {
  return useQuery({
    queryKey: ["pendingDrivers", jobId],
    queryFn: async () => {
      const response = await JobService.getPendingDrivers(jobId);
      return response?.data ?? [];
    },
    initialData: [],
  });
}
