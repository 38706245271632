import { AxiosResponse } from "axios";
import { SwingDto } from "../contracts/models/SwingDto";
import api from "./network";
import { SetDiscountDto } from "../contracts/models/swingjobs/SetDiscountDto";
import { SwingApiResponse } from "../contracts/models/Swings/JobsApiResponse";

const API_URL = "Swing/";

class SwingService {
  getAllSwings() {
    return api.get(API_URL + "all");
  }

  getSwings(filters: any, pageNumber: any) {
    return api.get(API_URL, {
      params: {
        fromDate: filters.fromDate,
        toDate: filters.toDate,
        searchTerm: filters.searchTerm,
        available: filters.available,
        accepted: filters.accepted,
        progress: filters.progress,
        completed: filters.completed,
        closed: filters.closed,
        failed: filters.failed,
        pending: filters.pending,
        cancelled: filters.cancelled,
        allocated: filters.allocated,
        customerId: filters.customerId,
        pageNumber,
      },
    });
  }

  getSwingsNew(searchParams: string): Promise<AxiosResponse<SwingApiResponse>> {
    return api.get(API_URL+"?"+searchParams);
  }

  async saveNewSwing(job: any): Promise<AxiosResponse<SwingDto>> {
    return api.post(API_URL, {
      customerId: job.customerId,
      jobType: job.jobType,
      collectionDate: job.collectionDate,
      jobEndDate: job.jobEndDate,
      meetTime: job.meetTime,
      shiftLength: job.shiftLength,
      meetLocationId: job.meetLocationId,
      groundTransportRequirement: job.groundTransportRequirement,
      overnightMove: job.overnightMove,
      plannedOutOfHours: job.plannedOutOfHours,
      readableFromLocation: job.readableFromLocation,
      readableToLocation: job.readableToLocation,
      swingDiscriminator: job.swingDiscriminator,
    });
  }

  editSwing(swingId: any, job: any) {
    console.log(job);
    return api.post(API_URL + "edit", {
      swingId,
      jobType: job.jobType,
      swingDiscriminator: job.swingDiscriminator,
      jobReference: job.jobReference,
      shiftLength: job.shiftLength,
      collectionDate: job.collectionDate,
      jobEndDate: job.jobEndDate,
      meetTime: job.meetTime,
      meetLocationId: job.meetLocationId,
      groundTransportRequirement: job.groundTransportRequirement,
      overnightMove: job.overnightMove,
      plannedOutOfHours: job.plannedOutOfHours,
      readableFromLocation: job.readableFromLocation,
      readableToLocation: job.readableToLocation,
    });
  }

  async getSwing(swingId: any) : Promise<AxiosResponse<SwingDto>> {
    return await api.get(API_URL + "get", {
      params: {
        swingId,
      },
    });
  }

  deleteSwing(swingId: any) {
    return api.delete(API_URL, {
      Id: parseInt(swingId),
    });
  }

  releaseSwing(swingId: any) {
    return api.post(API_URL + "release", {
      swingId,
    });
  }

  duplicateIdentical(swingId: any) {
    return api.post(API_URL + "duplicate", {
      swingId,
    });
  }

  duplicateReverse(swingId: any) {
    return api.post(API_URL + "duplicate/reverse", {
      swingId,
    });
  }

  regenerateReference(swingId: any) {
    return api.post(API_URL + "reference/regenerate", {
      swingId,
    });
  }

  async setDiscountAsync(discount: SetDiscountDto): Promise<AxiosResponse<boolean>> {
    const response = await api.put(API_URL + "discount", discount);
    return response.data;
  }

  async changeParentSwing(swingId: number, newParentId: number | undefined): Promise<AxiosResponse<boolean>> {
    const response = await api.post(API_URL + "change-parent", {
      swingId,
      newParentId
    });
    return response.data;
  }
}

export default new SwingService();
