import React, { useState } from "react";
import SwingService from "../../services/swing.service";
import { Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./Jobs.css";

export default function DuplicateSwing({
  swingId
}: any) {
  const MySwal = withReactContent(Swal);

  const [loading, setLoading] = useState(false);
  const duplicate = (serviceCall: any, text: any) => {
    MySwal.fire({
      title: "Are you sure you want to duplicate this swing?",
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        serviceCall(swingId).then(
          (response: any) => {
            setLoading(false);
            MySwal.fire({
              title: "Job successfully duplicated",
              text: "Please ensure all job details are correct, including swing reference and job description",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            }).then(() => {
              window.location.replace("/swing/view/" + response.data.id);
            });
          },
          (error: any) => {
            setLoading(false);
            console.log(error.response);
            var msg =
              error.response?.data ?? "An error occured duplicating swing.";
            MySwal.fire({
              title: "Error",
              text: msg,
              icon: "error",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
          }
        );
      }
    });
  };

  return (
    <Dropdown>
      <Dropdown.Toggle>Duplicate Swing</Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() =>
            duplicate(
              SwingService.duplicateIdentical,
              "All jobs and moves will be copied, with addresses and contacts identical."
            )
          }
        >
          Duplicate Identical
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            duplicate(
              SwingService.duplicateReverse,
              "All jobs and moves will be copied, with addresses and contacts reversed."
            )
          }
        >
          Duplicate Reversed
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
