import React from "react";
import { useJobRemovalAlerts } from "../../hooks/useJobRemovalAlerts";
import LoadingSpinner from "../loading/loading-spinner.component";
import Moment from "react-moment";

import { Link } from "react-router-dom";

export default function JobRemovals({}) {
  const { data: jobRemovals, isLoading } = useJobRemovalAlerts(true, false);

  let removalTable; //= error message
  if (jobRemovals && jobRemovals.length > 0) {
    let removalList = jobRemovals.map((item: any) => (
      <tr key={item.id}>
        <td>{item.removalType}</td>
        <td>{item.removedDriver.name}</td>
        <td>{item.updateItem.classUpdateType}</td>
        <td>{item.job?.swing?.jobReference}</td>
        <td>
          {item.resolvedAt && item.resolvedAt != null ? (
            <Moment format="DD/MM/YYYY" date={item.resolvedAt} />
          ) : (
            <></>
          )}
        </td>
        <td>
          <Moment format="DD/MM/YYYY" date={item.createdAt} />
        </td>
        <td>
          <Link to={"/swing/view/" + item.job?.swing?.id} className="nav-link">
            <button className="btn btn-primary" type="button">
              View Job Group
            </button>
          </Link>
        </td>
      </tr>
    ));

    removalTable = (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Removal Type</th>
              <th scope="col">Driver</th>
              <th scope="col">Update Type</th>
              <th scope="col">Swing Reference</th>
              <th scope="col">Resolved At</th>
              <th scope="col">Created At</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{removalList}</tbody>
        </table>
      </div>
    );
  } else {
    removalTable = (
      <div className="alert alert-danger">
        <p>Job Removals List empty.</p>
      </div>
    );
  }

  if (isLoading) return <LoadingSpinner></LoadingSpinner>;
  else return <div>{removalTable}</div>;
}
