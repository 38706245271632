import React from 'react';
import './address.css';

export default function ViewAddressLine({
  address
}: any) {

  return (
    <>
      <p className="address-information">{address.line1}, {address.line2 ?? address.line1 + ','} {address.city}, {address.postcode}, {address.country}</p>
      {/* <p className="address-information">{address.addressInformation}</p> */}
    </>
  );
}
