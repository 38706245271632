import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import SelectAddress from "../address/select-address.component";
import SelectCustomer from "../customers/select-customer.component";
import { useAddresses } from "../../hooks/useAddresses";
import { useCustomerLabels } from "../../hooks/useCustomers";
import Select from "react-select";
import SelectFromAllCustomerRequests from "../customer-requests-management/select-all-customer-requests.component";
import { AdminViewCustomerRequestDetails } from "../customer-requests-management/admin-view-customer-request.component";
import { useMutation } from "@tanstack/react-query";
import customerManagementService from "../../services/customer-management.service";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const AddSwingSchema = Yup.object().shape({
  swingDiscriminator: Yup.string()
    .required("Field is required")
    .min(2, "Field is too short")
    .max(8, "Field is too long"),
  collectionDate: Yup.date()
    .required("A valid Collection Date is required")
    .nullable(),
  jobEndDate: Yup.date().nullable(),
  meetTime: Yup.date().required("A valid meet time is required").nullable(),
  readableFromLocation: Yup.string()
    .required("Field is required")
    .min(2, "Field is too short")
    .max(60, "Field is too long"),
  readableToLocation: Yup.string()
    .required("Field is required")
    .min(2, "Field is too short")
    .max(60, "Field is too long"),
  shiftLength: Yup.number().required("Shift is required"),
});

export default function AddSwingForm({ initialValues, onSubmit }: any) {
  const [customerId, onChangeCustomer] = useState(0);
  const [collectionDate, onChangeCollectionDate] = useState(null);
  const [endDate, onChangeEndDate] = useState(null);
  const [meetTime, onChangeMeetTime] = useState(null);
  const [meetLocationId, onChangeMeetLocation] = useState(0);

  const [customerRequestId, setCustomerRequestId] = useState<number>();

  const { data: addresses } = useAddresses();
  const { data: customers } = useCustomerLabels();

  useEffect(() => {
    if (initialValues) {
      onChangeCustomer(initialValues.customer.id);
      // @ts-expect-error TS(2345): Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
      onChangeCollectionDate(new Date(initialValues.collectionDate));
      // @ts-expect-error TS(2345): Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
      onChangeMeetTime(new Date(initialValues.meetTime));
      onChangeMeetLocation(initialValues.meetLocationId);
    } else {
      initialValues = {
        jobType: "SWING",
        customerId: 0,
        swingDiscriminator: "",
        collectionDate: null,
        jobEndDate: null,
        meetTime: null,
        meetLocationId: 0,
        groundTransportRequirement: null,
        overnightMove: false,
        plannedOutOfHours: false,
        readableFromLocation: "",
        readableToLocation: "",
        shiftLength: 12,
        contactId: null,
      };
    }
  }, []);

  var initial = initialValues ?? {
    customerId: 0,
    jobType: "SWING",
    swingDiscriminator: "",
    collectionDate: null,
    jobEndDate: null,
    meetTime: null,
    meetLocationId: 0,
    overnightMove: false,
    plannedOutOfHours: false,
    readableFromLocation: "",
    readableToLocation: "",
    shiftLength: 12,
    contactId: null,
  };

  return (
    <>
      <p className="required-text">
        Required fields<span className="required">*</span>
      </p>

      <Formik
        initialValues={initial}
        validationSchema={AddSwingSchema}
        onSubmit={(values, { resetForm }) => {
          values.customerId = customerId;
          values.meetLocationId = meetLocationId;
          onSubmit(values, resetForm, customerRequestId);

          
        }}
      >
        {({ errors, touched, setFieldValue, isValid, dirty }) => (
          <Form>
            {/* {{ errors }} */}

            <div className="my-4">
      <p>Would you like to base this Job on a customer request?</p>
      <SelectFromAllCustomerRequests
        selectRequest={(requestId:number) => setCustomerRequestId(requestId)}
        defaultRequest={customerRequestId}
      />
    </div>

    {customerRequestId && (
      <AdminViewCustomerRequestDetails id={customerRequestId} />
    )}

            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label className="radio-label">
                    <Field type="radio" name="jobType" value="SWING" />
                    Swing
                  </label>
                </div>
                <div className="col">
                  <label className="radio-label">
                    <Field type="radio" name="jobType" value="DAILY_RUNNER" />
                    Daily Runner
                  </label>
                </div>
                <div className="col">
                  <label className="radio-label">
                    <Field type="radio" name="jobType" value="STANDBY" />
                    Standby
                  </label>
                </div>
                <div className="col">
                  <label className="radio-label">
                    <Field type="radio" name="jobType" value="DRY_HIRE" />
                    Dry Hire
                  </label>
                </div>
              </div>
            </div>
            {!initialValues ? (
              <div className="form-group">
                <label htmlFor="name">
                  Customer<span className="required">*</span>
                </label>
                {dirty && customerId === 0 ? (
                  <div className="alert alert-danger" role="alert">
                    A customer is required
                  </div>
                ) : null}
                <SelectCustomer
                  customers={customers}
                  onChange={onChangeCustomer}
                ></SelectCustomer>
              </div>
            ) : null}

            <div className="form-group">
              <label htmlFor="name">
                Shift Length
                <span className="required">*</span>
              </label>
              <Select
                onChange={(v: any) => setFieldValue("shiftLength", v.value)}
                options={[
                  { value: 8, label: "8 hours" },
                  { value: 10, label: "10 hours" },
                  { value: 12, label: "12 hours" },
                  { value: 15, label: "15 hours" },
                ]}
                defaultValue={{ value: 8, label: "8 hours" }}
                isClearable={false}
                name="shiftLength"
              />
            </div>

            <div className="form-group">
              <label htmlFor="name">
                Job Date<span className="required">*</span>
              </label>
              {errors.collectionDate ? (
                <div className="alert alert-danger" role="alert">
                  {errors.collectionDate.toString()}
                </div>
              ) : null}
              <DatePicker
                className="form-control date-picker"
                selected={collectionDate}
                onChange={(date: any) => {
                  onChangeCollectionDate(date);
                  setFieldValue("collectionDate", date);
                }}
                dateFormat="dd/MM/yyyy"
                calendarStartDay={1}
              />
            </div>

            <div className="form-group">
              <label htmlFor="name">
                Driver Meet Time<span className="required">*</span>
              </label>
              {errors.meetTime ? (
                <div className="alert alert-danger" role="alert">
                  {errors.meetTime.toString()}
                </div>
              ) : null}
              <DatePicker
                className="form-control date-picker"
                selected={meetTime}
                onChange={(date: any) => {
                  onChangeMeetTime(date);
                  setFieldValue("meetTime", date);
                }}
                showTimeInput
                dateFormat="dd/MM/yyyy HH:mm"
                timeIntervals={5}
                calendarStartDay={1}
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">Job End Date</label>
              <p className="small">
                Optional end date for long running standby jobs
              </p>
              {errors.jobEndDate ? (
                <div className="alert alert-danger" role="alert">
                  {errors.jobEndDate.toString()}
                </div>
              ) : null}
              <DatePicker
                className="form-control date-picker"
                selected={endDate}
                onChange={(date: any) => {
                  onChangeEndDate(date);
                  setFieldValue("jobEndDate", date);
                }}
                dateFormat="dd/MM/yyyy"
                calendarStartDay={1}
              />
            </div>

            <div className="form-group">
              <label htmlFor="class">
                Meet Location<span className="required">*</span>
              </label>
              {dirty && meetLocationId === 0 ? (
                <div className="alert alert-danger" role="alert">
                  A meet location is required
                </div>
              ) : null}
              <SelectAddress
                addresses={addresses}
                onChange={onChangeMeetLocation}
                defaultAddress={
                  initialValues ? initialValues.meetLocationId : null
                }
              ></SelectAddress>
              {/* <p className="input-note">Vehicle Move collectio</p> */}
            </div>

            <div className="form-group">
              <label htmlFor="name">Ground Transport Requirement</label>
              {errors.groundTransportRequirement &&
              touched.groundTransportRequirement ? (
                <div className="alert alert-danger" role="alert">
                  {errors.groundTransportRequirement.toString()}
                </div>
              ) : null}
              <Field
                className="form-control"
                name="groundTransportRequirement"
              />
              <p className="input-note">
                Additional ground transport required to facilitate the move
              </p>
            </div>

            <div className="form-group">
              <label htmlFor="name">
                Short Description of job<span className="required">*</span>
              </label>
              {errors.readableFromLocation && touched.readableFromLocation ? (
                <div className="alert alert-danger" role="alert">
                  {errors.readableFromLocation.toString()}
                </div>
              ) : null}
              <Field className="form-control" name="readableFromLocation" />
              <p className="input-note">
                Short and Human readable description of the job for billing purposes.</p>
              <p className="input-note">e.g. "SDL Heavy Lifting PAYE Timesheet" or "2nd Unit Tech Luton Vans Loading and Dry Hire"
              </p>
            </div>

            <div className="form-group">
              <label htmlFor="name">
                Short Text representing Job Locations
                <span className="required">*</span>
              </label>
              {errors.readableToLocation && touched.readableToLocation ? (
                <div className="alert alert-danger" role="alert">
                  {errors.readableToLocation.toString()}
                </div>
              ) : null}
              <Field className="form-control" name="readableToLocation" />
              <p className="input-note">
                Short and Human readable text representing the from and to location for billing purposes.</p>
              <p className="input-note">e.g. "Bovingdon/Pinewood" or "WBSL/Alicante"
              </p>
            </div>

            <div className="form-group">
              <label className="radio-label">
                <Field type="checkbox" name="plannedOutOfHours" />
                Planned Out Of Hours
              </label>
            </div>

            <div className="form-group">
              <label className="radio-label">
                <Field type="checkbox" name="overnightMove" />
                Overnight move
              </label>
            </div>

            <div className="form-group">
              <label htmlFor="name">
                Job Discriminator<span className="required">*</span>
              </label>
              {errors.swingDiscriminator && touched.swingDiscriminator ? (
                <div className="alert alert-danger" role="alert">
                  {errors.swingDiscriminator.toString()}
                </div>
              ) : null}
              <Field className="form-control" name="swingDiscriminator" />
              <p className="input-note">
                8 character max identifiable reference for the swing. e.g. "TechCrew" or "SDL" or "Wicked" or "PAYE"
              </p>
            </div>

            <div className="form-group">
              <button
                className="btn btn-primary btn-block"
                disabled={!isValid || meetLocationId === 0 || customerId === 0}
                type="submit"
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
