import { ExpenseDto } from "../contracts/models/ExpenseDto";
import api from "./network";

const API_URL = "Expenses/";

class ExpensesService {
  getExpenses(
    fromDate: any,
    toDate: any,
    searchTerm: any,
    status: any,
    customerId: any
  ) {
    return api.get(API_URL + "list", {
      params: {
        fromDate,
        toDate,
        searchTerm,
        status,
        customerId,
      },
    });
  }

  getVehicleExpenses(vehicleId: number, jobReference: string) {
    return api.get(API_URL + "vehicle", {
      params: {
        vehicleId,
        jobReference,
      },
    });
  }

  exportExpenses(
    fromDate: any,
    toDate: any,
    searchTerm: any,
    status: any,
    customerId: any
  ) {
    return api.get(API_URL + "list/export/csv", {
      responseType: "blob",
      referenceType: "blob",
      params: {
        fromDate,
        toDate,
        searchTerm,
        status,
        customerId,
      },
    });
  }

  async getExpenseImage(expenseId: any, name: any) {
    return api
      .get(`expenses/image`, {
        params: {
          expenseId,
          name,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        return `data:${response.headers["content-type"]};base64,${new Buffer(
          response.data,
          "binary"
        ).toString("base64")}`;
      });
  }

  markProcessed(expenseId: any, status: any) {
    return api.post(API_URL + "process", {
      expenseId,
      status,
    });
  }

markVatCharged(expenseId: any, charged: boolean) {
  return api.post(API_URL + "vat/charged", {
    expenseId,
    charged,
  });
}

async addExpense(expenseDto: ExpenseDto): Promise<void> {
  const formData = new FormData();
  
  formData.append('type', expenseDto.type);
  formData.append('amount', expenseDto.amount.toString());
  formData.append('vatCharged', expenseDto.vatCharged.toString());
  
  if (expenseDto.jobId) {
    formData.append('jobId', expenseDto.jobId.toString());
  }
  
  if (expenseDto.vehicleMoveId) {
    formData.append('vehicleMoveId', expenseDto.vehicleMoveId.toString());
  }
  
  if (expenseDto.expenseDescription) {
    formData.append('expenseDescription', expenseDto.expenseDescription);
  }
  
  if (expenseDto.vehicleRegistration) {
    formData.append('vehicleRegistration', expenseDto.vehicleRegistration);
  }
  
  if (expenseDto.status) {
    formData.append('status', expenseDto.status);
  }
  
  if (expenseDto.createdAt) {
    formData.append('createdAt', expenseDto.createdAt);
  }
  
  if (expenseDto.file) {
    formData.append('file', expenseDto.file);
  }

  return api
    .post(API_URL + 'add', formData)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error adding expense:', error);
      throw error;
    });
}

}

export default new ExpensesService();
