import React from "react";
import LoadingSpinner from "../loading/loading-spinner.component";

export default function DriverClassTable({
  licenceClasses,
  onSetOverride
}: any) {
  let classTable;
  if (licenceClasses && licenceClasses.length > 0) {
    let classList = licenceClasses.map((c: any) => <tr key={c.id}>
      <td className="center">{c.class.name}</td>
      <th scope="row" className="center">
        {c.class.description}
      </th>
      <th>{c.issueDate}</th>
      <th>{c.expiryDate}</th>
      {c.officeDisabled == false ? (
        <th>
          Permitted
          <button
            className="btn btn-sm btn-danger btn-override"
            type="button"
            onClick={() => onSetOverride(c.id, true)}
          >
            Disable
          </button>
        </th>
      ) : (
        <th>
          Disabled
          <button
            className="btn btn-sm btn-success btn-override"
            type="button"
            onClick={() => onSetOverride(c.id, false)}
          >
            Permit
          </button>
        </th>
      )}
      <th>{c.createdAt}</th>
      <th>{c.updatedAt}</th>
    </tr>);

    classTable = (
      <div className="job-list">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Class Name</th>
                <th scope="col">Description</th>
                <th scope="col">Issue Date</th>
                <th scope="col">Expiry Date</th>
                <th scope="col">Office Deactivated</th>
                <th scope="col">Created At</th>
                <th scope="col">Updated At</th>
              </tr>
            </thead>
            <tbody>{classList}</tbody>
          </table>
        </div>
      </div>
    );
  } else {
    classTable = (
      <div className="alert alert-danger">
        <p>Class list empty.</p>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card-container">
          <h3 className="page-title">Driver Classes</h3>
          {classTable}
        </div>
      </div>
    </div>
  );
}
