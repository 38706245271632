import React from 'react';
import Moment from 'react-moment';
import "./Jobs.css";

export default function Update({
    update
}: any) {
    return (
        <>
            <div className="update-container">
                <div className="row">
                    <div className="col-md-3">
                        <label className="job-update-status">{update.status.replace('_', ' ')}</label>
                    </div>
                    <div className="col">
                        {update.driver ?
                        <label className="job-label">{update.driver.licenceNumber}</label> : null }
                        {update.user ?
                        <label className="job-label">{update.user.name}</label> : null }
                    </div>
                    <div className="col">
                        {update.vehicle ?
                            <label className="job-label">{update.vehicle.registration}</label> : null}
                    </div>
                    <div className="col">
                        <label className="job-label"><Moment format="DD/MM/YYYY HH:mm" date={update.eventTime} /></label>
                    </div>
                </div>
            </div>
        </>
    );
}

