import React, { useState } from "react";
import AddAddress from "./add-address.component";
import LoadingSpinner from "../loading/loading-spinner.component";
import { useAddresses } from "../../hooks/useAddresses";
import { useQueryClient } from "@tanstack/react-query";
import { AddressDto } from "../../contracts/models/AddressDto";

export default function Addresses() {
  const { data: addresses, isLoading } = useAddresses();
  const [address, setAddress] = useState<AddressDto>();

  const queryClient = useQueryClient();

  let addressTable;
  if (isLoading) {
    addressTable = <LoadingSpinner></LoadingSpinner>;
  } else if (addresses && addresses.length > 0) {
    let addressList = addresses.map((address: any) => (
      <tr key={address.id}>
        <th scope="row">{address.line1}</th>
        <td>{address.line2}</td>
        <td>{address.city}</td>
        <td>{address.county}</td>
        <td>{address.country}</td>
        <td>{address.postcode}</td>
        <td>
          <button
            className="btn btn-primary btn-block"
            type="button"
            onClick={() => {
              setAddress(address);
            }}
          >
            Edit
          </button>
        </td>
      </tr>
    ));

    addressTable = (
      <div className="address-list">
        <div className="table-responsive">
          <table className="table overflow-scroll">
            <thead>
              <tr>
                <th scope="col">Line 1</th>
                <th scope="col">Line 2</th>
                <th scope="col">City</th>
                <th scope="col">County</th>
                <th scope="col">Country</th>
                <th scope="col">Postcode</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>{addressList}</tbody>
          </table>
        </div>
      </div>
    );
  } else {
    addressTable = (
      <div className="alert alert-danger">
        <p>Address list empty. Add a address using the form on this page.</p>
      </div>
    );
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <div className="card card-container">
            <h3 className="page-title">Address List</h3>
            {addressTable}
          </div>
        </div>
        <div className="col-md-4">
          <AddAddress
            // @ts-expect-error TS(2769): No overload matches this call.
            onComplete={() => queryClient.invalidateQueries("addresses")}
            existingAddress={address}
            setAddress={setAddress}
          />
        </div>
      </div>
    </div>
  );
}
