import React from "react";

function Terms(props: any) {
  return (
    <div className="container">
      <div className="jumbotron centeredBox">
        <h3>Swing Driver Terms</h3>
        <p>Please read this Privacy Policy carefully. You must not use the Swing Driver mobile application or our
Services if you do not accept this Privacy Policy or our Terms of Use.</p>

        <p></p>
      </div>
    </div>

  )
}

export default Terms;