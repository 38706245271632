import React, { useEffect, useState, useCallback } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./Jobs.css";
import { FilterCriteria } from "../../contracts/models/Swings/FilterCriteria";
import debounce from "lodash/debounce";

const deselectedStyle = { opacity: 0.15 };

const MySwal = withReactContent(Swal);

export default function Filter({
  filters,
  onChange,
}: {
  filters: any;
  jobs: any;
  onChange: (key: keyof FilterCriteria, value: any) => void;
}) {
  const [searchTerm, setSearchTerm] = useState(filters.searchTerm);

  const debouncedSearchChange = useCallback(
    debounce((value: string) => {
      onChange("searchTerm", value);
    }, 500),
    []
  );

  useEffect(() => {
    setSearchTerm(filters.searchTerm);
  }, [filters.searchTerm]);

  const handleSearchChange = (event: any) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedSearchChange(value);
  };

  const handleAvailableClick = () => {
    var f = filters;
    f.available = !filters.available;
    onChange("available", f.available);
  };

  const handleAllocatedClick = () => {
    var f = filters;
    f.allocated = !filters.allocated;
    onChange("allocated", f.allocated);
  };

  const handleCurrentClick = () => {
    var f = filters;
    f.current = !filters.current;
    onChange("current", f.current);
  };

  const handleAcceptedClick = () => {
    var f = filters;
    f.accepted = !filters.accepted;
    onChange("accepted", f.accepted);
  };

  const handleProgressClick = () => {
    var f = filters;
    f.progress = !filters.progress;
    onChange("progress", f.progress);
  };

  const handleCompletedClick = () => {
    var f = filters;
    f.completed = !filters.completed;
    onChange("completed", f.completed);
  };

  const handleClosedClick = () => {
    var f = filters;
    f.closed = !filters.closed;
    onChange("closed", f.closed);
  };

  const handlePendingClick = () => {
    var f = filters;
    f.pending = !filters.pending;
    onChange("pending", f.pending);
  };

  const handleFailedClick = () => {
    var f = filters;
    f.failed = !filters.failed;
    onChange("failed", f.failed);
  };

  const handleCancelledClick = () => {
    var f = filters;
    f.cancelled = !filters.cancelled;
    onChange("cancelled", f.cancelled);
  };

  const setDateFilterValue = (key: any, value: any) => {
    var f = filters;
    f[key] = value;
    onChange(key, value);
  };

  // @ts-expect-error TS(2339): Property 'children' does not exist on type '{}'.
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      className="swing-export-link"
      href="/"
      // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ));

  return (
    <div className="filters-container w-100">
      <div className="row my-3">
        <div className="col-md-8">
          <p className="filters-title">Filter Results</p>
          <span>Toggle category visibility by clicking below</span>
        </div>
      </div>
      <div className="row filter-section">
        <div className="col">
          <div
            className="filter-item"
            // @ts-expect-error TS(2322): Type '{ opacity: number; } | null' is not assignab... Remove this comment to see the full error message
            style={filters.available ? null : deselectedStyle}
            onClick={handleAvailableClick}
            value={filters.available}
          >
            <img
              className="filter-icon"
              src="/images/icons/available.png"
              alt="Available Icon"
            ></img>
            <p className="filter-text">Available</p>
          </div>
        </div>
        <div className="col">
          <div
            className="filter-item"
            // @ts-expect-error TS(2322): Type '{ opacity: number; } | null' is not assignab... Remove this comment to see the full error message
            style={filters.accepted ? null : deselectedStyle}
            onClick={handleAcceptedClick}
            value={filters.accepted}
          >
            <img
              className="filter-icon"
              src="/images/icons/accepted.png"
              alt="Accepted Icon"
            ></img>
            <p className="filter-text">Accepted</p>
          </div>
        </div>
        <div className="col">
          <div
            className="filter-item"
            // @ts-expect-error TS(2322): Type '{ opacity: number; } | null' is not assignab... Remove this comment to see the full error message
            style={filters.allocated ? null : deselectedStyle}
            onClick={handleAllocatedClick}
            value={filters.allocated}
          >
            <img
              className="filter-icon"
              src="/images/icons/choice.png"
              alt="Allocated Icon"
            ></img>
            <p className="filter-text">Allocated</p>
          </div>
        </div>
        <div className="col">
          <div
            className="filter-item"
            // @ts-expect-error TS(2322): Type '{ opacity: number; } | null' is not assignab... Remove this comment to see the full error message
            style={filters.progress ? null : deselectedStyle}
            onClick={handleProgressClick}
            value={filters.progress}
          >
            <img
              className="filter-icon"
              src="/images/icons/truck-01.png"
              alt="In Progress Icon"
            ></img>
            <p className="filter-text">In Progress</p>
          </div>
        </div>
        <div className="col">
          <div
            className="filter-item"
            // @ts-expect-error TS(2322): Type '{ opacity: number; } | null' is not assignab... Remove this comment to see the full error message
            style={filters.completed ? null : deselectedStyle}
            onClick={handleCompletedClick}
            value={filters.completed}
          >
            <img
              className="filter-icon"
              src="/images/icons/completed.png"
              alt="Completed Icon"
            ></img>
            <p className="filter-text">Completed</p>
          </div>
        </div>
        <div className="col">
          <div
            className="filter-item"
            // @ts-expect-error TS(2322): Type '{ opacity: number; } | null' is not assignab... Remove this comment to see the full error message
            style={filters.closed ? null : deselectedStyle}
            onClick={handleClosedClick}
            value={filters.closed}
          >
            <img
              className="filter-icon"
              src="/images/icons/closed.png"
              alt="Closed Icon"
            ></img>
            <p className="filter-text">Closed</p>
          </div>
        </div>
        {/* removed for now */}
        <div className="col">
          <div
            className="filter-item"
            // @ts-expect-error TS(2322): Type '{ opacity: number; } | null' is not assignab... Remove this comment to see the full error message
            style={filters.failed ? null : deselectedStyle}
            onClick={handleFailedClick}
            value={filters.failed}
          >
            <img
              className="filter-icon"
              src="/images/icons/failed.png"
              alt="Failed Icon"
            ></img>
            <p className="filter-text">Failed</p>
          </div>
        </div>
        <div className="col">
          <div
            className="filter-item"
            // @ts-expect-error TS(2322): Type '{ opacity: number; } | null' is not assignab... Remove this comment to see the full error message
            style={filters.pending ? null : deselectedStyle}
            onClick={handlePendingClick}
            value={filters.pending}
          >
            <img
              className="filter-icon"
              src="/images/icons/pending.png"
              alt="Pending Icon"
            ></img>
            <p className="filter-text">Pending</p>
          </div>
        </div>
        <div className="col">
          <div
            className="filter-item"
            // @ts-expect-error TS(2322): Type '{ opacity: number; } | null' is not assignab... Remove this comment to see the full error message
            style={filters.cancelled ? null : deselectedStyle}
            onClick={handleCancelledClick}
            value={filters.cancelled}
          >
            <img
              className="filter-icon"
              src="/images/icons/failed.png"
              alt="Cancelled Icon"
            ></img>
            <p className="filter-text">Cancelled</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5">
          <p className="">Search by reference or meet location</p>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">
                <BsSearch />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Search term"
              value={searchTerm}
              aria-label="Search by job reference, customer or meet location"
              aria-describedby="basic-addon1"
              onChange={handleSearchChange}
            />
          </InputGroup>
        </div>
        <div className="col-md-3">
          <p className="">From Date</p>
          <DatePicker
            className="form-control date-picker"
            selected={filters.fromDate}
            onChange={(date: any) => setDateFilterValue("fromDate", date)}
            dateFormat="dd/MM/yyyy"
            calendarStartDay={1}
          />
        </div>
        <div className="col-md-3">
          <p className="">To Date</p>
          <DatePicker
            className="form-control date-picker"
            selected={filters.toDate}
            onChange={(date: any) => setDateFilterValue("toDate", date)}
            dateFormat="dd/MM/yyyy"
            calendarStartDay={1}
          />
        </div>
        <div className="col-md-1 showing-results-bottom-container">
          {/* {jobs && (
            <p className="showing-results-text">
              Showing{" "}
              {jobs?.pages.reduce((p: any, c: any) => p + c.items.length, 0)}{" "}
              results
            </p>
          )} */}
        </div>
      </div>
    </div>
  );
}
