import { AxiosResponse } from "axios";
import { CustomerDto } from "../contracts/models/CustomerDto";
import { CustomerUserDto } from "../contracts/models/CustomerUserDto";
import api from "./network";

const API_URL = "Customer/";

class CustomerService {

  getCustomers(): Promise<AxiosResponse<CustomerDto[]>> {
    return api.get(API_URL + "get");
  }

  saveCustomer(
    Shortcode: any,
    Name: any,
    ContactName: any,
    ContactTelephone: any,
    Email: any
  ) {
    return api.post(API_URL + "add", {
      Shortcode,
      Name,
      ContactName,
      ContactTelephone,
      Email,
    });
  }

  addUserToCustomer(
    customerId: number,
    userId: number
  ) {
    return api.post(API_URL + "user/add", {
      customerId,
      userId,
    });
  }

  editCustomer(values: any) {
    return api.post(API_URL + "edit", values);
  }

  getCustomerUsersByCustomerId(customerId?: number): Promise<AxiosResponse<CustomerUserDto[]>> {
    return api.get(`${API_URL}Users${customerId ? "?customerId="+customerId : ""}`);
  }
}

export default new CustomerService();
