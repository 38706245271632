import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { useParams } from "react-router-dom";
import { GetById } from "../../helpers/LicenceClasses";
import { useSwingVehicles } from "../../hooks/useSwingVehicles";
import JobService from "../../services/job.service";
import SwingService from "../../services/swing.service";
import ManageDrivers from "../job-drivers/manage-drivers.component";
import LoadingSpinner from "../loading/loading-spinner.component";
import Status from "./status.component";
import Swing from "./swing.component";
import ViewJob from "./view-job.component";
import { JobDto } from "../../contracts/models/JobDto";
import { ViewJobList } from "./view-job-list.component";
import DefaultModal from "../modal/modal.component";

export default function Jobs() {
  const { id: idString } = useParams<{ id: string }>();
  const id = parseInt(idString || '0', 10);

  if (isNaN(id)) {
    // Handle invalid id, maybe show an error or redirect
    return <div>Invalid job ID</div>;
  }

  return <JobsHolder id={id} />
}
export function JobsHolderModal({id}: {id: number}) {
  return <DefaultModal title="Jobs" trigger={"View Job"}
  size="lg" content={<JobsHolder id={id} />}/>
}

export function JobsHolder({id}: {id: number}) {
  const queryClient = useQueryClient();
  const { data: vehicles } = useSwingVehicles(id);
  const swing = useQuery({
    queryKey: ["swing", id],
    queryFn: async () => {
      const response = await SwingService.getSwing(id);
      return response.data;
    },
  });

  const getJobs = ({ queryKey, pageParam }: any) => {
    const [_key, id] = queryKey;
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    return JobService.getJobs(id).then((j) => {
      return j.data;
    });
  };

  const jobs = useQuery({
    queryKey: ["jobs", id],
    queryFn: getJobs,
    refetchInterval: 60000,
  });

  const getType = (type: any) => {
    if (type === "1") return "Vehicle";
    else if (type === "2") return "Trailer";
    else if (type === "3") return "Minibus";
    else return "Unknown";
  };

  const getJobClasses = (veh: any) => {
    return veh.map((v: any) => GetById(v.requiredClassId)).join(", ");
  };

  const onUpdate = () => {
    queryClient.invalidateQueries({ queryKey: ["jobs"] });
    queryClient.invalidateQueries({ queryKey: ["availableDrivers"] });
    queryClient.invalidateQueries({ queryKey: ["pendingDrivers"] });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="jumbotron">
            <div className="card-container">
              <h3 className="page-title">Job Group Information</h3>
              {!swing.isLoading ? (
                swing.data && <Swing swing={swing.data}></Swing>
              ) : (
                <LoadingSpinner></LoadingSpinner>
              )}
            </div>
            <div className="card-container">
              <h3 className="page-title">Job List</h3>
              {!jobs.isLoading ? (
                <ViewJobList
                  jobs={jobs.data}
                  vehicles={vehicles}
                  swingData={swing.data}
                  onUpdate={onUpdate}
                  queryClientInvalidate={(id) => {
                    queryClient.invalidateQueries({ queryKey: ["swing", id] });
                    queryClient.invalidateQueries({queryKey: ["jobs", id]});
                  }}
                  id={id}
                />
              ) : (
                <LoadingSpinner></LoadingSpinner>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

