import DriverService from "../services/driver.service";
import { useInfiniteQuery } from "@tanstack/react-query";

async function getDrivers({ queryKey, pageParam = 1 }: any) {
  const [_key, searchTerm, terminated] = queryKey;
  const response = await DriverService.getDrivers(searchTerm, terminated, pageParam);
  return {
    items: response.data,
    nextCursor: pageParam + 1,  // Assuming your API doesn't return nextCursor, we use pageParam
    prevCursor: pageParam - 1,  // Assuming your API doesn't return prevCursor, we use pageParam
  };
}

export function useDrivers(searchTerm: any, terminated: any) {
  return useInfiniteQuery({
    queryKey: ["drivers", searchTerm, terminated],
    queryFn: getDrivers,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.items.length) {
        return lastPage.nextCursor;
      }
      return false;
    },
    getPreviousPageParam: (firstPage) => {
      if (firstPage.items.length) {
        return firstPage.prevCursor;
      }
      return false;
    },
    refetchInterval: 10000,
  });
}
