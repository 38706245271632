import React, { useRef, useState } from "react";
import { RoleDto } from "../../contracts/models/Roles/RoleDto";
import { UserDto } from "../../contracts/models/Users/UserDto";
import { useSuccessSwal } from "../../hooks/useSuccessSwal";
import userService from "../../services/user.service";
import RoleSelector from "./role-selector-component";
import { UpdateUserRolesDto } from "../../contracts/models/Users/UpdateUserRolesDto";
import ResetPassword from "../users/reset-password-component";
import DefaultModal from "../modal/modal.component";
import { useUsers } from "../../hooks/useUsers";
import { useRoles } from "../../hooks/useRoles";
import { useQueryClient } from "@tanstack/react-query";
import UpdateUserActiveStatus from "./update-user-active-status-component";
import UserForm from "./user-form-component";

export default function ManageUsers() {
  const { data: users } = useUsers();
  const { data: roles } = useRoles();
  const [selectedRoles, setSelectedRoles] = useState<RoleDto[]>([]);
  const swal = useSuccessSwal();
  const queryClient = useQueryClient();
  const modalRef = useRef<{ openModal: () => void; closeModal: () => void } | null>(null);
  const updateUserRoles = (updateUserRoles: UpdateUserRolesDto) => {
    userService.updateUserRoles(updateUserRoles).then((response) => {
      if (response.data.success) {
        queryClient.invalidateQueries({ queryKey: ["users"] });
        swal.execute("Success", "Role deleted successfully", "success");
      } else {
        swal.execute("Error", response.data.message, "error");
      }
    });
  };

  const resetPasswordButton = ({ onClick }: { onClick: () => void }) => (
    <button
      className="btn btn-primary m-0"
      type="button"
      onClick={onClick}
      data-toggle="tooltip"
      data-placement="top"
      title="Reset Password"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        fill="currentColor"
        className="bi bi-key-fill"
        viewBox="0 0 16 16"
      >
        <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2M2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
      </svg>
    </button>
  );

  const editRolesButton = (user: UserDto) => (
    <button
      className="btn btn-primary m-0"
      type="button"
      data-toggle="tooltip"
      data-placement="top"
      title="Edit Roles"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        fill="currentColor"
        className="bi bi-person-fill-gear"
        viewBox="0 0 16 16"
      >
        <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4m9.886-3.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382zM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0" />
      </svg>
    </button>
  );

  return (
    <div>
      <div className="shadow-sm p-3 rounded mb-4">
        <h4 className="mb-3">Users</h4>
        <p>
          Manage your organisations users. Create, edit, delete users. Assign
          roles, reset passwords.
        </p>
      </div>
      <div className="shadow-sm p-3">
        <div className="mb-3 d-flex justify-content-end">
          <DefaultModal
            title={"Create User"}
            trigger={"Create User"}
            content={<UserForm onComplete={() => modalRef?.current?.closeModal()}/>}
            ref={modalRef}
          ></DefaultModal>
        </div>
        {users && users.length > 0 ? (
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr className="text-center">
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Roles</th>
                  <th scope="col center-text">Action</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id} className="text-center">
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>
                      {user.roles && user.roles.length > 0 ? (
                        <ul className="list-group list-group-flush">
                          {user.roles.map((role) => (
                            <li key={role.id} className="list-group-item pl-0">
                              {role.roleName}
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </td>
                    <td className="">
                      <div className="d-flex justify-content-between flex-wrap flex-xl-nowrap">
                        <div className="p-2">
                          <ResetPassword
                            userId={user.id}
                            ActionComponent={resetPasswordButton}
                          />
                        </div>
                        <div className="p-2">
                          <DefaultModal
                            title={"Edit User Roles"}
                            content={
                              <div>
                                <RoleSelector
                                  className="my-3"
                                  allRoles={roles ?? []}
                                  currentUserRoles={user.roles}
                                  onUpdateUserRoles={setSelectedRoles}
                                />
                                <button
                                  className="btn btn-primary mt-3 mt-md-0"
                                  onClick={() =>
                                    updateUserRoles({
                                      userId: user.id,
                                      roleIds: selectedRoles.map(
                                        (role) => role.id
                                      ),
                                    })
                                  }
                                >
                                  Update Roles
                                </button>
                              </div>
                            }
                            trigger={editRolesButton(user)}
                          ></DefaultModal>
                        </div>
                        <div className="p-2">
                          <UpdateUserActiveStatus
                            userId={user.id}
                            isActive={user.isActive}
                          ></UpdateUserActiveStatus>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  );
}
