import React, { useState } from "react";
import { FormControl } from "react-bootstrap";
import SelectCustomer from "../customers/select-customer.component";
import Select from "react-select";

import DatePicker from "react-datepicker";
import { useCustomerLabels } from "../../hooks/useCustomers";
import "react-datepicker/dist/react-datepicker.css";
import "./Expenses.css";

const options = [
  {
    value: "ALL",
    label: "All",
  },
  {
    value: "PENDING",
    label: "Pending",
  },
  {
    value: "ACCEPTED",
    label: "Accepted",
  },
  {
    value: "DECLINED",
    label: "Declined",
  },
  {
    value: "PAID",
    label: "Paid",
  },
];
export default function FilterExpenses({
  expenses,
  filters,
  setFilters,
  isExporting,
  exportExpenses,
}: any) {
  const { data: customers, isLoading } = useCustomerLabels();

  const updateFilters = (item: any, value: any) => {
    var f = { ...filters, [item]: { label: filters[item].label, value } };
    console.log("new filters", f);
    setFilters(f);
  };

  if(isLoading || !customers) return <></>;
  
  return (
    <div className="filter-container">
      <div className="filter-row row">
        <div className="col-md-6">
          <SelectCustomer
            customers={customers}
            onChange={(c: any) => updateFilters("customer", c)}
            hideMessage={true}
            // defaultCustomer={filters.customer.value}
            clearable={true}
          ></SelectCustomer>
        </div>
        <div className="col-md-2">
          <Select
            onChange={(o: any) => updateFilters("status", o.value)}
            options={options}
            isClearable={false}
            name="status"
            defaultValue={{
              value: filters.status.value,
              label: filters.status.label,
            }}
          />
        </div>
        <div className="col-md-2">
          <DatePicker
            className="form-control date-picker"
            selected={filters.from.value}
            onChange={(date: any) => updateFilters("from", date)}
            dateFormat="dd/MM/yyyy"
            calendarStartDay={1}
          />
        </div>
        <div className="col-md-2">
          <DatePicker
            className="form-control date-picker"
            selected={filters.to.value}
            onChange={(date: any) => updateFilters("to", date)}
            dateFormat="dd/MM/yyyy"
            calendarStartDay={1}
          />
        </div>
      </div>
      <div className="filter-row row">
        <div className="col-md-8">
          <FormControl
            placeholder="Search by swing reference, driver name or vehicle registration."
            aria-label="Search Term"
            value={filters.searchTerm.value}
            onChange={(evt) => updateFilters("searchTerm", evt.target.value)}
          />
        </div>
        <div className="col-md-2">
          <button
            className="btn btn-info btn-block"
            type="button"
            disabled={isExporting}
            onClick={() => exportExpenses()}
          >
            {isExporting && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            {!isExporting && (
              <span>Export {expenses ? expenses.length : 0} Results </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
