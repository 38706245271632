import React, { useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import JobService from "../../services/job.service";
import "./Pending.css";

import { Dropdown } from "react-bootstrap";

const MySwal = withReactContent(Swal);

export default function AddPendingDrivers({
  job,
  availableDrivers,
  onUpdate,
  loading,
}: any) {
  const [isSaveLoading, setLoading] = useState(false);
  const [selectedDrivers, setDrivers] = useState([]);

  const quickSelectDrivers = availableDrivers.filter(
    (f: any) => f.driver.quickSelectEnabled
  );

  const addDrivers = () => {
    if (selectedDrivers.length === 0) {
      //error swal
    } else {
      setLoading(true);
      JobService.addPendingDrivers(
        // @ts-expect-error TS(2339): Property 'value' does not exist on type 'never'.
        selectedDrivers.map((d) => d.value),
        job.id
      ).then(
        (response) => {
          MySwal.fire({
            title: "Success",
            text: "Drivers successfully added.",
            icon: "success",
            // @ts-expect-error TS(2769): No overload matches this call.
            button: "Continue",
          });
          onUpdate();
          setDrivers([]);
          setLoading(false);
        },
        (error) => {
          console.log(error);
          if (error.response) {
            MySwal.fire({
              title: "Error Adding Pending Drivers",
              text: error.response.data,
              icon: "error",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
            setLoading(false);
          }
        }
      );
    }
  };

  const addValidDrivers = () => {
    setDrivers(quickSelectDrivers);
    // addDrivers();
  };

  const resetDrivers = () => {
    setDrivers([]);
  };

  const getHgvDrivers = () =>
    quickSelectDrivers.filter(
      (a: any) =>
        a.driver.licenceClasses.some(
          (l: any) => l.class.name == "C1" && !l.officeDisabled
        ) &&
        a.driver.licenceClasses.some(
          (l: any) => l.class.name == "CPC" && !l.officeDisabled
        ) &&
        a.driver.licenceClasses.some(
          (l: any) => l.class.name == "C" && !l.officeDisabled
        ) &&
        a.driver.licenceClasses.some(
          (l: any) => l.class.name == "TACHO" && !l.officeDisabled
        )
    );

  const addHGVDrivers = () => {
    setDrivers(getHgvDrivers());
  };

  const getNonHgvDrivers = () =>
    quickSelectDrivers.filter(
      (a: any) =>
        a.driver.licenceClasses?.every(
          (l: any) => l.class.name != "C" || l.officeDisabled
        ) ||
        a.driver.licenceClasses?.every(
          (l: any) => l.class.name != "TACHO" || l.officeDisabled
        ) ||
        a.driver.licenceClasses?.every(
          (l: any) => l.class.name != "CPC" || l.officeDisabled
        )
    );
  const addNoneHGVDrivers = () => {
    setDrivers(getNonHgvDrivers());
  };

  const getPayee = () =>
    quickSelectDrivers.filter((a: any) => a.driver.isPayee);

  const addPayee = () => {
    setDrivers(getPayee);
  };

  const getTeamLeaders = () =>
    quickSelectDrivers.filter((a: any) => a.driver.isTeamLeader);

  const addTeamLeaders = () => {
    setDrivers(getTeamLeaders());
  };

  const getRating = (rating: any) =>
    quickSelectDrivers.filter((a: any) => a.driver.rating == rating);

  const addRating = (rating: any) => {
    setDrivers(() => getRating(rating));
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <p className="available-title">Request Driver Availability</p>
          <p>
            Select drivers from the list below to request their availability for
            this job.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <button
            type="button"
            className="btn btn-primary btn-block"
            disabled={quickSelectDrivers?.length === 0}
            onClick={() => addValidDrivers()}
          >
            All Valid{" "}
            <span className="small">({quickSelectDrivers?.length})</span>
          </button>
        </div>
        <div className="col">
          <button
            type="button"
            className="btn btn-primary btn-block"
            disabled={getHgvDrivers()?.length === 0}
            onClick={() => addHGVDrivers()}
          >
            HGV <span className="small">({getHgvDrivers()?.length})</span>
          </button>
        </div>
        <div className="col">
          <button
            type="button"
            className="btn btn-primary btn-block"
            disabled={getNonHgvDrivers()?.length === 0}
            onClick={() => addNoneHGVDrivers()}
          >
            Non-HGV{" "}
            <span className="small">({getNonHgvDrivers()?.length})</span>
          </button>
        </div>
        <div className="col">
          <button
            type="button"
            className="btn btn-warning btn-block"
            disabled={getTeamLeaders()?.length === 0}
            onClick={() => addTeamLeaders()}
          >
            TL <span className="small">({getTeamLeaders()?.length})</span>
          </button>
        </div>
        <div className="col">
          <button
            type="button"
            className="btn btn-info btn-block"
            disabled={getPayee()?.length === 0}
            onClick={() => addPayee()}
          >
            Full Time <span className="small">({getPayee()?.length})</span>
          </button>
        </div>
        <div className="col">
          <Dropdown>
            <Dropdown.Toggle
              disabled={quickSelectDrivers?.length === 0}
              variant="warning"
            >
              Rating Based
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => addRating(1)}>
                1 ({getRating(1)?.length})
              </Dropdown.Item>
              <Dropdown.Item onClick={() => addRating(2)}>
                2 ({getRating(2)?.length})
              </Dropdown.Item>
              <Dropdown.Item onClick={() => addRating(3)}>
                3 ({getRating(3)?.length})
              </Dropdown.Item>
              <Dropdown.Item onClick={() => addRating(4)}>
                4 ({getRating(4)?.length})
              </Dropdown.Item>
              <Dropdown.Item onClick={() => addRating(5)}>
                5 ({getRating(5)?.length})
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col">
          <button
            type="button"
            className="btn btn-block btn-success"
            disabled={selectedDrivers?.length === 0 || isSaveLoading}
            onClick={() => addDrivers()}
          >
            Save <span className="small">({selectedDrivers?.length})</span>
          </button>
        </div>
      </div>

      <hr></hr>
      <div className="row reset-right">
        <div className="col-lg-2 col-sm-4">
          <button
            type="button"
            className="btn btn-block btn-danger"
            disabled={availableDrivers?.length === 0}
            onClick={() => resetDrivers()}
          >
            Reset
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Select
            onChange={(option: any) => {
              setDrivers(option);
            }}
            isMulti={true}
            options={availableDrivers}
            name="drivers"
            value={selectedDrivers}
            isLoading={loading}
          />
        </div>
      </div>
    </>
  );
}
