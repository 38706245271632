import VehicleService from "../services/vehicle.service";
import { GetById } from "../helpers/LicenceClasses";
import { useQuery } from "@tanstack/react-query";

const getType = (type: any) => {
  if (type === 1) return "Vehicle";
  if (type === 2) return "Trailer";
  if (type === 3) return "Minibus";
  return "Unknown";
};

export const convertVehicles = (vehicles: any[]) =>
  vehicles?.map((v: any) => ({
    value: v.id,
    label:
      v.vehicleDescription +
      " - " +
      GetById(v.class) +
      (v.registration ? " - VRN: " + v.registration : "") +
      " - " +
      getType(v.type),
  })) ?? [];

export function useSwingVehicles(swingId: any) {
  return useQuery({
    queryKey: ["swingVehicles", parseInt(swingId)],
    queryFn: async () => {
      if (!swingId) return [];
      const response = await VehicleService.getVehiclesBySwing(swingId);
      return response?.data ?? [];
    },
    refetchInterval: 60000,
  });
}
