import { AxiosResponse } from "axios";
import { ContactDto } from "../contracts/models/ContactDto";
import api from "./network";

const API_URL = "Contacts/";

class ContactsService {

  getContacts(): Promise<AxiosResponse<ContactDto[]>> {
    return api.get(API_URL);
  }

  handleGetContacts(onReturn: any) {
    this.getContacts().then(
      (response) => {
        if (response.data.length > 0) {
          var contacts = response.data.map((contact: any) => ({
            value: contact.id,
            label:
              contact.name + ", " + contact.telephone + ", " + contact.email,
          }));
          onReturn(contacts);
        }
      },
      (error) => {
        console.log(error);
        onReturn([]);
      }
    );
  }

  saveContact(contact: any) {
    return api.post(API_URL, contact);
  }
}

export default new ContactsService();
