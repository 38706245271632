import { Field, Form, Formik } from "formik";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";
import "./Jobs.css";
const AddJobSchema = Yup.object().shape({
  jobReference: Yup.string()
    .min(0, "Field is too short")
    .max(300, "Field is too long"),
});

const MySwal = withReactContent(Swal);

export default function FilterJobReference({
  jobReference,
  setJobReference,
  onSubmit,
}: {
  jobReference: string;
  setJobReference: (string: any) => void;
  onSubmit: (jobReference: string) => void;
}) {
  return (
    <Formik
      initialValues={{
        jobReference: "",
      }}
      validationSchema={AddJobSchema}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values.jobReference);
      }}
    >
      {({ errors, touched, setFieldValue, isValid, dirty }) => (
        <Form>
          <div className="row">
            <div className="col-md-8">
              <p>Job Reference</p>
              {errors.jobReference && touched.jobReference ? (
                <div className="alert alert-danger" role="alert">
                  {errors.jobReference}
                </div>
              ) : null}
              <div className="input-group">
                <Field
                  className="form-control"
                  name="jobDescription"
                  onChange={(e: any) => setJobReference(e.target.value)}
                />
              </div>
            </div>
            {!isValid ? (
              <div className="alert alert-danger" role="alert">
                Correct form errors before submitting.
              </div>
            ) : null}

            <div className="col-md-4 bottom-container">
              <button
                className="btn btn-primary btn-block"
                disabled={!isValid}
                type="submit"
              >
                Search
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
