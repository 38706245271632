import React from "react";
import { DriverJobDto } from "../../contracts/models/DriverJobDto";
import Status from "./status.component";
import ViewJob from "./view-job.component";
import ManageDrivers from "../job-drivers/manage-drivers.component";
import { GetById } from "../../helpers/LicenceClasses";
import { VehicleMoveDto } from "../../contracts/models/VehicleMoveDto";

interface ViewJobListProps {
  jobs: DriverJobDto[];
  vehicles: any; // Define a more specific type if available
  swingData: any; // Define a more specific type if available
  onUpdate?: () => void;
  queryClientInvalidate?: (id: string | number) => void;
  id?: string | number;
  hideAdmin?: boolean;
}

export const ViewJobList: React.FC<ViewJobListProps> = ({
  jobs,
  vehicles,
  swingData,
  onUpdate,
  queryClientInvalidate,
  id,
  hideAdmin = false,
}) => {
  if (jobs && jobs.length === 0) {
    if (hideAdmin) return <></>;
    return (
      <div className="alert alert-danger">
        Job list empty. Add a job using the jobs page.
      </div>
    );
  }
  const getJobClasses = (veh?: VehicleMoveDto[]) => {
    if (!veh) return "";
    return veh.map((v: any) => GetById(v.requiredClassId)).join(", ");
  };

  return (
    <div className="job-list">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col" className="center">
                Status
              </th>
              <th scope="col" className="center">
                Description
              </th>
              <th scope="col" className="center">
                Vehicles
              </th>
              <th scope="col" className="center">
                Licence Requirement
              </th>
              <th scope="col" className="center">
                Job Classes
              </th>
              <th scope="col" className="center">
                Driver
              </th>
              <th scope="col"> </th>
            </tr>
          </thead>
          <tbody>
            {jobs.map((job) => (
              <tr key={job.id}>
                <td className="center">
                  <Status status={job?.status}></Status>
                </td>
                <th scope="row" className="center">
                  {job.jobDescription}
                </th>
                <td className="center">
                  {job.vehicles ? job.vehicles.length : 0}
                </td>
                <th className="center">{job.jobClassRequirement?.name}</th>
                <th className="center">{getJobClasses(job.vehicles)}</th>
                {!hideAdmin && (
                  <td className="center">
                    {job?.driver ? (
                      <>
                        <p className={job.isTeamLeader ? "team-leader" : ""}>
                          {job?.driver.name}
                          {job.isTeamLeader ? "*" : ""}
                        </p>
                      </>
                    ) : (
                      <ManageDrivers
                        job={job}
                        onUpdate={onUpdate}
                      ></ManageDrivers>
                    )}
                  </td>
                )}
                {!hideAdmin && (
                  <td className="center">
                    <ViewJob
                      vehicles={vehicles}
                      swing={swingData}
                      job={job}
                      onUpdate={() => {
                        if (queryClientInvalidate && id)
                          queryClientInvalidate(id);
                      }}
                    ></ViewJob>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p className="right small team-leader">Team Leader*</p>
    </div>
  );
};
