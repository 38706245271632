export enum PermissionType {
      ViewJobs,
      CreateEditJobs,
      ViewAssests,
      CreateEditAssets,
      ViewDrivers,
      ManageDrivers,
      ViewExpenses,
      ManageExpenses,
      Exporting
}

// Define a mapping object for friendly names
export const permissionFriendlyNames = {
      [PermissionType.CreateEditAssets]: "Create and Edit Assets",
      [PermissionType.CreateEditJobs]: "Create and Edit Jobs",
      [PermissionType.Exporting]: "Exports",
      [PermissionType.ManageDrivers]: "Manage Drivers",
      [PermissionType.ManageExpenses]: "Manage Expenses",
      [PermissionType.ViewAssests]: "View Assets",
      [PermissionType.ViewDrivers]: "View Drivers",
      [PermissionType.ViewExpenses]: "View Expenses",
      [PermissionType.ViewJobs]: "View Jobs",
};
