import { useQuery } from "@tanstack/react-query";
import customerManagementService from "../services/customer-management.service";

export function usePendingCustomerRequests() {
  return useQuery({
    queryKey: ["pendingRequests"],
    queryFn: async () => {
      const response = await customerManagementService.listCustomerRequests();
      return response?.filter(c => c.status === "PENDING") ?? [];
    },
    refetchInterval: 60000,
  });
}
