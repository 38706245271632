import { useQuery } from '@tanstack/react-query';
import React from 'react';
import customerManagementService from '../../services/customer-management.service';
import LoadingSpinner from '../loading/loading-spinner.component';
import AdminViewCustomerRequestModal from './admin-view-customer-request.component';
import Moment from 'react-moment';

const AdminListJobRequest: React.FC = () => {
  const { data: jobRequests, isLoading, isError, error } = useQuery({
    queryKey: ["adminCustomerRequestManagement"],
    queryFn: async () => {
      return await customerManagementService.listCustomerRequests();
    },
  });

  if (isLoading || !jobRequests) return <LoadingSpinner/>;

  return (
    <div>
      <h2>Job Requests</h2>

              <table className="table table-hover table-striped"> {/* Enhanced table appearance */}
          <thead>
            <tr>
            <th>Status</th>
            <th>Job Type</th>
            <th>Job Description</th>
              <th>Department</th>
              <th>Start Date</th>
              <th></th> {/* Added for clarity */}
            </tr>
          </thead>
          <tbody>
            {jobRequests.map((request) => (
              <tr key={request.id}>
                <td>{request.status}</td>
                <td>{request.jobType}</td>
                <td>{request.jobDescription}</td>
                <td>{request.department}</td>
                <p><Moment format="DD/MM/YYYY HH:mm" date={request.startDate} /></p>
                <td>
                <td><AdminViewCustomerRequestModal id={request.id ?? 0} /></td>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
    </div>
  );
};

export default AdminListJobRequest;