import SwingService from "../services/swing.service";
import { useQuery } from "@tanstack/react-query";
import { SwingApiResponse } from "../contracts/models/Swings/JobsApiResponse";

export function useSwings(searchParams: string) {
  return useQuery<SwingApiResponse>({
    queryKey: ["swings", searchParams],
    queryFn: async () => (await SwingService.getSwingsNew(searchParams)).data,
    staleTime: 30_000, //don't refetch previously viewed pages until cache is more than 30 seconds old
  });

  
}


