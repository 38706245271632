import { AxiosResponse } from "axios";
import { GetUsersDto } from "../contracts/models/Users/GetUsersDto";
import { UpdateUserRolesResponseDto } from "../contracts/models/Users/UpdateUserRolesResponseDto";
import { UpdateUserRolesDto } from "../contracts/models/Users/UpdateUserRolesDto";
import { UserDto } from "../contracts/models/Users/UserDto";
import api from "./network";
import { PermissionType } from "../contracts/enums/PermissionType";
import { UpdateUserActiveStatus } from "../contracts/models/Users/UpdateUserActiveStatus";
import { CreateUserDto } from "../contracts/models/Users/CreateUserDto";
import { CreateUserResponseDto } from "../contracts/models/Users/CreateUserResponseDto";

const API_URL = "User/";

class UserService {
  // Method to fetch users based on criteria defined in GetUsersDto
  async getUsers(request: GetUsersDto): Promise<AxiosResponse<UserDto[]>> {
    try {
      return await api.get(API_URL, {
        params: request,
      });
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  }

  // Method to fetch user permissions based on userId
  async getUserPermissions(): Promise<PermissionType[]> {
    try {
      let result = await api.get(`${API_URL}permissions`);
      return result.data;
    } catch (error) {
      console.error("Error fetching user permissions:", error);
      return [];
    }
  }

  // Method to create a new user based on CreateUserDto
  async createUser(request: CreateUserDto): Promise<AxiosResponse<CreateUserResponseDto>> {
    try {
      return await api.post(API_URL, request);
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  }

  // Method to update user roles based on AssignRolesDto
  async updateUserRoles(
    request: UpdateUserRolesDto
  ): Promise<AxiosResponse<UpdateUserRolesResponseDto>> {
    try {
      return await api.put(API_URL, request);
    } catch (error) {
      console.error("Error updating user roles:", error);
      throw error;
    }
  }

  // Method to update users active status based on userId
  async updateActiveStatus(
    request: UpdateUserActiveStatus
  ): Promise<AxiosResponse> {
    try {
      return await api.patch(`${API_URL}active-status`, request);
    } catch (error) {
      console.error("Error updating user active status:", error);
      throw error;
    }
  }

  containsPermission(
    permissions: PermissionType[],
    requiredPermission: PermissionType
  ): boolean {
    return (
      permissions.filter(
        (x) => x.toString() == PermissionType[requiredPermission]
      ).length > 0
    );
  }
}

export default new UserService();
