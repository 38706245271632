import api from "./network";

const API_URL = 'Address/';

class AddressService {

  getAddresses() {
    return api.get(API_URL);
  }

  saveAddress(address: any) {
    return api.post(API_URL, address);
  }

  editAddress(address: any) {
    return api.put(`${API_URL}edit`, address);
  }

}

export default new AddressService();