import React from 'react';
import { ErrorMessage, Field } from 'formik';

const NumberDriversComponent = ({name}: {name:string}) => {
  return (
    <div className="form-group">
      <label htmlFor="numberOfDrivers">How many do you need?</label>
      <Field name={name} as="select" className="form-control">
        {[...Array(20).keys()].map((number) => (
          <option key={number + 1} value={number + 1}>{number + 1}</option>
        ))}
      </Field>
      <ErrorMessage name={name}>
    {(msg: string) => (
                      <div className="alert alert-danger" role="alert">
                      <>{msg}</>
                      </div>
    )}
                      </ErrorMessage>    
    </div>
  );
};

export default NumberDriversComponent;