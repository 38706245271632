import ComplianceService from "../services/compliance.service";
import { useQuery } from "@tanstack/react-query";

export function useQuestionnaireSubmission(id: any) {
  return useQuery({
    queryKey: ["questionnairesubmission", id],
    queryFn: async () => {
      const response = await ComplianceService.getSubmission(id);
      return response.data;
    },
    refetchInterval: 60000,
  });
}
