import JobService from "../services/job.service";
import { useQuery } from "@tanstack/react-query";

export function useAvailableDrivers(jobId: any) {
  return useQuery({
    queryKey: ["availableDrivers", jobId],
    queryFn: async () => {
      const response = await JobService.getAvailablePendingDrivers(jobId);
      if (response.data.length > 0) {
        return response.data.map((user: any) => ({
          value: user.id,
          label: user.name,
          driver: user
        }));
      }
      return [];
    },
    initialData: [],
  });
}
