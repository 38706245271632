import React from "react";
import { useQuery } from "@tanstack/react-query";
import CustomerJobRequestService from "../../services/customer-request.service";
import DefaultModal from "../modal/modal.component";
import LoadingSpinner from "../loading/loading-spinner.component";
import Moment from "react-moment";
import { ViewJobList } from "../jobs/view-job-list.component";
import { CustomerJobRequestDto, RequestVehicleDetails } from "../../contracts/models/CustomerJobRequestDto";
import { CustomerJobRequestResponseDto } from "../../contracts/models/CustomerJobRequestResponseDto";
import { useJobRequestById } from "../../hooks/useJobRequestById";
import ViewAddressLine from "../address/view-address-line.component";
import DuplicateCustomerRequest from "./duplicate-customer-request.component";
import AddOrEditJobRequestForm from "./add-new-job-request";

export default function ViewCustomerRequest({ id }: { id: number }) {

  const { data: request, isLoading, isError, error } = useJobRequestById(id);

  const footerButtons = [
    ((request && request.id) ? <DuplicateCustomerRequest requestId={request.id}/> : <></>)
  ]
  if (request) return (
    <DefaultModal
      title={"View Customer Request"}
      trigger={"View Customer Request"}
      headerButtons={footerButtons}
      content={<AddOrEditJobRequestForm jobRequest={request} />}
    />
  );

  return <></>
}

interface CustomerRequestDetailsProps {
  request: CustomerJobRequestResponseDto;
}

export function CustomerRequestDetails({ request }: CustomerRequestDetailsProps) {
  if (!request) return null;

  return (
    <>
    <div className="row view-customer-request-container">
      <>
        <div className="col-md-4 information-container">
          <label>Status:</label>
          <p>{request.status}</p>
        </div>
        <div className="col-md-4 information-container">
          <label>Customer:</label>
          <p>{request.customer.name}</p>
        </div>
        <div className="col-md-4 information-container">
          <label>Job Description:</label>
          <p>{request.jobDescription}</p>
        </div>
        <div className="col-md-4 information-container">
          <label>Department:</label>
          <p>{request.department}</p>
        </div>
        <div className="col-md-4 information-container">
          <label>Job Type:</label>
          <p>{request.jobType}</p>
        </div>
        <div className="col-md-4 information-container">
          <label>Start Date:</label>
          <p><Moment format="DD/MM/YYYY HH:mm" date={request.startDate} /></p>
        </div>        
        {(request.jobPickupAddress || request.jobPickupAddressDetails) && (
          <div className="col-md-4 information-container">
            <label>Pickup Address:</label>
            {request.jobPickupAddressDetails && <ViewAddressLine address={request.jobPickupAddressDetails} />}
            {request.jobPickupAddress && <p>{request.jobPickupAddress}</p> }
          </div>
        )}
        {(request.jobDeliveryAddress || request.jobDeliveryAddressDetails) && (
          <div className="col-md-4 information-container">
            <label>Delivery Address:</label>
            
            {request.jobDeliveryAddressDetails && <ViewAddressLine address={request.jobDeliveryAddressDetails} />}
            {request.jobDeliveryAddress && <p>{request.jobDeliveryAddress}</p> }
          </div>
        )}
        <div className="col-md-4 information-container">
          <label>Created At:</label>
          <p><Moment format="DD/MM/YYYY HH:mm" date={request.createdAt} /></p>
        </div>
      </>
      </div>

      <div className="row">
        {request?.vehicles?.map((vehicle: RequestVehicleDetails, index: number) => (
          <div key={index} className="col-md-4 information-container my-4">
            <label>Vehicle Type:</label>
            <p>{vehicle.vehicleType} {vehicle.numberOfDrivers && "x " + vehicle.numberOfDrivers}</p>
          </div>
        ))}
      </div>
    </>

  );

  {/* <ViewJobList jobs={request.jobs} vehicles={request.vehicles} swingData={request.swing} hideAdmin={true} /> */ }
}
