import AddressService from "../services/address.service";
import { useQuery } from "@tanstack/react-query";

export function useAddresses() {
  return useQuery({
    queryKey: ["addresses"],
    queryFn: async () => {
      const response = await AddressService.getAddresses();
      return response.data.length > 0
        ? response.data.map((address: any) => ({
            ...address,
            value: address.id,
            label: `${address.line1}, ${address.city}, ${address.postcode}`
          }))
        : [];
    },
    refetchInterval: 60000,
  });
}
