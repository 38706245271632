import { useQuery } from '@tanstack/react-query';
import React from 'react';
import Moment from 'react-moment';
import { useParams } from 'react-router-dom';
import { CustomerUserDto } from '../../contracts/models/CustomerUserDto';
import { useCustomerLabels } from '../../hooks/useCustomers';
import { useCustomers } from '../../hooks/useCustomers';
import customerService from '../../services/customer.service';
import LoadingSpinner from '../loading/loading-spinner.component';
import AddNewCustomerUser from './add-new-customer-user.component';
import AddUserToCustomer from './add-user-to-customer.component';

export const useCustomerUsers = (customerId: number) => {
  return useQuery({queryKey:['customerUsers', customerId], queryFn:  async () => {
    var response = await customerService.getCustomerUsersByCustomerId(customerId);
    return response.data;
  } },);
};

export const useAllCustomerUsers = () => {
  return useQuery({queryKey: ['allCustomerUsers'], queryFn: async () => {
    var response = await customerService.getCustomerUsersByCustomerId();
    return response.data;
  }});
};

export const AllCustomerUsersPage = () => {
  const { data: customerUsers, isLoading } = useAllCustomerUsers();

  if (isLoading || !customerUsers) return <LoadingSpinner />;

  return (
    <div>
      <h2>Customer Users</h2>
      <AddNewCustomerUser />
      <CustomerUserList customerUsers={customerUsers} />
    </div>
  );
};

const CustomerUsersPage = () => {
  const { id } = useParams<{ id: string }>();
  const customerId = parseInt(id);
  const { data: customerUsers, isLoading } = useCustomerUsers(customerId);

  if (isLoading || !customerUsers) return <LoadingSpinner />;

  return (
    <div>
      <h2>Customer Users</h2>
      <AddNewCustomerUser customerId={customerId} />
      <CustomerUserList customerUsers={customerUsers} />
    </div>
  );
};


interface CustomerUserListProps {
  customerUsers: CustomerUserDto[];
}

export const CustomerUserList: React.FC<CustomerUserListProps> = ({ customerUsers }) => {
  return (
    <div className="my-2 table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Status</th>
            <th scope="col">Customers</th>
            <th scope="col">Created At</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {customerUsers?.map((user) => (
            <CustomerUserItem key={user.id} user={user} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface CustomerUserItemProps {
  user: CustomerUserDto;
}

const CustomerUserItem: React.FC<CustomerUserItemProps> = ({ user }) => {

  const {data:customers} = useCustomerLabels(true, false, true);
  return (
    <tr>
      <td>{user.name}</td>
      <td>{user.email}</td>
      <td>{user.status}</td>
      <td>{(customers ? user.customerIds?.map((id) => customers.find(c => c.value == id)?.label) : []).join(", ")}</td>
      <td><Moment format="DD/MM/YYYY" date={user.createdAt} /></td>
      <td><AddUserToCustomer userId={user.id}/></td>
    </tr>
  );
};

export default CustomerUsersPage;