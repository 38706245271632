import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LoadingSpinner from "../loading/loading-spinner.component";
import "./drivers.css";
import DriverClassUpdatesTable from "./driver-class-updates-table.component";
import { useDriverClassUpdates } from "../../hooks/useDriverClassUpdates";

const MySwal = withReactContent(Swal);

export default function ViewClassUpdates({}) {
  const { data: driverClassUpdates, isLoading } = useDriverClassUpdates();

  return (
    <div className="container">
      {!isLoading ? (
        <div className="jumbotron">
          <div className="row">
            <DriverClassUpdatesTable
              updates={driverClassUpdates}
              showDriver={true}
            ></DriverClassUpdatesTable>
          </div>
          <p className="right small">Last 250 shown</p>
        </div>
      ) : (
        <LoadingSpinner></LoadingSpinner>
      )}
    </div>
  );
}
