import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import jobService from "../../services/job.service";

const MySwal = withReactContent(Swal);

// Define the form validation schema
const SetPaymentNumberSchema = Yup.object().shape({
  type: Yup.string().required("Type is required"),
  number: Yup.string().required("Request number is required"),
  value: Yup.number()
    .min(1, "Value must be greater than 0")
    .required("Value is required"),
});

const SetPaymentNumber = ({
  swingId,
  type,
}: {
  swingId: number;
  type: "PO" | "invoice";
}) => {
  const title = type === "PO" ? "Set PO Number" : "Set Invoice Number";
  const queryClient = useQueryClient();
  
  const mutation = useMutation({
    mutationFn: (values: { number: string; value: number; swingId: number; type: "PO" | "invoice" }) =>
      jobService.setPaymentNumber(values),
    onSuccess: () => {
      MySwal.fire("Success", `Successfully set ${type} number.`, "success");
      queryClient.invalidateQueries({ queryKey: ["swing"] });
    },
    onError: (error) => {
      MySwal.fire("Error", "An error occurred. Please try again.", "error");
    },
  });

  const handleSubmit = (
    values: { number: string; value: number },
    { resetForm }: any
  ) => {
    mutation.mutate({ ...values, swingId, type });
    resetForm();
  };

  return (
    <div className="float-right my-2 w-50 md:w-100">
      <Formik
        initialValues={{ type, number: "", value: 0 }}
        validationSchema={SetPaymentNumberSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div>
              <label htmlFor="number">Set {type} Request Number</label>
              <Field className="form-control" name="number" type="text" />
              {errors.number && touched.number && <div>{errors.number}</div>}
            </div>
            <div>
              <label htmlFor="value">Value</label>
              <Field className="form-control" name="value" type="number" />
              {errors.value && touched.value && <div>{errors.value}</div>}
            </div>
            <button className="btn btn-primary my-1" type="submit">
              Save
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SetPaymentNumber;
