export const VehicleTypes = [
  { value: 0, label: "Unknown" },
  { value: 1, label: "Standard Vehicle" },
  { value: 2, label: "General Trailer" },
  { value: 3, label: "7.5 Ton Box Truck" },
  { value: 4, label: "7.5 Ton Curtain-Sided Truck" },
  { value: 5, label: "Class 2 Flatbed Truck" },
  { value: 6, label: "Class 2 Box Truck" },
  { value: 7, label: "Class 2 Curtain-Sided Truck" },
  { value: 8, label: "Class 2 Low Loader" },
  { value: 9, label: "Drawbar Trailer" },
  { value: 10, label: "Flatbed Trailer" },
  { value: 11, label: "Urban Flatbed Trailer" },
  { value: 12, label: "Box Trailer" },
  { value: 13, label: "Curtain-Sided Trailer" },
  { value: 14, label: "Low Loader Trailer" },
  { value: 15, label: "Tractor Unit" },
  { value: 16, label: "Tractor Unit with Crane" },
  { value: 17, label: "Rigid Truck with Crane" },
];
export function GetVehicleById(id: any) {
  var c = VehicleTypes.find((option) => option.value === id);
  if (c) {
    return c.label;
  }
  return "Unknown";
}

export function GetVehicleSelectItemById(id: any) {
  var c = VehicleTypes.find((option) => option.value === id);
  if (c) {
    return c;
  }
  return VehicleTypes[0];
}
