import React from "react";
import Answer from "./answer.component";
import "./Compliance.css";
export default function Question({
  question,
  submissionAnswers
}: any) {
  const submissionQuestionAnswer = submissionAnswers.find(
    (sa: any) => sa.questionId == question.id
  );

  return <>
    <div className="row">
      <div className="col">
        <p>{question.fullQuestion}</p>
      </div>
    </div>

    <div className="row">
      <div className="answers-container">
        {question.answers.map((answer: any) => <Answer
          key={answer.id}
          answer={answer}
          wasSelected={
            submissionQuestionAnswer.questionAnswerId === answer.id
          }
          submissionAnswer={submissionQuestionAnswer}
        ></Answer>)}
      </div>
    </div>

    {submissionQuestionAnswer.answerNote && (
      <div className="driver-comment-container">
        <p className="label">Driver Comment</p>
        <p>{submissionQuestionAnswer.answerNote}</p>
      </div>
    )}
  </>;
}
