import React from "react";
import { Classes } from "../../helpers/LicenceClasses";
import Select from "react-select";

export default function SelectNotificationPreference({ defaultValue, onChange }: {defaultValue?: string, onChange: any}) {
  const options = [
    { value: "None", label: "Dont notify me with job updates" },
    { value: "Email", label: "Notify me with job updates via email" },
    { value: "SMS", label: "Notify me with job updates via SMS"},
    { value: "Whatsapp", label: "Notify me with job updates via WhatsApp"}
  ];

  return (
    <>
      {/* <label htmlFor="customerId">Classes<span className="required">*</span></label> */}
      <Select
        onChange={(option: any) => {
          onChange(option?.value);
        }}
        isMulti={false}
        defaultValue={options.find((option) => option.value === defaultValue) ?? options[0]}
        options={options}
        isClearable={false}
        name="notificationPreference"
      />
    </>
  );
}
