import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

interface ToggleProps {
  value: boolean;
  serviceCall: (value: boolean) => Promise<any>;
  onSuccess: () => void;
  label: string;
}

const Toggle: React.FC<ToggleProps> = ({ value, serviceCall, onSuccess, label }) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (newValue: boolean) => serviceCall(newValue),
    onSuccess: () => {
      onSuccess();
    },
    onError: (error) => {
      MySwal.fire("Error", "An error occurred. Please try again.", "error");
    },
  });

  const handleToggle = () => {
    mutation.mutate(!value);
  };

  return (
      <button
        className={`btn btn-primary my-2`}
        onClick={handleToggle}
        disabled={mutation.isPending}
      >
        {mutation.isPending ? 'Loading...' : `${label}: ${value ? 'On' : 'Off'}`}
      </button>
  );
};

export default Toggle;
