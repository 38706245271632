import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import * as Yup from "yup";
import { CustomerDto } from "../../contracts/models/CustomerDto";
import { VehicleDto } from "../../contracts/models/VehicleDto";
import { Classes, GetSelectItemById } from "../../helpers/LicenceClasses";
import { VehicleTypes, GetVehicleSelectItemById } from "../../helpers/VehicleTypes";
import { useCustomerLabels } from "../../hooks/useCustomers";
import CustomerService from "../../services/customer.service";
import SelectCustomer from "../customers/select-customer.component";

const AddVehicleSchema = Yup.object().shape({
  vehicleDescription: Yup.string()
    .required("Field is required")
    .min(2, "Field is too short")
    .max(300, "Field is too long"),
  class: Yup.string().required("Vehicle class is required"),
  vehicleMake: Yup.string().max(300, "Field is too long").nullable(),
  registration: Yup.string()
    .nullable()
    .min(2, "Field is too short")
    .max(150, "Field is too long"),
  weight: Yup.string().min(1, "Field is too short"),
  trailerReference: Yup.string().max(150, "Field is too long"),
  trailerAxles: Yup.string().max(150, "Field is too long"),
});

interface AddVehicleProps {
  vehicle: VehicleDto | null;
  onSubmit: (vehicle: VehicleDto) => void;
}

export default function AddVehicle({ vehicle, onSubmit }: AddVehicleProps) {
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [customerId, setCustomerId] = useState(0);

  const { data: customers, isLoading } = useCustomerLabels();

  useEffect(() => {
    if (vehicle) {
      setCustomerId(vehicle.customerId);
    }
  }, []);

  const handleSubmission = (newVehicle: VehicleDto) => {
    onSubmit(newVehicle);
    setShow(false);
  };

  let initialVehicle: VehicleDto = {
    customerId: 0,
    type: 1,
    vehicleDescription: "",
    vehicleMake: "",
    class: 1,
    registration: "",
    weight: "0",
    loadedTrailer: false,
    trailerReference: "",
    trailerAxles: "",
    archived: false,
  };

  if (vehicle) {
    initialVehicle = vehicle;

    //temp
    if (!initialVehicle.trailerAxles) initialVehicle.trailerAxles = "";
    if (!initialVehicle.trailerReference) initialVehicle.trailerReference = "";
    //temp

    initialVehicle.type = vehicle.type;
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => setShow(true)}
      >
        {vehicle ? "Edit Vehicle" : "Add New Vehicle"}
      </button>
      <Modal
        size="lg"
        backdrop="static"
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {vehicle ? "Edit Vehicle" : "Add New Vehicle"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col">
              <img
                src="/images/icons/job.png"
                alt="page-img"
                className="page-img"
              />
              {/* <p>Vehicles represent each vehicle move a driver must do within a day. Each vehicle must manually be entered with vehicle information and costings.</p> */}
              <p>
                Enter saved vehicle information. This vehicle will be available
                to be added against a driver job.
              </p>
              <p className="required-text">
                Required fields<span className="required">*</span>
              </p>
              {!successful && message ? (
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              ) : null}
              <Formik
                initialValues={initialVehicle}
                validationSchema={AddVehicleSchema}
                onSubmit={(values, { resetForm }) => {
                  values.customerId = customerId;
                  handleSubmission(values);
                }}
              >
                {({
                  errors,
                  touched,
                  setFieldValue,
                  values,
                  isValid,
                  dirty,
                }) => (
                  <Form>
                    <div className="form-group">
                      <label htmlFor="type">
                        Vehicle Type<span className="required">*</span>
                      </label>
                      {errors.type && touched.type ? (
                        <div className="alert alert-danger" role="alert">
                          {errors.type}
                        </div>
                      ) : null}
                      <Select
                        onChange={(option: any) => {
                          setFieldValue("type", option.value);
                        }}
                        options={VehicleTypes}
                        name="type"
                        defaultValue={GetVehicleSelectItemById(initialVehicle.type)}
                      />
                    </div>
                    {!vehicle && (
                      <div className="form-group">
                        <label htmlFor="customer">
                          Customer<span className="required">*</span>
                        </label>
                        <SelectCustomer
                          customers={customers}
                          onChange={setCustomerId}
                        ></SelectCustomer>
                      </div>
                    )}
                    <div className="form-group">
                      <label htmlFor="class">
                        Vehicle Class<span className="required">*</span>
                      </label>
                      {errors.class && touched.class ? (
                        <div className="alert alert-danger" role="alert">
                          {errors.class}
                        </div>
                      ) : null}
                      <Select
                        onChange={(option: any) => {
                          setFieldValue("class", option.value);
                        }}
                        options={Classes}
                        name="class"
                        defaultValue={GetSelectItemById(initialVehicle.class)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="vehicleDescription">
                        Description<span className="required">*</span>
                      </label>
                      {errors.vehicleDescription &&
                      touched.vehicleDescription ? (
                        <div className="alert alert-danger" role="alert">
                          {errors.vehicleDescription}
                        </div>
                      ) : null}
                      <Field
                        className="form-control"
                        name="vehicleDescription"
                      />
                      <p className="input-note">
                        Description must be unique to each customer's vehicles.
                      </p>
                      <p className="input-note">
                        Include registration for similar vehicles.
                      </p>
                    </div>
                    {values.type.toString() !== "2" ? (
                      <>
                        <div className="form-group">
                          <label htmlFor="vehicleMake">Vehicle Make</label>
                          {errors.vehicleMake && touched.vehicleMake ? (
                            <div className="alert alert-danger" role="alert">
                              {errors.vehicleMake}
                            </div>
                          ) : null}
                          <Field className="form-control" name="vehicleMake" />
                        </div>

                        <div className="form-group">
                          <label htmlFor="name">Registration</label>
                          {errors.registration && touched.registration ? (
                            <div className="alert alert-danger" role="alert">
                              {errors.registration}
                            </div>
                          ) : null}
                          <Field className="form-control" name="registration" />
                        </div>
                        <div className="form-group">
                          <label htmlFor="name">Vehicle Weight</label>
                          {errors.weight && touched.weight ? (
                            <div className="alert alert-danger" role="alert">
                              {errors.weight}
                            </div>
                          ) : null}
                          <Field className="form-control" name="weight" />
                        </div>
                      </>
                    ) : null}
                    {values.type.toString() === "2" ? (
                      <>
                        {/* <div className="form-group">
                          <label className="radio-label"><Field type="checkbox" name="loadedTrailer" />Loaded Trailer</label>
                        </div> */}
                        <div className="form-group">
                          <label htmlFor="trailerType">Trailer Reference</label>
                          {errors.trailerReference &&
                          touched.trailerReference ? (
                            <div className="alert alert-danger" role="alert">
                              {errors.trailerReference}
                            </div>
                          ) : null}
                          <Field
                            className="form-control"
                            name="trailerReference"
                          />
                        </div>
                        {/* <div className="form-group">
                          <label htmlFor="trailerDescription">Trailer Description</label>
                          {errors.trailerDescription && touched.trailerDescription ? (
                            <div className="alert alert-danger" role="alert">{errors.trailerDescription}</div>
                          ) : null}
                          <Field className="form-control" name="trailerDescription" />
                        </div> */}
                        <div className="form-group">
                          <label htmlFor="trailerAxles">Trailer Axles</label>
                          {errors.trailerAxles && touched.trailerAxles ? (
                            <div className="alert alert-danger" role="alert">
                              {errors.trailerAxles}
                            </div>
                          ) : null}
                          <Field className="form-control" name="trailerAxles" />
                        </div>
                      </>
                    ) : null}
                    {/* <div className="form-group">
                      <label htmlFor="additionalInformation">Additional Information</label>
                      {errors.additionalInformation && touched.additionalInformation ? (
                        <div className="alert alert-danger" role="alert">{errors.additionalInformation}</div>
                      ) : null}
                      <Field className="form-control" name="additionalInformation" />
                    </div> */}
                    {/* <p className="posting-message">Posting this job will allow drivers to view all information entered above except vehicle cost. One driver will be able to accept the job, removing it from the job list of other drivers.</p>
                    <p className="posting-message">Jobs can be removed at any time via the dashboard</p> */}
                    {!isValid ? (
                      <div className="alert alert-danger" role="alert">
                        Correct form errors before submitting.
                      </div>
                    ) : null}
                    <div className="form-group">
                      <button
                        className="btn btn-primary btn-block"
                        disabled={!dirty || !isValid || customerId === 0}
                        type="submit"
                      >
                        {vehicle ? "Save Changes" : "Add Vehicle"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={this.hideModal}>
            Close
          </Button>
          <Button variant="primary" onClick={this.handleSubmission}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
