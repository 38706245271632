import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { UserDto } from "../../contracts/models/Users/UserDto";
import { useRoles } from "../../hooks/useRoles";
import RoleSelector from "./role-selector-component";
import userService from "../../services/user.service";
import { AxiosResponse } from "axios";
import { useSuccessSwal } from "../../hooks/useSuccessSwal";
import { useQueryClient } from "@tanstack/react-query";
import { CreateUserResponseDto } from "../../contracts/models/Users/CreateUserResponseDto";

interface UserFormProps {
  onComplete?: () => void; // Add this prop
}

const UserForm: React.FC<UserFormProps> = ({ onComplete }) => {
  const queryClient = useQueryClient();
  const swal = useSuccessSwal();
  const roles = useRoles();
  const initialValues: UserDto & { confirmPassword?: string } = {
    id: 0,
    name: "",
    email: "",
    roles: [],
    password: "",
    confirmPassword: "",
    isActive: true,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Required")
      .min(2, "Field is too short")
      .max(150, "Field is too long"),
    email: Yup.string()
      .required("Required")

      .min(2, "Field is too short")
      .max(150, "Field is too long"),
    password: Yup.string()
      .required("Required")
      .min(6, "Field is too short")
      .max(30, "Field is too long"),
    confirmPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const handleSubmission = (values: UserDto) => {
    const operationPromise = userService.createUser({
      user: values,
      password: values.password,
    });
    operationPromise.then(
      (response: AxiosResponse<CreateUserResponseDto>) => {
        if (response.data.success) {
          swal.execute("Success", "User created successfully", "success");
          queryClient.invalidateQueries({ queryKey: ["users"] });
          if (onComplete) {
            onComplete();
          }
        } else {
          swal.execute("Error", response.data.message, "error");
        }
      },
      (error) => {
        swal.execute(
          "Error",
          "An error has occurred while creating role. Error: " + error,
          "error"
        );
      }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, formikHelpers) => {
        handleSubmission(values);
        formikHelpers.resetForm({ values: initialValues });
      }}
      enableReinitialize={true}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="name">
              Name<span className="required">*</span>
            </label>
            <Field name="name" className="form-control" />
            {errors.name && touched.name ? (
              <div className="alert alert-danger" role="alert">
                {errors.name}
              </div>
            ) : null}
          </div>
          <div className="form-group">
            <label>
              Email<span className="required">*</span>
            </label>
            <Field name="email" className="form-control" />
            {errors.email && touched.email ? (
              <div className="alert alert-danger" role="alert">
                {errors.email}
              </div>
            ) : null}
          </div>
          <div className="form-group">
            <label>
              Password<span className="required">*</span>
            </label>
            <Field className="form-control" name="password" type="password" />
            {errors.password && touched.password ? (
              <div className="alert alert-danger" role="alert">
                {errors.password}
              </div>
            ) : null}
          </div>
          <div className="form-group">
            <label>
              Confirm Password<span className="required">*</span>
            </label>
            <Field
              className="form-control"
              name="confirmPassword"
              type="password"
            />
            {errors.confirmPassword && touched.confirmPassword ? (
              <div className="alert alert-danger" role="alert">
                {errors.confirmPassword}
              </div>
            ) : null}
          </div>
          <div className="form-group">
            <label>
              Roles<span className="required"></span>
            </label>
            <RoleSelector
              allRoles={roles.data ?? []}
              onUpdateUserRoles={(newRoles) => {
                setFieldValue("roles", newRoles);
              }}
            />
          </div>
          <div className="form-group mt-5 text-center">
            <button type="submit" className="btn w-25 btn-primary">
              Create
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
