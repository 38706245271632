import React, { useState } from "react";
import { useQuestionnaireSubmission } from "../../hooks/useQuestionnaireSubmission";
import LoadingSpinner from "../loading/loading-spinner.component";
import "./Compliance.css";
import Moment from "react-moment";
import DriverInformationLine from "../drivers/driver-information-line.component";
import JobInformationSection from "../jobs/job-information-section.component";
import Questionnaire from "./questionnaire.component";
import complianceService from "../../services/compliance.service";
import SubmissionType from "../../domain/constants/submissionType";

export default function ViewSubmission({
  submissionId,
}: {
  submissionId: number;
}) {
  const [exportLoading, setExportLoading] = useState(false);

  const { data: submission, isLoading } =
    useQuestionnaireSubmission(submissionId);

  const getStatusStyle = () => {
    if (submission.submissionSuccessful) return "green";
    return "red";
  };

  const getAlertType = () => {
    if (submission.submissionAlert) {
      if (submission.submissionAlert.status == "PENDING") return "alert-danger";
      if (submission.submissionAlert.status == "VIEWED") return "alert-warning";
      if (submission.submissionAlert.status == "HANDLED")
        return "alert-primary";
    }
  };

  const exportSubmission = () => {
    setExportLoading(true);
    complianceService.exportSubmission(submissionId).then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", submission.job.swing.jobReference+"-SUBMISSION.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
      setExportLoading(false);
    });
  };

  return (
    <div className="container">
      {isLoading || !submission ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <>
          {submission.submissionAlert && (
            <div className={"row alert " + getAlertType()}>
              <div className="col">
                {/* <p className="section-title">Alert Information</p> */}
                <div className="row">
                  <div className="col">
                    <p className="label">Alert Status</p>
                    <p className="status-value">
                      {submission.submissionAlert.status}
                    </p>
                    {!submission.submissionAlert.isViewed ? (
                      <p className="label">New Alert</p>
                    ) : (
                      // viewed at
                      <p className="label">
                        {submission.submissionAlert.adminUser.name} -{" "}
                        {
                          <Moment
                            format="DD/MM/YYYY HH:mm"
                            date={new Date(submission.submissionAlert.viewedAt)}
                          />
                        }
                      </p>
                    )}
                  </div>
                  <div className="col admin-notes">
                    <p className="label">Admin Notes</p>
                    <p className="value">
                      {!submission.submissionAlert.adminNotes
                        ? "N/A"
                        : submission.submissionAlert.adminNotes}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row action-container">
            {!submission.submissionSuccessful && (
              <button className="btn btn-success" type="button">
                Mark as Handled
              </button>
            )}

            {submission.submissionType == SubmissionType.PostVehicleChecklist ||
            submission.submissionType == SubmissionType.VehicleChecklist ? (
              <button
                className="btn btn-info"
                type="button"
                disabled={exportLoading}
                onClick={() => {
                  exportSubmission();
                }}
              >
                Export Submission
                {exportLoading ? (
                  <span
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : null}
              </button>
            ) : null}
          </div>
          <div className="row">
            <div className="col">
              <p className="section-title">Submission Information</p>
              <div className="row">
                <div className="col">
                  <p className="label">Submission Result</p>
                  <p className={`status-value ${getStatusStyle()}`}>
                    {submission.submissionSuccessful ? "SUCCESS" : "FAILED"}
                  </p>
                </div>
                <div className="col">
                  <p className="label">Type</p>
                  <p className="value">{submission.submissionType}</p>
                </div>
              </div>
              {submission.driverNotes && (
                <div className="row">
                  <div className="col">
                    <p className="label">Driver Notes</p>
                    <p className="value">{submission.driverNotes}</p>
                  </div>
                </div>
              )}
              {(submission.unsuccessfulSubmissionReason ||
                submission.successfulMessage) && (
                <div className="row">
                  <div className="col">
                    <p className="label">Reason Given</p>
                    <div className="alert alert-dark" role="alert">
                      {submission.unsuccessfulSubmissionReason ||
                        submission.successfulMessage}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <DriverInformationLine
            driver={submission.driver}
          ></DriverInformationLine>
          <JobInformationSection
            jobId={submission.jobId}
            swing={submission.job.swing}
          ></JobInformationSection>
          <Questionnaire
            questionnaire={submission.questionnaire}
            submissionAnswers={submission.submissionAnswers}
          ></Questionnaire>
        </>
      )}
    </div>
  );
}
