import api from "./network";

const API_URL = "DriverManagement/";

class DriverService {
  getDrivers(searchTerm: any, terminated: any, pageParam: any) {
    return api.get("DriverManagement", {
      params: {
        terminated,
        driverSearchTerm: searchTerm,
        pageNumber: pageParam,
      },
    });
  }

  resetPassword(userId: number) {
    return api.post(API_URL + "password/reset", {
      userId,
    });
  }

  getDriverJobs(driverId: any) {
    return api.get(API_URL + "driver/jobs", {
      params: {
        driverId,
      },
    });
  }

  editDriver(
    DriverId: any,
    Name: any,
    Email: any,
    TelephoneNumber: any,
    LicenceNumber: any,
    LicenceClasses: any
  ) {
    return api.post(API_URL + "driver/edit", {
      DriverId,
      Name,
      Email,
      TelephoneNumber,
    });
  }

  addDriver(Name: any, Email: any, TelephoneNumber: any) {
    return api.post(API_URL + "driver/add", {
      Name,
      Email,
      TelephoneNumber,
    });
  }

  terminateDriver(driverId: any) {
    return api.post(API_URL + "driver/terminate", {
      driverId,
    });
  }

  toggleVisibility(driverId: any) {
    return api.post(API_URL + "driver/quickselect/toggle", {
      driverId,
    });
  }

  overrideLicenceClass(driverClassId: any, disabled: any) {
    return api.post(API_URL + "driver/class/override", {
      driverClassId,
      disabled,
    });
  }

  GetDriverClassUpdates() {
    return api.get(API_URL + "classupdates");
  }

  GetDriverClassUpdatesByDriver(driverId: any) {
    return api.get(API_URL + "classupdates/driver", {
      params: {
        driverId,
      },
    });
  }

  activateDriver(driverId: any) {
    return api.post(API_URL + "driver/activate", {
      driverId,
    });
  }

  setRating(driverId: any, rating: any) {
    return api.post(API_URL + "driver/rating", {
      driverId,
      value: rating,
    });
  }

  setCategory(driverId: any, type: any, value: any) {
    return api.post(API_URL + "driver/category", {
      driverId,
      type,
      value,
    });
  }

  exportDriverDetails(driverId: any) {
    return api.get(API_URL + "export/driver", {
      responseType: "blob",
      referenceType: "blob",
      params: {
        driverId,
      },
    });
  }
}

export default new DriverService();
