import React from "react";
import LoadingSpinner from "../loading/loading-spinner.component";
import { useExpenseImage } from "../../hooks/useExpenseImage";
import Moment from "react-moment";
import "./Expenses.css";
export default function ExpenseImage({ expenseId, image }: any) {
  const { data: imageSrc, isLoading } = useExpenseImage(expenseId, image.name);

  return (
    <div className="row">
      <div className="col">
        {isLoading && <LoadingSpinner></LoadingSpinner>}
        {!isLoading && imageSrc && (
          <div className="image-container">
            <img
              className="expense-image"
              src={imageSrc}
              alt="Expense Image"
            ></img>
            <p>
              <Moment
                format="DD/MM/YYYY HH:mm"
                date={new Date(image.createdAt)}
              />
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
