import { useEffect, useMemo, useState } from "react";
import { SwingsQuery } from "../../../contracts/models/Swings/SwingsQuery";
import { useSwings } from "../../../hooks/useSwings";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { FilterCriteria } from "../../../contracts/models/Swings/FilterCriteria";

export function useGetSwings({
  columnFilters,
  globalFilter,
  sorting,
  pagination,
  customFilters,
}: SwingsQuery) {
  const query = useMemo(() => {
    const searchParams = new URLSearchParams();
    searchParams.set("start", `${pagination.pageIndex * pagination.pageSize}`);
    searchParams.set("size", `${pagination.pageSize}`);
    const filtersWithCustom = [
      ...(columnFilters ?? []),
      ...Object.entries(customFilters).map(([key, value]) => ({
        id: key,
        value,
      })),
    ];
    searchParams.set("filters", JSON.stringify(filtersWithCustom));
    searchParams.set("globalFilter", globalFilter ?? "");
    searchParams.set("sorting", JSON.stringify(sorting ?? []));
    return searchParams.toString();
  }, [columnFilters, globalFilter, sorting, pagination, customFilters]);

  return useSwings(query);
}

export function useFilterState() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
  
    const getFilterValue = (key: string, defaultValue: any) => {
      const value = queryParams.get(key);
      try {
        return value !== null ? JSON.parse(value) : defaultValue;
      } catch (e) {
        console.error(`Error parsing query param ${key}:`, e);
        return defaultValue;
      }
    };
  
    const parseDate = (dateStr: moment.MomentInput, defaultValue: Date) => {
      const date = moment(dateStr);
      return date.isValid() ? date.toDate() : defaultValue;
    };
  
    const initialFilters = {
      fromDate: parseDate(
        getFilterValue("fromDate", moment().subtract(1, "days").startOf('day').toISOString()),
        moment().subtract(1, "days").startOf('day').toDate()
      ),
      toDate: parseDate(
        getFilterValue("toDate", moment().endOf('day').toISOString()),
        moment().endOf('day').toDate()
      ),
      pending: getFilterValue("pending", true),
      available: getFilterValue("available", true),
      accepted: getFilterValue("accepted", true),
      current: getFilterValue("current", true),
      progress: getFilterValue("progress", true),
      completed: getFilterValue("completed", true),
      closed: getFilterValue("closed", true),
      failed: getFilterValue("failed", true),
      allocated: getFilterValue("allocated", true),
      cancelled: getFilterValue("cancelled", true),
      searchTerm: getFilterValue("searchTerm", ""),
    };
  
    const [customFilters, setCustomFilters] = useState<FilterCriteria>(initialFilters);
  
    useEffect(() => {
      const newFilters = {
        fromDate: parseDate(
          getFilterValue("fromDate", moment().subtract(1, "days").startOf('day').toISOString()),
          moment().subtract(1, "days").startOf('day').toDate()
        ),
        toDate: parseDate(
          getFilterValue("toDate", moment().endOf('day').toISOString()),
          moment().endOf('day').toDate()
        ),
        pending: getFilterValue("pending", true),
        available: getFilterValue("available", true),
        accepted: getFilterValue("accepted", true),
        current: getFilterValue("current", true),
        progress: getFilterValue("progress", true),
        completed: getFilterValue("completed", true),
        closed: getFilterValue("closed", true),
        failed: getFilterValue("failed", true),
        allocated: getFilterValue("allocated", true),
        cancelled: getFilterValue("cancelled", true),
        searchTerm: getFilterValue("searchTerm", ""),
      };
      setCustomFilters(newFilters);
    }, [location.search]);
  
    return { customFilters, setCustomFilters };
  }
  
  export default useFilterState;
