import React, { useEffect, useState } from "react";
import LoadingSpinner from "../loading/loading-spinner.component";
import { useDrivers } from "../../hooks/useDrivers";
import ViewDriver from "./view-driver.component";

import { Link } from "react-router-dom";
import { FormControl, InputGroup } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import AddNewDriver from "./add-new-driver.component";
import { useQueryClient } from "@tanstack/react-query";
import "./drivers.css";

export default function DriverList() {
  const [searchTerm, setSearchTerm] = useState();

  const [terminated, setTerminated] = useState(false);
  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useDrivers(searchTerm, terminated);

  const queryClient = useQueryClient();

  const getDriverClasses = (classes: any) => {
    return classes.map((c: any) => c.class.name).join(", ");
  };

  console.log("DT", data);
  let driverItems;
  if (data && data.pages && data.pages.length > 0) {
    driverItems = data.pages.map((d, i) => (
      <React.Fragment key={i}>
        {d.items.map((item: any) => (
          <tr key={item.id}>
            <th scope="row">{item.name}</th>
            <td>{item.email}</td>
            <td>{getDriverClasses(item.licenceClasses)}</td>
            <td>
              <span
                className={
                  item.endorsements.reduce(
                    (a: any, end: any) => a + end.noOfPoints,
                    0
                  ) <= 3
                    ? "points green"
                    : "points orange"
                }
              >
                {item.endorsements.reduce(
                  (a: any, end: any) => a + end.noOfPoints,
                  0
                )}
              </span>
            </td>
            <td className="center">{item.jobCount}</td>
            <td className="center">
              <ViewDriver driver={item}></ViewDriver>
            </td>
          </tr>
        ))}
      </React.Fragment>
    ));
  }

  let driversTable;
  if (data && data.pages.length > 0 && data.pages[0].items.length > 0) {
    driversTable = (
      <div className="job-list">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Driver Name</th>
                <th scope="col">Email</th>
                <th scope="col">Licence Classes</th>
                <th scope="col">Points</th>
                <th scope="col">Job Count</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>{driverItems}</tbody>
          </table>
        </div>
      </div>
    );
  } else {
    driversTable = (
      <div className="alert alert-danger">
        <p>There are no users.</p>
      </div>
    );
  }

  return (
    <>
      <div className="row top-page-actions">
        <Link to={"/classupdates"}>
          <button className="btn btn-small btn-warning">
            View Licence Update History
          </button>
        </Link>
        <Link to={"/job/removals"}>
          <button className="btn btn-small btn-danger">
            View Recent Job Removals
          </button>
        </Link>
        <AddNewDriver
          // @ts-expect-error TS(2769): No overload matches this call.
          onComplete={() => queryClient.invalidateQueries("drivers")}
        ></AddNewDriver>
      </div>
      <div className="row">
        <div className="col">
          <div className="verification-container">
            <div className="card">
              <div className="verification-header">
                <h2>View Drivers</h2>
                {/* <p>Accept and Decline pending Drivers verification codes. Verification codes provide drivers with the ability to accept jobs.</p> */}
                {/* <p>Please manually check each drivers DVLA check code with their license number before accepting them into the system.</p> */}

                <button
                  className="btn btn-primary mb-4 right"
                  onClick={() => setTerminated(!terminated)}
                >
                  <span className="small">
                    Showing {terminated === false ? "Active" : "Terminated"}{" "}
                    Drivers
                  </span>
                </button>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {/* <p className="">Search by driver name</p> */}
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        <BsSearch />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      placeholder="Driver Name"
                      aria-label="Search by driver name"
                      aria-describedby="basic-addon1"
                      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                      onChange={(event) => setSearchTerm(event.target.value)}
                    />
                  </InputGroup>
                </div>
              </div>
              {isLoading && <LoadingSpinner></LoadingSpinner>}
              {!isLoading && <>{driversTable}</>}
              <button
                className="btn btn-primary btn-block"
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage}
              >
                {isFetchingNextPage
                  ? "Loading more..."
                  : hasNextPage
                  ? "Load More"
                  : "Nothing more to load"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
