import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import { useQuery } from "@tanstack/react-query";
import SwingService from "../../services/swing.service";
import { SwingDto } from "../../contracts/models/SwingDto";

type SwingOption = {
  value: SwingDto;
  label: string;
};

function useSwings() {
  return useQuery<SwingOption[]>({
    queryKey: ["swings"],
    queryFn: async () => {
      const response = await SwingService.getAllSwings();
      return response.data.length > 0
        ? response.data?.map((swing: SwingDto) => ({
            value: swing,
            label: `${swing.jobReference} - ${swing.customer?.name} - ${new Date(swing.collectionDate).toLocaleDateString()}`,
          }))
        : [];
    },
    refetchInterval: 60000,
  });
}

interface SelectSwingProps {
  onChange: (option: SwingDto | undefined) => void;
  defaultSwingId?: number;
  hideNote?: boolean;
  overrideTitle?: string;
}

export default function SelectSwing({ onChange, defaultSwingId, hideNote, overrideTitle }: SelectSwingProps) {
  const { data: swings = [], isLoading } = useSwings();
  const [selectedSwing, setSelectedSwing] = useState<SwingOption | null>(null);

  useEffect(() => {
    if (swings.length > 0 && defaultSwingId) {
      const defaultSwing = swings.find(swing => swing.value.id === defaultSwingId);
      if (defaultSwing) {
        setSelectedSwing(defaultSwing);
      }
    }
  }, [swings, defaultSwingId]);

  const handleSwingChange = (option: SingleValue<SwingOption>) => {
    setSelectedSwing(option);
    onChange(option?.value);
  };

  return (
    <>
      <p>
        {overrideTitle || (<span>Swing<span className="required">*</span></span>)}
      </p>
      {swings.length === 0 || isLoading ? (
        <Select<SwingOption>
          isDisabled={true}
          options={[]}
          placeholder="Loading swings..."
          name="swingId"
        />
      ) : (
        <Select<SwingOption>
          onChange={handleSwingChange}
          options={swings}
          isClearable={true}
          value={selectedSwing}
          name="swingId"
        />
      )}
      {!hideNote && (
        <p className="input-note">
          Swing selection is required. The swing list auto-updates can be
          maintained via the <Link to={"/swing"}>Jobs</Link> page.
        </p>
      )}
    </>
  );
}