import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useExpenses } from "../../hooks/useExpenses";
import LoadingSpinner from "../loading/loading-spinner.component";
import ViewExpense from "./view-expense.component";
import FilterExpenses from "./filter-expenses.component";
import Moment from "react-moment";

import "./Expenses.css";

export default function ExpenseTable({ expenses, isLoading }: any) {
  if (isLoading === true) return <LoadingSpinner></LoadingSpinner>;
  const getExpenseClass = (status: any) => {
    switch (status) {
      case "PENDING":
        return "orange";
      case "DECLINED":
        return "red";
      case "ACCEPTED":
      case "PAID":
        return "green";
    }
  };

  if (expenses && expenses.length > 0) {
    let expenseList = expenses.map((expense: any) => (
      <tr key={expense.id}>
        <td className={`status ${getExpenseClass(expense.status)}`}>
          {expense.status}
        </td>
        <td>{expense.type}</td>
        <td scope="row">{expense?.job?.swing?.jobReference}</td>
        <td>{expense.job?.swing?.customer?.name}</td>
        <td>£{expense.amount}</td>
        <td>{expense?.driver?.name}</td>
        <td>
          <Moment format="DD/MM/YYYY" date={expense.createdAt} />
        </td>
        <td>
          {expense && expense.job && (
            <ViewExpense expense={expense}></ViewExpense>
          )}
        </td>
      </tr>
    ));

    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Status</th>
              <th scope="col">Type</th>
              <th scope="col">Job</th>
              <th scope="col">Customer</th>
              <th scope="col">Amount</th>
              <th scope="col">Driver</th>
              <th scope="col">Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{expenseList}</tbody>
        </table>
      </div>
    );
  } else {
    return (
      <div className="alert alert-warning">
        <p>Expense list empty</p>
      </div>
    );
  }
}
