import { AxiosResponse } from "axios";
import { CustomerDto } from "../contracts/models/CustomerDto";
import { CustomerJobRequestDto } from "../contracts/models/CustomerJobRequestDto";
import { CustomerJobRequestResponseDto } from "../contracts/models/CustomerJobRequestResponseDto";
import { CustomerUserCustomerDto } from "../contracts/models/CustomerUserCustomerDto";
import api from "./network";

const API_URL = "CustomerJobRequest";

class CustomerJobRequestService {
  createCustomerJobRequest(dto: CustomerJobRequestDto) {
    return api.post(`${API_URL}`, dto);
  }

  async listCustomerJobRequests(): Promise<CustomerJobRequestDto[]> {
    var r = await api.get(`${API_URL}`);
    return r.data;
  }

  async listCustomers(): Promise<AxiosResponse<CustomerUserCustomerDto[]>> {
    return await api.get(`${API_URL}/Customers`);
  }

  async getCustomerJobRequestById(
    id: number
  ): Promise<CustomerJobRequestResponseDto> {
    var r = await api.get(`${API_URL}/${id}`);
    return r.data;
  }

  async editCustomerJobRequest(
    dto: CustomerJobRequestDto
  ): Promise<CustomerJobRequestDto> {
    var r = await api.post(`${API_URL}/edit`, dto);
    return r.data;
  }

  async duplicateCustomerJobRequest(
    id: number,
    reverse: boolean
  ): Promise<CustomerJobRequestDto> {
    var r = await api.get(`${API_URL}/duplicate?requestId=${id}&reverse=${reverse}`);
    return r.data;
  }
}

export default new CustomerJobRequestService();
