import { useState } from "react";
import {
  MantineReactTable,
  useMantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_ColumnFilterFnsState,
} from "mantine-react-table";
import { ActionIcon, Tooltip } from "@mantine/core";
import { IconRefresh } from "@tabler/icons-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { FilterCriteria } from "../../../contracts/models/Swings/FilterCriteria";
import { SwingDto } from "../../../contracts/models/SwingDto";
import moment from "moment";
import Filter from "../../jobs/filter.component";
import Status from "../../jobs/status.component";
import { getStatusString } from "./swing-list-utils";
import { useGetSwings, useFilterState } from "./swing-list-hooks";

const queryClient = new QueryClient();

const columns: MRT_ColumnDef<SwingDto>[] = [
  {
    accessorFn: (swing: SwingDto) => getStatusString(swing.jobs || []),
    Cell: ({ cell }) => <Status status={cell.getValue() as string} />,
    header: "Status*",
    enableColumnFilter: false,
    enableSorting: false,
    id: "status",
  },
  {
    accessorFn: (swing: SwingDto) =>
      swing?.collectionDate
        ? moment(swing.collectionDate).format("MM/DD/YYYY")
        : "",
    header: "Swing Date",
    enableColumnFilter: false,
    enableSorting: false,
    id: "collectionDate",
  },
  {
    accessorFn: (swing: SwingDto) => swing.jobReference,
    header: "Swing Reference",
    enableColumnFilter: false,
    enableSorting: false,
    id: "jobReference",
  },
  {
    accessorFn: (swing: SwingDto) => swing.customer?.name,
    header: "Customer",
    enableColumnFilter: false,
    enableSorting: false,
    id: "customer",
  },
  {
    accessorFn: (swing: SwingDto) => swing.id,
    Cell: ({ cell }) => (
      <Link to={"/swing/view/" + cell.getValue()} className="view">
        View Job
      </Link>
    ),
    header: "Jobs",
    enableColumnFilter: false,
    enableSorting: false,
    id: "jobs",
  },
  {
    accessorFn: (swing: SwingDto) => swing.poAmount,
    header: "PO Amount",
    enableColumnFilter: false,
    enableSorting: false,
    id: "poAmount",
  },
  {
    accessorFn: (swing: SwingDto) =>
      swing.poDate ? moment(swing.poDate).format("MM/DD/YYYY") : "",
    header: "PO Date",
    enableColumnFilter: false,
    enableSorting: false,
    id: "poDate",
  },
  {
    accessorFn: (swing: SwingDto) => swing.invoiceNumber,
    header: "Invoice Number",
    enableColumnFilter: false,
    enableSorting: false,
    id: "invoiceNumber",
  },
  {
    accessorFn: (swing: SwingDto) =>
      swing.jobEndDate ? moment(swing.jobEndDate).format("MM/DD/YYYY") : "",
    header: "Job End Date",
    enableColumnFilter: false,
    enableSorting: false,
    id: "jobEndDate",
  },
];

export default function SwingsList() {
  const location = useLocation();
  const { customFilters, setCustomFilters } = useFilterState();

  const updateQueryParams = (params: any) => {
    const newQueryParams = new URLSearchParams(location.search);
    Object.entries(params.customFilters).forEach(([key, value]) => {
      newQueryParams.set(key, JSON.stringify(value));
    });
    newQueryParams.set("globalFilter", params.globalFilter);
    newQueryParams.set("sorting", JSON.stringify(params.sorting));
    newQueryParams.set("pagination", JSON.stringify(params.pagination));
    window.history.replaceState(
      {},
      "",
      `${location.pathname}?${newQueryParams.toString()}`
    );
  };

  const handleCustomFilterChange = (
    filterName: keyof FilterCriteria,
    value: any
  ) => {
    const newFilters = { ...customFilters, [filterName]: value };
    updateQueryParams({
      columnFilterFns,
      columnFilters,
      globalFilter,
      sorting,
      pagination,
      customFilters: newFilters,
    });
    setCustomFilters(newFilters);
  };

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );

  const [columnFilterFns, setColumnFilterFns] =
    useState<MRT_ColumnFilterFnsState>(
      Object.fromEntries(
        columns.map(({ accessorKey }) => [accessorKey, "contains"])
      )
    );

  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });

  const { data, isError, isFetching, isLoading, refetch } = useGetSwings({
    columnFilterFns,
    columnFilters,
    globalFilter,
    pagination,
    sorting,
    customFilters,
  });

  const fetchedSwings = data?.data ?? [];
  const totalRowCount = data?.meta?.totalRowCount ?? 0;

  const table = useMantineReactTable({
    columns,
    data: fetchedSwings,
    enableColumnFilterModes: true,
    columnFilterModeOptions: ["contains", "startsWith", "endsWith"],
    initialState: {
      showColumnFilters: false,
      columnVisibility: {
        poAmount: false,
        poDate: false,
        invoiceNumber: false,
        jobEndDate: false,
      },
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    mantineToolbarAlertBannerProps: isError
      ? {
          color: "red",
          children: "Error loading data",
        }
      : undefined,
    onColumnFilterFnsChange: setColumnFilterFns,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    renderTopToolbarCustomActions: () => (
      <>
        <div className="w-100">
          <Filter
            onChange={handleCustomFilterChange}
            filters={customFilters}
            jobs={data}
          />
        </div>
        <Tooltip label="Refresh Data">
          <ActionIcon onClick={() => refetch()}>
            <IconRefresh />
          </ActionIcon>
        </Tooltip>
      </>
    ),
    rowCount: totalRowCount,
    state: {
      columnFilterFns,
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <MantineReactTable table={table} />
    </QueryClientProvider>
  );
}
