import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";
import DriverService from "../../services/driver.service";
import DefaultModal from "../modal/modal.component";

const AddDriverSchema = Yup.object().shape({
  name: Yup.string().min(2, "Field is too short").max(150, "Field is too long"),
  email: Yup.string()
    .min(2, "Field is too short")
    .max(150, "Field is too long"),
  telephone: Yup.string()
    .min(9, "Field is too short")
    .max(14, "Field is too long"),
});

const MySwal = withReactContent(Swal);

export default function AddDriver({ onComplete }: any) {
  const [isLoading, setLoading] = useState(false);

  const handleSubmission = (values: any) => {
    setLoading(true);

    DriverService.addDriver(values.name, values.email, values.telephone).then(
      (response) => {
        setLoading(false);
        if (response.data) {
          onComplete();

          MySwal.fire({
            title: "Success",
            text:
              "Driver successfully registered and but not added to DriverCheck. They will not be added to any jobs until they have been added,their edeclaration has been signed and DriverCheck has ran its initial check of their licences. Their new password is: " +
              response.data +
              "",
            icon: "success",
            // @ts-expect-error TS(2769): No overload matches this call.
            button: "Continue",
          });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        MySwal.fire({
          title: "Error",
          text: resMessage,
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });

        setLoading(false);
      }
    );
  };

  return (
    <DefaultModal
      title={"Register New Driver"}
      trigger={"Register New Driver"}
      content={
        <div className="col-md-12">
          <div className="card card-container">
            <h3 className="page-title">New Driver Details</h3>
            <p>
              Please do not register the driver until we are ready for them to
              start driving for your business.
            </p>
            <p>
              Registering a driver will add the driver to Driver Check and
              trigger the sending of their e-declarartion.{" "}
            </p>
            <p>
              The driver will not be assignable for atleast 24 hours upon
              signing.
            </p>
            <p className="required-text">
              Required fields<span className="required">*</span>
            </p>
            <Formik
              validationSchema={AddDriverSchema}
              onSubmit={(values) => {
                handleSubmission(values);
              }}
              initialValues={{ name: "", telephone: "", email: "" }}
            >
              {({ errors, touched, isValid, dirty }) => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="name">
                      Name<span className="required">*</span>
                    </label>
                    {errors.name && touched.name ? (
                      <div className="alert alert-danger" role="alert">
                        {errors.name}
                      </div>
                    ) : null}
                    <Field className="form-control" name="name" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">
                      Email<span className="required">*</span>
                    </label>
                    {errors.email && touched.email ? (
                      <div className="alert alert-danger" role="alert">
                        {errors.email}
                      </div>
                    ) : null}
                    <Field className="form-control" name="email" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="telephone">
                      Telephone<span className="required">*</span>
                    </label>
                    {errors.telephone && touched.telephone ? (
                      <div className="alert alert-danger" role="alert">
                        {errors.telephone}
                      </div>
                    ) : null}
                    <Field className="form-control" name="telephone" />
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-block"
                      disabled={isLoading || !isValid}
                      type="submit"
                    >
                      Register
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      }
    ></DefaultModal>
  );
}
