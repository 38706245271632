import React from "react";
import "./drivers.css";

export default function DriverInformationLine({
  driver
}: any) {
  return (
    <div className="row">
      <div className="col">
        <div className="driver-container">
          <p className="section-title">Driver information</p>
          <div className="row">
            <div className="col">
              <p className="label">Name</p>
              <p className="value">{driver.name}</p>
            </div>
            <div className="col">
              <p className="label">Telephone</p>
              <p className="value">{driver.telephoneNumber}</p>
            </div>
            <div className="col">
              <p className="label">Email</p>
              <p className="value">{driver.email}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
