import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../../context/userContext";
import AuthService from "../../services/auth.service";

import * as Yup from "yup";
import LoadingSpinner from "../loading/loading-spinner.component";

const LoginSchema = Yup.object().shape({
  email: Yup.string().required("Field is required").email("Email is not valid"),
  password: Yup.string()
    .required("Email is required")
    .min(6, "Field is too short")
    .max(30, "Field is too long"),
});

function Login() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const { setAuthToken } = useAuth();

  const handleLogin = (e: any) => {
    setLoading(true);
    AuthService.login(e.email, e.password).then(
      (token) => {
        window.location.href = "/";
        setAuthToken(token);
        setLoggedIn(true);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage(resMessage);
        setLoading(false);
      }
    );
  };

  if (loading) return <LoadingSpinner />;

  if (loggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="col-md-6">
        <div className="card card-container">
          <Formik
            initialValues={{}}
            validationSchema={LoginSchema}
            onSubmit={handleLogin}
          >
            <Form>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field type="text" className="form-control" name="email" />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Field
                  type="password"
                  className="form-control"
                  name="password"
                />
              </div>

              <div className="form-group">
                <button className="btn btn-primary btn-block" type="submit">
                  {loading && (
                    <span className="spinner-border spinner-border-sm"> </span>
                  )}
                  <span> Login</span>
                </button>
              </div>

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Login;
