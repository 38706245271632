import React from 'react';
import { ErrorMessage, Field } from 'formik';
import './VehicleType.css';
interface VehicleTypeProps {
  defaultValue: string;
  name: string;
  vehicleOptions: { value: string; label: string; imageSrc: string }[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const VehicleTypeComponent: React.FC<VehicleTypeProps> = ({ defaultValue, vehicleOptions, name, setFieldValue }) => {

  const [val, selectedValue] = React.useState<string | undefined>(defaultValue);

  return (
    <div className="row">
      {!val && <>{vehicleOptions.map((option) => (
        <div className="col-sm-4 col-md-3">
        <label key={option.value} className="flex flex-col m-2 text-center">
        <img src={option.imageSrc} alt={option.label} className="rounded w-100"/>
          <Field className="m-2" type="radio" name={name} value={option.value} onChange={(val: any) => {
            console.log(val.target.value);
            selectedValue(val.target.value)
            setFieldValue(name, val.target.value);
          }} />
          <h6 className='m-2'>{option.label}</h6>
        </label>

        <ErrorMessage name={name}>
    {(msg: string) => (
                      <div className="alert alert-danger" role="alert">
                      <>{msg}</>
                      </div>
    )}
                      </ErrorMessage>    
        </div>
      ))}</>}
{val && (
  <div className="col-xs-8 col-sm-6 col-md-6">
    <label className="d-flex flex-column align-items-start m-2">
      <img
        src={vehicleOptions.find((option) => option.value === val)?.imageSrc}
        alt={vehicleOptions.find((option) => option.value === val)?.label}
        className="rounded img-fluid w-50 w-md-50 w-lg-25"
      />
      <button onClick={() => selectedValue(undefined)} className="btn btn-info my-2">
        Change Selected: {vehicleOptions.find((option) => option.value === val)?.label}
      </button>
    </label>
  </div>
)}
</div>
  );
};

export default VehicleTypeComponent;