import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";
import AddressService from "../../services/address.service";
import { AddressDto } from "../../contracts/models/AddressDto";

const AddAddressSchema = Yup.object().shape({
  line1: Yup.string()
    .required("Field is required")
    .min(2, "Field is too short")
    .max(150, "Field is too long"),
  line2: Yup.string().max(150, "Field is too long"),
  city: Yup.string()
    .required("Field is required")
    .min(2, "Field is too short")
    .max(150, "Field is too long"),
  county: Yup.string()
    .min(2, "Field is too short")
    .max(150, "Field is too long"),
  country: Yup.string()
    .required("Field is required")
    .min(2, "Field is too short")
    .max(150, "Field is too long"),
  postcode: Yup.string()
    .required("Field is required")
    .min(2, "Field is too short")
    .max(150, "Field is too long"),
  addressInformation: Yup.string().max(300, "Field is too long"),
});

const MySwal = withReactContent(Swal);

export default function AddAddress({
  onComplete,
  existingAddress,
  setAddress,
}: {
  onComplete: any;
  existingAddress?: AddressDto;
  setAddress?: any;
}) {
  const [isLoading, setLoading] = useState(false);

  const handleSubmission = (address: AddressDto, resetForm: any) => {
    setLoading(true);
    const isEditing = Boolean(existingAddress);

    // new edit/update address.
    (isEditing
      ? AddressService.editAddress(address)
      : AddressService.saveAddress(address)
    ).then(
      () => {
        setLoading(false);
        onComplete();

      // Only reset the form if it's a new address being added
      if (!isEditing) {
        resetForm({});
      }

        MySwal.fire({
          title: "Success",
          text: "Address successfully added.",
          icon: "success",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        MySwal.fire({
          title: "Error",
          text: resMessage,
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });

        setLoading(false);
      }
    );
  };

  const defaultNullValues = (address?: AddressDto) => {
    if (!address) return null;
    address.line1 = address.line1 || "";
    address.line2 = address.line2 || "";
    address.city = address.city || "";
    address.county = address.county || "";
    address.country = address.country || "";
    address.postcode = address.postcode || "";
    address.addressInformation = address.addressInformation || "";
    return address;
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <h3 className="page-title">
          {existingAddress ? "Edit" : "Add"} Address
        </h3>
        <p className="required-text">
          Required fields<span className="required">*</span>
        </p>
        {/* {!this.state.successful && this.state.message ? <div className="alert alert-danger" role="alert">{this.state.message}</div> : null} */}

        <Formik
          initialValues={defaultNullValues(existingAddress) || {
            line1: "",
            line2: "",
            city: "",
            county: "",
            country: "",
            postcode: "",
            addressInformation: "",
          }}
          enableReinitialize={true}
          validationSchema={AddAddressSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmission(values, resetForm);
          }}
        >
          {({ errors, touched, isValid, dirty }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="line1">
                  Line 1<span className="required">*</span>
                </label>
                {errors.line1 && touched.line1 ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.line1}
                  </div>
                ) : null}
                <Field className="form-control" name="line1" />
              </div>
              <div className="form-group">
                <label htmlFor="line2">Line 2</label>
                {errors.line2 && touched.line2 ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.line2}
                  </div>
                ) : null}
                <Field className="form-control" name="line2" />
              </div>
              <div className="form-group">
                <label htmlFor="city">
                  City<span className="required">*</span>
                </label>
                {errors.city && touched.city ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.city}
                  </div>
                ) : null}
                <Field className="form-control" name="city" />
              </div>
              <div className="form-group">
                <label htmlFor="county">County</label>
                {errors.county && touched.county ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.county}
                  </div>
                ) : null}
                <Field className="form-control" name="county" />
              </div>
              <div className="form-group">
                <label htmlFor="country">
                  Country<span className="required">*</span>
                </label>
                {errors.country && touched.country ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.country}
                  </div>
                ) : null}
                <Field className="form-control" name="country" />
              </div>
              <div className="form-group">
                <label htmlFor="postcode">
                  Postcode<span className="required">*</span>
                </label>
                {errors.postcode && touched.postcode ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.postcode}
                  </div>
                ) : null}
                <Field className="form-control" name="postcode" />
              </div>
              <div className="form-group">
                <label htmlFor="additionalInformation">
                  Address information
                </label>
                {errors.addressInformation && touched.addressInformation ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.addressInformation}
                  </div>
                ) : null}
                <Field className="form-control" name="addressInformation" />
                <p className="input-note">
                  Common additional address information shown to all drivers
                </p>
              </div>
              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  disabled={isLoading || !dirty || !isValid}
                  type="submit"
                >
                  {existingAddress ? "Save" : "Add New Address"}
                </button>
                {existingAddress && (
                  <button
                    className="btn btn-primary btn-block"
                    type="button"
                    onClick={() => {
                      setAddress();
                    }}
                  >
                    Reset
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
