import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LoadingSpinner from "../loading/loading-spinner.component";
import DriverService from "../../services/driver.service";

import { Dropdown } from "react-bootstrap";

const MySwal = withReactContent(Swal);

export default function SetDriverRating({
  id
}: any) {
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const setRating = (rating: any) => {
    MySwal.fire({
      title: "Are you sure?",
      text: `This will update the drivers rating in the system and may effect how they are allocated jobs`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "I'm sure.",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        DriverService.setRating(id, rating).then(
          (response) => {
            // @ts-expect-error TS(2769): No overload matches this call.
            queryClient.invalidateQueries("drivers");
            setLoading(false);
            MySwal.fire({
              title: "User Rating Updated",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
          },
          (error) => {
            setLoading(false);
            MySwal.fire({
              title: "Error",
              text: error.response.data,
              icon: "error",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
          }
        );
      }
    });
  };

  if (loading) return <LoadingSpinner></LoadingSpinner>;
  else
    return (
      <Dropdown>
        <Dropdown.Toggle>Set Rating</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setRating(1)}>1</Dropdown.Item>
          <Dropdown.Item onClick={() => setRating(2)}>2</Dropdown.Item>
          <Dropdown.Item onClick={() => setRating(3)}>3</Dropdown.Item>
          <Dropdown.Item onClick={() => setRating(4)}>4</Dropdown.Item>
          <Dropdown.Item onClick={() => setRating(5)}>5</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
}
