import { promises } from "dns";
import { FleetList } from "../contracts/models/FleetListDto";
import { VehicleQuery } from "../contracts/models/Queries/VehicleQuery";
import { VehicleDto } from "../contracts/models/VehicleDto";
import { MileageSubmissionDto, PendingMileage, VehicleMoveDto } from "../contracts/models/VehicleMoveDto";
import api from "./network";

const API_URL = "Vehicle/";

class VehicleService {
  //gets available swing vehicles
  getVehiclesBySwing(swingId: any) {
    return api.get(API_URL + "swing/available", {
      params: {
        swingId,
      },
    });
  }

  getVehicleJobs(vehicleId: number, jobReference: string) {
    return api.get(API_URL + "jobs", {
      params: {
        vehicleId,
        jobReference,
      },
    });
  }

  getVehicles(vehicleQuery: VehicleQuery): Promise<VehicleDto[]> {
    return api
      .get(API_URL, {
        params: {
          customerId: vehicleQuery.customerId,
          registration: vehicleQuery.registration,
          activeOnly: vehicleQuery.activeOnly,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
      });
  }

  //gets current allocated swing vehicles
  getSwingVehicles(swingId: any) {
    return api.get(API_URL + "swing", {
      params: {
        swingId,
      },
    });
  }

  addVehicle(vehicle: VehicleDto) {
    return api.post(API_URL, vehicle);
  }

  editVehicle(vehicle: VehicleDto) {
    return api.post(API_URL + "edit", {
      VehicleId: vehicle.id,
      vehicle,
    });
  }

  editVehicleMove(vehicleMoveId: number, vehicleMove: VehicleMoveDto) {
    return api.post(API_URL + "move/edit", {
      vehicleMoveId,
      vehicleMove,
    });
  }

  addVehicleMove(jobId: any, vehicleMove: any) {
    return api.post(API_URL + "move/add", {
      jobId,
      vehicleMove,
    });
  }

  removeVehicleMove(vehicleMoveId: any) {
    return api.post(API_URL + "move/remove", {
      vehicleMoveId,
    });
  }

  async getVehicleImage(name: any) {
    return api
      .get("vehicle/image", {
        params: {
          name,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        return `data:${response.headers["content-type"]};base64,${new Buffer(
          response.data,
          "binary"
        ).toString("base64")}`;
      });
  }

  async updateArchivedStatus(vehicleId: number, archived : boolean): Promise<boolean> {
    return api
      .patch(API_URL + "archived-status", {
        vehicleId,
        archived,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
      });
  }

  async getFleetBreakdown(fromDate: string, toDate: string): Promise<FleetList> {
    return api
      .get(API_URL + "fleet/breakdown", {
        params: {
          fromDate,
          toDate,
        }
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
      });
  }

  async getPendingMileage(fromDate: string, toDate: string): Promise<PendingMileage[]> {
    return api
      .get(API_URL + "mileage/pending", {
        params: {
          fromDate,
          toDate,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
      });
  }

  async submitMileageReading(mileageSubmission: MileageSubmissionDto): Promise<void> {
    console.log(mileageSubmission);
    const formData = new FormData();
    formData.append("vehicleMoveId", mileageSubmission.vehicleMoveId.toString());
    if (mileageSubmission.startMileage !== undefined && mileageSubmission.startMileage as any !== "" && mileageSubmission.startMileage !== null) {
      formData.append("startMileage", mileageSubmission.startMileage.toString());
    }
    if (mileageSubmission.endMileage !== undefined && mileageSubmission.endMileage as any !== "" && mileageSubmission.endMileage !== null) {
      formData.append("endMileage", mileageSubmission.endMileage.toString());
    }
    if (mileageSubmission.distance !== undefined && mileageSubmission.distance as any !== "" && mileageSubmission.distance !== null) {
      formData.append("distance", mileageSubmission.distance.toString());
    }
    if (mileageSubmission.file) {
      formData.append("file", mileageSubmission.file);
    }

    return api
      .post(API_URL + "mileage/submit", formData)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
      });
  }

}

export default new VehicleService();
