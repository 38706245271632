import { useQuery } from "@tanstack/react-query";
import drivehoursService from "../services/drivehours.service";
import { DriverHoursDriverDto } from "../contracts/models/DriverDto";

export function useDriverHours(fromDate: string, toDate: string) {
  return useQuery<DriverHoursDriverDto[]>({
    queryKey: ["driverHours", fromDate, toDate],
    queryFn: async () => {
      const response = await drivehoursService.getDriverHours(fromDate, toDate);
      return response.data;
    }
  });
}
