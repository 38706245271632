import JobService from "../services/job.service";
import { useQuery } from "@tanstack/react-query";

export function useJobRemovalAlerts(jobBased = true, unresolved = true) {
  return useQuery({
    queryKey: ["jobremovals", jobBased, unresolved],
    queryFn: async () => {
      const response = await JobService.getJobRemovalAlerts(jobBased, unresolved);
      return response.data;
    },
    refetchInterval: 60000,
  });
}
