import DriverService from "../services/driver.service";
import { useQuery } from "@tanstack/react-query";

export function useDriverClassUpdatesByDriver(driverId: any) {
  return useQuery({
    queryKey: ["driverClassUpdates", driverId],
    queryFn: async () => {
      const response = await DriverService.GetDriverClassUpdatesByDriver(driverId);
      return response.data;
    }
  });
}
