import ComplianceService from "../services/compliance.service";
import { useQuery } from "@tanstack/react-query";

export function useUnseenAlerts() {
  return useQuery({
    queryKey: ["unseenalerts"],
    queryFn: async () => {
      const response = await ComplianceService.getUnseenAlerts();
      return response.data;
    },
    refetchInterval: 60000,
  });
}
