import React from "react";
import Select from "react-select";
import { CustomerJobRequestDto } from "../../contracts/models/CustomerJobRequestDto";

export default function SelectCustomerRequest({
  requests,
  selectRequest,
  defaultRequest,
}: {requests: CustomerJobRequestDto[], selectRequest: (value: number) => void, defaultRequest?: number}) {
  // Function to create label which limits desc to 50 characters and adds ... if longer
  const createLabel = (desc: CustomerJobRequestDto) => {
    var label = desc.jobType + " - " + desc.jobDescription;
    if(label.length > 50) {
      return label.substring(0, 50) + "...";
    }
    return label;
  };

  // Explicitly define the type for options
  const options: any[] = requests.map((r: CustomerJobRequestDto) => {
    var l = { value: r.id ?? -1, label: createLabel(r) };
    console.log('r', l)
    return l
  });

  if(requests && requests.length > 0) return (
    <>
      <label htmlFor="vehicleId">Select Customer Request</label>
      <Select
        onChange={(value: any) => selectRequest(value?.value)}
        options={options}
        defaultValue={defaultRequest ?? null}
        isClearable={false}
        name="requestId"
      />
    </>
  );

  return <p>There are no customer requests to pick from :(</p>;
}