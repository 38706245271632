import { useQuery } from "@tanstack/react-query";
import React from "react";
import Select from "react-select";
import { CustomerJobRequestDto } from "../../contracts/models/CustomerJobRequestDto";
import customerManagementService from "../../services/customer-management.service";
import SelectCustomerRequest from "./select-customer-request.component";

export default function SelectFromAllCustomerRequests({
  selectRequest,
  defaultRequest,
}: {selectRequest: (value: number) => void, defaultRequest?: number}) {

  const { data: requests, isLoading, isError, error } =  useQuery({
    queryKey: ["adminCustomerRequestManagement"],
    queryFn: async () => {
      return await customerManagementService.listCustomerRequests();
    },
  });

  if(isLoading || !requests) return <></>;
  
  return <SelectCustomerRequest requests={requests} selectRequest={selectRequest} defaultRequest={defaultRequest} />;
}
