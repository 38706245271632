import React, { useState } from "react";
import CustomerJobRequestService from "../../services/customer-request.service";
import { Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function DuplicateCustomerRequest({
  requestId
}: { requestId: number }) {
  const MySwal = withReactContent(Swal);

  const [loading, setLoading] = useState(false);
  const duplicate = (reverse: boolean) => {
    MySwal.fire({
      title: "Are you sure you want to duplicate this request?",
      text: "This will duplicate the request with all its details.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        CustomerJobRequestService.duplicateCustomerJobRequest(requestId, reverse).then(
          (response) => {
            setLoading(false);
            MySwal.fire({
              title: "Request successfully duplicated",
              text: "Please ensure all request details are correct.",
              icon: "success",
            }).then(() => {
              window.location.replace("/");
            });
          },
          (error) => {
            setLoading(false);
            console.log(error.response);
            var msg =
              error.response?.data ?? "An error occurred duplicating the request.";
            MySwal.fire({
              title: "Error",
              text: msg,
              icon: "error",
            });
          }
        );
      }
    });
  };

  return (
    <Dropdown>
      <Dropdown.Toggle>Duplicate Request</Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => duplicate(false)}
        >
          Duplicate Identical
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => duplicate(true)}
        >
          Duplicate Reversed
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
