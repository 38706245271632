import React from "react";

import { Link } from "react-router-dom";
import Select from "react-select";
import { CustomerDto, CustomerLabelsDto } from "../../contracts/models/CustomerDto";
import CustomerService from "../../services/customer.service";

export default function SelectCustomer({
  customers,
  onChange,
  hideMessage,
  defaultCustomer,
  clearable = false,
}: {
  customers?: CustomerLabelsDto[];
  onChange: (value: number) => void;
  hideMessage?: boolean;
  defaultCustomer?: number;
  clearable?: boolean;
}) {
  const filterCustomers = (inputValue: any, customers: any) => {
    return customers.filter((i: any) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  if(!customers) return <></>

  if(customers.length == 0) return <></>
   
  return (
    <>
      <Select
        onChange={(option: any) => {
          onChange(option?.value);
        }}
        isClearable={clearable}
        placeholder={"Select Customer"}
        options={customers}
        defaultValue={
          defaultCustomer
            ? customers.find((v: any) => v.value === defaultCustomer)
            : undefined
        }
        name="customerId"
      />
      {!hideMessage && (
        <p className="input-note">
          Customer selection is required. The customer list can be maintained
          via the <Link to={"/customers"}>Customers</Link> page.
        </p>
      )}
    </>
  );
}
