import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import VehicleService from "../../services/vehicle.service";
import SelectCustomer from "../customers/select-customer.component";
import AddVehicle from "./add-vehicle.component";
import ViewVehicleList from "./view-vehicle-list.component";
import { useCustomerLabels } from "../../hooks/useCustomers";
import { VehicleDto } from "../../contracts/models/VehicleDto";
import debounce from "lodash/debounce";
import useVehicleManager from "../../hooks/useVehicleManager";
import * as Yup from "yup";
import { Field, Form, Formik, FormikProps } from "formik";
import { VehicleQuery } from "../../contracts/models/Queries/VehicleQuery";
import { useRef, useCallback  } from 'react';

const MySwal = withReactContent(Swal);

export default function ManageVehicles() {
  const { vehicles, fetchVehicles, updateArchivedStatus } = useVehicleManager();
  const vehicleQueryForm = useRef<FormikProps<VehicleQuery>>(null);
  const { data: customers } = useCustomerLabels();
  const debouncedSubmit = useCallback(
    debounce(() => {
      vehicleQueryForm.current?.submitForm();
    }, 500),
    []
  );

  const getVehiclesSchema = Yup.object().shape({
    customerId: Yup.string().nullable(),
    registration: Yup.string()
      .min(2, "Field is too short")
      .max(150, "Field is too long"),
    activeOnly: Yup.boolean(),
  });

  let initialVehicle: VehicleQuery = {
    customerId: null,
    registration: "",
    activeOnly: false,
  };

  const addVehicle = (vehicle: VehicleDto) => {
    VehicleService.addVehicle(vehicle).then(
      (response) => {
        MySwal.fire({
          title: "Success",
          text: "Vehicle Added Successfully",
          icon: "success",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
        fetchVehicles({ customerId: vehicle.customerId, activeOnly: false });
      },
      (error) => {
        console.log(error);
        var message = "Error saving vehicle";
        if (error.response && error.response.data) {
          message = error.response.data;
        }
        MySwal.fire({
          title: "Error!",
          text: message,
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
      }
    );
  };

  const editVehicle = (vehicle: any) => {
    VehicleService.editVehicle(vehicle).then(
      (response) => {
        MySwal.fire({
          title: "Success",
          text: "Vehicle Edited Successfully",
          icon: "success",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
        // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
        getVehicles();
      },
      (error) => {
        console.log(error);
        MySwal.fire({
          title: "Error!",
          text: error,
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
      }
    );
  };

  const deleteVehicle = (vehicleId: any) => {
    // @ts-expect-error TS(2339): Property 'deleteVehicle' does not exist on type 'V... Remove this comment to see the full error message
    VehicleService.deleteVehicle(vehicleId).then(
      (response: any) => {
        MySwal.fire({
          title: "Success",
          text: "Vehicle Deleted Successfully",
          icon: "success",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
        // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
        getVehicles();
      },
      (error: any) => {
        console.log(error);
        MySwal.fire({
          title: "Error!",
          text: error,
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
      }
    );
  };

  const handleCustomerChange = (value: number) => {
    vehicleQueryForm.current?.setFieldValue("customerId", value);
    submitForm();
  };

  const handleRegistrationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    vehicleQueryForm.current?.setFieldValue("registration", value);
    debouncedSubmit();
  };

  const handleOnlyActiveChange = (value: boolean) => {
    vehicleQueryForm.current?.setFieldValue("onlyActive", value);
    if (
      vehicleQueryForm.current?.values.customerId ||
      vehicleQueryForm.current?.values.registration
    ) {
      submitForm();
    }
  };

  const submitForm = () => {
    vehicleQueryForm.current?.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        vehicleQueryForm.current?.submitForm();
      }
    });
  };

  const handleSubmission = (vehicleQuery: VehicleQuery) => {
    fetchVehicles(vehicleQuery);
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="/images/icons/job.png"
          alt="page-img"
          className="page-img"
        />
        <h3 className="page-title">Manage Vehicles</h3>
        <p>
          Create, edit and delete the vehicles that are available for use within
          jobs on the system.
        </p>
        <p>
          A vehicle must be visible in this list before it can be posted on a
          job for a driver.
        </p>
        <div className="row">
          <div className="col">
            <AddVehicle vehicle={null} onSubmit={addVehicle}></AddVehicle>
          </div>
        </div>
        <div className="vehicles-container my-3">
          <Formik
            innerRef={vehicleQueryForm}
            initialValues={initialVehicle}
            validationSchema={getVehiclesSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmission(values);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="row">
                  <div className="col">
                    <label htmlFor="customer">Customer</label>
                    <SelectCustomer
                      customers={customers}
                      onChange={handleCustomerChange}
                      hideMessage={true}
                    ></SelectCustomer>
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-8">
                    <label htmlFor="registration">Registration</label>
                    {errors.registration && touched.registration ? (
                      <div className="alert alert-danger" role="alert">
                        {errors.registration}
                      </div>
                    ) : null}
                    <Field
                      name="registration"
                      onChange={handleRegistrationChange}
                      type="text"
                      className="form-control"
                    />
                  </div>
                  <div className="col-2">
                    <div className="form-group">
                      <label className="radio-label" htmlFor="activeOnly">
                        Active only
                        <Field
                          name="activeOnly"
                          type="checkbox"
                          className="form-control"
                          onClick={handleOnlyActiveChange}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>

          <div className="row">
            <div className="col">
              <ViewVehicleList
                vehicles={vehicles}
                deleteVehicle={deleteVehicle}
                addVehicle={editVehicle}
                updateArchivedStatus={updateArchivedStatus}
              ></ViewVehicleList>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
