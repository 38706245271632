import moment from "moment";
import React, { useState } from "react";
import { useExpenses } from "../../hooks/useExpenses";
import LoadingSpinner from "../loading/loading-spinner.component";
import "./Expenses.css";
import FilterExpenses from "./filter-expenses.component";
import ExpenseTable from "./expense-table.component";
import expensesService from "../../services/expenses.service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function ExpenseList() {
  const [filters, setFilters] = useState({
    from: { label: "From Date", value: moment().subtract(7, "days").toDate() },
    to: { label: "To Date", value: moment().add(7, "days").toDate() },
    customer: { label: "Customer", value: null },
    searchTerm: { label: "Search Term", value: "" },
    status: { label: "All", value: "ALL" },
  });

  const [isExporting, setIsExporting] = useState(false);

  const { data: expenses, isLoading } = useExpenses(filters);

  const MySwal = withReactContent(Swal);

  const exportExpenses = () => {
    setIsExporting(true);
    expensesService
      .exportExpenses(
        filters.from.value,
        filters.to.value,
        filters.searchTerm.value,
        filters.status.value,
        filters.customer.value
      )
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "export.zip");
        document.body.appendChild(link);
        link.click();
        link.remove();
        setIsExporting(false);
      })
      .catch((error) => {
        MySwal.fire({
          title: "Error",
          text: "Error exporting - please contact support.",
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
        setIsExporting(false);
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="card card-container">
            <h3 className="page-title">Expense List</h3>

            <FilterExpenses
              expenses={expenses}
              filters={filters}
              setFilters={setFilters}
              isExporting={isExporting}
              exportExpenses={exportExpenses}
            ></FilterExpenses>

            {isLoading && <LoadingSpinner></LoadingSpinner>}

            {!isLoading && <ExpenseTable expenses={expenses}></ExpenseTable>}
          </div>
        </div>
      </div>
    </div>
  );
}
