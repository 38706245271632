import { useQuery } from '@tanstack/react-query';
import React from 'react';
import customerRequestService from '../../services/customer-request.service';
import LoadingSpinner from '../loading/loading-spinner.component';
import ViewCustomerRequest from './view-customer-request.component';
import Moment from 'react-moment';

const ListJobRequest: React.FC = () => {
  const { data: jobRequests, isLoading } = useQuery({queryKey: ['customerRequests'], queryFn: customerRequestService.listCustomerJobRequests} , );

  if (isLoading || !jobRequests) return <LoadingSpinner/>;

  return (
    <div>
      <h2>Your Company Job Requests</h2>
      <div className="table-responsive"> {/* Responsive table wrapper */}
        <table className="table table-hover table-striped"> {/* Enhanced table appearance */}
          <thead>
            <tr>
            <th>Status</th>
            <th>Customer</th>
            <th>Job Type</th>
            <th>Job Description</th>
              <th>Department</th>
              <th>Start Date</th>
              <th></th> {/* Added for clarity */}
            </tr>
          </thead>
          <tbody>
            {jobRequests.map((request) => (
              <tr key={request.id}>
                <td>{request.status}</td>
                <td>{request.customer?.name}</td>
                <td>{request.jobType}</td>
                <td>{request.jobDescription}</td>
                <td>{request.department}</td>
                <p><Moment format="DD/MM/YYYY HH:mm" date={request.startDate} /></p>
                <td>
                    <ViewCustomerRequest id={request.id ?? 0} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListJobRequest;