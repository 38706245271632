import React from "react";
import { useVehicleExpenses } from "../../hooks/useVehicleExpenses";
import ExpenseTable from "../expenses/expense-table.component";

export default function VehicleExpenseList({
  id,
  jobReference,
}: {
  id: number;
  jobReference: string;
}) {
  const expenses = useVehicleExpenses(id, jobReference);
  return (
    <ExpenseTable
      expenses={expenses.data}
      isLoading={expenses.isLoading}
    ></ExpenseTable>
  );
}
