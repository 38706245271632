import React from "react";
import AddVehicleMove from "../jobs/add-vehicle-move.component";
import VehicleMove from "../vehicles/vehicle-move.component";
import VehicleService from "../../services/vehicle.service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DefaultModal from "../modal/modal.component";
import { useAddresses } from "../../hooks/useAddresses";
import { useContacts } from "../../hooks/useContacts";
const MySwal = withReactContent(Swal);

export default function ViewJobVehicles({
  job,
  vehicles,
  onUpdate
}: any) {
  const { data: addresses } = useAddresses();
  const { data: contacts } = useContacts();

  const editVehicleMove = (vehicleMoveId: any, vehicleMove: any) => {
    MySwal.fire({
      title: "Are you sure you want to edit this vehicle move?",
      text: "Editing this move will update the move data for any accepted driver. They will see the new details when next viewing this job.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, edit it!",
    }).then((result) => {
      if (result.isConfirmed) {
        VehicleService.editVehicleMove(vehicleMoveId, vehicleMove).then(
          (response) => {
            MySwal.fire({
              title: "Success",
              text: "Vehicle Move successfully edited.",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
            onUpdate();
          },
          (error) => {
                MySwal.fire({
                  title: "Error",
                  text: error?.response?.data ?? "An error occurred",
                  icon: "error",
                  // @ts-expect-error TS(2769): No overload matches this call.
                  button: "Continue",
                });
          }
        );
      }
    });
  };

  const addVehicleMove = (vehicleMove: any) => {
    console.log(vehicleMove);
    MySwal.fire({
      title: "Are you sure you want to add this vehicle move?",
      text: "Adding this move may effect the pending drivers list if some drivers cannot accept a vehicle of this class.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, add it!",
    }).then((result) => {
      if (result.isConfirmed) {
        VehicleService.addVehicleMove(job.id, vehicleMove).then(
          (response) => {
            MySwal.fire({
              title: "Success",
              text: "Vehicle Move successfully added.",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
            onUpdate();
          },
          (error) => {
            if (error && error.response) {
              console.log(error.response.data);
              MySwal.fire({
                title: "Error adding vehicle move",
                text: error.response.data,
                icon: "error",
                // @ts-expect-error TS(2769): No overload matches this call.
                button: "Continue",
              });
            }
          }
        );
      }
    });
  };

  const removeVehicleMove = (vehicleMoveId: any) => {
    console.log(vehicleMoveId);
    MySwal.fire({
      title: "Are you sure you want to remove this vehicle move?",
      text: "This move will not longer show to the driver who accept this job.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        VehicleService.removeVehicleMove(vehicleMoveId).then(
          (response) => {
            MySwal.fire({
              title: "Success",
              text: "Vehicle Move successfully removed.",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
            onUpdate();
          },
          (error) => {
            if (error && error.response) {
              console.log(error.response.data);
              MySwal.fire({
                title: "Error removing vehicle move",
                text: error.response.data,
                icon: "error",
                // @ts-expect-error TS(2769): No overload matches this call.
                button: "Continue",
              });
            }
          }
        );
      }
    });
  };

  const vehicleMoves = job.vehicles.map((move: any) => // key={move.vehicleId}
  <div key={move.id} className="row">
    <VehicleMove
      jobId={job.id}
      addresses={addresses}
      contacts={contacts}
      vehicles={vehicles}
      vehicleMove={move}
      editVehicleMove={editVehicleMove}
      removeVehicleMove={removeVehicleMove}
      onUpdate={onUpdate}
    ></VehicleMove>
  </div>);

  return (
    <>
      <h4 className="vehicle-title">Job Vehicles</h4>
      <DefaultModal
        title={"Add Vehicle Move"}
        trigger={"Add Job Vehicle Move"}
        content={
          <AddVehicleMove
            addresses={addresses}
            vehicles={vehicles}
            onSubmit={addVehicleMove}
            contacts={contacts}
          ></AddVehicleMove>
        }
      ></DefaultModal>
      {vehicleMoves}
    </>
  );
}
