import React from "react";
import { GetVehicleById } from "../../helpers/VehicleTypes";
import { GetById } from "../../helpers/LicenceClasses";
import AddVehicle from "./add-vehicle.component";
import ViewVehicle from "./view-vehicle.component";
import { VehicleDto } from "../../contracts/models/VehicleDto";
import ArchiveVehicle from "./archive-vehicle.component";

export default function Vehicle({
  vehicle,
  onSubmit,
  updateArchivedStatus,
  deleteVehicle,
}: {
  vehicle: VehicleDto;
  onSubmit?: any;
  deleteVehicle?: any;
  updateArchivedStatus?: (
    vehicleId: number,
    archivedStatus: boolean
  ) => Promise<void>;
}) {
  return (
    <div className="vehicle-row">
      <div className="row">
        <div className="col">
          <p className="card-text">
            <label className="job-label">Vehicle Type</label>
            {GetVehicleById(vehicle.type)}
          </p>
        </div>
        <div className="col">
          <p className="card-text">
            <label className="job-label">Class</label>
            {GetById(vehicle.class)}
          </p>
        </div>
        {vehicle.weight && (
          <div className="col">
            <p className="card-text">
              <label className="job-label">Weight</label>
              {vehicle.weight}
            </p>
          </div>
        )}
      </div>
      <div className="row">
        {vehicle.vehicleDescription && (
          <div className="col-md-8">
            <label className="job-label">Description</label>
            <h5 className="card-title">{vehicle.vehicleDescription}</h5>
          </div>
        )}

        {vehicle.registration && (
          <div className="col">
            <label className="job-label">Registration</label>
            <h5 className="card-title">{vehicle.registration}</h5>
          </div>
        )}
      </div>

      <div className="d-flex justify-content-between">
        <div>
          <ViewVehicle vehicle={vehicle}></ViewVehicle>
          {onSubmit ? (
            <AddVehicle vehicle={vehicle} onSubmit={onSubmit}></AddVehicle>
          ) : null}
        </div>
        {updateArchivedStatus && (
          <div>
              <ArchiveVehicle
                vehicle={vehicle as VehicleDto}
                updateArchivedStatus={updateArchivedStatus}
              />
          </div>
        )}
      </div>
    </div>
  );
}
