import ComplianceService from "../services/compliance.service";
import { useInfiniteQuery } from "@tanstack/react-query";

async function getQuestionnaireSubmissions({ queryKey, pageParam = 1 }: any) {
  const response = await ComplianceService.getSubmissions(pageParam);
  return {
    items: response.data,
    nextCursor: pageParam + 1,  // Assuming your API doesn't return nextCursor, we use pageParam
    prevCursor: pageParam - 1,  // Assuming your API doesn't return prevCursor, we use pageParam
  };
}

export function useQuestionnaireSubmissions() {
  return useInfiniteQuery({
    queryKey: ["questionnaireSubmissions"],
    queryFn: getQuestionnaireSubmissions,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.items.length) {
        return lastPage.nextCursor;
      }
      return false;
    },
    getPreviousPageParam: (firstPage) => {
      if (firstPage.items.length) {
        return firstPage.prevCursor;
      }
      return false;
    },
  });
}
