import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./filter-date.component.css";
export default function FilterDate({ dates, onDateChange }: { dates: { startDate: Date; endDate: Date }; onDateChange: (dates: { startDate: Date; endDate: Date }) => void }){
  const [startDate, setStartDate] = useState(dates.startDate);
  const [endDate, setEndDate] = useState(dates.endDate);

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
    onDateChange({ startDate: date, endDate });
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
    onDateChange({ startDate, endDate: date });
  };

  return (
      <div className="filters-container">
        <div className="row filter-section">
          
      <div className="w-40 my-2 mx-2">
        <p>From Date</p>
        <DatePicker
        className="form-control date-picker"
          selected={startDate}
          onChange={handleStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd/MM/yyyy"
        />
      </div>
      <div className="w-40 my-2 mx-2">
        <p>To Date</p>
        <DatePicker
        className="form-control date-picker"
          selected={endDate}
          onChange={handleEndDateChange}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          dateFormat="dd/MM/yyyy"
        />
        </div>
        </div>
      </div>
  );
}
