import vehicleService from "../services/vehicle.service";
import { useQuery } from "@tanstack/react-query";

export function useVehicleJobs(vehicleId: number, jobReference: string) {
  return useQuery({
    queryKey: ["vehicleJobs", vehicleId, jobReference],
    queryFn: async () => {
      const response = await vehicleService.getVehicleJobs(vehicleId, jobReference);
      return response.data;
    },
  });
}
