import { useMemo, useState } from "react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_TableOptions,
  useMantineReactTable,
} from "mantine-react-table";
import { ActionIcon, Button, Flex, NumberInput, Tooltip } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { UseMutationResult } from "@tanstack/react-query";
import React from "react";
import { DateInput } from "@mantine/dates";
import { ChargeDto } from "../../contracts/models/Charges/ChargeDto";
import {
  useCreateCharge,
  useDeleteCharge,
  useGetCharges,
} from "../../hooks/useCharges";
import "./charges-table.component.css";

const ChargesTable = ({ swingId }: { swingId: number }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});

  // Define columns
  const columns = useMemo<MRT_ColumnDef<ChargeDto>[]>(
    () => [
      {
        accessorKey: "description",
        header: "Description",
        mantineEditTextInputProps: {
          type: "text",
          required: true,
          error: validationErrors?.description,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              description: undefined,
            }),
        },
      },
      {
        accessorKey: "chargeDate",
        header: "Charge Date",
        required: true,
        Edit: ({ column, row }) => {
          return (
            <DateInput
              error={validationErrors?.chargeDate}
              required={true}
              onChange={(value: any) => (row._valuesCache[column.id] = value)}
              placeholder="Charge Date"
              maw={400}
              mx="auto"
            />
          );
        },
        Cell: ({ cell }) =>
          new Date(cell.getValue() as string).toLocaleDateString(),
      },
      {
        accessorKey: "quantity",
        header: "No. of Units",
        Edit: ({ column, row }) => {
          return (
            <NumberInput
              defaultValue={1}
              onChange={(value: any) => (row._valuesCache[column.id] = value)}
              error={validationErrors?.quantity}
            />
          );
        },
        Cell: ({ cell }) => `${cell.getValue<number>()}`,
      },
      {
        accessorKey: "cost",
        header: "Cost",
        Edit: ({ column, row }) => {
          return (
            <NumberInput
              defaultValue={0.0}
              precision={2}
              prefix="£"
              onChange={(value: any) => (row._valuesCache[column.id] = value)}
              error={validationErrors?.cost}
            />
          );
        },
        Cell: ({ cell }) => `£${cell.getValue<number>()?.toFixed(2)}`,
      },
      {
        accessorKey: "totalPrice",
        header: "Total Price",
        enableEditing: false,
        accessorFn: (row) => `£${(row.quantity * row.cost).toFixed(2)}`,
      },
      {
        accessorKey: "createdBy",
        header: "Created By",
        enableEditing: false,
      }
    ],
    [validationErrors]
  );

  useGetCharges({ swingId });

  const { mutateAsync: createCharge, isPending: isCreatingCharge } =
    useCreateCharge(swingId) as UseMutationResult<ChargeDto>;

  //call DELETE hook
  const { mutateAsync: deleteCharge, isPending: isDeletingCharge } =
    useDeleteCharge(swingId);

  //call READ hook
  const {
    data: fetchedCharges = [],
    isError: isLoadingChargesError,
    isFetching: isFetchingCharges,
    isLoading: isLoadingCharges,
  } = useGetCharges({ swingId });

  //DELETE action
  const handleCreateCharge: MRT_TableOptions<ChargeDto>["onCreatingRowSave"] =
    async ({ values, exitCreatingMode }) => {
      // Ensure values contains all the required properties
      const chargeValues: ChargeDto = {
        swingId: swingId,
        id: values.id || undefined,
        description: values.description || undefined,
        createdAt: values.createdAt || undefined,
        chargeDate: values.chargeDate || undefined,
        quantity: values.quantity || 1,
        createdById: values.createdBy || undefined,
        createdby: values.createdBy || undefined,
        cost: values.cost || undefined,
      };

      const newValidationErrors = validateCharge(chargeValues);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      await createCharge(chargeValues);
      setIsCreating(false);
      exitCreatingMode();
    };

  const table = useMantineReactTable({
    columns,
    data: fetchedCharges,
    initialState: {
      columnVisibility: {
        createdBy: false,
      },
    },
    createDisplayMode: "row",
    editDisplayMode: "row",
    enableEditing: true,
    getRowId: (row) => row.id,
    mantineToolbarAlertBannerProps: isLoadingChargesError
      ? {
          color: "red",
          children: "Error loading data",
        }
      : undefined,
    mantineTableContainerProps: {
      style: {
        minHeight: "500px",
      },
    },
    onCreatingRowCancel: () => {
      setValidationErrors({});
      setIsCreating(false);
    },
    onCreatingRowSave: handleCreateCharge,
    onEditingRowCancel: () => setValidationErrors({}),
    renderRowActions: ({ row }) => (
      <Flex gap="md">
        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => deleteCharge(row.original.id)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        className="my-2"
        disabled={isCreating}
        onClick={() => {
          table.setCreatingRow(true);
          setIsCreating(true);
        }}
      >
        Create New Charge
      </Button>
    ),
    state: {
      isLoading: isLoadingCharges,
      isSaving: isCreatingCharge || isDeletingCharge,
      showAlertBanner: isLoadingChargesError,
      showProgressBars: isFetchingCharges,
    },
  });

  const validateRequired = (value: string | number | Date) => {
    if (value instanceof Date) {
      return !isNaN(value.getTime());
    }
    return value === 0 || Boolean(value);
  };

  function validateCharge(charge: ChargeDto) {
    return {
      description: !validateRequired(charge.description)
        ? "Description is Required"
        : "",
      quantity: !validateRequired(charge.quantity)
        ? "Quantity is Required"
        : "",
      cost: !validateRequired(charge.cost) ? "Cost is Required" : "",
      chargeDate: !validateRequired(charge.chargeDate)
        ? "Charge Date is Required"
        : "",
    };
  }

  return <MantineReactTable table={table} />;
};

export default ChargesTable;
