import { Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { on } from "stream";
import * as Yup from "yup";
import { VehicleMoveDto } from "../../contracts/models/VehicleMoveDto";
import {
  Classes,
  GetOptionalSelectItemById,
  GetSelectItemById,
} from "../../helpers/LicenceClasses";
import {
  VehicleTypes,
  GetVehicleSelectItemById,
} from "../../helpers/VehicleTypes";
import SelectAddress from "../address/select-address.component";
import SelectContact from "../contacts/select-contact.component";
import SelectVehicle from "../vehicles/select-vehicle.component";

const AddOrEditVehicleSchema = Yup.object().shape({
  requiredClassId: Yup.string().required("Vehicle class is required"),
  additionalInformation: Yup.string().max(150, "Field is too long"),
  collectionInformation: Yup.string().max(150, "Field is too long"),
  deliveryInformation: Yup.string().max(150, "Field is too long"),
  vehicleDetails: Yup.string()
    .required("Vehicle must be known or unknown")
    .oneOf(["known", "unknown"]),
  vehicleType: Yup.string().when("vehicleDetails", ([vehicleDetails], schema) =>
    vehicleDetails === "unknown"
      ? schema.required("Vehicle type is required")
      : schema.nullable()
  ),
  secondaryVehicleDetails: Yup.string()
    .required("Vehicle must be known or unknown")
    .oneOf(["none", "known", "unknown"]),
  secondaryVehicleType: Yup.string().when(
    "secondaryVehicleDetails",
    ([secondaryVehicleDetails], schema) =>
      secondaryVehicleDetails === "unknown"
        ? schema.required("Vehicle type is required")
        : schema.nullable()
  ),
  walkaroundRequired: Yup.boolean(),
  imagesRequired: Yup.boolean(),
  addressRequired: Yup.string(),
  vehicleProvisionType: Yup.string().required(
    "Vehicle provision type is required"
  ),
  providedVehicle: Yup.string().required("Vehicle provision type is required"),
  collectionAddressId: Yup.number().nullable(),
  deliveryAddressId: Yup.number().nullable(),
  collectionContactId: Yup.number().nullable(),
  deliveryContactId: Yup.number().nullable(),
  vehicleId: Yup.number().when("vehicleDetails", ([vehicleDetails], schema) =>
    vehicleDetails === "known"
      ? schema.required("Vehicle is required")
      : schema.nullable()
  ),
  secondaryVehicleId: Yup.number().when(
    "secondaryVehicleDetails",
    ([secondaryVehicleDetails], schema) =>
      secondaryVehicleDetails === "known"
        ? schema.required("Vehicle is required")
        : schema.nullable()
  ),
});

export default function AddOrEditVehicleMove({
  addresses,
  contacts,
  vehicles,
  onSubmit,
  initialData,
}: {
  addresses: any;
  contacts: any;
  vehicles: any;
  onSubmit: any;
  initialData?: VehicleMoveDto | null;
}) {
  console.log("INITIAL DATA", initialData);
  const selectVehicle = (
    vehicle: any,
    setVehicle: (val: number) => void,
    onSet: (val: string) => void
  ) => {
    console.log("VEHICLE", vehicle);
    setVehicle(vehicle);
    //call onSet with requiredClassId
    console.log(
      "VEHICLE TO Set",
      vehicles.find((v: any) => v.id === vehicle)
    );
    onSet(vehicles.find((v: any) => v.id === vehicle).class);
  };

  const ProvisionType = [
    { value: "CUSTOMER", label: "Customer Provided" },
    { value: "SDL", label: "SDL Provided" },
    { value: "SDL_UNCHARGED", label: "SDL Uncharged" },
  ];

  const resetFields = () => {};

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <Formik
              initialValues={{
                vehicleDetails: initialData?.vehicleId ? "known" : "unknown",
                secondaryVehicleDetails: initialData?.secondaryVehicleId
                  ? "known"
                  : "none",
                driverPrice: initialData?.driverPrice || 0,
                cost: initialData?.cost || 0,
                requiredClassId: initialData?.requiredClassId || undefined,
                collectionInformation: initialData?.collectionInformation || "",
                deliveryInformation: initialData?.deliveryInformation || "",
                additionalInformation: initialData?.additionalInformation || "",
                providedVehicle: initialData?.providedTowVehicle
                  ? "tow"
                  : initialData?.providedGroundVehicle
                  ? "ground"
                  : "none",
                vehicleProvisionType:
                  initialData?.vehicleProvisionType || "CUSTOMER",
                walkaroundRequired: initialData?.walkaroundRequired ?? true,
                imagesRequired: initialData?.imagesRequired ?? true,
                collectionAddressId:
                  initialData?.collectionAddressId ?? undefined,
                deliveryAddressId: initialData?.deliveryAddressId ?? undefined,
                collectionContactId:
                  initialData?.collectionContactId ?? undefined,
                deliveryContactId: initialData?.deliveryContactId ?? undefined,
                vehicleId: initialData?.vehicleId ?? undefined,
                secondaryVehicleId:
                  initialData?.secondaryVehicleId ?? undefined,
                vehicleType: initialData?.vehicleType ?? undefined,
                secondaryVehicleType:
                  initialData?.secondaryVehicleType ?? undefined,
                addressRequired: initialData?.collectionAddress
                  ? "yes"
                  : "none",
              }}
              validationSchema={AddOrEditVehicleSchema}
              onSubmit={(values, { resetForm }) => {
                // @ts-expect-error TS(2339): Property 'providedTowVehicle' does not exist on ty... Remove this comment to see the full error message
                values.providedTowVehicle =
                  values.providedVehicle !== "none" &&
                  values.providedVehicle == "tow";

                // @ts-expect-error TS(2339): Property 'providedGroundVehicle' does not exist on... Remove this comment to see the full error message
                values.providedGroundVehicle =
                  values.providedVehicle !== "none" &&
                  values.providedVehicle != "tow";

                if (values.vehicleDetails !== "known") {
                  values.vehicleId = undefined;
                }

                if (values.secondaryVehicleDetails === "none") {
                  values.secondaryVehicleId = undefined;
                }
                if (!values.walkaroundRequired) {
                  values.walkaroundRequired = false;
                }

                if (!initialData) {
                  onSubmit(values, resetForm);
                } else {
                  onSubmit(initialData.id, values);
                }
              }}
            >
              {({ errors, touched, setFieldValue, setFieldTouched, isValid, values, dirty, submitForm }) => (
                <Form>
                  <>{console.log("errors!!", errors)}</>
                  <>{console.log(values)}</>
                  <label htmlFor="name">
                    {initialData ? "Edit" : "Add"} Vehicle Move
                  </label>

                  <div className="form-group">
                    <label htmlFor="name">
                      Are Address Detailed Required for this move?
                      <span className="required">*</span>
                    </label>
                    <label className="radio-label">
                      <Field type="radio" name="addressRequired" onChange={() => {
                        setFieldValue("addressRequired", "none");
                        setFieldValue("collectionAddressId", undefined);
                        setFieldValue("deliveryAddressId", undefined);
                        setFieldValue("collectionContactId", undefined);
                        setFieldValue("deliveryContactId", undefined);
                      }} value="none" />
                      Address Not Required
                    </label>
                    <label className="radio-label">
                      <Field type="radio" name="addressRequired" value="yes" />
                      Address Details Required
                    </label>
                  </div>

                  {values.addressRequired === "yes" && (
                    <>
                      <div className="form-group">
                        <label htmlFor="class">Collection Address</label>
                        <SelectAddress
                          addresses={addresses}
                          onChange={(val: number) =>
                            setFieldValue("collectionAddressId", val)
                          }
                          defaultAddress={initialData?.collectionAddressId}
                        ></SelectAddress>
                        {/* <p className="input-note">Vehicle Move collectio</p> */}
                      </div>

                      <div className="form-group">
                        <label htmlFor="class">Collection Contact</label>
                        <SelectContact
                          contacts={contacts}
                          onChange={(val: number) =>
                            setFieldValue("collectionContactId", val)
                          }
                          defaultContact={initialData?.collectionContactId}
                        ></SelectContact>
                      </div>

                      <div className="form-group">
                        <label htmlFor="name">
                          Optional Collection Information
                        </label>
                        {errors.collectionInformation &&
                        touched.collectionInformation ? (
                          <div className="alert alert-danger" role="alert">
                            {errors.collectionInformation}
                          </div>
                        ) : null}
                        <Field
                          className="form-control"
                          name="collectionInformation"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="class">Delivery Address</label>
                        <SelectAddress
                          addresses={addresses}
                          onChange={(val: number) =>
                            setFieldValue("deliveryAddressId", val)
                          }
                          defaultAddress={initialData?.deliveryAddressId}
                        ></SelectAddress>
                        {/* <p className="input-note">Vehicle Move collectio</p> */}
                      </div>

                      <div className="form-group">
                        <label htmlFor="class">Delivery Contact</label>
                        <SelectContact
                          contacts={contacts}
                          onChange={(val: number) =>
                            setFieldValue("deliveryContactId", val)
                          }
                          defaultContact={initialData?.deliveryContactId}
                        ></SelectContact>
                      </div>

                      <div className="form-group">
                        <label htmlFor="deliveryInformation">
                          Optional Delivery Information
                        </label>
                        {errors.deliveryInformation &&
                        touched.deliveryInformation ? (
                          <div className="alert alert-danger" role="alert">
                            {errors.deliveryInformation}
                          </div>
                        ) : null}
                        <Field
                          className="form-control"
                          name="deliveryInformation"
                        />
                      </div>
                    </>
                  )}

                  <div className="form-group">
                    <label htmlFor="name">
                      Special Vehicle Charge
                      <span className="required">*</span>
                    </label>
                    <label className="radio-label">
                      <Field type="radio" name="providedVehicle" value="none" />
                      No Special Vehicle Charge
                    </label>
                    <label className="radio-label">
                      <Field type="radio" name="providedVehicle" value="tow" />
                      Tow Vehicle
                    </label>
                    <label className="radio-label">
                      <Field
                        type="radio"
                        name="providedVehicle"
                        value="ground"
                      />
                      Ground Vehicle
                    </label>
                  </div>

                  <div className="form-group">
                    <label htmlFor="class">
                      Vehicle Provision Type<span className="required">*</span>
                    </label>
                    {errors.vehicleProvisionType &&
                    touched.vehicleProvisionType ? (
                      <div className="alert alert-danger" role="alert">
                        {errors.vehicleProvisionType}
                      </div>
                    ) : null}
                    <Select
                      onChange={(option: any) => {
                        setFieldValue("vehicleProvisionType", option.value);
                      }}
                      options={ProvisionType}
                      name="vehicleProvisionType"
                      defaultValue={ProvisionType.find(
                        (p) =>
                          p.value == initialData?.vehicleProvisionType ??
                          "CUSTOMER"
                      )}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="name">
                      Do you know the details of the move vehicle?
                      <span className="required">*</span>
                    </label>
                    <label className="radio-label">
                      <Field type="radio" name="vehicleDetails" value="known" />
                      Vehicle Details Known
                    </label>
                    <label className="radio-label">
                      <Field
                        type="radio"
                        name="vehicleDetails"
                        value="unknown"
                        onChange={() => {
                          setFieldValue("vehicleDetails", "unknown");
                          setFieldValue("vehicleId", "");
                        }}
                      />
                      Vehicle Details Unknown
                    </label>

                    {
                      //if we want to change the form based on the vehicle selected then we need select vehicle to
                      //set to the whole vehucle rather than just the id (maybe we should do this anyway)
                    }

                    {values.vehicleDetails === "known" && (
                      <div className="form-group">
                        <label htmlFor="name">
                          Select Vehicle<span className="required">*</span>
                        </label>
                        {/* <AddVehicle removeVehicle={this.removeVehicle} onSubmit={this.addVehicle}></AddVehicle> */}
                        {values.vehicleProvisionType == "SDL" && (
                          <label className="small">
                            You have selected that the vehicle was provisioned
                            by SDL, you should usually select an SDL Vehicle
                            here.
                          </label>
                        )}

                        <SelectVehicle
                          vehicles={vehicles}
                          selectVehicle={(option: any) => {
                            console.log("option", option);
                            selectVehicle(
                              option.value,
                              (val: number) => {setFieldValue("vehicleId", val); setTimeout(() => setFieldTouched("vehicleId", true));},
                              (val: string) =>
                                setFieldValue("requiredClassId", val)
                            );
                          }}
                          defaultVehicle={initialData?.vehicleId}
                        />
                        <p>
                          This dropdown only displays vehicles not current on
                          this swing. The current vehicle list can be seen
                          below.
                        </p>
                      </div>
                    )}

                    {values.vehicleDetails === "unknown" && (
                      <div className="form-group">
                        <label htmlFor="type">
                          Vehicle Type<span className="required">*</span>
                        </label>
                        {errors.vehicleType && touched.vehicleType ? (
                          <div className="alert alert-danger" role="alert">
                            {errors.vehicleType}
                          </div>
                        ) : null}
                        <Select
                          onChange={(option: any) => {
                            setFieldValue("vehicleType", option.value);
                          }}
                          options={VehicleTypes}
                          name="type"
                          defaultValue={GetVehicleSelectItemById(
                            values.vehicleType
                          )}
                        />
                      </div>
                    )}
                    <hr />
                  </div>

                  <div className="form-group">
                    <label htmlFor="name">
                      Are there any additional vehicle attachments such as
                      Trailers?
                      <span className="required">*</span>
                    </label>
                    <label className="radio-label">
                      <Field
                        type="radio"
                        name="secondaryVehicleDetails"
                        value="none"
                      />
                      No Secondary Vehicle
                    </label>
                    <label className="radio-label">
                      <Field
                        type="radio"
                        name="secondaryVehicleDetails"
                        value="known"
                      />
                      Vehicle Details Known
                    </label>
                    <label className="radio-label">
                      <Field
                        type="radio"
                        name="secondaryVehicleDetails"
                        value="unknown"
                      />
                      Vehicle Details Unknown
                    </label>
                    {values.secondaryVehicleDetails !== "none" && (
                      <>
                        {values.secondaryVehicleDetails === "known" && (
                          <div className="form-group">
                            <label htmlFor="name">
                              Select Vehicle
                              <span className="required">*</span>
                            </label>
                            {/* <AddVehicle removeVehicle={this.removeVehicle} onSubmit={this.addVehicle}></AddVehicle> */}
                            <SelectVehicle
                              vehicles={vehicles}
                              selectVehicle={(option: any) => {
                                console.log("option", option);
                                selectVehicle(
                                  option.value,
                                  (val: number) => {setFieldValue("secondaryVehicleId", val); setTimeout(() => setFieldTouched("secondaryVehicleId", true), 300);},
                                  (val: string) => {}
                                );
                              }}
                              defaultVehicle={initialData?.secondaryVehicleId}
                            />
                          </div>
                        )}

                        {values.secondaryVehicleDetails === "unknown" && (
                          <div className="form-group">
                            <label htmlFor="type">
                              Secondary Vehicle Type
                              <span className="required">*</span>
                            </label>
                            {errors.secondaryVehicleType && touched.secondaryVehicleType ? (
                              <div className="alert alert-danger" role="alert">
                                {errors.secondaryVehicleType.toString()}
                              </div>
                            ) : null}
                            <Select
                              onChange={(option: any) => {
                                setFieldValue("secondaryVehicleType", option.value);
                              }}
                              options={VehicleTypes}
                              name="vehicleType"
                              defaultValue={GetVehicleSelectItemById(
                                values.secondaryVehicleType
                              )}
                            />
                          </div>
                        )}
                      </>
                    )}
                    <hr />
                    {errors.vehicleId && touched.vehicleId ? (
                      <div className="alert alert-danger" role="alert">
                        {errors.vehicleId}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label htmlFor="class">
                      Maximum Licence Class for this Move
                      <span className="required">*</span>
                    </label>
                    {errors.requiredClassId && dirty ? (
                      <div className="alert alert-danger" role="alert">
                        {errors.requiredClassId}
                      </div>
                    ) : null}

                    {/* {values.vehicleId === null ||
                    values.vehicleId == undefined ? (
                      <> */}
                        <Select
                          // isDisabled={
                          //   values.vehicleId !== undefined &&
                          //   values.vehicleId !== null
                          // }
                          onChange={(option: any) => {
                            setFieldValue("requiredClassId", option.value);
                          }}
                          options={Classes}
                          name="requiredClassId"
                          defaultValue={GetOptionalSelectItemById(
                            values.requiredClassId
                          )}
                        />
                        <p className="input-note">
                          Enter the maximum required license category for this
                          vehicle move.
                        </p>
                        <p className="input-note">
                          It will not be possible to edit this move for a higher
                          class vehicle selected.
                        </p>
                      {/* </>
                    ) : (
                      <p className="">
                        You have selected a vehicle and therefore Class{" "}
                        {GetSelectItemById(values.requiredClassId).label} will
                        be required
                      </p>
                    )} */}
                    {(errors.requiredClassId && touched.requiredClassId) && (
                      <div className="alert alert-danger" role="alert">
                        {errors.requiredClassId}
                      </div>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="radio-label">
                      <Field type="checkbox" name="walkaroundRequired" />
                      Does the Driver need to do the walkaround for this
                      vehicle?
                    </label>
                    {errors.walkaroundRequired && touched.walkaroundRequired ? (
                      <div className="alert alert-danger" role="alert">
                        {errors.walkaroundRequired}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="radio-label">
                      <Field type="checkbox" name="imagesRequired" />
                      Does the Driver need to take before and after images of this
                      vehicle?
                    </label>
                    {errors.walkaroundRequired && touched.imagesRequired ? (
                      <div className="alert alert-danger" role="alert">
                        {errors.imagesRequired}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label htmlFor="name">Additional Move Information</label>
                    {errors.additionalInformation &&
                    touched.additionalInformation ? (
                      <div className="alert alert-danger" role="alert">
                        {errors.additionalInformation}
                      </div>
                    ) : null}
                    <Field
                      className="form-control"
                      name="additionalInformation"
                    />
                    <p className="input-note">
                      Optional move information such as tow vehicle registration
                      or further instructions
                    </p>
                  </div>

                  {/* <p className="posting-message">Posting this job will allow drivers to view all information entered above except vehicle cost. One driver will be able to accept the job, removing it from the job list of other drivers.</p>
                  <p className="posting-message">Jobs can be removed at any time via the dashboard</p> */}

                  {!isValid ? (
                    <div className="alert alert-danger" role="alert">
                      Please ensure all required fields are filled in correctly.
                    </div>
                  ) : null}

                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-block"
                      disabled={!isValid || !dirty}
                      type="button"
                      onClick={() => {
                        console.log("clicked");
                        submitForm();
                      }}
                    >
                      {initialData ? "Edit" : "Add"} Vehicle Move
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
