import React from "react";

function Privacy(props: any) {
  return (
    <div className="container">
      <div className="jumbotron centeredBox">
        <h3>Swing Driver Privacy Policy</h3>
        <p>Please read this Privacy Policy carefully. You must not use the Swing Driver mobile application or our
Services if you do not accept this Privacy Policy or our Terms of Use.</p>

        <p>Below is a summary of some key terms of this Privacy Policy. This summary is for your reference only and does not form part of the Privacy Policy.


        We are committed to protecting your personal information when you use our Services and we want you to be confident that your personal information is safe and secure with us.  This Privacy Policy explains the following:


        How we collect your personal information
        What personal information we collect
        How we use your personal information
        Who we share your personal information with
        The rights and choices you have when it comes to your personal information

        Use of our Sites is subject to the terms of this Privacy Policy and our Terms of Use.  If you don't agree to these terms, please stop using the Sites immediately.


        We may collect personal information about you when you use our Services.  We may also collect information about the way in which you use our Sites.


        The ways in which we may use your personal information are described below.  They include:


        Enabling you to access and use the Services
        Personalising and improving aspects of our Services
        Research (such as analysing market trends and customer demographics)
        Communicating with you about your quotes and our Services

        For certain purposes as highlighted below, we may share your personal information with members of our group, our partners, service providers and regulatory or governmental bodies.


        To make enquiries or exercise any of your rights as set out in this Privacy Policy, please contact our Complaints Team at Office@swingdriver.com








        Who are we?


        Swing Driver mobile application is a product developed by Direct Software Group Ltd on behalf of Swing Driver Limited.


        For the purposes of this Privacy Policy, references to Swing Driver mobile application ("we", "us" or "our") excludes Direct Software Group Ltd which has their own privacy policies (see below for further information).


        Direct Software Group Ltd (company number 10115040) is the data controller in relation to the processing of the personal information that you provide to us when you use our Services. Its registered address is Direct Software Group Ltd, Sherwood House, 7 Gregory Blvd, Nottingham, NG76LB. If you have any queries relating to our use of your personal information, or any other related data protection questions, please contact our Customer Services Team at Office@swingdriver.com.


        This Privacy Policy explains how we will use personal information about you and the steps we take to ensure your personal information is kept secure and confidential. It should be read together with our Terms of Use.


        How do we collect personal information?


        We may obtain personal information from you through the mobile applications or other similar devices, channels or applications operated by or on behalf of any of the following brands (referred to collectively in this Privacy Policy as the "Sites"): Swing Driver mobile application.


        Direct Software Group Ltd may also share your personal information, but only if you have given your informed consent for it to do so whilst using Swing Driver mobile application. This information will be used to speed up form filling, or to personalise, or improve your experience when using the Sites. Other than this, Direct Software Group Ltd does not share your personal information with 3rd parties.


        What personal information do we collect?


        We collect personal information about you when you give this to us in the course of registering for and/or using our Services via any of the Sitesfor example we may collect your name, address, email address or telephone number, along with your driver information. This will be used to enable you to use the Swing Driver system. In the course of providing the Services to you, we may also store information about how you use the mobile application and store information to improve repeat use of the application. We make sure that we have appropriate security measures to protect your information (see - How secure is our site and what steps do we take to keep you safe? below).  We will periodically review your personal information to ensure that we do not keep it for longer than is permitted by law.

        Note that it is your responsibility to check and ensure that all information, content, material or data you provide on the sites is correct, complete, accurate and not misleading and that you disclose all relevant facts.


        We do not knowingly collect or store any personal information about children under the age of 16.  This application is to be used by people of the legal driving age. The minimum age to hold a full driving licence is currently 17 in the United Kingdom.


        If you are providing us with another person's information you should first, ask them to read this Privacy Policy and our Terms of Use. By giving us information about another person you are confirming that they have given you consent to provide the information to us and that they understand how their details will be used.


        In order to ensure the Services we provide you continue to meet your needs we may ask you for feedback on your experience of using the Sites. Any feedback you provide will only be used as part of our programme of continuous improvement and will not be published on the Sites.



        How do we use your personal information?


        We may use your personal information as follows:


        To enable you to access and use the Services
        To personalise and improve aspects of our Services
        For research, such as analysing market trends and customer demographics
        To communicate with you, by sending you information about products and services which we think may be of interest to you - If you agree, we will contact you (depending on your contact preferences) via email, post, telephone, sms, or by other electronic means such as via social and digital media this may include new product launches, newsletters and opportunities to participate in market research
        To track users, which may involve us sharing data with the Direct Software Group Ltd
        To match our data with data from other sources - we may validate and analyse your information and, in some cases, match it against information that has been collected by a third party to ensure that the information we hold about you is as accurate, consistent and well-organised as possible. As well as ensuring that any marketing material that we send you is appropriate to your needs, this process also ensures that our services continue to be as personalised and focused as possible.
        To enable you to use our smart search services
        To enable telematics - if you have chosen to have a telematics in-tele-box fitted to your car and/or to download a telematics app to your mobile device, this will collect a wide range of driving information.  Our providers may use the telematics data in a number of ways, as defined in their privacy policies or terms and conditions.  The police and certain statutory and/or authorised bodies may request information collected via the telematics device/app to help them with road accident investigations and the prevention or detection of crime. Such information may currently only be obtained via an application to the Courts and we will not provide any information to these organisations except when subject to a Court Order or with your consent


        We will store the personal information you provide and may use it to pre-populate fields on the Sites and to make it easier for you to use the Sites when making return visits. If you do not want us to store and use your personal information in this way, please email us Office@swingdriver.com and we will delete your personal information.


        We may monitor or record your calls, emails, sms or other communications but we will do so in accordance with data protection legislation and other applicable law. Monitoring or recording will always be for business purposes, such as for quality control and training (e.g. where you call our customer services help line), to prevent unauthorised use of our telecommunication systems and Sites, to ensure effective systems operation, to meet any legal obligation and/or to prevent or detect crime.


        Why do we possess personal information?


        We will only collect and use your personal information in accordance with data protection laws.  Our grounds for processing your personal information are as follows:


        1.     Consent – Where necessary we will only collect and process your personal information if you have given your consent for us to do so, for example, we will only send you certain marketing emails and process any sensitive information about you if we have your consent


        2.     Legitimate Interests – We may use and process some of your personal information where we have sensible and legitimate business grounds for doing so.  Under European privacy laws there is a concept of “legitimate interests” as a justification for processing your personal information.  Our legitimate interests for processing your personal information are:


        To enable you to access and use the Services of Swing Driver. Using your information in this way is an essential part of us being able to provide the Services to you
        To communicate with you about the Services
        To improve our Services - We may use your personal information to personalise aspects of our service, for market research and to use our smart search services.  We constantly aim to improve our Services to you and using your personal information in this way helps us to do this.

        You have a right to object to our use of your personal information for these legitimate interests. If you raise an objection we will stop processing your personal information unless very exceptional circumstances apply, in which case we will let you know why we are continuing to process your personal information.  Please contact our Support Team at Office@swingdriver.com if you wish to exercise this right.


        Who do we share your personal information with?


        When you use any of our Services, we may disclose your personal information to the following parties:


        -      Other members of the Direct Software Group Ltd

        -      Swing Driver mobile app : (i) to communicate with you, including (if you consent) sending you information about products and services which may be of interest to you; (ii) to speed up form filling, or to personalise, or improve your experience on its website, mobile applications or other similar devices, channels or applications; or (iii) in accordance with its privacy policy


        Where permitted by data protection and privacy law, we may also disclose information about you (including electronic identifiers such as IP addresses) and/or access your account:


        If required or permitted to do so by law
        If required to do so by any court, the Financial Conduct Authority, the Competition and Markets Authority or any other applicable regulatory, compliance, Governmental or law enforcement agency
        If necessary in connection with legal proceedings or potential legal proceedings; and/or in connection with the sale or potential sale of all or part of our business
        If we reasonably believe false or inaccurate information has been provided and fraud is suspected, details may be passed to fraud prevention agencies to prevent fraud and money laundering


        What cookies do we use?


        A cookie is a very small text file placed on your computer or device. Cookies help us to:


        Understand browsing habits on the Sites
        Understand the number of visitors to the Sites and the pages visited
        Remember you when you return to the Sites

        We currently don’t use Cookies with the mobile application. If a Site is added at a later time a cookie policy document will be available.


        How secure is your data and what steps do we take to keep your data secure?


        Your personal data’s security is very important to us. This is why, where it’s appropriate, our Sites use HTTPS to help keep information about you secure. However, no data transmission over the internet can be guaranteed to be totally secure. We don’t take payments or payment details via the Sites or mobile application.


        You may complete a registration process when you sign up to use parts of the Sites. This may include the creation of a username, password and/or other identification information. Any such details should be kept confidential by you and should not be disclosed to or shared with anyone. Where you do disclose any of these details, you are solely responsible for all activities undertaken on the Sites where they are used. To protect your account, we ask you to choose a strong password to access your information on our Sites. A strong password should be lengthy and include a mixture of letters and numbers. Your password can only be reset with access to the email address registered in our system.


        We do our best to keep the information you disclose to us secure. However, we can't guarantee or warrant the security of any information which you send to us, and you do so at your own risk. By using our Sites you accept the inherent risks of providing information online and will not hold us responsible for any breach of security.

        

        How can you amend preferences?


        Any electronic marketing communications we send you will include clear and concise instructions to follow should you wish to unsubscribe at any time. You may also amend your marketing preferences by email Office@swingdriver.com.


        Should you no longer wish to be contacted by us, you can advise us at any time by contacting our Customer Services team by sending an email to Office@swingdriver.com.


        Your personal data rights?


        You have certain rights under existing data protection legislation including the right to request a copy of the personal information we hold about you, if you request it from us in writing.


        You have the following rights:


        (i)     Right to access: The right to request copies of your personal information from us


        (ii)   Right to correct: The right to have your personal information rectified if it is inaccurate or incomplete


        (iii)  Right to erase: The right to request that we delete or remove your personal information from our systems


        (iv)  Right to restrict our use of your information: The right to ‘block’ us from using your personal information or limit the way in which we can use it


        (v)    Right to data portability: The right to request that we move, copy or transfer your personal information


        (vi)  Right to object: The right to object to our use of your personal information including where we use it for our legitimate interests or where we use your personal information to carry out profiling to inform our market research and customer demographics.


        To make enquiries, exercise any of your rights set out in this Privacy Policy and/or make a complaint please contact our Support team at: Office@swingdriver.com


        If you are not satisfied with the way any complaint you make in relation to your personal information is handled by us, then you may be able to refer your complaint to the relevant data protection regulator which in the UK is the Information Commissioner’s Office.


        This Privacy Policy shall be governed and construed in all respects in accordance with the laws of the United Kingdom.


        How long do we keep your data for?


        Unless a longer retention period is required or permitted by law, we will only hold your personal information on our systems for the period necessary to fulfil the purposes outlined in this Privacy Policy or until you request it is deleted. If, having registered for any of our Services, you do not use them for a reasonable time (which may vary depending on the Service(s) you’ve registered for) we may contact you to ensure you’re still happy to receive communications from us.  Even if we delete your personal information it may persist on backup or archival media for legal, tax or regulatory purposes.


        Changes to this privacy policy


        We reserve the right to amend or modify this Privacy Policy at any time and any changes will be published on the Sites.  The date of the most recent revision will appear on this page.  If we make significant changes to this policy, we may also notify you by other means such as sending an email.  Where required by law we will obtain your consent to make these changes.  If you do not agree with any changes, please do not continue to use the Sites.



        (Last updated November 2020)



        Company Registration No. 10115040

        Co-Founders/ Directors: R.E. Storey | D.L. Stringfellow

        Registered Office: Sherwood House, 7 Gregory Boulevard, Nottingham, NG7 6LB

</p>
      </div>
    </div>

  )
}

export default Privacy;