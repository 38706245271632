import React from "react";
import { useUnseenAlerts } from "../../hooks/useUnseenAlerts";
import BannerAlert from "../alerts/banner-alert.component";

export default function PendingAlertBanner() {
  return (
    <BannerAlert
      hook={useUnseenAlerts}
      type={"WARNING"}
      message={
        "There is one or more failed compliance submissions that has not been viewed by the office."
      }
      link={"/customer/requests"}
    ></BannerAlert>
  );
}
