import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LoadingSpinner from "../loading/loading-spinner.component";
import DefaultModal from "../modal/modal.component";
import DriverService from "../../services/driver.service";
import "./drivers.css";
import DriverJobList from "../jobs/driver-job-list.component";
import EditDriver from "./edit-driver.component";
import DriverClassTable from "./driver-class-table.component";
// @ts-expect-error TS(2691): An import path cannot end with a '.tsx' extension.... Remove this comment to see the full error message
import BasicTable from "./basic-table.component.tsx";
import SetDriverRating from "./set-driver-rating.component";
import SetDriverCategory from "./set-driver-category.component";
import DriverClassUpdatesByDriver from "./driver-class-updates-by-driver.component";
import driverService from "../../services/driver.service";
import ResetPassword from "../users/reset-password-component";
const MySwal = withReactContent(Swal);

type ConfirmationButtonProps = {
  confirmationTitle: string;
  onConfirm: () => void;
  confirmationText?: string;
  icon?: "warning" | "error" | "success" | "info" | "question";
  confirmButtonText?: string;
  btnClass: string;
  btnText: string;
};

export function ConfirmationButton(props: ConfirmationButtonProps) {
  const {
    confirmationTitle,
    onConfirm,
    confirmationText,
    icon,
    confirmButtonText,
  } = props;
  const handleClick = () => {
    MySwal.fire({
      title: confirmationTitle,
      text: confirmationText,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirmButtonText,
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm();
      }
    });
  };
  return (
    <div className="col">
      <button className={props.btnClass} type="button" onClick={handleClick}>
        {props.btnText}
      </button>
    </div>
  );
}

export default function ViewDriver({ driver }: any) {
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const setOfficeDisabled = (id: any, disabled: any) => {
    MySwal.fire({
      title: "Are you sure?",
      text: `This will override the current drivers licence value. ${
        disabled == true
          ? "If disabled, the driver will not be able to drive a vehicle of this class. If this driver is on a job matching this class, they will be removed from the job."
          : "If enabled, the driver will be able to drive a vehicle of this class."
      }`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "I'm sure.",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        DriverService.overrideLicenceClass(id, disabled).then(
          (response) => {
            setLoading(false);
            MySwal.fire({
              title: "Licence Class Successfully Updated",
              text: "Please ensure any effected jobs are covered. ",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
            // @ts-expect-error TS(2769): No overload matches this call.
            queryClient.invalidateQueries("drivers");
          },
          (error) => {
            setLoading(false);
            MySwal.fire({
              title: "Error",
              text: error.response.data,
              icon: "error",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
          }
        );
      }
    });
  };

  const terminateDriver = (id: any) => {
    setLoading(true);
    DriverService.terminateDriver(id).then(
      (response) => {
        // @ts-expect-error TS(2769): No overload matches this call.
        queryClient.invalidateQueries("drivers");
        setLoading(false);
        MySwal.fire({
          title: "User Account Deactivated",
          icon: "success",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
      },
      (error) => {
        setLoading(false);
        MySwal.fire({
          title: "Error",
          text: error.response.data,
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
      }
    );
  };

  const toggleVisibility = (id: any) => {
    setLoading(true);
    DriverService.toggleVisibility(id).then(
      (response) => {
        // @ts-expect-error TS(2769): No overload matches this call.
        queryClient.invalidateQueries("drivers");
        setLoading(false);
        MySwal.fire({
          title: "Visibility Toggled",
          icon: "success",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
      },
      (error) => {
        setLoading(false);
        MySwal.fire({
          title: "Error",
          text: error.response.data,
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
      }
    );
  };

  const activateDriver = (id: any) => {
    setLoading(true);
    DriverService.activateDriver(id).then(
      (response) => {
        // @ts-expect-error TS(2769): No overload matches this call.
        queryClient.invalidateQueries("drivers");
        setLoading(false);
        MySwal.fire({
          title: "User Account Activated",
          icon: "success",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
      },
      (error) => {
        setLoading(false);
        MySwal.fire({
          title: "Error",
          text: error.response.data,
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
      }
    );
  };

  const exportDriver = (driverId: any) => {
    setLoading(true);
    driverService
      .exportDriverDetails(driverId)
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "driver-export.pdf");
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoading(false);
      })
      .catch((error) => {
        MySwal.fire({
          title: "Error",
          text: "Error exporting - please contact support.",
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
        setLoading(false);
      });
  };

  const getDriverClasses = (classes: any) => {
    return classes.map((c: any) => c.class.name).join(", ");
  };

  return (
    <div>
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <>
          <DefaultModal
            size={"xl"}
            title={"View Driver"}
            trigger={"View Driver"}
            content={
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="driver-container">
                      <p className="section-title">Driver information</p>
                      <p className="label">Driver Id: {driver.id}</p>
                      <div className="row">
                        <div className="col">
                          <small>{driver.id}</small>
                          <p className="label">Name</p>
                          <p className="value">{driver.name}</p>
                          {(driver.isPayee || driver.isTeamLeader) && (
                            <p>
                              {driver.isTeamLeader && (
                                <span className="badge bg-primary">
                                  Team Leader
                                </span>
                              )}
                              {driver.isPayee && (
                                <span className="badge bg-primary">
                                  Full Time
                                </span>
                              )}
                            </p>
                          )}
                        </div>
                        <div className="col">
                          <p className="label">Telephone</p>
                          <p className="value">{driver.telephoneNumber}</p>
                        </div>
                        <div className="col">
                          <p className="label">Email</p>
                          <p className="value">{driver.email}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <p className="label">Licence Number</p>
                          <p className="value">{driver.licenceNumber}</p>
                        </div>
                        <div className="col">
                          <p className="label">Licence Classes</p>
                          <p className="value">
                            {getDriverClasses(driver.licenceClasses)}
                          </p>
                        </div>
                        <div className="col">
                          <p className="label">Job Count</p>
                          <p className="value">{driver.jobCount}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <p className="label">Rating</p>
                          <p className="value">{driver.rating}</p>
                        </div>
                        <div className="col-md-4">
                          <p className="label">Status</p>
                          <p className="value">{driver.status}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="row">
                  <div className="col">
                    <EditDriver
                      driver={driver}
                      onComplete={() =>
                        // @ts-expect-error TS(2769): No overload matches this call.
                        queryClient.invalidateQueries("drivers")
                      }
                    ></EditDriver>
                  </div>

                  <div className="col">
                    <ResetPassword
                      userId={driver.userId}
                      setLoading={setLoading}
                    />
                  </div>

                  <ConfirmationButton
                    confirmationTitle={"Are you sure?"}
                    onConfirm={() => toggleVisibility(driver.id)}
                    confirmationText={
                      driver.quickSelectEnabled
                        ? "This will hide the user from quick select buttons and will only be findable by name"
                        : "This will add the user back to the quick select buttons."
                    }
                    icon={"info"}
                    confirmButtonText={"Toggle"}
                    btnClass={
                      driver.quickSelectEnabled
                        ? "btn btn-warning"
                        : "btn btn-success"
                    }
                    btnText={
                      driver.quickSelectEnabled
                        ? "Disable Quick Select"
                        : "Enable Quick Select"
                    }
                  />

                  {driver.status == "ACTIVE" && (
                    <ConfirmationButton
                      confirmationTitle={"Are you sure?"}
                      onConfirm={() => terminateDriver(driver.id)}
                      confirmationText={
                        "This will block the user from accessing the app and they will no longer show up in available driver lists."
                      }
                      icon={"info"}
                      confirmButtonText={"Terminate Driver"}
                      btnClass={"btn btn-danger"}
                      btnText={"Terminate Driver"}
                    />
                  )}

                  {driver.status == "TERMINATED" && (
                    <ConfirmationButton
                      confirmationTitle={"Are you sure?"}
                      onConfirm={() => activateDriver(driver.id)}
                      confirmationText={
                        "This will permit the driver access to the app and they will show up in pending driver lists."
                      }
                      icon={"info"}
                      confirmButtonText={"Terminate Driver"}
                      btnClass={"btn-danger"}
                      btnText={"Terminate Driver"}
                    />
                  )}

                  <div className="col">
                    <button
                      className="btn btn-primary"
                      onClick={() => exportDriver(driver.id)}
                    >
                      Driver Export
                    </button>
                  </div>

                  <div className="col">
                    <SetDriverRating id={driver.id}></SetDriverRating>
                  </div>

                  <div className="col">
                    <SetDriverCategory driver={driver}></SetDriverCategory>
                  </div>
                </div>
                <hr />
                <DriverClassTable
                  onSetOverride={setOfficeDisabled}
                  licenceClasses={driver.licenceClasses}
                ></DriverClassTable>
                <BasicTable
                  title={"Driver Endorsements"}
                  values={driver.endorsements}
                  action={undefined}
                  actionText={""}
                ></BasicTable>
                <DriverClassUpdatesByDriver
                  driverId={driver.id}
                ></DriverClassUpdatesByDriver>
                <DriverJobList id={driver.id}></DriverJobList>
              </div>
            }
          ></DefaultModal>
        </>
      )}
    </div>
  );
}
