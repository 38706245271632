import React, { useState } from "react";
import Status from "./status.component";
import JobService from "../../services/job.service";
import LoadingSpinner from "../loading/loading-spinner.component";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export default function PaymentStatus({ status, jobId }: any) {
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const markApproved = (id: any) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "This will set the job invoice status to approved. Please only do this if you are authorised to do so as it may effect accounting.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, mark approved!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        JobService.setPaymentStatusApproved(id).then(
          () => {
            setLoading(false);
            // @ts-expect-error TS(2769): No overload matches this call.
            queryClient.invalidateQueries("jobs");
            MySwal.fire({
              title: "Success",
              text: "Job payment status updated.",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
          },
          (error) => {
            setLoading(false);
            MySwal.fire({
              title: "Error",
              text: error.response.data,
              icon: "error",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
          }
        );
      }
    });
  };

  return (
    <div className="payment-status-container">
      <label className="job-label">Payment Status</label>
      <Status status={status}></Status>
      {status != "APPROVED" && (
        <button
          type="button"
          className="btn btn-primary"
          disabled={loading}
          onClick={() => markApproved(jobId)}
        >
          Invoice Approved
        </button>
      )}
      {loading && <LoadingSpinner></LoadingSpinner>}
    </div>
  );
}
