import api from "./network";

import { CustomerJobRequestDto } from "../contracts/models/CustomerJobRequestDto";
import { CustomerJobRequestResponseDto } from "../contracts/models/CustomerJobRequestResponseDto";

const API_URL = "CustomerRequestManagement";

class CustomerRequestManagementService {

  async listCustomerRequests() : Promise<CustomerJobRequestDto[]>{
    var r = await api.get(`${API_URL}`);
    return r.data;
  }


  async getCustomerRequestById(id: number) : Promise<CustomerJobRequestResponseDto> {
    var r = await api.get(`${API_URL}/${id}`);
    return r.data;
  }

  allocateSwingToCustomerRequest(requestId: number, swingId: number) {
    return api.post(`${API_URL}/allocate`, {
      RequestId: requestId,
      SwingId: swingId,
    });
  }
}

export default new CustomerRequestManagementService();