import React from "react";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/userContext";
import PendingAlertBanner from "../compliance/pending-alert-banner.component";
import JobRemovalAlertBanner from "../jobs/job-removal-alert.component";

import "./header.css";
import { usePermissions } from "../../hooks/usePermissions";
import PendingCustomerRequests from "../customer-requests/pending-customer-requests-banner";

function Header(props: any) {
  const { authToken, setAuthToken, userType } = useAuth();

  // An example on how to use permissions
  const permissions = usePermissions(authToken || "").data || [];
  const logOut = () => {
    setAuthToken("");
  };

  return (
    <>
      <Navbar
        expand="lg"
        className="navbar navbar-expand-lg navbar-dark navbar-blue"
      >
        <Link to={"/"} className="navbar-brand">
          <img className="logo" src="/images/logo.png" alt="Logo" />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {authToken && userType === "Customer" && (
              <div className="navbar-nav mr-auto">
                <li className="nav-item">
                  <Link to={"/"} className="dropdown-top-level">
                    Dashboard
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/request/create"} className="nav-link">
                    Create Request
                  </Link>
                </li>
              </div>
            )}

            {authToken && userType === "Administrator" && (
              <div className="navbar-nav mr-auto">
                <li className="nav-item">
                  <Link to={"/"} className="dropdown-top-level">
                    Dashboard
                  </Link>
                </li>

                <li className="nav-item">
                  <Dropdown>
                    <Dropdown.Toggle
                      as={CustomToggle}
                      className="dropdown-top-level"
                    >
                      Create
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to={"/swing/add"}>
                        Job Group
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to={"/job/add"}>
                        Driver Job
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>

                <li className="nav-item">
                  <Dropdown>
                    <Dropdown.Toggle
                      as={CustomToggle}
                      className="dropdown-top-level"
                    >
                      Manage
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to={"/drivers"}>Drivers</Dropdown.Item>
                      <Dropdown.Item as={Link} to={"/expenses"}>Expenses</Dropdown.Item>
                      <Dropdown.Item as={Link} to={"/compliance/alerts"}>Compliance Alerts</Dropdown.Item>
                      <Dropdown.Item as={Link} to={"/compliance/submissions"}>Questionnaire Submissions</Dropdown.Item>
                      <Dropdown.Item as={Link} to={"/customer/requests"}>Customer Requests</Dropdown.Item>
                      <Dropdown.Item as={Link} to={"/users/customers"}>Customer Users</Dropdown.Item>
                      <Dropdown.Item as={Link} to={"/fleet/breakdown"}>Fleet Breakdown</Dropdown.Item>
                      <Dropdown.Item as={Link} to={"/overtime"}>Overtime Approvals</Dropdown.Item>
                      <Dropdown.Item as={Link} to={"/mileage"}>Pending Mileage</Dropdown.Item>
                      <Dropdown.Item as={Link} to={"/driver/hours"}>Driver Hours</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>

                <li className="nav-item">
                  <Dropdown>
                    <Dropdown.Toggle
                      as={CustomToggle}
                      className="dropdown-top-level"
                    >
                      Assets
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to={"/vehicles"}>
                        Vehicles
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to={"/customers"}>
                        Customers
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to={"/address"}>
                        Addresses
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to={"/contacts"}>
                        Contacts
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>

                <li className="nav-item">
                  <Link to={"/reporting"} className="dropdown-top-level">
                    Reporting
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/admin"} className="dropdown-top-level">
                    Admin
                  </Link>
                </li>
              </div>
            )}

            {!authToken && (
              <div className="navbar-nav mr-auto">
                <li className="nav-item">
                  <Link to={"/"} className="dropdown-top-level">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/about"} className="dropdown-top-level">
                    About
                  </Link>
                </li>
              </div>
            )}
          </Nav>
          <Nav className="ml-auto">
            {authToken ? (
              <div className="navbar-nav ml-lg-auto">
                <li className="nav-item">
                  <Link
                    to={"/login"}
                    onClick={logOut}
                    className="dropdown-top-level"
                  >
                    Log Out
                  </Link>
                </li>
              </div>
            ) : (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to={"/login"} className="dropdown-top-level">
                    Login
                  </Link>
                </li>
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {authToken && userType === "Administrator" && (
        <>
          <PendingAlertBanner />
          <PendingCustomerRequests />
          <JobRemovalAlertBanner />
        </>
      )}
    </>
  );
}

// @ts-expect-error TS(2339): Property 'children' does not exist on type '{}'.
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    className="dropdown-top-level"
    href="/"
    // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </a>
));

export default Header;
