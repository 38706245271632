import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Select, FileInput, NumberInput, Checkbox} from "@mantine/core";
import { ExpenseDto } from "../../contracts/models/ExpenseDto";
import { JobDto } from "../../contracts/models/JobDto";
import { VehicleMoveDto } from "../../contracts/models/VehicleMoveDto";
import ExpensesService from "../../services/expenses.service";
import { ChangeEvent } from "react";

const AddOrEditExpenseSchema = Yup.object().shape({
  jobId: Yup.number().required("Job is required"),
  vehicleMoveId: Yup.number().nullable(),
  vehicleRegistration: Yup.string().when('vehicleMoveId', {
    is: (vehicleMoveId: number | null | undefined) => !vehicleMoveId,
    then: (schema) => schema.required('Vehicle registration is required when no vehicle move is selected'),
    otherwise: (schema) => schema.nullable(),
  }),
  amount: Yup.number()
    .required("Amount is required")
    .min(0, "Amount must be positive"),
  expenseDescription: Yup.string().required("Description is required"),
  file: Yup.mixed().required("File is required"),
  type: Yup.string().required("Type is required"),
  vatCharged: Yup.boolean()
    .required("VAT charged is required")
});

interface AddOrEditExpenseProps {
  job: JobDto;
  vehicleMoves: VehicleMoveDto[];
  onSubmit: (expense: ExpenseDto) => void;
}

export default function AddOrEditExpense({
  job,
  vehicleMoves,
  onSubmit,
}: AddOrEditExpenseProps) {
  const [file, setFile] = useState<File | null>(null);

  console.log(vehicleMoves)

  const expenseTypes = [
    { value: "FUEL", label: "Fuel" },
    { value: "FUELCARD", label: "Fuel Card" },
    { value: "MAINTENANCE", label: "Maintenance" },
    { value: "OTHER", label: "Other" },
  ];

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <Formik
              initialValues={{
                jobId: job.id,
                vehicleMoveId: null,
                vehicleRegistration: "",
                amount: 0,
                expenseDescription: "",
                file: null,
                type: "FUEL",
                vatCharged: false,
              }}
              validationSchema={AddOrEditExpenseSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  const expenseDto: ExpenseDto = {
                    ...values,
                    file,
                    vehicleMoveId: values.vehicleMoveId || null,
                    vatCharged: values.vatCharged || false,
                  };
                  await ExpensesService.addExpense(expenseDto);
                  onSubmit(expenseDto);
                  resetForm();
                  setFile(null);
                } catch (error) {
                  console.error("Error adding expense:", error);
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              {({ errors, touched, setFieldValue, isValid, dirty }) => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="vehicleMoveId">
                      Vehicle Move (Optional)
                    </label>
                    <Select
                      data={vehicleMoves.map((vm) => ({
                        value: vm?.id?.toString() || "",
                        label: `${
                          vm.vehicle
                            ? `${vm.vehicle.type} - ${vm.vehicle.registration}${
                                vm.secondaryVehicle
                                  ? ` / ${vm.secondaryVehicle.type} - ${vm.secondaryVehicle.registration}`
                                  : ''
                              }`
                            : `${vm.vehicleType}${
                                vm.secondaryVehicleType ? ` / ${vm.secondaryVehicleType}` : ''
                              }`
                        } - ${vm.collectionAddress?.city || 'Unknown'} to ${
                          vm.deliveryAddress?.city || 'Unknown'
                        }`,
                      }))}
                      onChange={(value) =>
                        setFieldValue(
                          "vehicleMoveId",
                          value ? parseInt(value) : null
                        )
                      }
                      clearable
                      placeholder="Select a vehicle move"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="vehicleRegistration">
                      Vehicle Registration
                    </label>
                    <Field
                      name="vehicleRegistration"
                      className="form-control"
                      placeholder="Enter vehicle registration if no move selected"
                    />
                    {errors.vehicleRegistration &&
                      touched.vehicleRegistration && (
                        <div className="alert alert-danger">
                          {errors.vehicleRegistration}
                        </div>
                      )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="amount">Amount</label>
                    <Field
                      name="amount"
                      type="number"
                      className="form-control"
                      placeholder="Enter amount"
                    />
                    {errors.amount && touched.amount && (
                      <div className="alert alert-danger">{errors.amount}</div>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="expenseDescription">Description</label>
                    <Field
                      name="expenseDescription"
                      as="textarea"
                      className="form-control"
                      placeholder="Enter description"
                    />
                    {errors.expenseDescription && touched.expenseDescription && (
                      <div className="alert alert-danger">
                        {errors.expenseDescription}
                      </div>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="file">File</label>
                    <FileInput
                      placeholder="Upload file"
                      onChange={(file) => {
                        setFile(file);
                        setFieldValue("file", file);
                      }}
                    />
                    {errors.file && touched.file && (
                      <div className="alert alert-danger">{errors.file}</div>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="type">Type</label>
                    <Select
                      data={expenseTypes}
                      onChange={(value) => setFieldValue("type", value)}
                      required
                    />
                    {errors.type && touched.type && (
                      <div className="alert alert-danger">{errors.type}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="vatCharged">VAT Charged</label>
                    <Checkbox
                      name="vatCharged"
                      onChange={(value: ChangeEvent<HTMLInputElement>) => setFieldValue("vatCharged", value.target.checked)}
                    />
                    {errors.vatCharged && touched.vatCharged && (
                      <div className="alert alert-danger">
                        {errors.vatCharged}
                      </div>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={!isValid || !dirty}
                  >
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
