import CustomerService from "../services/customer.service";
import CustomerJobRequestService from "../services/customer-request.service";
import { useQuery } from "@tanstack/react-query";
import { CustomerLabelsDto } from "../contracts/models/CustomerDto";
import customerService from "../services/customer.service";

export function useCustomerLabels(enabled: boolean = true, isCustomer: boolean = false, short: boolean = false) {
  return useQuery<CustomerLabelsDto[]>({
    queryKey: ["customers"],
    queryFn: async () => {
      const response = await (isCustomer ? CustomerJobRequestService.listCustomers() : customerService.getCustomers());
      if (response.data.length > 0) {
        return response.data.map((customer: any) => ({
          value: customer.id,
          label: customer.name + (short ? ` - ${customer.email}` : ""),
        }));
      }
      return [] as CustomerLabelsDto[];
    },
    enabled,
  });
}

export function useCustomers(enabled: boolean = true, isCustomer: boolean = false) {
  return useQuery({
    queryKey: ["customers"],
    queryFn: async () => {
      const response = await CustomerService.getCustomers();
      if (response.data.length > 0) {
        return response.data;
      }
      return [];
    },
    enabled,
  });
}

