import React from "react";
import VehicleImage from "./vehicle-image.component";
import "./Vehicles.css";

export default function VehicleImageType({
  type,
  images
}: any) {
  let moveImages;
  if (images) {
    moveImages = images
      .filter((i: any) => i.type.indexOf(type) != -1)
      .map((i: any) => <VehicleImage key={i.name} image={i}></VehicleImage>);
  }

  const typeName = type == "COLLECT" ? "Collection Images" : "Delivery Images";

  return (
    <div className="row">
      <div className="col">
        {moveImages.length > 0 && (
          <>
            <h3>
              {typeName}
            </h3>
            {moveImages}
          </>
        )}
        {moveImages.length == 0 && <p>No {typeName} uploaded</p>}
      </div>
    </div>
  );
}
