import React from "react";
import { useAuth } from '../../context/userContext';
import ListJobRequest from "../customer-requests/list-job-requests";
import Dashboard from "../dashboard/dashboard.component";

function Home(props: any) {
  return (
    <div>
        <header className="jumbotron centeredBox">
          <div className="dash-guest">
            <h3>Please login to access the SwingDriver System</h3>
            <h4>If you are a driver, please download the SwingDriver apps using the links below!</h4>

            <div className="row badges">
              <div className="col-md-6">
                <img
                  src='/images/google-play-badge.png'
                  alt="google-store"
                  className="store-banner"
                />
              </div>
              <div className="col-md-6">
                <img
                  src='/images/apple-badge.png'
                  alt="apple-store"
                  className="store-banner"
                />
              </div>
            </div>
          </div>
        </header>
    </div>
  )
}

export default Home;