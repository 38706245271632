import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import customerManagementService from "../../services/customer-management.service";
import SelectFromAllCustomerRequests from "./select-all-customer-requests.component";

const MySwal = withReactContent(Swal);

export default function SelectSwingCustomerRequest({
  swingId,
  customerRequestId,
}: {
  swingId: number;
  customerRequestId?: number;
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (requestId: number) =>
      customerManagementService.allocateSwingToCustomerRequest(requestId, swingId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["swing", swingId] });
      MySwal.fire({
        title: "Success",
        text: "Your Swing was allocated to the customer request successfully",
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        // Uncomment and update the following lines if you need to handle confirmation
        // if (result.isConfirmed) {
        //   window.location.href = "/add-new-job-request"; // Redirect to the add-new-job-request page
        // }
      });
    },
    onError: (error) => {
      alert("Failed to allocate swing to customer request: " + error);
    },
  });

  return (
    <div>
      <p>Set a new customer request to associate with this Swing</p>
      <SelectFromAllCustomerRequests
        selectRequest={(requestId: number) => mutation.mutate(requestId ?? 0)}
        defaultRequest={customerRequestId}
      />
    </div>
  );
}
