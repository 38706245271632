import React from "react";
import { useVehicleJobs } from "../../hooks/useVehicleJobs";
import FilterJobReference from "../jobs/filter-job-reference";
import VehicleExpenseList from "../jobs/vehicle-expense-list.component";
import LoadingSpinner from "../loading/loading-spinner.component";
import ViewVehicleImageList from "./view-vehicle-image-list.component";

export function ViewVehicleJobInformation({ id }: { id: number }) {
  const [jobReference, setJobReference] = React.useState("");
  const {
    data: vehicleJobs,
    isLoading,
    refetch,
  } = useVehicleJobs(id, jobReference);

  return (
    <>
      <div className="vehicle-container">
        <FilterJobReference
          jobReference={jobReference}
          setJobReference={setJobReference}
          onSubmit={() => {
            refetch();
          }}
        ></FilterJobReference>
        {isLoading && <LoadingSpinner></LoadingSpinner>}
      </div>
      {!isLoading && (
        <>
          <VehicleExpenseList
            id={id}
            jobReference={jobReference}
          ></VehicleExpenseList>
          <ViewVehicleImageList
            id={id}
            vehicleJobs={vehicleJobs}
          ></ViewVehicleImageList>{" "}
        </>
      )}
    </>
  );
}
