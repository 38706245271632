import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import VehicleImageType from "./vehicle-image-type.component";
import VehicleImage from "./vehicle-image.component";
import "./Vehicles.css";

export default function ViewMoveImages({ vehicleImages }: any) {
  const [modalShow, setModalShow] = useState(false);

  function getType(type: any) {
    if (type === 1) return "Vehicle";
    else if (type === 2) return "Trailer";
    else if (type === 3) return "Minibus";
    else return "Unknown";
  }

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            View Vehicle Move Images
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="vehicle-container">
            <VehicleImageType
              type="COLLECT"
              images={vehicleImages}
            ></VehicleImageType>
            <VehicleImageType
              type="DELIVER"
              images={vehicleImages}
            ></VehicleImageType>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => setModalShow(true)}
      >
        View Images
      </button>
    </>
  );
}
