import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import Select from "react-select";

export default function SelectContact({
  contacts,
  onChange,
  defaultContact,
}: any) {
  useEffect(() => {}, []);

  return (
    <>
      <Select
        onChange={(option: any) => {
          onChange(option.value);
        }}
        options={contacts}
        isClearable={false}
        name="contactId"
        defaultValue={
          contacts
            ? contacts.find((v: any) => v.value === defaultContact)
            : null
        }
      />
      <p className="input-note">
        The contact list auto-updates can be
        maintained via the <Link to={"/contacts"}>Contacts</Link> page.
      </p>
    </>
  );
}
