import React from "react";
import LoadingSpinner from "../loading/loading-spinner.component";
import { useVehicleImage } from "../../hooks/useVehicleImage";
import "./Vehicles.css";
import Moment from "react-moment";

export default function VehicleImage({ image }: any) {
  const { data: imageSrc, isLoading } = useVehicleImage(image.name);

  function getType(type: any) {
    switch (type.substring(type.indexOf("_") + 1, type.length)) {
      case "DRIVER":
        return "Drivers side";
      case "PASSENGER":
        return "Passengers side";
      case "REAR":
        return "Rear";
      case "FRONT":
        return "Front";
      default:
        return "Image";
    }
  }

  return (
    <div className="row">
      <div className="col">
        {isLoading && <LoadingSpinner></LoadingSpinner>}
        {!isLoading && imageSrc && (
          <div className="image-container">
            <h3>{getType(image.type)}</h3>
            <img
              className="vehicle-image"
              src={imageSrc}
              alt="Vehicle Image"
            ></img>
            <p>
              <Moment
                format="DD/MM/YYYY HH:mm"
                date={new Date(image.createdAt)}
              />
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
