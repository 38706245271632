import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jobService from "../../services/job.service";
import { useQueryClient } from "@tanstack/react-query";
import { useSuccessSwal } from "../../hooks/useSuccessSwal";
const OverrideJobTimeSchema = Yup.object().shape({
  jobId: Yup.string().required("JobId is required"),
  overrideTime: Yup.string()
    .required("Field is required")
    .min(2, "Field is too short")
    .max(300, "Field is too long"),
});

export default function OverrideJobTime({
  label,
  jobId,
  isCheckIn,
  value,
}: any) {
  console.log(value);

  const initialValues = {
    jobId,
    overrideTime: new Date(value),
  };

  const swal = useSuccessSwal();

  const queryClient = useQueryClient();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={OverrideJobTimeSchema}
      onSubmit={(values, { resetForm }) => {
        console.log("submit", values);
        // resetForm();
        jobService
          .overrideJobTimes(jobId, isCheckIn, values.overrideTime.toISOString())
          .then(() => {
            queryClient.invalidateQueries({ queryKey: ["jobs", jobId]});
            swal.execute("Success", "Job time has been updated", "success");
          })
          .catch((err) => {
            console.log(err);
            swal.execute(
              "Error",
              err?.response?.data ?? "Error saving value",
              "error"
            );
          });
      }}
    >
      {({ values, errors, touched, setFieldValue, isValid, dirty }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="name">Edit {label}</label>
            <div className="input-group mb-3">
              <DatePicker
                className="form-control date-picker"
                selected={values.overrideTime}
                onChange={(date: any) => {
                  setFieldValue("overrideTime", date);
                }}
                showTimeInput
                dateFormat="dd/MM/yyyy HH:mm"
                timeIntervals={5}
                calendarStartDay={1}
              />
              {dirty && (
                <div className="input-group-append">
                  <button
                    className="input-group-text"
                    id="basic-addon2"
                    disabled={!dirty || !isValid}
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
