import React from "react";
import { Link } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import JobService from "../../services/job.service";
import ViewJob from "./view-job.component";

export default function JobInformationSection({
  jobId,
  vehicleMove,
  swing,
}: any) {
  let job: any | null;

  const queryClient = useQueryClient();

  const getAddressLine = (address: any) => {
    if (!address) return "";
    return address.line1 + ", " + address.city + ", " + address.postcode;
  };

  const getJob = async () => {
    return await JobService.getJob(jobId);
  };

  const jobQuery = useQuery({
    queryKey: ["Job", jobId],
    queryFn: getJob,
    refetchInterval: 60000,
  });

  if (jobQuery.data) {
    job = jobQuery.data;
  }

  return job ? (
    <div className="row">
      <div className="col">
        <p className="section-title">Job Overview</p>
        <div className="row">
          <div className="col">
            <p className="label">Job Reference</p>
            <p className="value">{swing.jobReference}</p>
          </div>
          <div className="col">
            <p className="label">Customer Name</p>
            <p className="value">{swing.customer.name}</p>
          </div>
          <div className="col">
            <p className="label">Job Status</p>
            <p className="value">{job.status}</p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p className="label">Job Description</p>
            <p className="value">{job.jobDescription}</p>
          </div>
          {vehicleMove && (
            <>
              <div className="col">
                <p className="label">Collection Address</p>
                <p className="value">
                  {getAddressLine(vehicleMove?.collectionAddress)}
                </p>
              </div>
              <div className="col">
                <p className="label">Delivery Address</p>
                <p className="value">
                  {getAddressLine(vehicleMove?.deliveryAddress)}
                </p>
              </div>
            </>
          )}
        </div>
        <div className="row">
          <ViewJob
            vehicles={job.vehicles}
            swing={swing}
            job={job}
            onUpdate={() => {
              queryClient.invalidateQueries({queryKey: ["swing", swing.id]});
              queryClient.invalidateQueries({queryKey: ["job", jobId]});
            }}
          ></ViewJob>{" "}
          <Link to={"/swing/view/" + swing.id} className="view">
            <button className="btn btn-primary" type="button">
              Open Job
            </button>
          </Link>
        </div>
      </div>
    </div>
  ) : null;
}
