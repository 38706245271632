import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { GetById } from "../../helpers/LicenceClasses";
import { GetVehicleById } from "../../helpers/VehicleTypes";
import JobService from "../../services/job.service";
import ViewAddressLine from "../address/view-address-line.component";
import AddOrEditVehicleMove from "../jobs/add-vehicle-move.component";
import Status from "../jobs/status.component";
import DefaultModal from "../modal/modal.component";
import Vehicle from "../vehicles/vehicle.component";
import VehicleMoveImages from "./view-move-images.component";

export default function VehicleMove({
  jobId,
  addresses,
  contacts,
  vehicles,
  vehicleMove,
  editVehicleMove,
  removeVehicleMove,
  onUpdate,
}: any) {
  // function getType(type) {
  //   if (type === "1") return "Vehicle";
  //   else if (type === "2") return "Trailer";
  //   else if (type === "3") return "Minibus"
  //   else return "Unknown";
  // }

  const MySwal = withReactContent(Swal);

  const resetFailedJobStatus = () => {
    MySwal.fire({
      title: "Are you sure you want to reset this failed vehicle to available?",
      text: "This will allow the driver to use the app as normal to submit this job. They will also be permitted and mark the vehicle as collected and delivered.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reset job.",
    }).then((result) => {
      if (result.isConfirmed) {
        JobService.resetJobFailedStatus(jobId, vehicleMove.id).then(
          () => {
            MySwal.fire({
              title: "Success",
              text: "Vehicle Successfully reset.",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
            onUpdate();
          },
          (error) => {
            if (error && error.response) {
              console.log(error.response.data);
              if (error.response) {
                MySwal.fire({
                  title: "Error",
                  text: error.response.data,
                  icon: "error",
                  // @ts-expect-error TS(2769): No overload matches this call.
                  button: "Continue",
                });
              }
            }
          }
        );
      }
    });
  };

  return (
    <div className="card vehicle-move-card">
      <div className="card-body">
        <div className="row">
          {vehicleMove.status && (
            <div className="col vehicle-move-status">
              <Status status={vehicleMove.status}></Status>
              {vehicleMove.status == "FAILED" && (
                <button
                  type="button"
                  className="btn btn-danger action-btn"
                  onClick={() => resetFailedJobStatus()}
                >
                  Reset Failed
                </button>
              )}
            </div>
          )}
          <div className="col">
            <label className="job-label">Maximum Vehicle Class</label>
            <h5 className="card-title">
              {GetById(vehicleMove.requiredClassId)}
            </h5>
          </div>

          <h4>Vehicle Images Required: {vehicleMove.imagesRequired ? "Yes" : "No"}</h4>
          <h4>Walkaround Required: {vehicleMove.walkaroundRequired ? "Yes" : "No"}</h4>

          <div className="col">
            <label className="job-label">Vehicle Type</label>
            <h5 className="card-title">{GetVehicleById(vehicleMove?.vehicle?.type ?? (vehicleMove.vehicleType ?? 0))}</h5>
          </div>
          <div className="col">
            <label className="job-label">Provided Ground Vehicle</label>
            <h5 className="card-title">
              {vehicleMove.providedGroundVehicle ? "Yes" : "No"}
            </h5>
          </div>
          <div className="col">
            <label className="job-label">Provided Tow Vehicle</label>
            <h5 className="card-title">
              {vehicleMove.providedTowVehicle ? "Yes" : "No"}
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="job-label">Vehicle Provision Type</label>
            <h5 className="card-title">{vehicleMove.vehicleProvisionType}</h5>
          </div>
        </div>
        <div className="row">
          {vehicleMove.collectionAddress && (
            <div className="col">
              <label className="job-label">Collection Address</label>
              <ViewAddressLine
                address={vehicleMove.collectionAddress}
              ></ViewAddressLine>
            </div>
          )}

          {vehicleMove.deliveryAddress && (
            <div className="col">
              <label className="job-label">Delivery Address</label>
              <ViewAddressLine
                address={vehicleMove.deliveryAddress}
              ></ViewAddressLine>
            </div>
          )}
        </div>

        {vehicleMove.additionalInformation && (
          <div className="row">
            <div className="col">
              <label className="job-label">Additional Move Information</label>
              <h5 className="card-title">
                {vehicleMove.additionalInformation}
              </h5>
            </div>
          </div>
        )}

        {vehicleMove.InitialOdometerReading && (
          <div className="row">
            <div className="col">
              <label className="job-label">Odometer Reading</label>
              <h5 className="card-title">
                {vehicleMove.InitialOdometerReading}
              </h5>
            </div>
          </div>
        )}

        {vehicleMove.overrideOdometerRegistration && (
          <div className="row">
            <div className="col">
              <label className="job-label">
                Override Odometer Registration
              </label>
              <h5 className="card-title">
                {vehicleMove.overrideOdometerRegistration}
              </h5>
            </div>
          </div>
        )}
        {vehicleMove.vehicle && (
          // <>
          //   <div className="row">
          //     <div className="col">
          //       {vehicleMove.vehicle.registration &&
          //         <>
          //           <label className="job-label">Registration</label>
          //           <h5 className="card-title">{vehicleMove.vehicle.registration}</h5>
          //         </>
          //       }
          //       <p className="card-text"><label className="job-label">Vehicle Class</label>{GetById(vehicleMove.vehicle.class)}</p>
          //     </div>
          //     <div className="col">
          //       <p className="card-text"><label className="job-label">Vehicle Type</label>{getType(vehicleMove.vehicle.type)}</p>
          //     </div>
          //   </div>
          //   <label className="job-label">Vehicle Description</label>
          //   <h5 className="card-title">{vehicleMove.vehicle.vehicleDescription}</h5>
          // </>
          <Vehicle vehicle={vehicleMove.vehicle}></Vehicle>
        )}

{vehicleMove.secondaryVehicle && (
          <Vehicle vehicle={vehicleMove.secondaryVehicle}></Vehicle>
        )}

        {vehicleMove.vehicleImages && vehicleMove.vehicleImages.length > 0 && (
          <VehicleMoveImages
            vehicleImages={vehicleMove.vehicleImages}
          ></VehicleMoveImages>
        )}

        {(editVehicleMove && vehicleMove) ? (
          <DefaultModal
          title="Edit Vehicle Move"
          trigger="Edit Vehicle Move"
          content={<AddOrEditVehicleMove
            addresses={addresses}
            contacts={contacts}
            vehicles={vehicles}
            initialData={vehicleMove}
            onSubmit={editVehicleMove}
          ></AddOrEditVehicleMove>}
          ></DefaultModal>
        ) : null}

        {removeVehicleMove ? (
          <button
            type="button"
            className="btn danger btn btn-primary"
            onClick={() =>
              removeVehicleMove(
                vehicleMove.id ? vehicleMove.id : vehicleMove.rid
              )
            }
          >
            Remove Vehicle Move
          </button>
        ) : null}
      </div>
    </div>
  );
}
