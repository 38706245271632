import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import SwingService from "../../services/swing.service";
import SelectSwing from "./select-swing.component";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { SwingDto } from "../../contracts/models/SwingDto";

interface ChangeParentSwingProps {
  swingId: number;
  currentParentId?: number | null;
}

const MySwal = withReactContent(Swal);

export const ChangeParentSwing: React.FC<ChangeParentSwingProps> = ({ swingId, currentParentId }) => {
  const queryClient = useQueryClient();

  const changeParentSwingMutation = useMutation({
    mutationFn: ({ newParentId }: { newParentId: number | undefined }) =>
      SwingService.changeParentSwing(swingId, newParentId),
    onSuccess: () => {
      MySwal.fire(
        "Success",
        "Parent swing changed successfully.",
        "success"
      );
      queryClient.invalidateQueries({ queryKey: ["swing"] });
    },
    onError: (error: any) => {
      MySwal.fire(
        "Error",
        error.response?.data ||
          "An error occurred while changing the parent swing.",
        "error"
      );
    },
  });

  const handleSwingChange = (selectedOption: SwingDto | undefined) => {
      changeParentSwingMutation.mutate({ newParentId: selectedOption?.id || undefined });
  };

  return (
    <div className="row">
      <div className="col-md-9 mt-4 mb-2">
        <SelectSwing onChange={handleSwingChange} defaultSwingId={currentParentId || undefined} hideNote={true} overrideTitle="Change Parent Swing" />
      </div>
    </div>
  );
};

export default ChangeParentSwing;
