import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import Moment from "react-moment";
import jobService from "../../services/job.service";
import LoadingSpinner from "../loading/loading-spinner.component";
import {
  MantineReactTable,
  MRT_Cell,
  MRT_Column,
  MRT_ColumnDef,
  MRT_Row,
  useMantineReactTable,
} from "mantine-react-table";
import { DriverJobDto } from "../../contracts/models/DriverJobDto";
import FilterDate from "./filter-date.component";
import moment from "moment";
import "./fleet.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import JobService from "../../services/job.service";
import { DateInput, DateTimePicker } from "@mantine/dates";
import { JobsHolderModal } from "./jobs.component";

export type OvertimeJobRecordDto = {
  swingId: number;
  meetTime: Date;
  jobId: number;
  jobReference: string;
  driverName: string;
  checkIn?: Date;
  checkOut?: Date;
  chargeCheckIn?: Date;
  chargeCheckOut?: Date;
  currentOvertimeMinutes: number;
  shiftLength: number;
  overtimeApprovalStatus: string;
};

export const useOvertimeDrivers = (
  fromDate: string,
  toDate: string,
  archived: boolean
) => {
  return useQuery<OvertimeJobRecordDto[]>({
    queryKey: ["overtimeDrivers"],
    queryFn: async () => {
      var r = await jobService.getOvertimeDrivers(fromDate, toDate, archived);
      return r.data;
    },
  });
};

export const OvertimeApproval: React.FC = () => {
  const [dates, setDates] = React.useState({
    startDate: moment().subtract(7, "days").toDate(),
    endDate: moment().add(28, "days").toDate(),
  });

  const [archived, setArchived] = React.useState(true);

  const {
    data: overtimeDrivers,
    isLoading,
    refetch,
  } = useOvertimeDrivers(
    dates.startDate.toISOString(),
    dates.endDate.toISOString(),
    archived
  );

  return (
    <OvertimeApprovalTable
      overtimeDrivers={overtimeDrivers}
      dates={dates}
      setDates={setDates}
      archived={archived}
      setArchived={setArchived}
      refetch={refetch}
      isLoading={isLoading}
    />
  );
};

export default OvertimeApproval;

export const OvertimeApprovalTable: React.FC<{
  overtimeDrivers: OvertimeJobRecordDto[] | undefined;
  dates: {
    startDate: Date;
    endDate: Date;
  };
  setDates: (dates: { startDate: Date; endDate: Date }) => void;
  archived: boolean;
  setArchived: (archived: boolean) => void;
  refetch: () => void;
  isLoading: boolean;
}> = ({
  overtimeDrivers,
  dates,
  setDates,
  archived,
  setArchived,
  refetch,
  isLoading,
}) => {
  const MySwal = withReactContent(Swal);

  const [dateValues, setDateValues] = React.useState<{
    [key: number]: {
      [key: string]: Date | null;
    };
  }>({});

  const queryClient = useQueryClient();
  const approveOvertimeMutation = useMutation({
    mutationFn: ({
      jobId,
      overtimeApprovalStatus,
      checkIn,
      checkOut,
      chargeCheckIn,
      chargeCheckOut,
    }: {
      jobId: number;
      overtimeApprovalStatus: string;
      checkIn: string;
      checkOut: string;
      chargeCheckIn?: string;
      chargeCheckOut?: string;
    }) =>
      JobService.setOvertimeApproval(
        jobId,
        overtimeApprovalStatus,
        checkIn,
        checkOut,
        chargeCheckIn,
        chargeCheckOut
      ),
    onSuccess: () => {
      MySwal.fire(
        "Success",
        "Overtime approval status updated successfully.",
        "success"
      );
      queryClient.invalidateQueries({ queryKey: ["overtimeJobs"] });
      refetch();
    },
    onError: (error: any) => {
      MySwal.fire(
        "Error",
        error.response?.data ||
          "An error occurred while updating the overtime approval status.",
        "error"
      );
    },
  });

  const handleDateChange = (
    rowId: number,
    field: string,
    value: Date | null
  ) => {
    var previousState = dateValues;
    var newState = {
      ...previousState,
      [rowId]: {
        ...(previousState[rowId] || {}),
        [field]: value,
      },
    };

    console.log("PREV STATE", previousState);
    console.log("NEW STATE", newState);
    setDateValues(newState);
  };

  const columns = React.useMemo<MRT_ColumnDef<OvertimeJobRecordDto>[]>(
    () => [
      {
        accessorKey: "jobReference",
        header: "Job Reference",
        Cell: ({ cell, row }) => (
          <>
            <p>{row.original.jobReference}</p>
            <JobsHolderModal id={row.original.swingId} />
          </>
        ),
      },
      {
        accessorKey: "driverName",
        header: "Driver Name",
      },
      {
        accessorKey: "checkIn",
        header: "Check In Time",
        Cell: ({ cell, row }) => (
          <>
            <p>
              Meet: {moment(row.original.meetTime).format("DD/MM/YYYY HH:mm")}
            </p>
            <DateTimePicker
              defaultValue={
                dateValues[row.original.jobId]?.checkIn ||
                moment(row.original.checkIn).toDate() ||
                moment(row.original.meetTime).toDate()
              }
              // onChange={(value) => handleDateChange(row.original.jobId, 'checkIn', value)}
              onChange={(value) => (row._valuesCache.checkIn = value)}
              valueFormat="DD/MM/YYYY HH:mm"
              maw={400}
              mx="auto"
            />
          </>
        ),
      },
      {
        accessorKey: "checkOut",
        header: "Check Out Time",
        Cell: ({ cell, row }) => (
          <>
            <p>
              End:{" "}
              {moment(row.original.meetTime)
                .add(row.original.shiftLength, "hours")
                .format("DD/MM/YYYY HH:mm")}
            </p>
            <DateTimePicker
              defaultValue={
                dateValues[row.original.jobId]?.checkOut ||
                moment(row.original.checkOut).toDate() ||
                moment(row.original.meetTime)
                  .add(row.original.shiftLength, "hours")
                  .toDate()
              }
              // onChange={(value) => handleDateChange(row.original.jobId, 'checkOut', value)}
              onChange={(value) => (row._valuesCache.checkOut = value)}
              placeholder="Check Out"
              valueFormat="DD/MM/YYYY HH:mm"
              maw={400}
              mx="auto"
            />
          </>
        ),
      },
      {
        accessorKey: "chargeCheckIn",
        header: "Charge Check In Time",
        Cell: ({ cell, row }) => (
          <>
            <p>
              Current Overtime: {row.original.currentOvertimeMinutes} minutes
            </p>
            <DateTimePicker
              defaultValue={
                dateValues[row.original.jobId]?.chargeCheckIn ??
                (row.original.chargeCheckIn == null
                  ? undefined
                  : moment(row.original.chargeCheckIn).toDate())
              }
              // onChange={(value) => handleDateChange(row.original.jobId, 'chargeCheckIn', value)}
              onChange={(value) => (row._valuesCache.chargeCheckIn = value)}
              placeholder="Charge Check In"
              valueFormat="DD/MM/YYYY HH:mm"
              maw={400}
              mx="auto"
            />
          </>
        ),
      },
      {
        accessorKey: "chargeCheckOut",
        header: "Charge Check Out Time",
        Cell: ({ cell, row }) => (
          <>
            <p>Optional</p>
            <DateTimePicker
              defaultValue={
                dateValues[row.original.jobId]?.chargeCheckOut ??
                (row.original.chargeCheckOut == null
                  ? undefined
                  : moment(row.original.chargeCheckOut).toDate())
              }
              // onChange={(value) => handleDateChange(row.original.jobId, 'chargeCheckOut', value)}
              onChange={(value) => (row._valuesCache.chargeCheckOut = value)}
              valueFormat="DD/MM/YYYY HH:mm"
              placeholder="Charge Check Out"
              maw={400}
              mx="auto"
            />
          </>
        ),
      },
      {
        accessorKey: "approve",
        header: "Approve Overtime",
        Cell: ({ cell, row }) => (
          <div className="flex gap-2 space-between">
            <button
              disabled={row.original.overtimeApprovalStatus == "PAY_CHARGE"}
              className="btn btn-sm btn-success m-2 w-full"
              onClick={() => approveOvertime("PAY_CHARGE", row)}
            >
              Approve (P & C)
            </button>
            <button
              disabled={row.original.overtimeApprovalStatus == "PAY"}
              className="btn btn-sm btn-success m-2 w-full"
              onClick={() => approveOvertime("PAY", row)}
            >
              Approve (P Only)
            </button>
            <button
              disabled={row.original.overtimeApprovalStatus == "CHARGE"}
              className="btn btn-sm btn-warning m-2 w-full"
              onClick={() => approveOvertime("CHARGE", row)}
            >
              Approve (C Only)
            </button>
            <button
              disabled={row.original.overtimeApprovalStatus == "NONE"}
              className="btn btn-sm btn-danger m-2 w-full"
              onClick={() => approveOvertime("NONE", row)}
            >
              Reject
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const approveOvertime = (
    status: string,
    row: MRT_Row<OvertimeJobRecordDto>
  ) => {
    console.log("DATE VALUES", row._valuesCache);
    approveOvertimeMutation.mutate({
      jobId: row.original.jobId,
      overtimeApprovalStatus: status,
      checkIn: moment(row._valuesCache.checkIn).toISOString(),
      checkOut: moment(row._valuesCache.checkOut).toISOString(),
      chargeCheckIn: moment(row._valuesCache.chargeCheckIn).toISOString(),
      chargeCheckOut: moment(row._valuesCache.chargeCheckOut).toISOString(),
    });

    // console.log('DATE VALUES', dateValues[row.original.jobId])
    // // approveOvertimeMutation.mutate({
    // //   jobId: row.original.jobId,
    // //   overtimeApprovalStatus: status,
    // //   checkIn: moment(dateValues[row.original.jobId]?.checkIn).toISOString(),
    // //   checkOut: moment(dateValues[row.original.jobId]?.checkOut).toISOString(),
    // //   chargeCheckIn: dateValues[row.original.jobId]?.chargeCheckIn ? moment(dateValues[row.original.jobId]?.chargeCheckIn).toISOString() : undefined,
    // //   chargeCheckOut: dateValues[row.original.jobId]?.chargeCheckOut ? moment(dateValues[row.original.jobId]?.chargeCheckOut).toISOString() : undefined,
    // // });
  };

  const table = useMantineReactTable({
    columns,
    data: overtimeDrivers || [],
    state: {
      showLoadingOverlay: isLoading, //fetching next page pagination
      showSkeletons: isLoading, //loading for the first time with no data
      showProgressBars: approveOvertimeMutation.isPending, //from a mutation
      isLoading: isLoading || approveOvertimeMutation.isPending,
    },
    getRowId: (row) => row?.jobId?.toString() || "",
  });

  return (
    <div className="overtime-approval">
      <h2>Pending Driver Overtime</h2>
      <ul>
        <li>Approve or reject overtime for use in the Invoice Request.</li>
        <li>Reject an overtime request without a value to set to 0 minutes.</li>
        <li>You can still approve overtime and change the value.</li>
        <li>
          Unentered Values are defaulted in this page to the start and end time
          with no overtime paid
        </li>
        <li>
          Rejected overtime will never be paid - even if you leave a longer
          shift length.
        </li>
        <li>
          Charge Start and End Time overrides the Check In and Check Out times
          on Invoicing
        </li>
      </ul>
      <div className="d-flex justify-content-between align-items-center">
        <FilterDate
          dates={dates}
          onDateChange={(newDates) => {
            setDates(newDates);
            setTimeout(() => {
              refetch();
            }, 200);
          }}
        />

        {/* <button
          className="btn btn-lg btn-primary"
          disabled={true}
          onClick={() => {
            setTimeout(() => {
              // setArchived(!archived);
              refetch();
            }, 1000);
          }}
        >
          (Coming Soon) Show Overtime Only
        </button> */}

        <button
          className="btn btn-lg btn-primary"
          onClick={() => {
            setArchived(!archived);
            setTimeout(() => {
              refetch();
            }, 500);
          }}
        >
          {archived ? "Showing All" : "Showing Pending Approval"}
        </button>
      </div>
      <MantineReactTable table={table}/>
    </div>
  );
};
