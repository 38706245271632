import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

export default function SelectAddress({
  addresses,
  onChange,
  defaultAddress,
  hideInputNote,
}: {
  addresses: { value: string; label: string }[];
  onChange: any;
  defaultAddress?: number | undefined | null | string;
  hideInputNote?: boolean;
}) {
  if(!addresses) return <></>
  
  return (
    <>
      <Select
        onChange={(option: any) => {
          onChange(option?.value);
        }}
        options={addresses}
        isClearable={true}
        name="addressId"
        defaultValue={
          addresses.find((v: any) => v.value === defaultAddress) ?? {}
        }
      />
      {hideInputNote !== true && (
        <p className="input-note">
          The address list auto-updates can be maintained via the{" "}
          <Link to={"/address"}>Addresses</Link> page.
        </p>
      )}
    </>
  );
}
