import React, { useState } from "react";
import Moment from "react-moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import JobService from "../../services/job.service";
import DefaultModal from "../modal/modal.component";
import EditJob from "./edit-job.component";
import Status from "./status.component";
import Update from "./update.component";
import PaymentStatus from "./payment-status.component";
import ViewJobVehicles from "./view-job-vehicles.component";
import { Dropdown } from "react-bootstrap";
import OverrideJobTime from "./override-jobtime";
import ToggleFitness from "./toggle-fitness";
import AddOrEditExpense from "./add-new-expense";

const MySwal = withReactContent(Swal);

export default function ViewJob({ job, vehicles, swing, onUpdate }: any) {
  const resetFailedJobStatus = (jobId: any) => {
    MySwal.fire({
      title: "Are you sure you want to reset this failed job to in progress?",
      text: "This will allow the driver to use the app as normal to submit this job. They will be permitted to Check In and mark the vehicle as collected.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reset job.",
    }).then((result) => {
      if (result.isConfirmed) {
        // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
        JobService.resetJobFailedStatus(jobId).then(
          (response) => {
            MySwal.fire({
              title: "Success",
              text: "Job successfully reset.",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
            onUpdate();
          },
          (error) => {
            if (error && error.response) {
              console.log(error.response.data);
              if (error.response) {
                MySwal.fire({
                  title: "Error",
                  text: error.response.data,
                  icon: "error",
                  // @ts-expect-error TS(2769): No overload matches this call.
                  button: "Continue",
                });
              }
            }
          }
        );
      }
    });
  };

  const resetJob = (jobId: any) => {
    MySwal.fire({
      title: "Are you sure you want to reset this job?",
      text: "This will remove the driver from the job and set the status back to pending and set all vehicles back to available. You won't be able to revert ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reset job.",
    }).then((result) => {
      if (result.isConfirmed) {
        JobService.resetJob(jobId).then(
          (response) => {
            MySwal.fire({
              title: "Success",
              text: "Job successfully reset.",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
            onUpdate();
          },
          (error) => {
            if (error && error.response) {
              console.log(error.response.data);
              if (error.response) {
                MySwal.fire({
                  title: "Error",
                  text: error.response.data,
                  icon: "error",
                  // @ts-expect-error TS(2769): No overload matches this call.
                  button: "Continue",
                });
              }
            }
          }
        );
      }
    });
  };

  const setTeamLeader = (jobId: any, value: any) => {
    MySwal.fire({
      title: value
        ? "Are you sure you mark this driver as the team leader?"
        : "Are you sure you remove team leader status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        JobService.setTeamLeader(jobId, value).then(
          (response) => {
            MySwal.fire({
              title: "Success",
              text: "Successfully updated.",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
            onUpdate();
          },
          (error) => {
            if (error && error.response) {
              console.log(error.response.data);
              if (error.response) {
                MySwal.fire({
                  title: "Error",
                  text: error.response.data,
                  icon: "error",
                  // @ts-expect-error TS(2769): No overload matches this call.
                  button: "Continue",
                });
              }
            }
          }
        );
      }
    });
  };

  const markCompleted = (jobId: any) => {
    MySwal.fire({
      title: "Are you sure you want to mark this job completed?",
      text: "This will set all vehicles to completed and complete the job. You won't be able to revert this without reseting the job.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, mark completed.",
    }).then((result) => {
      if (result.isConfirmed) {
        JobService.markJobCompleted(jobId).then(
          (response) => {
            MySwal.fire({
              title: "Success",
              text: "Job successfully completed.",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
            onUpdate();
          },
          (error) => {
            if (error && error.response) {
              console.log(error.response.data);
              if (error.response) {
                MySwal.fire({
                  title: "Error",
                  text: error.response.data,
                  icon: "error",
                  // @ts-expect-error TS(2769): No overload matches this call.
                  button: "Continue",
                });
              }
            }
          }
        );
      }
    });
  };

  const reopenJob = (jobId: any) => {
    MySwal.fire({
      title: "Are you sure you want to mark reopen this job?",
      text: "This will set the status to completed and notify the driver, allowing them to edit data.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reopen job.",
    }).then((result) => {
      if (result.isConfirmed) {
        JobService.reopenJob(jobId).then(
          (response) => {
            MySwal.fire({
              title: "Success",
              text: "Job successfully reopened.",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
            onUpdate();
          },
          (error) => {
            if (error && error.response) {
              console.log(error.response.data);
              if (error.response) {
                MySwal.fire({
                  title: "Error",
                  text: error.response.data,
                  icon: "error",
                  // @ts-expect-error TS(2769): No overload matches this call.
                  button: "Continue",
                });
              }
            }
          }
        );
      }
    });
  };

  const closeJob = (jobId: any) => {
    MySwal.fire({
      title: "Are you sure you want to close this job?",
      text: "This will set the status to closed and stop the driver uploading further information.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close job.",
    }).then((result) => {
      if (result.isConfirmed) {
        JobService.closeJob(jobId).then(
          (response) => {
            MySwal.fire({
              title: "Success",
              text: "Job successfully closed.",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
            onUpdate();
          },
          (error) => {
            if (error && error.response) {
              console.log(error.response.data);
              if (error.response) {
                MySwal.fire({
                  title: "Error",
                  text: error.response.data,
                  icon: "error",
                  // @ts-expect-error TS(2769): No overload matches this call.
                  button: "Continue",
                });
              }
            }
          }
        );
      }
    });
  };

  const deleteJob = (jobId: any) => {
    MySwal.fire({
      title: "Are you sure you want to delete the job?",
      text: "Deleting this job will delete the job and its history forever. You won't be able to revert ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        JobService.deleteJob(jobId).then(
          (response) => {
            MySwal.fire({
              title: "Success",
              text: "Job successfully deleted.",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
            onUpdate();
          },
          (error) => {
            if (error && error.response) {
              console.log(error.response.data);
              if (error.response) {
                MySwal.fire({
                  title: "Error",
                  text: error.response.data,
                  icon: "error",
                  // @ts-expect-error TS(2769): No overload matches this call.
                  button: "Continue",
                });
              }
            }
          }
        );
      }
    });
  };

  const editJob = (job: any) => {
    console.log(job);
    MySwal.fire({
      title: "Are you sure you want to edit this job?",
      text: "This will update the job for the accepted driver.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, edit it!",
    }).then((result) => {
      if (result.isConfirmed) {
        JobService.editJob(job).then(
          (response) => {
            MySwal.fire({
              title: "Success",
              text: "Job successfully edited.",
              icon: "success",
              // @ts-expect-error TS(2769): No overload matches this call.
              button: "Continue",
            });
            onUpdate();
          },
          (error) => {
            if (error && error.response) {
              console.log(error.response.data);
              MySwal.fire({
                title: "Error updating job",
                text: error.response.data,
                icon: "error",
                // @ts-expect-error TS(2769): No overload matches this call.
                button: "Continue",
              });
            }
          }
        );
      }
    });
  };

  const cancelJob = (jobId: any) => {
    MySwal.fire({
      title: "Are you sure you want to cancel this job?",
      text: "This will set the status to cancelled and stop the driver uploading further information.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel job.",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Was this job cancelled within 24 hours of start time?",
          text: "Please be aware your selection here will directly affect billing for this job. Upon making your selection, this job will be cancelled.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Cancelled within 24 hours",
          cancelButtonText: "Not within 24 hours",
        }).then((result) => {
          JobService.cancelJobs([jobId], result.isConfirmed).then(
            (response) => {
              MySwal.fire({
                title: "Success",
                text: "Job successfully cancelled.",
                icon: "success",
                // @ts-expect-error TS(2769): No overload matches this call.
                button: "Continue",
              });
              onUpdate();
            },
            (error) => {
              if (error && error.response) {
                console.log(error.response.data);
                if (error.response) {
                  MySwal.fire({
                    title: "Error",
                    text: error.response.data,
                    icon: "error",
                    // @ts-expect-error TS(2769): No overload matches this call.
                    button: "Continue",
                  });
                }
              }
            }
          );
        });
      }
    });
  };

  // const vehicles = job.vehicles.map((vehicle) =>
  //     <Vehicle key={vehicle.id} vehicle={vehicle} onSubmit={editVehicle}></Vehicle>
  // );

  let updates = [<></>];

  if (job.updates) {
    updates = job.updates.map((update: any) => (
      <Update key={update.id} update={update}></Update>
    ));
  }

  return (
    <DefaultModal
      title={"View Job"}
      trigger={"View Job"}
      content={
        <div className="view-job-container">
          <div className="row">
            <div className="col-md-5">
              <Dropdown>
                <Dropdown.Toggle className="btn-info btn-block toggle-btn">
                  Job Actions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {job.status != "CANCELLED" && job?.driver?.isTeamLeader && (
                    <Dropdown.Item
                      onClick={() => setTeamLeader(job.id, !job.isTeamLeader)}
                      // onClick={() => setCategory("PAYEE", !driver.isPayee)}
                    >
                      <div className="information-container">
                        <button
                          className="btn btn-warning action-btn"
                          // disabled={isLoading}
                        >
                          {job.isTeamLeader
                            ? "Remove Team Leader"
                            : "Set Team Leader"}
                        </button>
                      </div>
                    </Dropdown.Item>
                  )}

                  {job.status != "COMPLETED" && job.Status != "CLOSED" && (
                    <Dropdown.Item onClick={() => markCompleted(job.id)}>
                      <div className="information-container">
                        <button
                          type="button"
                          className="btn btn-primary action-btn"
                        >
                          Set Delivered and Completed
                        </button>
                      </div>
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item onClick={() => resetJob(job.id)}>
                    <div className="information-container">
                      <button
                        type="button"
                        className="btn btn-danger action-btn"
                      >
                        Reset Job to Pending
                      </button>
                    </div>
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => deleteJob(job.id)}>
                    <div className="information-container">
                      <button
                        type="button"
                        className="btn btn-danger action-btn"
                      >
                        Delete Job
                      </button>
                    </div>
                  </Dropdown.Item>

                  {job.status == "CLOSED" && (
                    <Dropdown.Item onClick={() => reopenJob(job.id)}>
                      <div className="information-container">
                        <button
                          type="button"
                          className="btn btn-success action-btn"
                        >
                          Reopen Job
                        </button>
                      </div>
                    </Dropdown.Item>
                  )}

                  {job.status == "COMPLETED" && (
                    <Dropdown.Item onClick={() => closeJob(job.id)}>
                      <div className="information-container">
                        <button
                          type="button"
                          className="btn btn-warning action-btn"
                        >
                          Close Job
                        </button>
                      </div>
                    </Dropdown.Item>
                  )}

                  {job.status != "CANCELLED" && (
                    <Dropdown.Item onClick={() => cancelJob(job.id)}>
                      <div className="information-container">
                        <button
                          type="button"
                          className="btn btn-danger action-btn"
                        >
                          Cancel Job
                        </button>
                      </div>
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <small>{job.id}</small>
          <div className="row">
            <div className="col-md-8">
              <EditJob job={job} onSubmit={editJob}></EditJob>
              {swing && (
                <>
                  <label className="job-label">Swing Reference</label>
                  <h4 className="job-description">{swing.jobReference}</h4>
                  {/* {swing.collectionDate ?
                                    <div className="information-container">
                                        <label className="job-label">Swing Date</label>
                                        <h4 className="job-description"><Moment format="DD/MM/YYYY HH:mm" date={swing.collectionDate} /></h4>
                                    </div> : null} */}
                </>
              )}
              <p><ToggleFitness jobId={job.id} swingId={swing.id} fitnessToDriveRequired={job.fitnessToDriveRequired} /></p>
              <p><DefaultModal title={"Add new Expense"} trigger="Add Expense" content={<AddOrEditExpense job={job} vehicleMoves={job.vehicles} onSubmit={onUpdate} />} /></p>
              {job.driver ? (
                <>
                  <label className="job-label">Driver Information</label>
                  <p className="job-information">
                    {job.driver.name}{" "}
                    {job.driver?.isPayee && (
                      <span className="badge bg-success">Full Time</span>
                    )}
                  </p>
                  <p className="job-information">{job.driver.email}</p>
                  <p className="job-information">
                    {job.driver.telephoneNumber}
                  </p>
                  <p className="job-information">{job.driver.licenseNumber}</p>
                </>
              ) : (
                <p className="job-information">
                  No driver has accepted this job
                </p>
              )}

              {job.checkInAt && (
                <div className="information-container">
                  {/* <label className="job-label">Check In</label>
                  <p className="job-information">
                    <Moment
                      format="DD/MM/YYYY HH:mm"
                      date={new Date(job.checkInAt)}
                    />
                  </p> */}

                  <OverrideJobTime
                    label={"Check In"}
                    jobId={job.id}
                    isCheckIn={true}
                    value={job.checkInAt}
                  ></OverrideJobTime>
                </div>
              )}

              {job.checkOutAt && (
                <div className="information-container">
                  <label className="job-label">Check Out</label>
                  <p className="job-information">
                    <Moment
                      format="DD/MM/YYYY HH:mm"
                      date={new Date(job.checkOutAt)}
                    />
                  </p>
                </div>
              )}

              {job.overrideCheckOutAt && (
                <div className="information-container">
                  {/* <label className="job-label">
                    Driver Provided Override Check Out
                  </label>
                  <p className="job-information">
                    <Moment
                      format="DD/MM/YYYY HH:mm"
                      date={new Date(job.overrideCheckOutAt)}
                    />
                  </p> */}

                  <OverrideJobTime
                    label={"Driver Check Out"}
                    jobId={job.id}
                    isCheckIn={false}
                    value={job.overrideCheckOutAt}
                  ></OverrideJobTime>
                </div>
              )}
            </div>

            <div className="col-md-4 job-status-container">
              {job.status ? (
                <div className="status-container">
                  <label className="job-label">Job Status</label>
                  <Status status={job.status}></Status>
                  {job.status == "CANCELLED" && (
                    <p className="job-label">
                      {job.cancelledWithin24
                        ? "Cancelled within 24 hours of meet time"
                        : "Not Cancelled within 24 hours"}
                    </p>
                  )}
                  {job.status == "FAILED" && (
                    <div className="information-container">
                      <button
                        type="button"
                        className="btn btn-danger action-btn"
                        onClick={() => resetFailedJobStatus(job.id)}
                      >
                        Reset Job to In Progress
                      </button>
                    </div>
                  )}
                </div>
              ) : null}

              {job.paymentStatus && (
                <PaymentStatus
                  jobId={job.id}
                  status={job.paymentStatus}
                ></PaymentStatus>
              )}

              {job.overtimeApprovalStatus && (
                <div className="information-container">
                  <label className="job-label">Overtime Approval</label>
                  <p className="job-information">{job.overtimeApprovalStatus}</p>
                </div>
              )}

              {job.isTeamLeader && job.driver?.isTeamLeader && (
                <div className="information-container">
                  <h4>
                    <span className="badge bg-warning">Team Leader</span>
                  </h4>
                </div>
              )}

              {job.createdAt && (
                <div className="information-container">
                  <label className="job-label">Created At</label>
                  <p className="job-information">
                    <Moment
                      format="DD/MM/YYYY HH:mm"
                      date={new Date(job.createdAt)}
                    />
                  </p>
                </div>
              )}
            </div>
          </div>
          <ViewJobVehicles
            job={job}
                            vehicles={vehicles}
            onUpdate={onUpdate}
          ></ViewJobVehicles>
          {updates.length > 0 && (
            <div className="card">
              <h4>Job Updates</h4>
              <div className="row">
                <div className="col-md-3">
                  <p className="job-information">Status Change</p>
                </div>
                <div className="col">
                  <p className="job-information">User</p>
                </div>
                <div className="col">
                  <p className="job-information">Registration</p>
                </div>
                <div className="col">
                  <p className="job-information">Event Date</p>
                </div>
              </div>
              {updates}
            </div>
          )}
        </div>
      }
    ></DefaultModal>
  );
}
