import React from "react";
import userService from "../../services/user.service";
import { useQueryClient } from "@tanstack/react-query";
import { useSuccessSwal } from "../../hooks/useSuccessSwal";

interface UpdateActiveStatusProps {
  userId: number;
  isActive: boolean;
}

const UpdateUserActiveStatus: React.FC<UpdateActiveStatusProps> = ({
  userId,
  isActive,
}) => {
  const queryClient = useQueryClient();
  const swal = useSuccessSwal();

  const UpdateUserActiveState = async (isActive: boolean) => {
    try {
      await userService.updateActiveStatus({ userId, isActive });
      queryClient.invalidateQueries({ queryKey: ["users"] });
      swal.execute("Success", "User status updated successfully", "success");
    } catch (error) {
      swal.execute("Error", "Failed to update user status", "error");
    }
  };

  return (
    <button
      onClick={() => UpdateUserActiveState(isActive ? false : true)}
      className={`${isActive ? "btn btn-danger" : "btn btn-primary"} m-0`}
      type="button"
      data-toggle="tooltip"
      data-placement="top"
      title={`${isActive ? "Deactivate" : "Activate"} User`}
    >
      {isActive ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          fill="currentColor"
          className="bi bi-person-fill-slash"
          viewBox="0 0 16 16"
        >
          <path d="M13.879 10.414a2.501 2.501 0 0 0-3.465 3.465zm.707.707-3.465 3.465a2.501 2.501 0 0 0 3.465-3.465m-4.56-1.096a3.5 3.5 0 1 1 4.949 4.95 3.5 3.5 0 0 1-4.95-4.95ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          fill="currentColor"
          className="bi bi-person-fill-check"
          viewBox="0 0 16 16"
        >
          <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
          <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
        </svg>
      )}
    </button>
  );
};

export default UpdateUserActiveStatus;
