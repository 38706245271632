import { useQuery } from "@tanstack/react-query";
import React from "react";
import Moment from "react-moment";
import vehicleService from "../../services/vehicle.service";
import LoadingSpinner from "../loading/loading-spinner.component";
import {
  FleetList,
  FleetItem,
  FleetDay,
  FleetActivity,
} from "../../contracts/models/FleetListDto";
import FilterDate from "./filter-date.component";
import moment from "moment";
import "./fleet.css";

export const useFleetBreakdown = (fromDate: string, toDate: string) => {
  return useQuery<FleetList, Error>({
    queryKey: ["fleetBreakdown"],
    queryFn: () => vehicleService.getFleetBreakdown(fromDate, toDate),
  });
};

export const FleetBreakdownPage: React.FC = () => {
  const [dates, setDates] = React.useState({
    startDate: moment().subtract(7, "days").toDate(),
    endDate: moment().add(28, "days").toDate(),
  });

  const { data: fleetList, isLoading } = useFleetBreakdown(
    dates.startDate.toISOString(),
    dates.endDate.toISOString()
  );

  if (isLoading || !fleetList) return <LoadingSpinner />;

  // const allDates = fleetList?.vehicles?.flatMap((vehicle: FleetItem) => vehicle?.days?.map((day: FleetDay) => day.date));

  return (
    <div>
      <h2>Fleet Breakdown</h2>
      <FilterDate dates={dates} onDateChange={setDates} />
      <table className="table my-2 table-responsive table-responsive-screen sticky-first">
        <thead>
          <tr>
            <th scope="col">Vehicle</th>
            {fleetList.days?.map((date, index) => (
              <th key={index} scope="col">
                <Moment format="DD/MM/YYYY">{date}</Moment>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {fleetList?.vehicles?.map((fleetItem: FleetItem) => (
            <tr key={`${fleetItem?.vehicle?.id}`}>
              <td className="no-wrap">
                {fleetItem.vehicle.vehicleDescription}
              </td>
              {fleetItem?.days?.map((day: FleetDay) => (
                <>
                  <td>
                    {day?.activities?.map(
                      (activity: FleetActivity, index: number) => (
                        <a
                          href={"/swing/view/" + activity.swingId}
                          target="_blank"
                        >
                          <div key={index}>{activity.name}</div>
                        </a>
                      )
                    )}
                  </td>
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FleetBreakdownPage;

