import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useAvailableDrivers } from "../../hooks/useAvailableDrivers";
import { usePendingDrivers } from "../../hooks/usePendingDrivers";
import JobService from "../../services/job.service";
import LoadingSpinner from "../loading/loading-spinner.component";
import AddPendingDrivers from "./add-pending-drivers.component";
import PendingDrivers from "./pending-drivers.component";
import "./Pending.css";

export default function ManageDrivers({ job, onUpdate }: any) {
  const [modalShow, setModalShowState] = useState(false);
  const { data: availableDrivers, isRefetching: isAvailableLoading } =
    useAvailableDrivers(job.id);
  const { data: pendingDrivers, isRefetching: isPendingLoading } =
    usePendingDrivers(job.id);

  let loading = isPendingLoading || isAvailableLoading;

  const setModalShow = (show: any) => {
    setModalShowState(show);
  };

  // const getAvailableDrivers = () => {
  //   setLoading(true);
  //   JobService.getAvailablePendingDrivers(job.id).then(
  //     (response) => {
  //       if (response.data.length > 0) {
  //         var drivers = response.data.map((user) => ({
  //           value: user.id,
  //           label: user.name,
  //         }));
  //         setAvailableDrivers(drivers);
  //         setLoading(false);
  //       }
  //     },
  //     (error) => {
  //       console.log(error);
  //       setLoading(false);
  //     }
  //   );
  // };

  // const getPendingDrivers = () => {
  //   setLoading(true);
  //   JobService.getPendingDrivers(job.id).then(
  //     (response) => {
  //       setPendingDrivers(response.data);
  //       setLoading(false);
  //     },
  //     (error) => {
  //       console.log(error);
  //       setLoading(false);
  //     }
  //   );
  // };

  return (
    <>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Manage Drivers
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col">
              <div className="drivers-container">
                <AddPendingDrivers
                  job={job}
                  availableDrivers={availableDrivers}
                  onUpdate={onUpdate}
                  loading={loading}
                ></AddPendingDrivers>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="drivers-container">
                <PendingDrivers
                  job={job}
                  pendingDrivers={pendingDrivers}
                  onUpdate={onUpdate}
                  loading={loading}
                ></PendingDrivers>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      <button
        className="btn btn-primary btn-grid driver-count"
        disabled={pendingDrivers?.length == 0 && availableDrivers?.length == 0}
        onClick={() => setModalShow(true)}
      >
        {loading && "Manage Drivers"}
        {!loading &&
          pendingDrivers?.length == 0 &&
          "0 of " + availableDrivers.length + " selected"}
        {!loading &&
          pendingDrivers?.length == 1 &&
          "Sent to " + pendingDrivers[0].driver.name}
        {!loading &&
          pendingDrivers?.length > 1 &&
          "Sent to " + pendingDrivers.length + " drivers"}
      </button>

      {/* {!loading && pendingDrivers && availableDrivers && (
        <p className="driver-count">
          {pendingDrivers?.length == 0 &&
            "0 of " + availableDrivers.length + " selected"}
          {pendingDrivers?.length == 1 &&
            "Sent to " + pendingDrivers[0].driver.name}
          {pendingDrivers?.length > 1 &&
            "Sent to " + pendingDrivers.length + " drivers"}
        </p>
      )} */}
    </>
  );
}
