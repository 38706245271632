import { useQueryClient } from "@tanstack/react-query";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import * as Yup from "yup";
import { useCustomerLabels } from "../../hooks/useCustomers";
import customerService from "../../services/customer.service";
import UserService from "../../services/user.service";
import DefaultModal from "../modal/modal.component";
import SelectCustomer from "./select-customer.component";

const AddCustomerUserSchema = Yup.object().shape({
  userId: Yup.number().required("User ID is required"),
  customerId: Yup.number().required("Customer ID is required"),
});

const MySwal = withReactContent(Swal);

export default function AddUserToCustomer({ userId }: { userId: number }) {
  const [isLoading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const { data: customers } = useCustomerLabels();

  return (
    <DefaultModal
      title={"Select and set a customer for the new user"}
      trigger={"Add new Customer to User"}
      content={
        <div className="col-md-12">
          <div className="card card-container">
            <h3 className="page-title">Select Customer</h3>
            <p className="required-text">
              Required fields<span className="required">*</span>
            </p>
            <Formik
              onSubmit={(values) => {
                setLoading(true);

                customerService
                  .addUserToCustomer(parseInt(values.customerId), values.userId)
                  .then(
                    (response) => {
                      setLoading(false);
                      if (response.data) {
                        queryClient.invalidateQueries({ queryKey: [
                          "customerUsers",
                          values.customerId,
                        ]});
                        queryClient.invalidateQueries({queryKey: ['allCustomerUsers']});
                        MySwal.fire({
                          title: "Success",
                          text: "Customer user successfully registered.",
                          icon: "success",
                        });
                      }
                    },
                    (error) => {
                      const resMessage =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();

                      MySwal.fire({
                        title: "Error",
                        text: resMessage,
                        icon: "error",
                        //   button: "Continue",
                      });

                      setLoading(false);
                    }
                  );
              }}
              initialValues={{ customerId: "", userId }}
            >
              {({ setFieldValue }) => (
                <Form>
                  {customers && (
                    <SelectCustomer
                      customers={customers}
                      onChange={(value: number) =>
                        setFieldValue("customerId", value)
                      }
                    />
                  )}
                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-block"
                      disabled={isLoading}
                      type="submit"
                    >
                      Set Customer
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      }
    ></DefaultModal>
  );
}
